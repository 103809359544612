import { autorun, observable, toJS } from "mobx";
import data from "../data/Orders.js";
import Store from "./Store.js";

class KeyOrdersStore extends Store {
  @observable pages = {
    all: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    requested: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    waiting_for_payment: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    paid: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    canceled: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    processing: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    to_be_collected: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    collected: {
      data: null,
      offset: 0,
      count: 0,
      total: 0,
      filters: {
        search: ""
      }
    },
    documents: {
      data: null,
      offset: 0,
      total: 0
    },
    logs: {
      data: null,
      offset: 0,
      total: 0
    },
    orderSummary: {
      data: null,
      offset: 0,
      total: 0
    },
    reports: {
      data: null,
      total: 0,
      offset: 0,
      filters: null
    }
  };

  @observable currentOrder = null;
  @observable currentOrderItem = null;

  constructor() {
    super();
    this.statuses = data.statuses;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/keys/orders`);
  }

  setPage = (page, status = "all") => {
    this.pages[status].offset = page * this.itemsPerPage;

    this.setKeyOrders({
      status: status
    });
  }

  setKeyOrders = (params = {}) => {
    const status = params.status;
    params.offset = this.pages[status].offset;

    return this.setList(null, params)
    .then(response => {
      this.pages[status].data = response.data.data;
      this.pages[status].total = response.data.count;
      this.pages[status].count = response.data.count;
      return response;
    });
  }

  setKeyOrder = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentOrder = response.data;
      return response;
    });
  }

  getCurrentOrder = () => {
    return this.pages.current;
  }

  setCurrentOrder = (item) => {
    this.currentOrder = item;
  }

  resetCurrentOrder = () => {
    this.currentOrder = null;
  }

  addOrder = (values) => {
    return this.addItem(values);
  }

  editOrder = (values) => {
    return this.editItem(values);
  }

  deleteOrder = () => {
    return this.deleteItem(this.pages.current.id)
    .then(response => {
      this.pages.current = null;
      return response;
    });
  }

  // Order Items
  getOrderItemsUrl = () => {
    return `${this.apiUrl}/${this.currentOrder.id}/items`;
  }

  setOrderItems = (params = {}) => {
    params.offset = this.pages.orderSummary.offset;

    return this.setList(this.getOrderItemsUrl(), params)
    .then(response => {                    
      this.pages.orderSummary.data = response.data.data;
      this.pages.orderSummary.total = response.data.count; 
    });
  }

  setCurrentOrderItem = (item) => {
    this.currentOrderItem = item;
  }

  resetCurrentOrderItem = () => {
    this.currentOrderItem = null;
  }

  addOrderItem = (values) => {
    return this.addItem(values, this.getOrderItemsUrl())
    .then(r => this.setOrderItems());
  }

  editOrderItem = (values) => {
    return this.editItem(values, this.getOrderItemsUrl())
    .then(r => this.setOrderItems());
  }

  deleteOrderItem = () => {
    return this.deleteItem(this.currentOrderItem.id, this.getOrderItemsUrl())
    .then(r => this.setOrderItems());
  }

  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.reports.filters = params;
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count;
    });
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl.replace("/keys/orders", "")}/documents/keyorder/${this.currentOrder.id}`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }

  // Other
  prepareCounters = () => {
    Object.keys(this.counters).forEach(key => {
      const status = key.replace("count_", "");
      if (this.pages[status]) {
        this.pages[status].count = this.counters[key];
      }
    });
  }

  exportOrders = (params = {}) => {
    return this.export(`${this.apiUrl.replace('/keys/orders', '')}/export/keys/orders`, params).then(response => response.data);
  }

  setFilters = (values) => {
    this.pages.reports.filters = values;
  }

  exportKeyOrdersPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/keys/orders", "/export/pdf/keys/orders")
    )
    .finally(this.hideLoader);
  }
}

const store = window.KeyOrdersStore = new KeyOrdersStore;

export default store;

autorun(() => {
  // console.log(store);
});