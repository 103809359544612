import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  types: {
    private: t("Private"),
    common: t("Common")
  },
  statuses: {
    all: t("All"),
    open: t("Open"),
    in_dispute: t("Under Review"),
    rejected: t("Rejected"),
    approved: t("Approved"),
    sent: t("Sent"),
    to_inspect: t("To Inspect"),
    resolved: t("Resolved")
  },
  statusColors: {
    open: "orange",
    in_dispute: "primary-light",
    rejected: "danger",
    approved: "dark-primary",
    sent: "primary",
    to_inspect: "purple",
    resolved: "success",
  },
  defectDetailLables: {
    number: t("Defect Number"),
    entity_name: t("Building"),
    sub_entity_name: t("Apartment"),
    user_name: t("Addressed to"),
    user_role: t("Account Type"),
    user_phone: t("Mobile Number"),
    type: t("Common or Private"),
    location_name: t("Location"),
    category_name: t("Category"),
    contractor_name: t("Allocated Contractor"),
    is_urgent: t("Priority"),
    est_complition_date: t("Estimated Completion"),
    date_created: t("Date Created"),
    staff_name: t("Created By"),
    description: t("Description")
  }
}

export default data;