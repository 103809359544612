import { translate } from "../helpers/Helper";

const t = translate;
const data = {
	categories: {
    amenities: t("Amenities"),
    car_parking: t("Car parking"),
    cleanliness: t("Cleanliness"),
    common_area: t("Common Area"),
    deliveries: t("Deliveries"),
    defects: t("Defects"),
    exhibitions: t("Exhibitions"),
    facilities: t("Facilities"),
    festivals: t("Festivals"),
    films: t("Films"),
    food_wine: t("Food & Wine"),
    kids_family: t("Kids & Family"),
    maintenance: t("Maintenance"),
    major_events: t("Major Events"),
    markets: t("Markets"),
    music: t("Music"),
    perfoming_arts: t("Perfoming Arts"),
    security: t("Security"),
    services: t("Services"),
    sports: t("Sports"),
    tours: t("Tours"),
    other: t("Other")
  },
  
  notificationTypes: {
    member_approved: {
      title: t("Account approved"),
      type: t("Member Registrations")
    },
    member_declined: {
      title: t("Account declined"),
      type: t("Member Registrations")
    },
    eol_30: {
      title: t("End of Lease - 1 month before end of lease"),
      type: t("End of lease - 1"),
      isResedential: true
    },
    eol_7: {
      title: t("End of Lease - 1 week before end of lease"),
      type: t("End of lease - 2"),
      isResedential: true
    },
    eol_1: {
      title: t("End of Lease - 1 day before end of lease"),
      type: t("End of lease - 3"),
      isResedential: true
    },
    bookings_approved: {
      title: t("Booking approved"),
      type: t("Facility bookings")
    },
    bookings_declined: {
      title: t("Booking declined"),
      type: t("Facility bookings")
    },
    bookings_canceled: {
      title: t("Booking canceled"),
      type: t("Facility bookings")
    },
    help_desk_resolved: {
      title: t("Help Desk Resolved"),
      type: t("Help Desk Tickets")
    },
    delivery: {
      title: t("Delivery Notification"),
      type: t("Parcels & Deliveries")
    },
    event: {
      title: t("Event Calendar - RSVP"),
      type: t("Event Calendar with RSVP")
    }
  },
  htmlTemplateBeforeTitle: '<!DOCTYPEhtmlPUBLIC"-//W3C//DTDXHTML1.0Transitional//EN""http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><htmlxmlns="http://www.w3.org/1999/xhtml"xmlns:v="urn:schemas-microsoft-com:vml"xmlns:o="urn:schemas-microsoft-com:office:office"><head><!--[ifgtemso9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]--><metahttp-equiv="Content-type"content="text/html;charset=utf-8"/><metaname="viewport"content="width=device-width,initial-scale=1,maximum-scale=1"/><metahttp-equiv="X-UA-Compatible"content="IE=edge"/><metaname="format-detection"content="date=no"/><metaname="format-detection"content="address=no"/><metaname="format-detection"content="telephone=no"/><metaname="x-apple-disable-message-reformatting"/><!--[if!mso]><!--><linkhref="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap"rel="stylesheet"><!--<![endif]--><title>',
  htmlTemplateAfterTitle: '</title><!--[ifgtemso9]><styletype="text/css"media="all">sup{font-size:100%!important;}</style><![endif]--></head><bodyclass="body"style="padding:0!important;margin:0!important;display:block!important;min-width:100%!important;width:100%!important;-webkit-text-size-adjust:none;"><tablewidth="100%"border="0"cellspacing="0"cellpadding="0"><tr><tdalign="center"valign="top"class="container"><!--Container--><tablewidth="100%"border="0"cellspacing="0"cellpadding="0"><tr><tdalign="center"><tablewidth="650"border="0"cellspacing="0"cellpadding="0"class="mobile-shell"><tr><tdclass="td"style="width:650px;min-width:650px;font-size:0pt;line-height:0pt;padding:0;margin:0;font-weight:normal;"><!--Intro--><tablewidth="100%"border="0"cellspacing="0"cellpadding="0"bgcolor="#ffffff"><tr><tdclass="p30-15"><tablewidth="100%"border="0"cellspacing="0"cellpadding="0"><tr><tdstyle="font-family: OpenSans,Arial,sans-serif;font-size:14px;line-height:21px;text-align:left;color:#000;">',
  htmlTemplateEnd: '</td></tr></table></td></tr></table><!--ENDIntro--></td></tr></table></td></tr></table><!--ENDContainer--></td></tr></table></body></html>'
};

export default data;