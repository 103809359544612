import { autorun, observable, toJS } from "mobx";
import data from "../data/Bookings.js";
import Store from "./Store";
import moment from "moment";

class BookingStore extends Store {
  @observable pages = {
    "all": {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    "pending": {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    "approved": {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    "declined": {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    "timeslots": {
      data: null,
      offset: 0,
      total: 0,
      total_attendees: 0,
      filterValues: {
        date: new Date(),
        facility: ""
      }
    },
    "logs": {
      data: null,
      offset: 0,
      total: 0,
      count_attendees: 0,
      filterValues: {
        facility_id: 0,
        date_start: null,
        date_end: null
      }
    },
    "counters": null,
    "modalBookings": {
      data: null,
      offset: 0,
      total: 0
    }
  };
  @observable calendarEvents = [];
  @observable bookedTime = [];
  @observable currentBooking = null;
  @observable currentTimeslot = null;
  @observable reservedBookingsCounters = {};
  @observable filterValues = {
    "all": {
      facility_id: 0,
      search: ""
    },
    "pending": {
      facility_id: 0,
      search: ""
    },
    "approved": {
      facility_id: 0,
      search: ""
    },
    "declined": {
      facility_id: 0,
      search: ""
    }
  };

  constructor() {
    super();
    this.statuses = data.statuses;
    this.statusColors = data.statusColors;
    this.addBookingUrl = null;
  }

  setData = (clientId, projectId, token, isScheduledBookings = false, timeslotId = 0) => {
    if (!isScheduledBookings) {
      this.setStoreData(token, `clients/${clientId}/projects/${projectId}/bookings`);
    } else {
      this.setStoreData(token, `clients/${clientId}/projects/${projectId}/sbookings/timeslots/${timeslotId}/bookings`);
      this.addBookingUrl = `${this.url}clients/${clientId}/projects/${projectId}/sbookings/bookings`
    }
  }

  setPage = (page, type = null) => {
    const offset = page * this.itemsPerPage;
    this.pages[type].offset = offset;

    if (!type) {
      this.setAllBookings();
    } else {
      let params = {
        status: type,
        offset: offset
      };

      if (this.filterValues[type].facility_id) {
        params.facility_id = this.filterValues[type].facility_id;
      }

      if (this.filterValues[type].search != "") {
        params.search = this.filterValues[type].search;
      }      

      this.setBookings(params);
    }
  }

  setAllBookings = () => {
    let promises = [];
    const requests = [ "all", "pending", "approved", "declined" ];

    requests.forEach(status => {
      promises.push( this.setBookings({
        status: status,
        offset: this.pages[status].offset
      }) );
    });

    return this.all(promises);
  }

  setBookings = (params, isCalendar = false) => {
    return this.setList(null, params)
    .then(response => {
      this.pages[params.status].data = response.data.data;
      this.pages[params.status].total = response.data.count;
      this.pages[params.status].count = response.data.count;

      if (isCalendar) {
        this.calendarEvents = this.prepareEvents(response.data.data);
      }

      return response;
    });
  }

  setBooking = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentBooking = response.data;
      return response;
    });
  }

  getCurrentBooking = () => {
    return toJS(this.currentBooking);
  }

  setCurrentBooking = (item) => {
    this.currentBooking = item;
  }

  resetCurrentBooking = () => {
    this.currentBooking = null;
  }

  addBooking = (values) => {
    return this.addItem(values);
  }

  editBooking = (values) => {
    return this.editItem(values);
  }

  // Calendar
  getCalendarEvents = () => {
    return toJS(this.calendarEvents);
  }
  
  prepareEvents = (data) => {
    let events = [];
    data.forEach(booking => {
      let event = booking;
      let start = moment(booking.date_start).format('HH:mm');
      event.start = booking.date_start;
      event.end = booking.date_end;
      event.color = this.statusColors[booking.status];
      event.title = `${start} ${booking.facility_name} - ${booking.user_name}`;
      event.comment = booking.private_comment;

      if (event.status != "canceled") {
        events.push(event);
      }
    });
    
    return events;
  }

  prepareReservedEvents = (data) => {
    let events = [];
    data.forEach(booking => {
      let event = booking;
      let start = booking.time_start;
      event.start = booking.date_start;
      event.end = booking.date_end;
      event.color = this.statusColors[booking.status];
      event.title = `${start} ${booking.facility_name} - ${booking.user_name}`;
      event.comment = booking.private_comment;

      if (event.status != "canceled") {
        events.push(event);
      }
    });
    
    return events;
  }

  setBookedTime = (data) => {
    let times = [];
    const timeFormat = 'HH:mm';

    if (data) {
      data.forEach(booking => {
        if (booking.status == "canceled" || booking.status == "declined") {
          return;
        }

        let start = moment(booking.date_start);
        let end = moment(booking.date_end);
        if (start.format(timeFormat) == end.format(timeFormat)) {
          times.push(start.format(timeFormat));
        }

        for (let i = start; i.format(timeFormat) != end.format(timeFormat); i.add(30, 'minutes')) {
          times.push(i.format(timeFormat));
        }                
      });
    }
    this.bookedTime = times;
    return times;
  }

  getBookedTime = () => {
    return toJS(this.bookedTime);
  }

  resetBookedTime = () => {
    this.bookedTime = [];
  }

  isTimeWasBooked = (time, duration, time_end) => {
    let result = false;
    const timeFormat = "HH:mm";
    const bookedTime = toJS(this.bookedTime),
    start = moment(time, timeFormat),
    end = moment(time, timeFormat).add(duration * 1 - 1, 'minutes');
    const lastTime = moment(time_end, 'HH:mm:ss').format(timeFormat);

    bookedTime.forEach(item => {
      if (moment(item, timeFormat).isBetween(start, end, 'minutes', '[]')) {
        result = true;
      }
    });

    if (moment(lastTime, timeFormat).isBefore(moment(end, timeFormat))) {
      return true;
    }

    return result;
  }

  // Reserved Bookings
  getSBookingsUrl = () => {
    return `${this.apiUrl.replace("/bookings", "")}/sbookings`;
  }

  setTimeslotPage = (page, options) => {
    const offset = page * this.itemsPerPage;
    this.pages.timeslots.offset = offset;
    this.setTimeSlots(options);
  }

  setTimeSlots = (sortObj = null) => {
    const url = `${this.getSBookingsUrl()}/timeslots`;
    return this.setList(url, sortObj)
    .then(response => {
      this.pages.timeslots.data = response.data.data;
      this.pages.timeslots.total = response.data.count;
      this.pages.timeslots.total_attendees = response.data.count_attendees;
    });
  }

  setTimeSlotById = (id) => {
    const url = this.apiUrl.replace(`/${id}/bookings`, "");
    
    return this.setItem(id, url)
    .then(response => {
      this.currentTimeslot = response.data;
    });
  }

  addSbookings = (values) => {
    const url = `${this.getSBookingsUrl()}/bookings`;
    return this.addItem(values, !this.addBookingUrl ? url : this.addBookingUrl);
  }

  editSBooking = (item) => {
    return this.editItem(`${this.apiUrl}/${item.id}`, item);
  }

  setBookingCounters = (params = {}) => {
    const url = `${this.apiUrl.replace("/bookings", "")}/counters/bookings`;
    return this.setList(url, params)
    .then(response => {
      this.pages.counters = response.data;
    });
  }

  // Reserved bookings log
  getLogs = () => {
    return this.pages.logs.data ? toJS(this.pages.logs.data) : [];
  }

  setLogsPage = (page, params = {}) => {
    const offset = page * this.itemsPerPage;
    this.pages.logs.offset = offset;
    params.limit = this.itemsPerPage;

    return this.setLogs(params, false);
  }

  setLogs = (params = {}, isScheduledBookings = true) => {
    params.offset = this.pages.logs.offset;

    const url = isScheduledBookings ? this.getSBookingsUrl() + "/bookings" : this.apiUrl;
    return this.setList(url, params)
    .then(response => {
      this.pages.logs.data = response.data.data;
      this.pages.logs.total = response.data.count;
      this.pages.logs.count_attendees = response.data.count_attendees || 0;
      return response;
    });
  }

  setScheduledCounters = () => {
    const url = `${this.apiUrl.replace("/bookings", "")}/counters/sbookings`;
    return this.setList(url)
    .then(response => {
      this.pages.counters = response.data;
    });
  }

  setLogFilters = (values) => {
    this.pages.logs.filterValues = values;
  }

  // Other  
  exportBookings = () => {
    return this.setList(`${this.apiUrl.replace('/bookings', '')}/export/bookings`, {})
    .then(response => {
      return response.data;
    });
  }

  setReservedBookingCounters = (clientId = null, projectId = null) => {
    const clientUrl = clientId ? `clients/${clientId}` : "";
    const projectUrl = projectId ? `/projects/${projectId}` : "";

    return this.setList(`${this.url}${clientUrl}${projectUrl}/counters/sbookings`)
    .then(response => {
      this.reservedBookingsCounters = response.data;
    })
    .catch(e => console.error(e));
  }
 
  exportBookings = (params = {}) => {
    return this.export(`${this.apiUrl.replace('/bookings', '')}/export/bookings`, params)
    .then(response => response.data);
  }

  exportBookingPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/bookings", "/export/pdf/bookings")
    )
    .finally(this.hideLoader);
  }
}

const store = window.bookingStore = new BookingStore;

export default store;

autorun(() => {
    // console.log(store);
});