import { autorun, observable } from "mobx";
import data from '../data/SurveysPolls.js';
import newsData from '../data/News.js';
import Store from "./Store.js";
 
class SurveysPollsStore extends Store {
  @observable pages = {
    all: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    scheduled: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    active: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    featured: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    archived: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    draft: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
  };
  @observable currentItem = null;
  @observable categoryCount = 1;
  @observable chozenCategoryesIds = [];
  @observable categoriesValues = [];
  @observable deletePostList = [];
  @observable statusCounters = null;
 
  constructor() {
    super();
    this.types = data.types;
    this.categories = data.categories;
    this.internal_link = newsData.internal_link;
    this.commercial_links = newsData.commercial_links;
  }
 
  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/surveys`);
  }
 
  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    this.setSurveys({ status: type });
  }
 
  setSurveys = (params = {}) => {
    let type = params.status ? params.status : "all";
    params.offset = this.pages[type].offset;
 
    if (params.status == "featured") {
      type = "featured";
      params.is_featured = "1";
    }

    if (params.status == "draft") {
      type = "draft";
    }

    return this.setList(null, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
      this.pages[type].count = response.data.count;
      return response;
    })
    .then(response => {
      this.setStatusCounters();
      return response;
    });
  }
 
  setCurrentItem = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentItem = response.data;
    });
  }
 
  resetCurrentItem = () => {
    this.currentItem = null;
  }
 
  addPost = (values) => {
    return this.addItem(values);
  }
 
  editPost = (values) => {
    return this.editItem(values);
  }

  archivePost = (values) => {
    return this.addItem(values, `${this.apiUrl}/${this.currentItem.id}/archive`);
  }
 
  deletePost = (id) => {
    return this.deleteItem(id)
    .then(r => {
      this.currentItem = null;
    });
  }
 
  deletePosts = (list) => {
    let promises = [];
 
    list.forEach(item => {
      const id = item.replace("item-", "");
      promises.push(this.deletePost(id));
    });
 
    return this.all(promises);
  }
  
  // Other
  addToDeleteList = (list) => {
    this.deletePostList = list;
  }

  setStatusCounters = () => {
    return this.setList(`${this.apiUrl.replace("surveys", "counters/surveys")}`)
    .then(response => {
      const data = response.data;
      Object.keys(data).forEach(key => {
        if (key.indexOf("total") != -1) {
          this.pages.all.total = data[key];
        } else {
          this.pages[key.replace("count_", "")].total = data[key];
        }
      });

      this.statusCounters = response.data;
    });
  }
}
 
const store = window.SurveysPollsStore = new SurveysPollsStore;
 
export default store;
 
autorun(() => {
    // console.log(store);
});