import { autorun, observable, toJS } from "mobx";
import { translate } from "../helpers/Helper";
import data from "../data/Staff.js";
import Store from "./Store";

class StaffStore extends Store {
  @observable staffList = null;
  @observable currentStaff = null;
  @observable clientId = 0;
  @observable pageOffset = 0;
  @observable totalItems = 0;
  @observable currentRestriction = null;

  constructor() {
    super();
    this.t = translate;
    this.countries = data.countries;

    this.regions = {
      oceania: this.t("Oceania"),
      asia: this.t("Asia"), 
      europe: this.t("Europe"), 
      north_america: this.t("North America"), 
      south_america: this.t("South America"),  
      africa: this.t("Africa")
    };

    this.roles = {
      client: this.t("Client"),
      manager: this.t("Manager"),
      project_manager: this.t("Project Manager"),
      staff: this.t("Staff")
    };
  }

  setData = (id, token) => {
    this.setStoreData(token, `clients/${id}/staff`);
    this.clientId = id;
  }

  setPage = (page, clientId) => {
    this.pageOffset = page * this.itemsPerPage;
    this.setStaffList({ client_id: clientId });
  }

  setStaffList = (params = {}) => {
    params.offset = this.pageOffset;

    return this.setList(this.apiUrl, params)
    .then(response => {
      this.totalItems = response.data.count;
      this.staffList = response.data.data;
    });
  }

  setStaffById = (id) => {
    return this.setItem(id, this.apiUrl)
    .then(response => {
      this.currentStaff = response.data;
    });
  }

  setCurrentStaff = (staff) => {
    this.currentStaff = staff;
  }

  getCurrentStaff = () => {
    return toJS(this.currentStaff);
  }

  resetCurrentStaff = () => {
    this.currentStaff = null;
  }

  addStaff = (values) => {
    return this.addItem(values)
    .then(response => {
      this.setStaffList();
      return response;
    });
  }

  editStaff = (values) => {
    return this.editItem(values)
    .then(response => {
      this.setStaffList();
      this.currentStaff = response.data;
    });
  }

  deleteCurrentStaff = () => {
    return this.deleteItem(this.currentStaff.id)
    .then(r => {
      this.currentStaff = null;
      return this.setStaffList();
    });
  }

  // Restrictions
  getRestrictionList = () => {
    const restrictionList = [];

    if (this.currentStaff && this.currentStaff.access_restrictions) {
      this.currentStaff.access_restrictions.forEach(item => {
        restrictionList.push({ feature: item });
      });
    }

    return restrictionList;
  }

  setCurrentRestriction = (item) => {
    this.currentRestriction = item;
  }

  deleteRestriction = () => {
    let restrictions = toJS(this.currentStaff.access_restrictions);

    if (restrictions && this.currentRestriction) {
      const index = restrictions.indexOf(this.currentRestriction.feature);
      restrictions.splice(index, 1);
      
      return this.editStaff({ 
        id: this.currentStaff.id,
        access_restrictions: restrictions.length > 0 ? restrictions : ""
      });
    }
  }
}

const store = window.StaffStore = new StaffStore;

export default store;

autorun(() => {
    // console.log(store);
});