import { autorun, observable, toJS } from "mobx";
import Store from "./Store";

class ActivityLogsStore extends Store {
  @observable pages = {
    logs: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    clients: {
      data: null
    }
  };

  constructor() {
    super();
  }

  setData = (clientId = null, projectId = null, staffId = null, token) => {
    let url = "logs";

    if (staffId) {
      url = `logs/staff/${staffId}`;
    }
    if (clientId) {
      url = `logs/clients/${clientId}`;
    }
    if (clientId && projectId) {
      url = `logs/clients/${clientId}/projects/${projectId}`;
    }

    this.setStoreData(token, url);
  }

  setPage = (page, type = "logs", callback = "setLogs", params = {}) => {
    let data = this.pages[type];
    
    data.offset = page * this.itemsPerPage;
    this[callback](params); 
  }

  setLogs = (params = {}) => {
    params.offset = this.pages.logs.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.logs.data = response.data.data;
      this.pages.logs.total = response.data.count;
    });
  }

  // Clients mapping
  setClients = (params = { limit: 500 }) => {
    const url = `${this.url}logs/mapping`;

    return this.setList(url, params)
    .then(response =>{
      this.pages.clients.data = response.data;
    });
  }
}

const store = window.ActivityLogsStore = new ActivityLogsStore;

export default store;

autorun(() => {
    // console.log(store);
});