import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  stageTypes: {
    "townhouses": t("Townhouses"),
    "house_land": t("House and land"),
    "apartments": t("Apartments"),
    "other": t("Other")
  }
}

export default data;