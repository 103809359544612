import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  assetType: {
    "equipment": t("Equipment Essential Services"),
    "equipment_non_essential": t("Equipment Non Essential Servces"),
    "other_essential": t("Other Essential Services"),
    "other_non_essential": t("Other Non Essential Servces")
  },
  categories: {
    "amenities": t("Amenities"), 
    "bmu": t("BMU"),
    "cctv": t("CCTV"),
    "deodorising": t("Deodorising"),
    "doors": t("Doors"),
    "decking": t("Decking"),
    "electrical": t("Electrical"),
    "facade": t("Facade"),
    "finishes": t("Finishes"),
    "fixtures": t("Fixtures"),
    "fire": t("Fire"),
    "first_aid": t("First Aid"),
    "flooring": t("Flooring"),
    "furniture": t("Furniture"),
    "garage_doors": t("Garage Doors"),
    "general_maintenance": t("General Maintenance"),
    "gymnasium": t("Gymnasium"),
    "hydraulic": t("Hydraulic"),
    "plumbing_drainage": t("Plumbing & Drainage"),
    "lighting": t("Lighting"),
    "landscaping": t("Landscaping"),
    "lifts": t("Lifts"),
    "matv": t("MATV"),
    "mechanical_hvac": t("Mechanical/HVAC"),
    "pool_spa": t("Pool/SPA"),
    "pest_control": t("Pest Control"),
    "sauna": t("Sauna"),
    "steam": t("Steam"),
    "office": t("Office"),
    "safety_certification": t("Safety & Certification"),
    "security": t("Security"),
    "signage": t("Signage"),
    "waste_management": t("Waste Management"),
    "other": t("Other")
  },
  statuses: {
    "live": t("Live"),
    "out_of_order": t("Out of Order"),
    "under_repair": t("Under Repair"),
    "needs_repair": t("Needs Repair"),
    "replace": t("Replace")
  },
  recurrenceType: {
    weekly: t("Weekly"),
    fortnightly: t("Fortnightly"),
    monthly: t("Monthly"),
    quaterly: t("Quaterly"),
    months_6: t("6 months"),
    annually: t("Annually"),
    bi_annually: t("Bi Annually"),
    years_3: t("3 years"),
    years_5: t("5 years"),
    custom: t("Long Term/Custom")
  }
}

export default data;