import { autorun, observable, toJS } from "mobx";
import posthog from "posthog-js";
import Helper from "../helpers/Helper";
import settings from "../data/Settings.json";
import Store from "./Store";

/**
 * Auth urls. The list of urls intended for authorization and authentication
 */
const authUrls = [
  "login", "signup", "/forgot-password", "/change-password", 
  "/verify", "/verify/", "/verify/email_prima", "/verify/email_collins", "/verify/email_kindred", "/verify/email_brisbane_quarter",
  "/2fa"
];

/**
 * Authorization store. Contains main logic for authorization and authentication users.
 */
class AuthStore extends Store {
  @observable isAuth = false;
  /*
   * User Model
   * {number} id - User identification number
   * {string} avatar - User image
   * {string} first_name - Name of the user
   * {number} clientId - If the user belong of client. The user is admin if the field is set 0.
   * {number} projectId - If the user belong of project
   * {number} business_id - If the user belong of business
   * {string} role - Role of the user
   * {string} language - Language of the user
   */
  @observable user = null;
  @observable token = null;
  @observable showVerificationMessage = false;
  @observable showVerificationError = false;
  @observable twoFAData = null;

  constructor() {
    super();
    this.getCookie = Helper.getCookie;
    this.setCoockie = Helper.setCoockie;
    this.intercomId = settings.intercomId || "dvuhe1xg";
  }

  /**
   * Method for init store data
   * @returns 
   */
  setData = () => {
    this.setStoreData(this.token, "");
  }

  /**
   * Method for checking if user was authorized. Also this method destined for redirection to login page if user don't authorized or client list page if user authorized
   * @param location 
   * @returns Object
   */
  checkToken = (location) => {
    const token = this.getCookie("access_token"),
      name = this.getCookie("userName"),
      id = this.getCookie("userId"),
      avatar = this.getCookie("avatar"),
      clientId = this.getCookie('clientId'),
      projectId = this.getCookie('projectId'),
      role = this.getCookie("role"),
      business_id = this.getCookie("business_id");

    if (token && token.length > 0) {
      this.token = token;
      this.user = {
        id: id,
        first_name: name,
        avatar: avatar,
        client_id: clientId,
        project_id: projectId,
        role: role ? role : "",
        business_id: business_id ? business_id : ""
      };
      this.setStoreData(token, "");
      
      // Tried to get user data
      return this.setUser()
      .then(r => {
        // if authorization was success redirect user for clients page
        authUrls.forEach(url => {
          if (window.location.pathname.indexOf(url) != -1) {
            window.appHistory.push('/clients');
          }
        });
      })
      .catch(error => {
        // if something went wrong redirect to login page
        if (this.token && error.response && error.response.status == 401) {
          this.token = null;
          this.user = null;
          this.deleteAllCookies();
          window.appHistory.push('/login');
        }
      })
    } else if (authUrls.indexOf(location.pathname) == -1) { 
      // if havn't token and we are now in not auth pages then redirect to login
      window.appHistory.push('/login');
    }    
  }

  /**
   * Method for setting coockies
   * @param user 
   * @returns
   */
  setCoockies = (user) => {
    const expires = 1*12*60*60; // 12 hours
    this.setCoockie("userName", user.first_name, { expires: expires });
    this.setCoockie("userId", user.id, { expires: expires });
    this.setCoockie("avatar", user.avatar, { expires: expires });
    this.setCoockie("clientId", user.client_id, { expires: expires });
    this.setCoockie("projectId", user.project_id, { expires: expires });
    this.setCoockie("language", user.language, { expires: expires });

    if (user.role) {
      this.setCoockie("role", user.role, { expires: expires });
    }

    if (user.business_id) {
      this.setCoockie("business_id", user.business_id, { expires: expires });
    }
  }

  /**
   * Method for deleting cookie
   * @param name 
   * @returns
   */
  deleteCookie(name) {
    this.setCoockie(name, "", {
      expires: -1
    });
  }

  /**
   * Method for deleting all cookies
   * @returns
   */
  deleteAllCookies = () => {
    this.deleteCookie("userName");
    this.deleteCookie("access_token");
    this.deleteCookie("userId");
    this.deleteCookie("avatar");
    this.deleteCookie("clientId");
    this.deleteCookie("projectId");
    this.deleteCookie("role");
    this.deleteCookie("language");
    this.deleteCookie("projectLang");
    this.deleteCookie("business_id");
  }

  getToken = (values = {}) => {
    const params = {
      email: values.email,
      password: values.password,
      client: "client_web"
    };

    return this.addItem(params, `${this.url}token`)
    .then(response => {
      this.twoFAData = params;
      return response;
    });
  }

  /**
   * Method for user login
   * @param values - the data of user 
   * @returns
   */
  login = (params = {}) => {
    const ignoreList = [
      "tenant", "owner", "landlord", "property_manager"
    ];

    return this.addItem(params, this.url + "token/otp")
    .then(response => {
      if (ignoreList.indexOf(response.data.user.role) == -1) {
        this.token = response.data.access_token;
        this.user = response.data.user;

        if (params.remember) {
          this.setCoockies(this.user);
          this.setCoockie("access_token", this.token, { expires: 12*60*60 }); // 12 hours
          posthog.identify(
            this.user.id,
            {
              email: this.user.email,
              name: `${this.user.first_name} ${this.user.last_name}`,
              clientId: this.user.client_id,
              projectId: this.user.project_id,
              role: this.user.role
            }
          );
        }
      } else {
        throw Error("Haven't access to login.");
      }

      return response;
    });
  }

  /**
   * Method for user logout
   * @returns
   */
  logOut = () => {
    return this.addItem({ "access_token": this.token }, this.url + "logout")
    .then(response => {
      this.deleteAllCookies();
      return response;
    })
    .then(response => {
      this.token = null;
      this.user = null;
      return response;
    });
  }

  /**
   * Method for reset password of user
   * @param values - the data of user 
   * @returns
   */
  resetPassword = (values) => {
    return this.addItem(values, this.url + "resetPassword");
  }

  /**
   * Method for changing password of user
   * @param values - the data of user 
   * @returns
   */
  changePassword = (values) => {
    return this.addItem(values, this.url + "changePassword");
  }

  // User

  /**
   * Method for setting data of auth user
   * @returns
   */
  setUser = () => {
    return this.setList(this.url + "staff")
    .then(response => {
      this.user = response.data;
      return response;          
    })
    .then(response => {
      if (this.getCookie("userName")) {      
        this.setCoockies(response.data);
      }
    });
  }

  /**
   * Method for getting data of auth user
   * @param values - the data of user 
   * @returns
   */
  getUser = () => {
    return toJS(this.user);
  }

  /**
   * Method for edit data of user
   * @param values - the data of user 
   * @returns
   */
  editUser = (values) => {
    return this.addItem(values, this.url + `staff`)
    .then(response => {
      this.user = response.data;
      this.setCoockies(response.data);
    });
  }

  /**
   * Method for verify email
   * @param values
   * @returns
   */
  verifyEmail = (values) => {
    return this.addItem(values, `${this.url}email/verify`)
    .then(r => {
      this.showVerificationMessage = true;
    });
  }
}

const store = window.AuthStore = new AuthStore;

export default store;

autorun(() => {
    // console.log(store);
});