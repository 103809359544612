import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';
import data from "../data/Companies";
import Helper from "../helpers/Helper";
import Store from "./Store";

class CompanyStore extends Store {
  @observable pages = {
    companies: {
      data: null,
      offset: 0,
      total: 0
    },
    departments: {
      data: null,
      offset: 0,
      total: 0
    },
    documents: {
      data: null,
      offset: 0,
      total: 0
    }
  };
  @observable currentCompany = null;
  @observable currentDepartment = null;
  @observable categoryCount = 1;
  @observable chozenCategoryesIds = [];
  @observable categoriesValues = [];

  constructor() {
    super();
    this.industries = data.industries;
    this.t = Helper.translate;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/companies`);
  }

  setPage = (page, type = "companies", settings = {}) => {
    this.pages[type].offset = page * this.itemsPerPage;

    if (type == "companies") {
      return this.setCompanies(settings);
    } else if (type == "departments") {
      return this.setDepartments(settings);
    }
  }

  setCompanies = (params = {}) => {
    let data = {
      offset: this.pages.companies.offset
    };

    return this.setList(null, params ? Object.assign(data, params) : data)
    .then(response => {
      this.pages.companies.data = response.data.data;
      this.pages.companies.total = response.data.count;
    });
  }

  setCompany = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentCompany = response.data;
      return response;
    });
  }

  setCurrentCompany = (company) => {
    this.currentCompany = company;
  }

  getCurrentCompany = () => {
    return toJS(this.currentCompany);
  }

  resetCurrentCompany = () => {
    this.currentCompany = null;
  }

  addCompany = (values) => {
    return this.addItem(values)
    .then(r => this.setCompanies());
  }

  editCompany = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentCompany = response.data;
      return this.setCompany(this.currentCompany.id);
    });
  }

  deleteCompany = () => {
    return this.deleteItem(this.currentCompany.id)
    .then(r => {
      this.currentCompany = null;
      return this.setCompanies();
    });
  }

  searchCompanies = (value) => {
    return this.setCompanies({ 
      search: value,
      limit: 100
    });
  }

  // Departments
  getDepartmentUrl = () => {
    return `${this.apiUrl.replace("/companies", "")}/departments`;
  }

  setDepartments = (params = {}) => {
    params.offset = this.pages.departments.offset;
    
    return this.setList(this.getDepartmentUrl(), params)
    .then(response => {
      this.pages.departments.data = response.data.data;
      this.pages.departments.total = response.data.count;
      return response;
    });
  }

  setDepartmentById = (id) => {
    return this.setItem(id, this.getDepartmentUrl())
    .then(response => {
      this.currentDepartment = response.data;      
      return response;
    });
  }

  getDepartments = () => {
    return toJS(this.pages.departments.data);
  }

  getCurrentDepartment = () => {
    return toJS(this.currentDepartment);
  }

  setCurrentDepartment = (department) => {
    this.currentDepartment = department;
  }

  resetCurrentDepartment = () => {
    this.currentDepartment = null;
  }

  addDepartment = (values) => {
    return this.addItem( values, this.getDepartmentUrl())
    .then(r => this.setDepartments());
  }

  editDepartment = (values) => {
    return this.editItem(values, this.getDepartmentUrl())
    .then(response => {
      this.currentDepartment = response.data;
      return this.setDepartments();
    });
  }

  deleteDepartment = () => {
    return this.deleteItem(this.currentDepartment.id, this.getDepartmentUrl())
    .then(r => {
      this.currentDepartment = null;
    });
  }

  searchDepartments = (value, entityId = null) => {
    return this.setList(this.getDepartmentUrl(), { 
      search: value,
      entity_id: entityId,
      limit: 100
    })
    .then(response => {
      this.pages.departments.data = response.data.data;
      return response;
    });
  }

  // Department categories
  setCategoryData = (count, ids, values) => {
    this.categoryCount = count;
    this.chozenCategoryesIds = ids;
    this.categoriesValues = values;
  }

  setCategoryValue = (value, index) => {
    if (this.categoriesValues.indexOf(value) == -1) {
      this.categoriesValues[index] = value;
    } else {
      NotificationManager.error(this.t("This category was choosen before."));
    }
  }

  addCategory = () => {
    let count = this.categoryCount;
    if (this.categoriesValues[count - 1]) {
        this.categoryCount = ++count;
    } else {
        NotificationManager.error(this.t("Please select category."));
    }
  }

  assignCategories = (values) => {
    return this.addItem(values, `${this.apiUrl.replace("/companies", "")}/bulk-categories-sub-entities`);
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl.replace("/companies", "")}/documents/departments/${this.currentDepartment.id}`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }
}

const store = window.CompanyStore = new CompanyStore;

export default store;

autorun(() => {
    // console.log(store);
});