import { autorun, observable, toJS } from "mobx";
import data from "../data/Assets.js";
import Store from "./Store";
import axios from "axios";

class AssetsStore extends Store  {
  @observable pages = {
    documents: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    assets: {
      all: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {
          search: "",
          category: null
        }
      },
      live: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {
          search: "",
          category: null
        }
      },
      out_of_order: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {
          search: "",
          category: null
        }
      },
      under_repair: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {
          search: "",
          category: null
        }
      },
      needs_repair: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {
          search: "",
          category: null
        }
      },
      replace: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {
          search: "",
          category: null
        }
      },
      reports: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {
          date_start: null,
          date_end: null
        }
      }
    },
  }
  @observable assets = null;
  @observable currentAsset = null;
  @observable category = null;
  @observable maintenanceList = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable currentMaintenance = null;
  @observable contractorList = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable currentContractor = null;

  constructor() {
    super();
    this.assetType = data.assetType;
    this.categories = data.categories;
    this.statuses = data.statuses;
    this.recurrenceType = data.recurrenceType;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/assets`);
  }

  setPage = (page, status, type = "") => {
    if (type == "maintenance") {
      this.maintenanceList.offset = page * this.itemsPerPage;
      this.setMaintenance();
    } else if (type == "contractor") {
      this.contractorList.offset = page * this.itemsPerPage;
      this.setContractors();
    } else {
      const filters = this.pages.assets[status].filterValues;
      this.pages.assets[status].offset = page * this.itemsPerPage;
      this.setAssets(status, filters);
    }
  }

  // Assets
  setAllAssets = () => {
    const statuses = ["all", "live", "out_of_order", "under_repair", "needs_repair", "replace"];        
    let promises = [];

    statuses.forEach(status => {
      promises.push(this.setAssets(status));
    });

    return axios.all(promises);
  }

  setAssets = (status, sortObj = null) => {
    let params = { 
      status: status != "all" ? status : "",
      offset: this.pages.assets[status].offset
    };
    let data = sortObj ? Object.assign(params, sortObj) : params;

    return this.setList(null, data)
    .then(response => {
      this.pages.assets[status].data = response.data.data;
      this.pages.assets[status].total = response.data.count;
    });
  }

  setAsset = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentAsset = response.data;
      return this.setDocuments();
    });
  }

  getAssets = (status) => {
    return toJS(this.pages.assets[status || "all"].data);
  }

  getAsset = () => {
    return toJS(this.currentAsset);
  }

  setCurrentAsset = (asset) => {
    this.currentAsset = asset;
  }

  resetCurrentAsset = () => {
    this.currentAsset = null;
  }

  addAsset = (asset) => {
    return this.addItem(asset)
    .then(response => {
      this.setAllAssets();
      return response;
    });
  }

  editAsset = (asset) => {
    return this.editItem(asset)
    .then(response => {
      this.currentAsset = response.data;
      this.setAllAssets();
      return response;
    });
  }

  deleteAsset = () => {
    return this.deleteItem(this.currentAsset.id)
    .then(r => {
      this.currentAsset = null;
      return this.setAllAssets();
    });
  }

  searchAssets = (value) => {
    return this.setList(this.apiUrl, { search: value })
    .then(response => {
      this.assets = response.data.data;
    });
  }

  sortAssets = (field, order, status) => {
    return this.setAssets(status, {
      offset: 0,
      order: order,
      sort: field
    });
  }

  getAssetIndex = (currentAsset) => {
    let currentAssetIndex = null;

    this.assets.forEach((asset, index) => {
      if (currentAsset.id == asset.id) {
        currentAssetIndex = index;
      }
    });

    return currentAssetIndex;
  }

  setFilter = (status, field, value) => {
    this.pages.assets[status].filterValues[field] = value;
  }

  uploadCSV = (values) => {
    const url = `${this.apiUrl.replace("/assets", "")}/import/assets`;

    return this.addItem(values, url)
    .then(response => {
      this.setAllAssets();
      return response;
    });
  }
    
  // Documents
  getDocumentUrl = () => {
    return `${this.apiUrl}/${this.currentAsset.id}/documents`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;
    
    return this.setList(this.getDocumentUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.pages.documents.current = document;
  }

  resetCurrentDocument = () => {
    this.pages.documents.current = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentUrl())
    .then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.pages.documents.current.id, this.getDocumentUrl())
    .then(r => {
      this.pages.documents.current = null;
      return this.setDocuments();
    });
  }

  // Maintenance Recurrence
  getMaintenanceUrl = () => {
    return `${this.apiUrl}/${this.currentAsset.id}/maintenance`;
  }

  setMaintenance = () => {
    return this.setList(this.getMaintenanceUrl(), {
      offset: this.maintenanceList.offset
    })
    .then(response => {
      this.maintenanceList.data = response.data.data;
      this.maintenanceList.total = response.data.count;
    });
  }
  
  setCurrentMaintenance = (maintenance) => {
    this.currentMaintenance = maintenance;
  }

  resetCurrentMaintenance = () => {
    this.currentMaintenance = null;
  }

  addMaintenance = (values) => {
    return this.addItem(values, this.getMaintenanceUrl())
    .then(response => {
      this.setMaintenance();
      return response;
    });
  }

  editMaintenance = (values) => {
    return this.editItem(values, this.getMaintenanceUrl())
    .then(response => {
      this.setMaintenance();
      return response;
    });
  }

  deleteMaintenance = () => {
    return this.deleteItem(this.currentMaintenance.id, this.getMaintenanceUrl())
    .then(r => {
      this.currentMaintenance = null;
      return this.setMaintenance();
    });
  }

  // Contractors
  setContractorUrl = () => {
    return `${this.apiUrl}/${this.currentAsset.id}/contractors`;
  }

  setContractors = () => {
    return this.setList(this.setContractorUrl(), {
      offset: this.contractorList.offset
    })
    .then(response => {
      this.contractorList.data = response.data.data;
      this.contractorList.total = response.data.count;
    });
  }

  setCurrentContractor = (contractor) => {
    this.currentContractor = contractor;
  }

  resetCurrentContractor = () => {
    this.currentContractor = null;
  }

  addContractor = (values) => {
    return this.addItem(values, this.setContractorUrl())
    .then(r => this.setContractors());
  }

  deleteContractor = () => {
    return this.deleteItem(this.currentContractor.id, this.setContractorUrl())
    .then(r => {
      this.currentContractor = null;
      return this.setContractors();
    });
  }

  // Reporting
  setReportsPage = (page, params = {}) => {
    const offset = page * (params.limit ? params.limit : this.itemsPerPage);
    this.pages.assets.reports.offset = offset;
    params = {...this.pages.assets.reports.filterValues};

    return this.setReports(params);
  }

  setReports = (params = {}) => {
    params.offset = this.pages.assets.reports.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.assets.reports.data = response.data.data;
      this.pages.assets.reports.total = response.data.count;       
    });
  }

  resetReports = () => {
    this.pages.assets.reports = {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        status: "all",
        category: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    if (values.limit) {
      delete values.limit;
    }
    
    this.pages.assets.reports.filterValues = values;
  }

  // Other   
  exportAssets = (params = {}) => {
    return this.export(`${this.apiUrl.replace('/assets', '')}/export/assets`, params).then(response => response.data);
  }

  exportAssetsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/assets", "/export/pdf/assets")
    )
    .finally(this.hideLoader);
  }
}

const store = window.AssetsStore = new AssetsStore;

export default store;

autorun(() => {
    // console.log(store);
});