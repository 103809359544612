import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  categories: {
    business: t("Business"),
    charity: t("Charity"),
    community: t("Community"),
    exhibitions: t("Exhibitions"),
    fashion: t("Fashion"),
    festivals: t("Festivals"),
    films: t("Films"),
    food_wine: t("Food & Wine"),
    health: t("Health"),
    kids_family: t("Kids & Family"),
    lifestyle: t("Lifestyle"),
    major_events: t("Major events"),
    markets: t("Markets"),
    music: t("Music"),
    outdoor: t("Outdoor"),
    performing_arts: t("Performing Arts"),
    seasonal: t("Seasonal"),
    sport_fitness: t("Sport & Fitness"),
    tech_science: t("Tech & Science"),
    travel: t("Travel"),
    tours: t("Tours"),
    other: t("Other")
  },
  maintenanceCategories: {
    repairs_maintenance: t("Repairs & Maintenance"),
    planned_maintenance: t("Planned Maintenance"),
    call_outs: t("Call Outs"),
    other: t("Other")
  },
  projectCalenarTypes: {
    asset: {
      title: t("Assets"),
      types: {
        contract: t("Contract end date"),
        maintenance: t("Maintenance Schedule")
      }
    },
    contractor: {
      title: t("Contractors"),
      types: {
        license: t("License expiry")
      }
    },
    defect: {
      title: t("Defects"),
      types: {}
    },
    maintenance: {
      title: t("Maintenance event"),
      types: {
        repairs_maintenance: t("Repairs & Maintenance"),
        planned_maintenance: t("Planned Maintenance"),
        call_outs: t("Call Outs"),
        other: t("Other")
      }
    },
    user: {
      title: t("Member Registration"),
      types: {
        lease_contract: t("End of Lease Contract")
      }
    },
    work_order: {
      title: t("Work Orders"),
      types: {
        repair_maintenance: t("Repairs & Maintenance"),
        cleaning: t("Cleaning"),
        defects: t("Defects"),
        ohs: t("O.H.S."),
        project: t("Project"),
        other: t("Others")
      }
    },
    inspection: {
      title: t("Inspections"),
      types: {
        due_dates: t("Due Dates"),
        schedule: t("Schedule items (one off)")
      }
    },
    event: {
      title: t("Calendar events"),
      types: {
        project_events: t("Project calendar events")
      }
    }
  },
  timeFilter: {
    today: t("Today"),
    this_week: t("This week"),
    next_week: t("Next week")
  },
  rsvpStatuses: {
    attending: t("Attending"),
    declined: t("Declined"),
    canceled: t("Canceled"),
  }
};

export default data;