import { autorun, observable, toJS } from "mobx";
import Store from "./Store";
import data from "../data/HelpCentre";
import Helper from "../helpers/Helper";
import axios from "axios";

class HelpCentreStore extends Store {
  @observable pages = {
    tutorials: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    tutorialFolders: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    faqs: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    faqFolders: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    tickets: {
      current: null,
      all: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      new: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      open: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      in_progress: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      on_hold: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      resolved: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      closed: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      reports: {
        data: null,
        offset: 0,
        total: 0
      }
    },
    ticketCounters: {},
    messages: null,

    news: {
      data: null,
      offset: 0,
      total: 0,
      current: null
    },
    comingSoon: {
      data: null,
      offset: 0,
      total: 0,
      current: null
    },

    imageLibrary: {
      folders: {
        current: null,
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },

      images: {
        current: null,
        data: null,
        offset: 0,
        total: 0,
        count: 0
      }
    }
  };

  constructor() {
    super();
    this.t = Helper.translate;

    this.tutorialCategories = data.tutorialCategories;
    this.faqCategories = data.faqCategories;
    this.issueTypes = data.issueTypes;
    this.interfaces = data.interfaces;
    this.priorities = data.priorities;
    this.features = data.features;
    this.statuses = data.statuses;
    this.roles = data.roles;
    this.roleNames = data.roleNames;
    this.newsCategories = data.newsCategories;
    this.comingSoonCategories = data.comingSoonCategories;
    this.comingSoonInterfaces = data.comingSoonInterfaces;

    this.fileTypes = {
      video: this.t("Video"),
      document: this.t("Document")
    };
  }

  setData = (clientId, projectId, token) => {
    let url = "helpcentre";

    if (clientId && projectId) {
      url = `clients/${clientId}/projects/${projectId}/helpcentre`;
    }
    this.setStoreData(token, url);    
  }

  setPage = (page, type, callbackName, subtype = null, params = {}) => {
    const offset = page * this.itemsPerPage;

    if (subtype) {
      this.pages[type][subtype].offset = offset;
      params.status = subtype;
    } else {
      this.pages[type].offset = offset;
    }

    params.limit = this.itemsPerPage;
    return this[callbackName](params);
  }

  getTutorialsUrl = () => {
    return `${this.getTutorialFoldersUrl()}/${this.pages.tutorialFolders.current.id}/tutorials`;
  }

  setTutorials = (params = {}) => {    
    return this.setList(this.getTutorialsUrl(), params)
    .then(response => {
      this.pages.tutorials.data = response.data.data;
      this.pages.tutorials.total = response.data.count;
    });
  }

  setCurrentTutorial = (item) => {
    this.pages.tutorials.current = item;
  }

  resetCurrentTutorial = () => {
    this.pages.tutorials.current = null;
  }

  addTutorial = (values) => {
    return this.addItem(values, this.getTutorialsUrl());
  }
  
  editTutorial = (values) => {
    return this.editItem(values, this.getTutorialsUrl());
  }

  deleteTutorial = () => {
    return this.deleteItem(this.pages.tutorials.current.id, this.getTutorialsUrl());
  }

  // Tutorial folders
  getTutorialFoldersUrl = () => {
    return `${this.apiUrl}/tutorials/folders`;
  }

  setTutorialFolders = (params = {}) => {
    params.limit = 500;

    return this.setList(this.getTutorialFoldersUrl(), params)
    .then(response => {
      this.pages.tutorialFolders.data = response.data.data;
      this.pages.tutorialFolders.total = response.data.count;
    });
  }

  setTutorialFolder = (id, params = {}) => {
    const folderId = id ? id : this.pages.tutorialFolders.current.id;

    return this.setItem(folderId, this.getTutorialFoldersUrl(), params)
    .then(response => {
      this.pages.tutorialFolders.current = response.data.item;
      this.pages.tutorials.data = response.data.data;
      return response;
    });
  }

  setTutorialFolderByAlias = (id, params = {}) => {
    const folderId = id ? id : this.pages.tutorialFolders.current.url_alias;

    return this.setItem(folderId, this.getTutorialFoldersUrl() + "-alias", params)
    .then(response => {
      this.pages.tutorialFolders.current = response.data.item;
      this.pages.tutorials.data = response.data.data;
      return response;
    });
  }

  setCurrentTutorialFolder = (folder) => {
    this.pages.tutorialFolders.current = folder;
  }

  resetCurrentTutorialFolder = () => {
    this.pages.tutorialFolders.current = null;
  }

  addTutorialFolder = (values) => {
    return this.addItem(values, this.getTutorialFoldersUrl())
    .then(response => {
      this.setTutorialFolders();
      return response;
    });
  }
  
  editTutorialFolder = (values) => {
    return this.editItem(values, this.getTutorialFoldersUrl())
    .then(response => {
      this.setTutorialFolders();
      return response;
    });
  }

  deleteTutorialFolder = () => {
    return this.deleteItem(this.pages.tutorialFolders.current.id, this.getTutorialFoldersUrl())
    .then(r => this.setTutorialFolders());
  }

  // Faq folders
  getFaqFoldersUrl = () => {
    return `${this.apiUrl}/faq/folders`;
  }

  setFaqFolders = (params = {}) => {
    params.offset = this.pages.faqFolders.offset;
    
    return this.setList(this.getFaqFoldersUrl(), params)
    .then(response => {
      this.pages.faqFolders.data = response.data.data;
      this.pages.faqFolders.total = response.data.count;
    });
  }

  setFaqFolder = (params = {}, id) => {
    const folderId = id ? id : this.pages.faqFolders.current.id;
    params.offset = this.pages.faqs.offset;

    return this.setItem(folderId, this.getFaqFoldersUrl(), params)
    .then(response => {
      this.pages.faqFolders.current = response.data.item;
      this.pages.faqs.data = response.data.data;
      this.pages.faqs.total = response.data.count;
      return response;
    });
  }

  setCurrentFaqFolder = (folder) => {
    this.pages.faqFolders.current = folder;
  }

  resetCurrentFaqFolder = () => {
    this.pages.faqFolders.current = null;
  }

  addFaqFolder = (values) => {
    return this.addItem(values, this.getFaqFoldersUrl())
    .then(response => {
      this.setFaqFolders();
      return response;
    });
  }
  
  editFaqFolder = (values) => {
    return this.editItem(values, this.getFaqFoldersUrl())
    .then(response => {
      this.setFaqFolders();
      return response;
    });
  }

  deleteFaqFolder = () => {
    return this.deleteItem(this.pages.faqFolders.current.id, this.getFaqFoldersUrl())
    .then(r => this.setFaqFolders());
  }

  // FAQs
  getFAQsUrl = () => {
    const faqFolder = this.pages.faqFolders.current;
    return `${this.getFaqFoldersUrl()}/${faqFolder.id}/items`;
  }

  setFAQs = (params = {}) => {    
    return this.setList(this.getFAQsUrl(), params)
    .then(response => {
      this.pages.faqs.data = response.data.data;
      this.pages.faqs.total = response.data.count;
    });
  }

  setCurrentFAQ = (item) => {
    this.pages.faqs.current = item;
  }

  resetCurrentFAQ = () => {
    this.pages.faqs.current = null;
  }

  addFAQ = (values) => {
    return this.addItem(values, this.getFAQsUrl());
  }
  
  editFAQ = (values) => {
    return this.editItem(values, this.getFAQsUrl());
  }

  deleteFAQ = () => {
    return this.deleteItem(this.pages.faqs.current.id, this.getFAQsUrl());
  }

  // Tickets
  getTicketsUrl = () => {
    return this.apiUrl + "/tickets";
  }

  setAllTickets = () => {
    const statuses = [ "all", "open", "in_progress", "resolved" ];        
    let promises = [];

    statuses.forEach(status => {
      promises.push(this.setTickets({ status: status }));
    });

    return axios.all(promises);
  }

  setTickets = (params = {}) => {
    const tickets = this.pages.tickets;
    params.offset = tickets[params.status].offset; 

    return this.setList(this.getTicketsUrl(), params)
    .then(response => {
      if (response.data) {
        tickets[params.status].data = response.data.data;
        tickets[params.status].total = response.data.count;
        tickets[params.status].count = response.data.count;
        tickets[params.status].unread = response.data.count_unread;
      }
    });
  }

  setTicket = (id) => {
    return this.setItem(id, this.getTicketsUrl())
    .then(response => {
      this.pages.tickets.current = response.data;
      return response;
    });
  }

  addTicket = (values) => {
    return this.addItem(values, this.getTicketsUrl())
    .then(response => {
      this.setAllTickets();
      return response;
    });
  }

  editTicket = (values) => {
    return this.editItem(values, this.getTicketsUrl())
    .then(response => {
      this.pages.tickets.current = response.data;
    });
  }

  getCurrentTicket = () => {
    return toJS(this.pages.tickets.current);
  }

  resetTicket = () => {
    this.pages.tickets.current = null;
  }

  deleteTicket = () => {
    return this.deleteItem(this.pages.tickets.current.id, this.getTicketsUrl())
    .then(r => {
      this.pages.tickets.current = null;
      return this.setAllTickets();
    });
  }

  setTicketCounters = () => {
    return this.setList(`${this.apiUrl.replace("/helpcentre", "")}/counters/helpcentre-tickets`)
    .then(response => {
      this.pages.ticketCounters = response.data.statuses;
      this.pages.ticketCounters.all = response.data.count;
    });
  }

  // Messages
  getMessageUrl = (id) => {
    return `${this.getTicketsUrl()}/${id}/message`;
  }

  addMessage = (id, message) => {
    let item = {
      description: message,
      type: "text"
    };

    return this.addItem(item, this.getMessageUrl(id))
    .then(r => {
      this.setTicket(id);
    });
  }

  addFileMessage = (id, files) => {
    const item = {
      filename: files[0],
      type: "file"
    };

    return this.addItem(item, this.getMessageUrl(id))
    .then(r => {
      this.setTicket(id);
    });
  }

  // News
  getNewsUrl = () => {
    return `${this.apiUrl}/newsfeed`;
  }

  setNews = (params = {}) => {
    return this.setList(this.getNewsUrl(), params)
    .then(response => {
      this.pages.news.data = response.data.data;
      this.pages.news.total = response.data.count;
    });
  }

  setNewsById = (id) => {
    return this.setItem(id, this.getNewsUrl())
    .then(response => {
      this.pages.news.current = response.data;
    });
  }

  setCurrentNews = (item) => {
    this.pages.news.current = item;
  }

  resetCurrentNews = () => {
    this.pages.news.current = null;
  }

  addNews = (values) => {
    return this.addItem(values, this.getNewsUrl());
  }
  
  editNews = (values) => {
    return this.editItem(values, this.getNewsUrl());
  }

  deleteNews = () => {
    return this.deleteItem(this.pages.news.current.id, this.getNewsUrl());
  }

  // Coming Soon
  getComingSoonUrl = () => {
    return `${this.apiUrl}/comingsoon`;
  }

  setComingSoon = (params = {}) => {
    return this.setList(this.getComingSoonUrl(), params)
    .then(response => {
      this.pages.comingSoon.data = response.data.data;
      this.pages.comingSoon.total = response.data.count;
    });
  }

  setComingSoonById = (id) => {
    return this.setItem(id, this.getComingSoonUrl())
    .then(response => {
      this.pages.comingSoon.current = response.data;
    });
  }

  setCurrentComingSoon = (item) => {
    this.pages.comingSoon.current = item;
  }

  resetCurrentComingSoon = () => {
    this.pages.comingSoon.current = null;
  }

  addComingSoon = (values) => {
    return this.addItem(values, this.getComingSoonUrl());
  }
  
  editComingSoon = (values) => {
    return this.editItem(values, this.getComingSoonUrl());
  }

  deleteComingSoon = () => {
    return this.deleteItem(this.pages.comingSoon.current.id, this.getComingSoonUrl());
  }

  // Image library
  getImageLibraryUrl = () => {
    return `${this.apiUrl}/library`;
  }

  getImageLibraryFolderUrl = () => {
    return `${this.apiUrl}/library/folders`;
  }

  setImageFolders = (params = {}) => {
    params.offset = this.pages.imageLibrary.folders.offset;

    return this.setList(this.getImageLibraryUrl(), params)
    .then(response => {
      this.pages.imageLibrary.folders.data = response.data.data;
      this.pages.imageLibrary.folders.total = response.data.count;
    });
  }

  setCurrentImageFolder = (item) => {
    this.pages.imageLibrary.folders.current = item;
  }

  resetCurrentFolder = () => {
    this.pages.imageLibrary.folders.current = null;
  }

  addFolder = (values) => {
    return this.addItem(values, this.getImageLibraryFolderUrl());
  }

  editFolder = (values) => {
    return this.editItem(values, this.getImageLibraryFolderUrl());
  }

  deleteFolder = () => {
    return this.deleteItem(this.pages.imageLibrary.folders.current.id, this.getImageLibraryUrl());
  }

  setImageLibrary = (params = {}) => {
    params.offset = this.pages.imageLibrary.images.offset;

    return this.setList(this.getImageLibraryUrl(), params)
    .then(response => {
      this.pages.imageLibrary.images.data = response.data.data;
      this.pages.imageLibrary.images.total = response.data.count;
    });
  }

  setCurrentImage = (item) => {
    this.pages.imageLibrary.images.current = item;
  }

  resetCurrentImage = () => {
    this.pages.imageLibrary.images.current = null;
  }

  addImage = (values) => {
    return this.addItem(values, this.getImageLibraryUrl());
  }

  editImage = (values) => {
    return this.editItem(values, this.getImageLibraryUrl());
  }

  deleteImage = () => {
    return this.deleteItem(this.pages.imageLibrary.images.current.id, this.getImageLibraryUrl());
  }

  // Other
  getFoldersByCategory = (list, category) => {
    let result = [];
    list.forEach(item => {
      if (item.category === category) {
        result.push(item);
      }
    });

    return result;
  }
}

const store = window.HelpCentreStore = new HelpCentreStore;

export default store;

autorun(() => {
    // console.log(store);
});