import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  types: {
    survey: t("All surveys"),
    poll: t("All polls")
  },
  categories: {
    "amenities": t("Amenities"),
    "ballots": t("Ballots"),
    "cleanliness": t("Cleanliness"),
    "common_area": t("Common Area"),
    "community": t("Community"),
    "competitions": t("Competitions"),
    "facilities": t("Facilities"),
    "maintenance": t("Maintenance"),
    "private_property": t("Private Property"),
    "services": t("Services"),
    "other": t("Other")
  }
};
export default data;