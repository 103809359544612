import { autorun, observable, toJS } from "mobx";
import data from "../data/LocalArea.js";
import locations from "../data/Locations.js";
import Store from "./Store";

class LocalAreaStore extends Store {
  @observable pages = {
    localArea: {
      data: null,
      offset: 0,
      total: 0,
      count_featured: 0,
      filters: null
    },
    allocatedProjects: {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        search: ""
      }
    }
  };
  @observable currentLocalArea = null;

  constructor() {
    super();
    this.categories = data.categories;
    this.countries = locations;
  }

  setData = (clientId, projectId, token) => {
    if (clientId != null) {
      this.setStoreData(token, `clients/${clientId}/projects/${projectId}/locals`);
    } else {
      this.setStoreData(token, `global/locals`);
    }
  }

  setPage = (page, params) => {
    this.pages.localArea.offset = page * this.itemsPerPage;
    this.setLocalAreaList(params);
  }

  setFilters = (params) => {
    this.pages.localArea.filters = params;
  }

  setLocalAreaList = (params = {}) => {
    if (this.pages.localArea.filters) {
      params = Object.assign(params, this.pages.localArea.filters);
    }
    params.offset = this.pages.localArea.offset;
    
    return this.setList(null, params)
    .then(response => {
      this.pages.localArea.data = response.data.data;
      this.pages.localArea.total = response.data.count;
      this.pages.localArea.count_featured = response.data.count_featured;
    });
  }

  getLocalAreaList = () => {
    return toJS(this.localAreaList);
  }

  getLocalArea = () => {
    return toJS(this.currentLocalArea);
  }

  setLocalArea = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentLocalArea = response.data;
    });
  }

  resetLocalArea = () => {
    this.currentLocalArea = null;
  }

  addLocalArea = (item) => {
    return this.addItem(item)
    .then(r => this.setLocalAreaList());
  }

  editLocalArea = (item) => {
    return this.editItem(item)
    .then(response => {
      this.currentLocalArea = response.data;
    });
  }

  deleteLocalArea = () => {
    return this.deleteItem(this.currentLocalArea.id)
    .then(r => {
      this.currentLocalArea = null;
      return this.setLocalAreaList();
    });
  }

  importLocalArea = (clientId, projectId, ids) => {
    const url = `${this.url}clients/${clientId}/projects/${projectId}/attach/locals`;
    return this.addItem({ ids: ids }, url);
  }

  // Allocated projects
  getProjectUrl = () => {
    return `${this.url}global/locals/${this.currentLocalArea.id}/allocated`;
  }

  setAllocatedProjects = (params = {}) => {
    if (this.pages.allocatedProjects.filters) {
      params = Object.assign(params, this.pages.allocatedProjects.filters);
    }
    params.offset = this.pages.allocatedProjects.offset;
    
    return this.setList(this.getProjectUrl(), params)
    .then(response => {
      this.pages.allocatedProjects.data = response.data.data;
      this.pages.allocatedProjects.total = response.data.count;
    });
  }
}

const store = window.LocalAreaStore = new LocalAreaStore;

export default store;

autorun(() => {
    // console.log(store);
});