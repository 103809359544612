import { autorun, observable, toJS } from "mobx";
import Store from "./Store.js";
import data from "../data/Events";
import { rrulestr } from 'rrule';
import moment from "moment";

class MaintenanceStore extends Store {
  @observable pages = {
    events: {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        category: "",
        start_date: null,
        contractor_name: "",
        search: ""
      }
    },
    reports: {
      data: null,
      total: 0,
      offset: 0,
      filters: {
        category: "",
        date_start: null,
        date_end: null
      }
    },
    documents: {
      data: null,
      offset: 0,
      total: 0
    }
  };

  @observable events = null;
  @observable currentEvent = null;
  @observable tempEvent = null;
  @observable tempRRule = null;
  @observable currentDocument = null;
  @observable lastId = null;

  constructor() {
    super();
    this.maintenanceCategories = data.maintenanceCategories;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/maintenance`);
  }

  setPage = (page) => {
    this.pages.events.offset = page * this.itemsPerPage;
  }

  setEvents = (params = {}, isntForPublic = false) => {
    params.offset = this.pages.events.offset;
    params.limit = 500;

    return this.setList(null, params)
    .then(response => {
      if (!isntForPublic) {
        this.pages.events.data = response.data.data;
        this.pages.events.total = response.data.count;
      } else {
        this.events = response.data.data;
      }

      return response;
    });
  }

  prepareRepeatEvents = (response, date) => {
    let list = [];

    response.data.data.forEach(event => {
      if (date && event.is_recurring == 1) {
        if (event.rrule_string && event.rrule_string.indexOf("undefined") != -1) {
          return false;
        }

        let rrule_string = event.rrule_string;
        if (rrule_string.indexOf(";UNTIL=100000") != -1) {
          rrule_string = rrule_string.slice(0, rrule_string.indexOf(";UNTIL=100000"));
        }

        const rrule = rrulestr(rrule_string);
        const dates = rrule.between(moment(date + " 00:00:00").toDate(), moment(date + " 23:59:00").toDate());

        if (dates.length != 0) {
          list.push(event);
        }
      } else {
        list.push(event);
      }
    });

    this.pages.events.data = list;
  }

  setEvent = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentEvent = response.data;
      return response;
    });
  }

  getCurrentEvent = () => {
    return toJS(this.currentEvent);
  }

  setCurrentEvent = (event) => {
    this.currentEvent = event;
  }

  resetCurrentEvent = () => {
    this.currentEvent = null;
  }

  getTemporaryEvent = () => {
    return toJS(this.tempEvent);
  }

  addEvent = (values) => {
    return this.addItem(values)
    .then(response => {
      return response;
    }); 
  }

  editEvent = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentEvent = response.data;
      this.setEvent(this.currentEvent.id);
      return response;
    });
  }

  deleteEvent = (id) => {
    return this.deleteItem(id || this.currentEvent.id);
  }

  prepareId = (item) => {
    this.lastId = `ME_${item ? item.id + 1 : 1}`;
  }

  // Reports
  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;
    params.limit = 500;

    return this.setList(this.apiUrl, params)
    .then(response => {                    
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count; 
      return response;
    });
  }

  prepareReportEvents = (response, dateStart, dateEnd) => {
    let list = [];

    response.data.data.forEach(event => {
      if (dateStart && dateEnd && event.is_recurring == 1) {
        // If event repeat
        if (event.rrule_string && event.rrule_string.indexOf("undefined") != -1) {
          return false;
        }

        let rrule_string = event.rrule_string;
        if (rrule_string.indexOf(";UNTIL=100000") != -1) {
          rrule_string = rrule_string.slice(0, rrule_string.indexOf(";UNTIL=100000"));
        }

        const rrule = rrulestr(rrule_string);
        const dates = rrule.between(moment(dateStart + " 00:00:00").toDate(), moment(dateEnd + " 23:59:00").toDate());

        if (dates.length > 1){
          // If included on several dates.
          dates.forEach(date => {
            let eventCopy = JSON.parse(JSON.stringify(event));
            eventCopy.date_start = moment(date).format("MM/DD/YYYY hh:mm");
            list.push(eventCopy);
          });
        } else if (dates.length != 0) {
          // If included on one date
          let eventCopy = JSON.parse(JSON.stringify(event)); 
          eventCopy.date_start = moment(dates[0]).format("MM/DD/YYYY hh:mm");
          list.push(eventCopy);
        }
      } else if (event.is_recurring == 0) {
        list.push(event);
      }
    });

    this.pages.reports.data = list;
    this.pages.reports.total = list.length;
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        role: "",
        status: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    this.pages.reports.filters = values;
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl}/${this.currentEvent.id}/documents`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }

  // Other
  prepareEvents = (data, calendarDates = null) => {
    let events = [];

    data.forEach(item => {
      let event = item;
      let start = moment(item.date_start).format('HH:mm');
      event.event_type = "maintenance";
      event.status = item.category;
      event.start = item.date_start;
      event.end = item.date_end;
      event.color = "#d543a3";
      event.title = `${start} - ${item.subject}`;
      event.html = this.getEventHtml(event);

      if (item.is_recurring == 1 && item.rrule_string && calendarDates) {
        const rule = rrulestr(item.rrule_string);
        const dates = rule.between(moment(calendarDates.date_start).add(-1, "days").toDate(), moment(calendarDates.date_end).add(1, "days").toDate());
        const timeDuration = moment.duration(moment(item.date_end).diff(moment(item.date_start)));
        const hourDuration = timeDuration.hours();

        dates.forEach((date, index) => {
          const eventCopy = JSON.parse(JSON.stringify(event));
          const end_time = moment(date).add(hourDuration, 'hours');

          eventCopy.start = `${moment(date).format("MM/DD/YYYY")} ${start}`;
          eventCopy.end = `${end_time.format("MM/DD/YYYY")} ${end_time.format("HH:mm")}`;
          events.push(eventCopy);
        });
      } else {
        events.push(event);
      }
    });

    return events;
  }

  getEventHtml = (event) => {
    return `<div class="body">
      <ul class="list-unstyled user-info">
        <li><span class="label">${"ID"}:</span>${event.m_id}</li>
        <li><span class="label">${"Start Date & Time"}:</span>${event.date_start}</li>
        <li><span class="label">${"End Date & Time"}:</span>${event.date_end}</li>
        <li><span class="label">${"Recurrence"}:</span>${event.is_recurring == 1 ? "Yes" : "No"}</li>
      </ul>
      <ul class="list-unstyled user-info">
        <li><span class="label">${"Location"}:</span> ${event.location}</li>
        <li><span class="label">${"Asset"}:</span> ${event.asset_name}</li>
        <li><span class="label">${"Contractor"}:</span> ${event.contractor_name}</li>
      </ul>
    </div>`;
  }

  exportMaintenancePDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/maintenance", "/export/pdf/maintenance")
    )
    .finally(this.hideLoader);
  }
} 

const store = window.MaintenanceStore = new MaintenanceStore;
export default store;
autorun(() => {});