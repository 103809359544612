import { NotificationManager } from 'react-notifications';
import { autorun, observable, toJS } from "mobx";
import Helper from "../helpers/Helper";
import data from "../data/Users.js";
import Store from "./Store";
import axios from "axios";

class UserStore extends Store {
  @observable users = [];
  @observable pets = null;
  @observable cars = null;
  @observable currentUser = null;	
  @observable currentOfflineMember = null;	
  @observable currentPet = null;
  @observable currentCar = null;
  @observable currentDocument = null;
  @observable pageCounters = null;
  @observable pages = {
    all: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    pending: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    approved: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    offline: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    declined: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    need_info: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    invited: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    archived: {
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        role: "",
        search: ""
      }
    },
    searchResult: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        role: "",
        search: "",
        status: ""
      }
    },

    offlineMembers: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        search: ""
      }
    },
    documents: {
      data: null,
      offset: 0,
      total: 0
    },
    reports: {
      data: null,
      total: 0,
      offset: 0,
      filters: {
        role: "",
        status: "",
        date_start: null,
        date_end: null
      }
    },
    contactList: {
      current: null,
      data: null,
      total: 0,
      offset: 0,
      filterValues: {
        search: ""
      }
    },
    statusCounters: null,

    repeatFields: {
      count: 1,
      ids: [],
      values: [""]
    }
  };

  constructor() {
    super();
    this.t = Helper.translate;		
    this.roles = data.roles;
    this.roleNames = data.roleNames;
    this.rolesCommercial = data.rolesCommercial;
    this.rolesRent = data.rolesRent;
    this.statuses = data.statuses;
    this.labels = data.userLabels;
    this.personalInfo = data.personalInfo;
    this.tableFields = data.tableFields;
    this.commercailTableFields = data.commercailTableFields;
    this.sortingFileds = data.sortingFileds;
    this.tableColumns = data.tableColumns;
    this.buildingInfo = data.buildingInfo;
    this.departmentInfo = data.departmentInfo;
    this.lifestyleInfo = data.lifestyleInfo;
    this.countryCodes = data.countryCodes;
    this.countryCodeList = data.countryCodeList;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}`);
  }

  setPage = (page, type, subtype = "") => {
    this.pages[type].offset = page * this.itemsPerPage;
    let params = { 
      status: type,
      role: this.pages[type].filterValues.role,
      search: this.pages[type].filterValues.search,
      offset: this.pages[type].offset
    };

    if (this.pages[type].sortingValues && this.pages[type].sortingValues.field != "") {
      params.sort = this.pages[type].sortingValues.field;
      params.order = this.pages[type].sortingValues.order;
    }

    if (type == "notes") {
      this.setNotes(params);
    } else if (type == "offlineMembers") {
      this.setOfflineMembrs();
    } else if (subtype == "contactUsers") {
      this.setContactUsers();
    } else if (type == "searchResult") {
      this.setSearchUsers({
        offset: this.pages[type].offset
      });
    } else {      
      this.setUsers(params);
    }
  }

  setAllUsers = () => {
    const requests = [ "all", "pending", "need_info", "approved", "declined", "offline", "archived" ];			
    let promises = [];

    requests.forEach(status => {
      promises.push(this.setUsers({ status: status }));
    });

    return axios.all(promises);
  }

  setUsers = (params = {}) => {
    const status = params.status;
    params = this.getParams(this.pages[status], params);

    return this.setList(`${this.apiUrl}/users`, params)
    .then(response => {
      this.pages[status].data = response.data.data;
      this.pages[status].total = response.data.count;
      return response;
    });
  }

  setUserList = () => {
    this.users = [];
  }

  setUserById = (id) => {		
    return this.setItem(id, `${this.apiUrl}/users`)
    .then(response => {
      this.users.push(response.data);
      return response;
    });
  }

  getUserById = (id) => {
    let result = null;

    toJS(this.users).forEach(user => {
      if (user.id == id) {
        result = user;
      }
    });

    return result;
  }

  setCurrentUserById = (id) => {		
    return this.setItem(id, `${this.apiUrl}/users`)
    .then(response => {
      this.currentUser = response.data;
      this.setDocuments();
      return response;
    });
  }

  getCurrentUser = () => {
    return toJS(this.currentUser);
  }

  resetCurrentUser = () => {
    this.currentUser = null;
  }

  addUser = (values) => {    
    return this.addItem(values, `${this.apiUrl}/users`)
    .then(response => {
      this.setAllUsers();
      return response;
    });
  }

  editUser = (values) => {
    return this.editItem(values, `${this.apiUrl}/users`)
    .then(response => {
      this.currentUser = response.data;
      this.setAllUsers();
      this.setDocuments();
      return response;
    });
  }

  changeUserStatus = (user) => {
    return this.editUser(user);
  }

  deleteUser = (userId) => {
    return this.deleteItem(userId, `${this.apiUrl}/users`)
    .then(r => {
      this.currentUser = null;
    });
  }

  searchUsers = (params = {}) => {
    let data = { 
      limit: 500,
      status: "approved",
      offset: this.pages.searchResult.offset,
      sort: "first_name",
      order: "ASC"
    };
    

    return this.setList(`${this.apiUrl}/users`, Object.assign(data, params))
    .then(response => {
      this.pages.searchResult.data = response.data.data;
      this.pages.searchResult.total = response.data.count;
    });
  }

  setSearchUsers = (params = {}) => {
    params = this.getParams(this.pages.searchResult, params);

    return this.setList(`${this.apiUrl}/users`, params)
    .then(response => {
      this.pages.searchResult.data = response.data.data;
      this.pages.searchResult.total = response.data.count;
    });
  }

  setSearchUser = (id) => {
    return this.setItem(id, `${this.apiUrl}/users`)
    .then(response => {
      console.log(response);
      this.pages.searchResult.data = [ response.data ];
      this.pages.searchResult.total = 1;
    });
  }
    
  exportUsers = () => {
    return this.export(`${this.apiUrl}/export/users`)
    .then(response => response.data);
  }

  sortingUsers = (field, order, status) => {
    this.pages[status].sortingValues.field = field;
    this.pages[status].sortingValues.order = order;

    return this.setUsers({
      offset: 0,
      order: order,
      sort: field,
      status: status,
      limit: this.itemsPerPage
    });
  }

  resendEmail = () => {
    return this.addItem({}, `${this.apiUrl}/users/${this.currentUser.id}/emails/verification`);
  }

  // Offline members
  getMembersUrl = (prefix) => {
    return `${this.apiUrl}${prefix ? "/" + prefix : ""}/offline-users`;
  }

  setOfflineMembrs = (params = {}) => {
    params.offset = this.pages.offlineMembers.offset;

    return this.setList(this.getMembersUrl(), params)
    .then(response => {
      this.pages.offlineMembers.data = response.data.data;
      this.pages.offlineMembers.total = response.data.count;
    });
  }

  setOfflineMember = (member) => {
    this.currentOfflineMember = member;
  }

  resetOfflineMember = () => {
    this.currentOfflineMember = null;
  }

  deleteOfflineMember = (userId) => {
    return this.deleteItem(userId, this.getMembersUrl())
    .then(r => {
      this.currentOfflineMember = null;
    });
  }

  importOfflineMembrs = (values) => {
    return this.addItem(values, this.getMembersUrl("import"))
    .then(response => {
      this.setOfflineMembrs();
      return response;
    });
  }

  exportOfflineMembrs = () => {
    return this.setList(this.getMembersUrl("export"), {})
    .then(response => {
      return response.data;
    });
  }

  // Pets
  getPetsUrl = () => {
    return `${this.apiUrl}/users/${this.currentUser.id}/pets`;
  }

  setPets = () => {
    return this.setList(this.getPetsUrl())
    .then(response => {
      this.pets = response.data.data;
    });
  }

  setCurrentPet = (pet) => {
    this.currentPet = pet;
  }

  resetCurrentPet = () => {
    this.currentPet = null;
  }

  getCurrentPet = () => {
    return toJS(this.currentPet);
  }

  addPet = (values) => {
    return this.addItem(values, this.getPetsUrl()).then(r => this.setPets());
  }

  editPet = (values) => {
    return this.editItem(values, this.getPetsUrl()).then(r => this.setPets());
  }

  deletePet = () => {
    return this.deleteItem(this.currentPet.id, this.getPetsUrl())
    .then(r => {
      this.currentPet = null;
      return this.setPets();
    });
  }

  // Cars
  getCarUrl = () => {
    return `${this.apiUrl}/users/${this.currentUser.id}/cars`;
  }

  setCars = () => {
    return this.setList(this.getCarUrl())
    .then(response => {
      this.cars = response.data.data;
    });
  }

  setCurrentCar = (car) => {
    this.currentCar = car;
  }

  resetCurrentCar = () => {
    this.currentCar = null;
  }

  getCurrentCar = () => {
    return toJS(this.currentCar);
  }

  addCar = (values) => {
    return this.addItem(values, this.getCarUrl()).then(r => this.setCars());
  }

  editCar = (values) => {
    return this.editItem(values, this.getCarUrl()).then(r => this.setCars());
  }

  deleteCar = () => {
    return this.deleteItem(this.currentCar.id, this.getCarUrl())
    .then(r => {
      this.currentCar = null;
      return this.setCars();
    });
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl}/users/${this.currentUser.id}/documents`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }

  // Contact List
  getContactListUrl = () => {
    return `${this.apiUrl}/contact-lists`;
  }

  setContactLists = (params = {}) => {
    params.offset = this.pages.contactList.offset;
    params = this.getParams(this.pages.contactList, params);
    
    return this.setList(this.getContactListUrl(), params)
    .then(response => {
      this.pages.contactList.data = response.data.data;
    });
  }

  setContactList = (id) => {
    return this.setItem(id, this.getContactListUrl())
    .then(response => {
      this.pages.contactList.current = response.data;
    });
  }

  setCurrentContactList = (document) => {
    this.pages.contactList.current = document;
  }

  resetCurrentContactList = () => {
    this.pages.contactList.current = null;
  }

  addContactList = (values) => {
    return this.addItem(values, this.getContactListUrl())
    .then(r => this.setContactLists());
  }

  deleteContactList = () => {
    return this.deleteItem(this.pages.contactList.current.id, this.getContactListUrl())
    .then(r => {
      this.pages.contactList.current = null;
      return this.setContactLists();
    });
  }

  // Contact List Users
  getContactUsersUrl = () => {
    return `${this.getContactListUrl()}/${this.pages.contactList.current.id}/users`;
  }

  setContactUsers = (params = {}) => {
    params.offset = this.pages.all.offset;

    return this.setList(this.getContactUsersUrl(), params)
    .then(response => {
      this.pages.all.data = response.data.data;
      this.pages.all.total = response.data.count;
    });
  }

  setUsersByFilter = (params = {}) => {
    params.offset = this.pages.all.offset;

    return this.setList(`${this.apiUrl}/users`, params)
    .then(response => {
      this.pages.all.data = response.data.data;
      this.pages.all.total = response.data.count;
    });
  }

  setCurrentContactUser = (user) => {
    this.currentUser = user;
  }

  resetCurrentContactList = () => {
    this.currentUser = null;
  }

  addContactUser = (values) => {
    return this.addItem(values, this.getContactUsersUrl())
    .then(r => this.setContactUsers());
  }

  deleteContactUser = (id) => {
    return this.deleteItem(id || this.currentUser.id, this.getContactUsersUrl())
    .then(r => {
      this.currentUser = null;
      return this.setContactUsers();
    });
  }
  
  // Reports
  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;
    params.limit = 400;

    return this.setList(`${this.apiUrl}/users`, params)
    .then(response => {                    
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count; 
    });
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        role: "",
        status: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    this.pages.reports.filters = values;
  }

  // Repeat Fields
  setRepeatData = (count, ids, values) => {
    this.pages.repeatFields.count = count;
    this.pages.repeatFields.ids = ids;
    this.pages.repeatFields.values = values;
  }

  setRepeatValue = (value, index) => {
    if (this.pages.repeatFields.values.indexOf(value + "") == -1) {
      this.pages.repeatFields.values[index] = value + "";
    } else {
      NotificationManager.error(this.t("The member was choosen before."));
    }
  }

  addSelectCategory = () => {
    let count = this.pages.repeatFields.count;
    if (this.pages.repeatFields.values[count - 1] != "") {
      this.pages.repeatFields.count = ++count;
      this.pages.repeatFields.values.push("");
    } else {
      NotificationManager.error(this.t("Please select a member."));
    }
  }

	// Other
  setPageCounters = (params = null) => {
    return this.setList(`${this.apiUrl}/counters/users`, params)
    .then(response => {
      this.pageCounters = response.data;
    })
  }

  setStatusCounters = () => {
    return this.setList(`${this.apiUrl}/counters/users/statuses`)
    .then(response => {
      this.pages.statusCounters = response.data;
    });
  }
  
  assignCategories = (values) => {
    return this.addItem(values, `${this.apiUrl}/bulk-categories`);
  }

  exportUsersPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl + "/export/pdf/users"
    )
    .finally(this.hideLoader);
  }
}

const store = window.UserStore = new UserStore;

export default store;

autorun(() => {
    // console.log(store);
});