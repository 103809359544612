import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  categories: {
		"charity": t("Charity"),
		"exhibitions": t("Exhibitions"),
		"fashion": t("Fashion"),
		"festivals": t("Festivals"),
		"films": t("Films"),
		"food_wine": t("Food & Wine"),
		"health": t("Health"),
		"kids_family": t("Kids & Family"),
		"lifestyle": t("Lifestyle"),
		"music": t("Music"),
		"outdoor": t("Outdoor"),
		"performing_arts": t("Performing arts"),
		"seasonal": t("Seasonal"),
		"sport_fitness": t("Sport & Fitness"),
		"tech_science": t("Tech & Science"),
		"travel": t("Travel"),
		"tours": t("Tours"),
		"other": t("Other"),
  }
};
export default data;