import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  industries: {
    "automotive": t("Automotive"),
    "business_services": t("Business Services"),
    "community": t("Community"),
    "construction": t("Construction"),
    "electronics": t("Electronics"),
    "financial": t("Financial"),
    "health": t("Health"),
    "home_repair": t("Home Repair"),
    "office": t("Office"),
    "personal_care": t("Personal Care"),
    "pets": t("Pets"),
    "real_estate": t("Real Estate"),
    "recreation": t("Recreation"),
    "removalist": t("Removalist"),
    "security": t("Security"),
    "tourism": t("Tourism"),
    "trades_person": t("Trades person"),
    "transport": t("Transport"),
    "other": t("Other"),
  }
};

export default data;