import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  bookingStatuses: {
    requested: t("Requested"),
    waiting_for_payment: t("Waiting for Payment"),
    paid: t("Paid"),
    active: t("Active"),
    expired: t("Expired"),
    canceled: t("Canceled")
  },
  bookingStatusColors: {
    requested: "pending",
    waiting_for_payment: "purple",
    paid: "light-primary",
    active: "success",
    expired: "grey-dark",
    canceled: "danger"
  },
  lockerStatuses: {
    pin_pending: t("PIN Pending"),
    occupied: t("Occupied"),
    available: t("Available"),
    disabled: t("Disabled")
  },
  lockerStatusColors: {
    pin_pending: "pending",
    occupied: "primary",
    available: "success",
    disabled: "grey-dark"
  }
};
export default data;