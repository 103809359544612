import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';

import data from "../data/Projects.js";
import { translate } from "../helpers/Helper";
import Helper from "../helpers/Helper";
import Store from "./Store";

class ProjectStore extends Store {
  @observable projects = null;
  @observable currentProject = null;
  @observable clientId = 0;
  @observable pageOffset = 0;
  @observable totalItems = 0;
  @observable categoriesData = {
    data: null,
    searchList: null,
    offset: 0,
    count: 0
  };
  @observable repeatFields = {
    count: 1,
    values: [""]
  };

  constructor() {
    super();
    this.t = translate;
    this.getCoockie = Helper.getCoockie;
    this.setCoockie = Helper.setCoockie;
    this.timezones = data.timezones;
    this.features = data.features;
    this.featureLabels = data.featureLabels;
    this.appCategories = {};

    this.projectTypes = {
      "residential": this.t("Residential"),
      "commercial": this.t("Commercial"),
      "lifestyle": this.t("Lifestyle"),
      "build_to_rent": this.t("Build to rent")
    };
  }

  setData = (clientId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects`);
  }

  setPage = (page, token, type = null) => {
    if (type && type == "categories") {
      this.categoriesData.offset = page * this.itemsPerPage;
      return this.setCategories({
        offset: this.categoriesData.offset,
        limit: this.itemsPerPage
      });
    } else {
      this.pageOffset = page * this.itemsPerPage;
      return this.setProjects(this.clientId, token);
    }
  }

  getProjects = () => {
    return toJS(this.projects);
  }

  getCurrentProject = () => {
    return toJS(this.currentProject);
  }

  setClientId = (id) => {
    this.clientId = id;
  }

  setProjects = (clientId) => {
    this.clientId = clientId;
    const params = {
      offset: this.pageOffset
    };

    return this.setList(null, params)
    .then(response => {
      this.projects = response.data.data;
      this.totalItems = response.data.count;
    });
  }

  setProject = (id) => {    
    return this.setItem(id)
    .then(response => {
      this.currentProject = response.data;
      this.setCoockie('projectLang', response.data.language);
      return response;
    });
  }

  setCurrentProject = (project) => {
    this.currentProject = project;
  }

  resetCurrentProject = () => {
    this.currentProject = null;
  }

  addProject = (values) => {
    return this.addItem(values)
    .then(r => this.setProjects(this.clientId));
  }

  editProject = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentProject = response.data;
      this.setProjects(this.clientId);
      return response;
    });
  }
  
  deleteProject = () => {
    return this.deleteItem(this.currentProject.id)
    .then(r => {
      this.currentProject = null;
      return this.setProjects(this.clientId);
    });
  }

  addDetails = (details) => {
    details.id = this.currentProject.id;
    return this.editProject(details).then(response => {
      this.setCoockie('projectLang', response.data.language);
    });
  }

  // Project type 
  getProjectType = () => {
    return this.currentProject ? this.currentProject.project_type : "residential";
  }

  getProjectTypeNames = () => {
    const type = this.getProjectType();
    switch (type) {
      case "commercial":
        return {
          "entity_id": "company_id",
          "entity_name": "company_name",
          "entities_names": "company_names",
          "sub_entity_id": "department_id",
          "sub_entity_name": "department_name"
        };
      case "lifestyle":
        return {
          "entity_id": "stage_id",
          "entity_name": "stage_name",
          "entities_names": "stage_names",
          "sub_entity_id": "address_id",
          "sub_entity_name": "address_name"
        };
      default:
        return {
          "entity_id": "building_id",
          "entity_name": "building",
          "entities_names": "buildings",
          "sub_entity_id": "apartment_id",
          "sub_entity_name": "apartment_number"
        };
    }
  }

  getProjectTypeName = (field) => {
    const names = this.getProjectTypeNames();
    const typeNames = {
      "company_id": this.t("Company"),
      "company_name": this.t("Company"),
      "company_names": this.t("Companies"),
      "department_name": this.t("Department"),
      "stage_id": this.t("Stage"),
      "stage_name": this.t("Stage Name"),
      "stage_names": this.t("Stages"),
      "address_name": this.t("Address"),
      "building_id": this.t("Building"),
      "building": this.t("Building"),
      "buildings": this.t("Buildings"),
      "apartment_number": this.t("Apartment Number")
    };

    return typeNames[names[field]];
  }

  isCommercialProject = () => {
    return this.currentProject ? this.currentProject.project_type == "commercial" : false;
  }

  // Categories
  getCategoriesUrl = () => {
    return `${this.apiUrl}/${this.currentProject.id}/categories`;
  }

  setCategories = (params) => {
    return this.setList(this.getCategoriesUrl(), params)
    .then(response => {
      this.categoriesData.data = response.data.data;
      this.categoriesData.total = response.data.count;
      this.categoriesData.count = response.data.count;
    });
  }

  getCategories = () => {
    return toJS(this.categoriesData.data);
  }

  addCategory = (values) => {
    return this.addItem(values, this.getCategoriesUrl())
    .then(r => this.setCategories({ limit: this.itemsPerPage }));
  }

  editCategory = (values) => {
    return this.editItem(values, this.getCategoriesUrl())
    .then(r => this.setCategories({ limit: this.itemsPerPage }));
  }

  getCurrentCategory = () => {
    return toJS(this.currentCategory);
  }

  setCurrentCategory = (category) => {
    this.currentCategory = category;
  }

  resetCurrentCategory = () => {
    this.currentCategory = null;
  }

  deleteCategory = () => {
    return this.deleteItem(this.currentCategory.id, this.getCategoriesUrl())
    .then(r => {
      this.currentCategory = null;
      this.setCategories({
        limit: 50                
      });
    });
  }

  searchCategories = (value) => {
    const params = {
      limit: 50,
      search: value
    };

    return this.setList(this.getCategoriesUrl(), params)
    .then(response => {
      this.categoriesData.searchList = response.data.data;
      return response.data.data;
    });
  }

  getCategoryNamesById = (ids, isSearch = false) => {
    let result = [];
    const data = isSearch ? this.categoriesData.searchList : this.categoriesData.data;

    ids.forEach(id => {
      if (data) {
        data.forEach(category => {
          if (id == category.id) {
            result.push(category.name);
          }
        });
      }
    });

    return result;
  }

  getCategoryNameById = (id) => {
    let result = null;
    this.categoriesData.data.forEach(category => {
      if (id == category.id) {
        result = category.name;
      }
    });

    return result;
  }

  // Other 
  setRepeatData = (count, values) => {
    this.repeatFields.count = count;
    this.repeatFields.values = values;
  }

  setRepeatValue = (value, index) => {
    if (this.repeatFields.values.indexOf(value + "") == -1) {
      this.repeatFields.values[index] = value + "";
    } else {
      NotificationManager.error(this.t("This project was choosen before."));
    }
  }

  addRepeatItem = () => {
    let count = this.repeatFields.count;
    const values = toJS(this.repeatFields.values);
    if (values[count - 1]) {
      this.repeatFields.count = ++count;
    } else {
      NotificationManager.error(this.t("Please select item."));
    }
  }

  deleteRepeatItem = (index) => {
    this.repeatFields.values.splice(index, 1);
    this.setRepeatData(--this.repeatFields.count, this.repeatFields.values);	
  }

  getRepeatNameById = (id) => {
    let result = null;
    this.projects.forEach(item => {
      if (id == item.id) {
        result = item.name;
      }
    });

    return result;
  }

  // Wysiwyg upload function
  getWysiwygFileUrl = () => {
    return `${this.apiUrl}/${this.currentProject.id}/files/wysiwyg`;
  }

  sentWysiwygFile = (file) => {
    return this.addItem({ file: file }, this.getWysiwygFileUrl());
  }
}

const store = window.ProjectStore = new ProjectStore;

export default store;

autorun(() => {
    // console.log(store);
});