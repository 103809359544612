import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  "countries": {
    AU: {
      "country": t("Australia"),
      "continent": t("Oceania")
    },
    NZ: {
      "country": t("New Zealand"),
      "continent": t("Oceania")
    },
    AE: {
      "country": t("United Arab Emirates"),
      "continent": t("Asia")
    },
    JP: {
      "country": t("Japan"),
      "continent": t("Asia")
    },
    US: {
      "country": t("United States"),
      "continent": t("North America")
    },
    VN: {
      "country": t("Vietnam"),
      "continent": t("Asia")
    },
    SG: {
      "country": t("Singapore"),
      "continent": t("Asia")
    },
    MY: {
      "country": t("Malaysia"),
      "continent": t("Asia")
    },
    IN: {
      "country": t("India"),
      "continent": t("Asia")
    },
    CN: {
      "country": t("China"),
      "continent": t("Asia")
    },
    NL: {
      "country": t("Netherlands"),
      "continent": t("Europe")
    },
    GB: {
      "country": t("United Kingdom"),
      "continent": t("Europe")
    },
    FR: {
      "country": t("France"),
      "continent": t("Europe")
    },
    SA: {
      "country": t("South Africa"),
      "continent": t("Africa")
    },
    TH: {
      "country": t("Thailand"),
      "continent": t("Asia")
    },
    BH: {
      "country": t("Bahrain"),
      "continent": t("Asia")
    },
    RU: {
      "country": t("Russian Federation"),
      "continent": t("Europe")
    }
  },

  "continents": {
    "Europe": "europe",
    "Oceania": "oceania",
    "Asia": "asia", 
    "North America": "north_america", 
    "South America": "south_america",  
    "Africa": "africa"
  }
}

export default data;