import { NotificationManager } from 'react-notifications';
import htmlToFormattedText from "html-to-formatted-text";
import moment from 'moment';
import axios from 'axios';
const ics = require('ics');

export default class Helper {
  constructor() {
    this.dropzoneStyles = {
      width: '100%',
      height: '150px',
      border: '2px dashed #b4b6c4',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'center',
      lineHeight: '150px'
    };

    this.activeDropzoneStyles = {
      borderColor: '#66bc62'
    };

    this.rejectDropzoneStyles = {
      borderColor: '#ee616f'
    };
  }

  static translate(text) {
    return text;
  }

  static importAllImages(r) {
    let result = {};
    const arr = r.keys().map(r),
      pattern = /\.(png|jpe?g|svg)$/;

    for (var i = 0; i < arr.length; ++i) {
      const name = arr[i].replace('/img/', '').replace(new RegExp(pattern, "gm"), '');
      result[name] = arr[i];
    }

    return result;
  }

  static getCookie(name) {
    const  matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  static setCoockie(name, value, options) {
    options = options || {};

    let expires = options.expires;

    if (typeof expires == "number" && expires) {
      let d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    let updatedCookie = name + "=" + value;

    for (let propName in options) {
      updatedCookie += "; " + propName;
      let propValue = options[propName];

      if (propValue !== true) {
        updatedCookie += "=" + propValue;
      }
    }

    document.cookie = updatedCookie + ';path=/;secure';
  }

  static getFormData = (obj) => {
    let formData = new FormData();

    for ( const key in obj ) {
      if (!Array.isArray(obj[key])) {
        formData.append(key, obj[key]);
      } else {
        obj[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      }
    }

    return formData;
  }

  static handleSuccess(r, callback = null) {
    if (callback) {
      callback();
    }
  }

  static handleError(error, callback = null, notification = null) {
    console.log(error);

    if (callback) {
      callback();
    }

    if (notification) {
      NotificationManager.error(notification);
    }
  }

  static handleErrors(error, list) {
    if (error.response) {
      const status = error.response.status + "";

      if (list[status]) {
        NotificationManager.error(list[status]);
      }
    }
  }

  static checkAttachment = (field) => {
    let result = "";

    if (field && field.length > 0 && field[0] instanceof File) {
      result = field[0];
    } else if (field && field.length == 0) {
      result = "";
    } else if (field && field.length > 0 && typeof field[0] == "string") {
      result = field[0];
    }

    return result;
  }

  static checkAttachments = (field) => {
    let result = false;

    if (field && field.length > 0 && field[0] instanceof File) {
      result = field[0];
    } else if (field && Array.isArray(field) && field.length == 0) {
      return null;
    }

    return result;
  }

  static getAttachmentValue = (attachment = "") => {

  }

  static isNotEmptyAttachment = (field) => {

  }

  static toggleModal(modalName) {
    let state = {};
    state[modalName] = !this.state[modalName];
    this.setState(state);
  }

  static onToggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  static onClickPaging(store, callback, page, type, subtype = "") {
    store.setPage(callback, page, type, subtype);
  }

  static downloadICS = (event) => {
    let start = moment(event.start);
    let end = moment(event.end);

    if (!end.isValid()) {
      end = start;
    }

    ics.createEvent({
      title: event.title,
      description: event.html ? htmlToFormattedText(event.html.replace(/>\s+?</g, '><').replace(/\s+/g, " ")) : "",
      busyStatus: 'FREE',
      start: [
        Number(start.format("YYYY")),
        Number(start.format("M")),
        Number(start.format("D")),
        Number(start.format("k")),
        Number(start.format("m")),
        Number(start.format("s"))
      ],
      end: [
        Number(end.format("YYYY")),
        Number(end.format("M")),
        Number(end.format("D")),
        Number(end.format("k")),
        Number(end.format("m")),
        Number(end.format("s"))
      ]
    }, (error, value) => {
      if (error) {
        console.log(error);
      }
      
      const data = new Blob([value], {type: 'text/calendar'});
      const icsUrl = window.URL.createObjectURL(data);
      let tempLink = document.createElement('a');
      tempLink.href = icsUrl;
      tempLink.setAttribute('download', 'event.ics');
      tempLink.click();
    });
  }

  static setData = (stores = [], clientId = "", projectId = "", token = "") => {
    stores.forEach(store => {
      store.setData(clientId, projectId, token);
    });
  }

  static setCounters = (stores = [], types = [], clientId = "", projectId = "") => {
    let list = [];
    stores.forEach((store, i) => {
      list.push(store.setCounters(types[i], clientId, projectId));
    });

    return axios.all(list);
  }

  static resetCounters = (stores = []) => {
    stores.forEach(store => {
      store.resetCounters();
    });
  }

  static getProjectTypeRoles = (ProjectStore, UserStore) => {
    const projectType = ProjectStore.getProjectType();

    const { roles, rolesCommercial, rolesRent } = UserStore;
    let userRoles = roles;

    if (projectType == "commercial") {
      userRoles = rolesCommercial;
    } else if (projectType == "build_to_rent") {
      userRoles = rolesRent;
    }

    return userRoles;
  }

  static setProjectTypeEntities = (projectType = "residential", stores = {}, settings = {}, isGetSubentity = false) => {
    if (projectType == "lifestyle" && stores.StageStore) {
      return stores.StageStore.setStages(settings)
      .then(r => {
        stores.StageStore.setCurrentStage(stores.StageStore.pages.stages.data[0]);
      })
      .then(r => {
        if (isGetSubentity) {
          const id = stores.StageStore.currentStage.id;
          stores.StageStore.setAddresses({ entity_id: id });
        }
      });
    } else if (projectType == "commercial" && stores.CompanyStore) {
      return stores.CompanyStore.setCompanies(settings)
      .then(r => {
        stores.CompanyStore.setCurrentCompany(stores.CompanyStore.pages.companies.data[0]);
      })
      .then(r => {
        if (isGetSubentity) {
          const id = stores.CompanyStore.currentCompany.id;
          stores.CompanyStore.setDepartments({ entity_id: id });
        }
      });
    } else if ((projectType == "residential" || projectType == "build_to_rent") && stores.BuildingStore) {
      return stores.BuildingStore.setBuildings(settings)
      .then(r => {
        stores.BuildingStore.setCurrentBuilding(stores.BuildingStore.pages.buildings.data[0]);
      })
      .then(r => {
        if (isGetSubentity) {
          const id = stores.BuildingStore.pages.buildings.current.id;
          stores.BuildingStore.setApartments();
        }
      });  
    }
  }

  static getProjectTypeEntityAddress = (projectType = "residential", stores = {}) => {
    if (projectType == "lifestyle" && stores.StageStore && stores.StageStore.currentStage) {
      return stores.StageStore.currentStage.address;
    } else if (projectType == "commercial" && stores.CompanyStore && stores.CompanyStore.currentCompany) {
      return stores.CompanyStore.currentCompany.postal_address;
    } else if ((projectType == "residential" || projectType == "build_to_rent") && stores.BuildingStore && stores.BuildingStore.pages.buildings.current) {
      const currentBuilding = stores.BuildingStore.pages.buildings.current;
      return currentBuilding.building_address;
    }
  }

  static setProjectTypeSubEntities = (projectType = "residential", stores = {}, settings = {}, resetEntity = true) => {
    if (projectType == "lifestyle" && stores.StageStore) {
      if (resetEntity) {
        stores.StageStore.resetCurrentStage();
      }
      stores.StageStore.setAddresses(settings);
    } else if (projectType == "commercial" && stores.CompanyStore) {
      if (resetEntity) {
        stores.CompanyStore.resetCurrentCompany();
      }
      stores.CompanyStore.setDepartments(settings);
    } else if ((projectType == "residential" || projectType == "build_to_rent") && stores.BuildingStore) {
      if (resetEntity) {
        stores.BuildingStore.resetCurrentBuilding();
      }
      stores.BuildingStore.setApartments(settings);      
    }
  }

  static setProjectTypeEntity = (projectType = "residential", stores = {}, subentity) => {
    let entity = null;
    if (projectType == "lifestyle" && stores.StageStore) {
      entity = stores.StageStore.setStage(subentity.entity_id);
    } else if (projectType == "commercial" && stores.CompanyStore) {
      entity = stores.CompanyStore.setCompany(subentity.company_id);
    } else if ((projectType == "residential" || projectType == "build_to_rent") && stores.BuildingStore) {
      entity = stores.BuildingStore.setBuilding(subentity.building_id);
    }

    return entity;
  }

  static setProjectTypeSubEntity = (projectType = "residential", stores = {}, id) => {
    let subentity = null;
    if (projectType == "lifestyle" && stores.StageStore) {
      subentity = stores.StageStore.setAddressById(id)
      .then(response => {
        stores.StageStore.setNotes();
        stores.StageStore.setDocuments();
        stores.StageStore.setOwners();
        return response;
      });
    } else if (projectType == "commercial" && stores.CompanyStore) {
      subentity = stores.CompanyStore.setDepartmentById(id)
      .then(response => {
        stores.CompanyStore.setNotes();
        stores.CompanyStore.setDocuments();
        return response;
      });
    } else if ((projectType == "residential" || projectType == "build_to_rent") && stores.BuildingStore) {
      subentity = stores.BuildingStore.setApartmentById(id)
      .then(response => {
        stores.BuildingStore.setNotes();
        stores.BuildingStore.setDocuments();
        stores.BuildingStore.setOwners();
        return response;
      });
    }

    return subentity;
  }

  static getProjectTypeSubEntities = (projectType = "residential", stores = {}) => {
    if (projectType == "lifestyle" && stores.StageStore) {
      return stores.StageStore.pages.addresses;
    } else if (projectType == "commercial" && stores.CompanyStore) {
      return stores.CompanyStore.pages.departments;
    } else if ((projectType == "residential" || projectType == "build_to_rent") && stores.BuildingStore) {
      return stores.BuildingStore.pages.apartments;
    }
  }

  static getEntityId = (stores) => {
    const { ProjectStore, CompanyStore, BuildingStore, StageStore } = stores;
    const buildings = BuildingStore.pages.buildings.data;
    const projectType = ProjectStore.getProjectType();

    if (projectType == "commercial") {
      return CompanyStore.currentCompany ? CompanyStore.currentCompany.id : ""
    } else if (projectType == "lifestyle") {
      return StageStore.currentStage ? StageStore.currentStage.id : ""
    }
    
    return buildings && buildings[0] ? buildings[0].id : "";
  }

  static getEntityData = (stores) => {
    const { ProjectStore, CompanyStore, BuildingStore, StageStore } = stores;
    const projectType = ProjectStore.getProjectType();

    if (projectType == "commercial") {
      return CompanyStore.pages.companies.data;
    } else if (projectType == "lifestyle") {
      return StageStore.pages.stages.data;
    }

    return BuildingStore.pages.buildings.data;
  }

  static prepareCounters = (counters, list) => {
    let result = [];

    Object.keys(list).forEach(key => {
      result.push({
        title: list[key],
        count: counters['count_' + key]
      });     
    });

    return result;
  }
}

export function translate(text) {
  return text;
}