import { autorun, observable, toJS } from "mobx";
import data from "../data/Cases.js";
import Store from "./Store.js";
import moment from "moment";
import axios from "axios";

class CaseStore extends Store {
  @observable currentCase = null;
  @observable defaultValues = null;
  @observable documents = null;
  @observable currentDocument = null;
  @observable lastCaseId = null;
  @observable pages = {
    all: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    quote_request: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    issued: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    issued_done: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    pending: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    in_progress: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    confirmed: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    completed: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    canceled: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        is_assigned: "",
        search: ""
      }
    },
    assets: {
      data: null,
      offset: 0,
      total: 0
    },
    contractors: {
      data: null,
      offset: 0,
      total: 0
    },
    emails: {
      data: null,
      offset: 0,
      total: 0
    },
    reportings: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        type: "",
        status: "all",
        entity_id: "",
        entity_name: "",
        date_start: null,
        date_end: null
      }
    },
    documents: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    counters: null
  };
  @observable currentHistory = null;
  @observable currentContractor = null;
  @observable currentAsset = null;
  @observable currentEmail = null;
  @observable filterValues = {
    is_assigned: "",
    type: "",
    search: ""
  };

  constructor() {
    super();
    this.setDefaultValues();
    this.projectId = null;
    this.types = data.types;
    this.statuses = data.statuses;
    this.priorities = data.priorities;
    this.hazardTypes = data.hazardTypes;
    this.areas = data.areas;
    this.logsLimit = 500;
    this.reportsLimit = 100;
  }

  setDefaultValues() {
    let defaultValues = {
      type: "repair_maintenance",
      is_assigned: "1",
      linkedAsset: "1",
      typeData: {
        "repair_maintenance": {
          area: "common",
          priority: "medium"
        },
        "maintenance": {
          area: "common",
          priority: "medium"
        },
        "cleaning": {
          area: "common",
          requirePayment: "Requires payment",
          priority: "medium",
          is_approved_required: "1",
          is_payment_required: "1"
        },
        "defects": {
          area: "common",
          priority: "medium",
          is_approved_required: "1"
        },
        "ohs": {
          priority: "medium"
        },
        "project": {
          area: "common",
          priority: "medium"
        },
        "other": {
          area: "common",
          priority: "medium"
        },
      }
    };

    if (this.currentCase) {
      defaultValues = toJS(this.currentCase);
      defaultValues = this.prepareDefaultData(defaultValues);
    }

    this.defaultValues = defaultValues;
  }

  prepareDefaultData = (values) => {
    let defaultValues = JSON.parse(JSON.stringify(values));
    defaultValues.typeData = {};
    defaultValues.is_assigned = values.is_assigned + "";
    defaultValues.linkedAsset = defaultValues.asset_id ? '1' : '0';
    defaultValues.estimatedDate = moment(defaultValues.date_estimated, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
    defaultValues.estimatedTime = moment(defaultValues.date_estimated, "YYYY-MM-DD HH:mm:ss").format('HH:mm:ss');
    const type = this.currentCase.type;

    switch (type) {
      case "repair_maintenance":
        defaultValues.typeData[type] = {
          area: this.currentCase.area,
          location: this.currentCase.location,
          description: this.currentCase.description,
          asset_type: this.currentCase.asset_type,
          job_activity: this.currentCase.job_activity,
          priority: this.currentCase.priority
        };
      break;
      case "cleaning":
        defaultValues.typeData[type] = {
          area: this.currentCase.area,
          location: this.currentCase.location,
          description: this.currentCase.description,
          job_activity: this.currentCase.job_activity,
          is_payment_required: this.currentCase.is_payment_required + "",
          priority: this.currentCase.priority,
          is_approved_required: this.currentCase.is_approved_required + ""
        };
      break;
      case "defects":
        defaultValues.typeData[type] = {
          area: this.currentCase.area,
          location: this.currentCase.location,
          description: this.currentCase.description,
          priority: this.currentCase.priority,
          is_approved_required: this.currentCase.is_approved_required + ""
        };
      break;
      case "ohs":
        defaultValues.typeData[type] = {
          hazard_type: this.currentCase.hazard_type,
          location: this.currentCase.location,
          people_involved: this.currentCase.people_involved,
          injury_type: this.currentCase.injury_type,
          description: this.currentCase.description,
          outcome: this.currentCase.outcome,
          priority: this.currentCase.priority
        };
      break;
      case "project":
        defaultValues.typeData[type] = {
          area: this.currentCase.area,
          location: this.currentCase.location,
          description: this.currentCase.description
        };
      break;
      case "other":
        defaultValues.typeData[type] = {
          area: this.currentCase.area,
          location: this.currentCase.location,
          description: this.currentCase.description,
          asset_type: this.currentCase.asset_type,
          job_activity: this.currentCase.job_activity,
          priority: this.currentCase.priority
        };
      break;
    }

    return defaultValues;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/cases`);
    this.clientId = clientId;
    this.projectId = projectId;
  }

  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    this.setCases(type, this.prepareFilterValues(this.filterValues));
  }

  setAllCases = () => {
    const statuses = [ 'all', 'quote_request', 'issued', 'issued_done', 'confirmed', 'completed','canceled' ];
    let promises = [];

    statuses.forEach(status => {
      promises.push(this.setCases(status, { status: status }));
    });

    return axios.all(promises);
  }

  setCases = (status, params = {}) => {
    params = this.getParams(this.pages[status], params);

    return this.setList(null, params)
    .then(response => {
      this.pages[status].data = response.data.data;
      this.pages[status].total = response.data.count;
      this.pages[status].count = response.data.count;
    });
  }

  setCase = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentCase = response.data;
      this.prepareDefaultData(response.data);
    });
  }

  resetCurrentCase = () => {
    this.currentCase = null;
  }

  prepareCase = (item) => {
    if (item.typeData) {
      Object.keys(item.typeData[item.type]).forEach(key => {
        item[key] = item.typeData[item.type][key];
      });
    }

    return item;
  }

  addCase = (values) => {
    return this.addItem(this.prepareCase(values));     
  }

  editCase = (values) => {
    return this.editItem(this.prepareCase(values))
    .then(response => {
      this.currentCase = response.data;
      this.setCase(this.currentCase.id);
    }); 
  }

  finalizeCase = (data) => {
    let caseItem = toJS(this.currentCase);
    return this.editCase(Object.assign(caseItem, {
      status: "in_progress",
      expected_duration: data.expected_duration,
      max_budget: data.max_budget,
      due_date: `${data.dueDate} ${data.dueTime}`
    }));
  }

  completeCase = () => {
    return this.editCase({
      id: this.currentCase.id,
      status: "completed"
    });
  }

  deleteCase = () => {
    return this.deleteItem(this.currentCase.id)
    .then(r => {
      this.currentCase = null;
      return this.setAllCases();
    });
  }

  unassignCase = () => {
    return this.editCase({
      id: this.currentCase.id,
      contractor_id: 0,
      contractor_name: ""
    });
  }

  getWONumber = (isQuoteRequest = false, value) => {
    if (value) {
      return value;
    } else {
      if (this.pages.all.data) {
        return `${isQuoteRequest ? "QR_" : ""}${moment().format('YYMM')}`;
      } 

      return moment().format('YYMM');
    }
  }

  // Work Order History (Assets)
  setAssetCases = (assetId, options = {}) => {
    const url = `${this.apiUrl.replace("/cases", "")}/assets/${assetId}/cases`;
    let params = {
      offset: this.pages.all.offset
    };

    return this.setList(url, options ? Object.assign(params, options) : params)
    .then(response => {
      this.pages.all.data = response.data.data;
      this.pages.all.total = response.data.count;
      this.pages.all.count = response.data.count;
    });
  }

  // WO Logs
  setReportings = (params = {}) => {
    params.offset = this.pages.reportings.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.reportings.data = response.data.data;
      this.pages.reportings.total = response.data.count; 
    });
  }

  resetLogs = () => {
    this.pages.reportings.data = null;
  }

  setCurrentHistory = (history) => {
    this.currentHistory = history;
  }

  resetCurrentHistory = () => {
    this.currentHistory = null;
  }

  setLogFilters = (values) => {
    this.pages.reportings.filterValues = values;
  }

  setLogCounters = () => {
    const url = `${this.apiUrl.replace("/cases", "")}/counters/cases`;
    return this.setList(url)
    .then(response => {
      this.pages.counters = response.data;
    });
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl}/${this.currentCase.id}/documents`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.total = response.data.count;
      this.pages.documents.data = response.data.data;
    });
  }

	setCurrentDocument = (document) => {
		this.pages.documents.current = document;
	}

	resetCurrentDocument = () => {
		this.pages.documents.current = null;
	}

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl())
    .then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.pages.documents.current.id, this.getDocumentsUrl())
    .then(r => {
      this.pages.documents.current = null;
      return this.setDocuments();
    });
  }

  // Assets
  getAssetsUrl = () => {
    return `${this.apiUrl}/${this.currentCase.id}/assets`;
  }

  setAssets = () => {
    return this.setList(this.getAssetsUrl())
    .then(response => {
      this.pages.assets.data = response.data.data;
      return response;
    });
  }

  setCurrentAsset = (asset) => {
    this.currentAsset = asset;
  }

  resetCurrentAsset = () => {
    this.currentAsset = null;
  }

  addAsset = (values, url = null) => {
    return this.addItem(values, url || this.getAssetsUrl())
    .then(r => {
      return this.setAssets();
    });
  }

  editAsset = (values) => {
    return this.editItem(values, this.getAssetsUrl())
    .then(r => {
      return this.setAssets();
    });
  }

  deleteAsset = () => {
    return this.deleteItem(this.currentAsset.id, this.getAssetsUrl())
    .then(r => {
      return this.setAssets();
    });
  }

  // Contractor
  getContractorUrl = () => {
    return `${this.apiUrl}/${this.currentCase.id}/contractor`;
  }

  setContractor = () => {
    return this.setList(this.getContractorUrl());
  }

  addContractor = (values, url = null) => {
    return this.addItem(values, url || this.getContractorUrl());
  }

  editContractor = (values) => {
    return this.editItem(values, this.getContractorUrl());
  }

  deleteContractor = () => {
    return this.deleteItem(this.currentContractor.id, this.getContractorUrl());
  }

  // Emails
  sendEmail = (values) => {
    return this.addItem(values, `${this.apiUrl}/${this.currentCase.id}/email`);
  }

  // Other   
  exportCSV = (params = {}) => {
    return this.export(`${this.apiUrl.replace('/cases', '')}/export/cases`, params).then(response => response.data);
  }
}

const store = window.CaseStore = new CaseStore;

export default store;

autorun(() => {
    // console.log(store);
});