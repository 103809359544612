import React from 'react';
import { T } from 'lioness';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { observer, inject } from 'mobx-react';
import { Button, Nav, NavItem, NavLink, Popover, PopoverBody, Modal, ModalBody } from 'reactstrap';
import Helper from "../../helpers/Helper";

@inject('AuthStore', 'AccessStore', 'ProjectStore', 'NavigationStore', 'LocalizationStore')
@withRouter
@observer
export default class UserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPopoerOpen: false,
      modal: false
    }
  }

  render() {
    const { user, getCookie } = this.props.AuthStore;
    const { currentProject } = this.props.ProjectStore;

    if (!user) {
      return <div />;
    }

    const userClass = this.state.isPopoerOpen ? "user clearfix menu-show": "user clearfix";
    const userId = getCookie('userId');
    const projectUrl = this.props.NavigationStore.getUrl(this.props.match);

    let avatar = <img src="/img/avatar.png" className="avatar" alt="User avatar"/>;

    if (user.avatar && user.avatar != 'null') {
      avatar = <img src={user.avatar} className="avatar" alt="User avatar"/>;
    }

    return (
      <div>
        <NavLink id="user" 
          href="#"
          onClick={this.togglePopover}
        >
          <div className={userClass}>
            {avatar}
            <div className="name d-none d-sm-block" >
              <span>{user["first_name"]}</span>
              <div className="project">
                {currentProject ? currentProject.name : ""}
              </div>
            </div>
          </div>
        </NavLink>

        <Popover 
          isOpen={this.state.isPopoerOpen} 
          toggle={this.togglePopover}
          target="user" 
          placement="bottom-end"                
        >
          <PopoverBody className="wide user-menu-wrapper">
            {this.renderProjectSwitcher(this.props.clientId)}
            <Nav vertical className="profile-menu">
              <NavItem>
                <Link to={`${projectUrl != '/' ? projectUrl : ''}/profile/${userId}`}
                  className="nav-link"
                  onClick={e => this.togglePopover(null, e)}
                >
                  <T>Profile</T>
                </Link>
              </NavItem>
              <NavItem>
                <NavLink href="#" 
                  onClick={this.openLogOutModal}
                >
                  <T>Log out</T>
                </NavLink>
              </NavItem>
            </Nav>
          </PopoverBody>
        </Popover>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalBody>
            <div className="logout-message">
              <T>Are you sure you want to Log out?</T>
            </div>
            <div className="logout-actions row">
              <div className="col">
                <Button size="lg" color="white" block onClick={this.toggleModal}>
                  <T>Cancel</T>
                </Button>
              </div>
              <div className="col">
                <Button color="primary" 
                  size="lg" 
                  block 
                  onClick={this.logOut}
                >
                  <T>Log out</T>
                </Button>
              </div>							
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  renderProjectSwitcher = (id) => {
    const { AuthStore, AccessStore } = this.props;
    const { getProjects, projects } = this.props.ProjectStore;

    if (!id || projects == null) {
      return <div />;
    }

    if (!AccessStore.checkAccess("user_profile", "switch_between_project", AuthStore.user)) {
      return <div />;
    }

    const list = getProjects();
    const result = list.map(this.renderProject);

    return (
      <div>
        <div className="title">
          <T>Switch projects</T>
        </div>
        <Nav vertical className="projects-menu">
          {result}
        </Nav>
      </div>
    );
  }

  renderProject = (project) => {
    const { currentProject } = this.props.ProjectStore;
    const { user } = this.props.AuthStore;

    if (user.role == "manager" && user.projects_restrictions.indexOf(project.id + "") != -1) {
      return null;
    }

    return (
      <NavItem key={`project-${project.id}`}>
        <Link to={`/client/${this.props.clientId}/project/${project.id}/`}
          className={currentProject && project.id == currentProject.id ? "nav-link active": "nav-link"}
          key={"project-" + project.name}
          onClick={e => this.setProject(project)}
        >
          {project.name}
        </Link>
      </NavItem>
    );
  }

  setProject = (project) => {
    const { ProjectStore, NavigationStore, LocalizationStore } = this.props;

    NavigationStore.setActiveLink('/');
    ProjectStore.setProject(project.id)
    .then(r => {
      LocalizationStore.setViewLanguage();
      this.togglePopover();
    })
    .catch(Helper.handleError);
  }

  togglePopover = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      isPopoerOpen: !this.state.isPopoerOpen
    });
  }

  toggleModal = (e) => {
    this.setState({
      modal: !this.state.modal
    });
  }

  openLogOutModal = (e) => {
    e.preventDefault();

    this.setState({
      isPopoerOpen: false,
      modal: true
    });
  }

  logOut = (e) => {
    const { AuthStore } = this.props;
    this.toggleModal(e);
    
    AuthStore.logOut()
    .then(r => this.goTo('login'))
    .catch(Helper.handleError);
  }

  goTo = (link) => {
    this.props.NavigationStore.goToPage(link);
  }
}