import React, { useState, useEffect, useRef } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import { Badge, NavLink, Popover, PopoverBody } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { T, withTranslators } from 'lioness';
import { withRouter } from "react-router-dom";

import Helper from '../../helpers/Helper';

const Notifications = (props) => {
  const { NotificationStore, NavigationStore, AuthStore, t, match } = props;
  const { notifications, notReadCount } = NotificationStore;

  const [ isShownNotifications, toggleNotifications ] = useState(false);
  const scrollbar = useRef(null);

  useEffect(() => {
    const { projectId, clientId } = match.params;
    NotificationStore.setData(null, null, AuthStore.token);

    NotificationStore.setNotifications()
    .catch(Helper.handleError);
  }, [ AuthStore.token ]);

  if (!notifications || notifications.length == 0) {
    return null;
  }

  return (
    <div>
      <NavLink id="notification" href="#" onClick={e => toggleNotifications(prev => !prev)}>
        <div className="badge-wrapper top">
          <img src={props.images['notification-dark']} alt="Notification list"/>
          {notReadCount ? <Badge color="danger">{notReadCount}</Badge> : null}
        </div>
      </NavLink>

      <Popover 
        isOpen={isShownNotifications} 
        toggle={() => toggleNotifications(false)}
        target="notification" 
        placement="bottom-end"                
      >
        <PopoverBody className="notification-list">
          <Scrollbars 
            style={{ height: "210px" }}
            onScrollStop={handleScrollStop}
            ref={scrollbar}
          >
            <div className="notification-header">
              <div className="title">
                <T>Help Centre Notifications</T>
                <div 
                  className="text-right mark-read"
                  onClick={e => {
                    NotificationStore.readAllNotifications()
                    .then(r => NotificationStore.setNotifications())
                    .catch(Helper.handleError);
                  }}
                >
                  <span>Mark as All Read</span>
                </div>
              </div>
            </div>
            <ul className="notifications list-unstyled">
              {notifications.map((item, index) => renderNotification(item, index))}
            </ul>
          </Scrollbars>
        </PopoverBody>
      </Popover>
    </div>
  )

  function renderNotification(notification, index) {
    const types = NotificationStore.types;
    let className = "item";

    if (notification.is_read === 0) {
      className += " unread";
    }

    return (
      <li className={className} 
        onClick={e => onRead(e, notification)}
        key={`notification-${index}`}
      >
        <div className="type">
          {t(types[notification.type])}
        </div>
        <div className="message">
          {notification.title}
        </div>
      </li>
    );
  }

  function onRead(e, item) {
    const projectUrl = `client/${item.client_id}/project/${item.project_id}`;
    const url = `${AuthStore.user && AuthStore.user.role != "admin" && item.project_id != 0 ? projectUrl + "/" : ""}${NotificationStore.urls[item.type]}/${item.entity_id}`;

    NotificationStore.readNotification(item)
    .then(r => NavigationStore.goToPage(url))
    .then(r => NotificationStore.setNotifications())
    .then(r => toggleNotifications(false))
    .catch(Helper.handleError);
  }

  function handleScrollStop() {
    const { scrollHeight, scrollTop, clientHeight } = scrollbar.current.getValues();

    if (scrollHeight == scrollTop + clientHeight) {
      appendNotitications();
    }
  }

  function appendNotitications() {
    const { count, notifications, appendNotifications } = NotificationStore;
    const length = notifications.length;

    if (length < count) {
      appendNotifications({ offset: length })
      then(r => {
        console.log(NotificationStore.notifications.length);
      })
      .catch(Helper.handleError);
    }
  }
}

export default withTranslators(withRouter(inject('NotificationStore', 'NavigationStore', 'AuthStore')(observer(Notifications))));