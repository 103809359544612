import { autorun, observable } from "mobx";
import axios from "axios";
import Helper from "../helpers/Helper";
import data from "../data/Emails.js";
import Store from "./Store";

class EmailStore extends Store {
  @observable pages = {
    emails: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    scheduled: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    signatures: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    users: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    reports: {
      data: null,
      total: 0,
      offset: 0,
      filters: null
    },
    email_templates: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    userEmails: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    }
  }
  @observable outgoingEmails = {
    member_registration: {
      data: null,
      offset: 0,
      total: 0
    },
    help_desk: {
      data: null,
      offset: 0,
      total: 0
    },
    help_desk_reminder: {
      data: null,
      offset: 0,
      total: 0
    },
    bookings: {
      data: null,
      offset: 0,
      total: 0
    },
    event: {
      data: null,
      offset: 0,
      total: 0
    },
    locker: {
      data: null,
      offset: 0,
      total: 0
    },
    incident: {
      data: null,
      offset: 0,
      total: 0
    }
  }
  @observable currentEmail = null;
  @observable currentSignature = null;
  @observable emailList = null;
  @observable outgoingEmail = null;

  constructor() {
    super();
    this.t = Helper.translate;
    this.categories = data.categories;
    this.htmlTemplateBeforeTitle = data.htmlTemplateBeforeTitle;
    this.htmlTemplateAfterTitle = data.htmlTemplateAfterTitle;
    this.htmlTemplateEnd = data.htmlTemplateEnd;
    this.notificationTypes = data.notificationTypes;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/emails`);
    this.signatureUrl = `${this.url}staff/signatures`;
  }

  setPage = (page, type, callback = "setEmails") => {
    let data = this.pages[type];
    
    data.offset = page * this.itemsPerPage;
    this[callback]({}, type);   
  }

  setEmails = (params = {}, type = "emails") => {
    params.offset = this.pages[type].offset;

    if (type == "scheduled") {
      params.is_scheduled = 1;
    }

    return this.setList(this.apiUrl, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
      return response;
    });
  }

  resetEmails = () => {
    this.pages.emails.data = null;
    this.pages.emails.total = 0;
  }

  setEmail = (id, sortObj = {}) => {
    return this.setItem(id, this.apiUrl, sortObj)
    .then(response => {
      this.pages.emails.current = response.data.email;
      this.emailList = response.data.data;
    });
  }

  addEmail = (values) => {
    return this.addItem(values);
  }

  setCurrentEmail = (email) => {
    this.pages.emails.current = email;
  }

  resetCurrentEmail = () => {
    this.pages.emails.current = null;
    this.emailList = null;
  }

  deleteEmail = (params = {}) => {
    return this.deleteItem(this.pages.emails.current.id)
    .then(r => {
      this.pages.emails.current = null;
      this.setEmails(params);
    });
  }

  exportEmails = (type) => {
    let data = { 
      params: { access_token: this.token } 
    };

    return axios.get(`${this.apiUrl.replace('/emails', '')}/export/emails/${type}`, data)
    .then(response => response.data);
  }

  // Users
  getUsersUrl = () => {
    return `${this.apiUrl}/${this.pages.emails.current.id}/users`;
  }

  setUsers = (params = {}) => {
    params.offset = this.pages.users.offset;

    return this.setList(this.getUsersUrl(), params)
    .then(response => {
      this.pages.users.data = response.data.data;
      this.pages.users.total = response.data.count;
    });
  }

  resetUsers = () => {
    this.pages.users.data = null;
  }

  // Reports
  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.reports.filters = params;
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count;
    });
  }

  // Signatures
  setSignatures = (params = {}) => {
    params.offset = this.pages.signatures.offset;

    return this.setList(this.signatureUrl, params)
    .then(response => {
      this.pages.signatures.data = response.data.data;
      this.pages.signatures.total = response.data.count;
      return response;
    });
  }

  setCurrentSignature = (signature) => {
    this.pages.signatures.current = signature;
  }

  resetCurrentSignature = () => {
    this.pages.signatures.current = null;
  }

  addSignature = (values) => {
    return this.addItem(values, this.signatureUrl)
    .then(r => this.setSignatures());
  }

  editSignature = (values) => {
    return this.editItem(values, this.signatureUrl)
    .then(response => {
      this.pages.signatures.current = response.data;
      this.setSignatures();
    });
  }

  deleteSignature = () => {
    return this.deleteItem(this.pages.signatures.current.id, this.signatureUrl)
    .then(r => {
      this.pages.signatures.current = null;
      this.setSignatures();
    });
  }

  // Incoming Emails Setup
  outgoingEmailsUrl = () => {
    return `${this.apiUrl.replace("/emails", "")}/email-notifications`;
  }

  setAllOutgoingEmails = () => {
    const types = [ "member_registration", "help_desk", "help_desk_reminder", "bookings", "event", "locker", "incident" ];        
    let promises = [];

    types.forEach(type => {
      promises.push(this.setOutgoingEmails(type));
    });

    return axios.all(promises);
  }

  setOutgoingEmails = (type = "member_registration") => {
    const params = {
      type: type,
      offset: this.pages.signatures.offset
    };

    return this.setList(this.outgoingEmailsUrl(), params)
    .then(response => {
      this.outgoingEmails[type].data = response.data.data;
      return response;
    });
  }

  setOutgoingEmail = (email) => {
    this.outgoingEmail = email;
  }

  resetOutgoingEmail = () => {
    this.outgoingEmail = null;
  }

  addOutgoingEmail = (values) => {
    return this.addItem(values, this.outgoingEmailsUrl())
    .then(r => this.setOutgoingEmails(values.type));
  }

  editOutgoingEmail = (values) => {
    return this.editItem(values, this.outgoingEmailsUrl())
    .then(response => {
      this.outgoingEmail = response.data;
      this.setOutgoingEmails(values.type);
    });
  }

  deleteOutgoingEmail = () => {
    const type = this.outgoingEmail.type;
    return this.deleteItem(this.outgoingEmail.id, this.outgoingEmailsUrl())
    .then(r => {
      this.outgoingEmail = null;
      this.setOutgoingEmails(type);
    });
  }

  // Notifications
  getNotificationsUrl = (type = "") => {
    return `${this.apiUrl.replace("emails", "email-templates")}${type != "" ? "/" + type : ""}`;
  }

  setNotifications = (params = {}) => {
    return this.setList(this.getNotificationsUrl(), params)
    .then(response => {
      this.pages.email_templates.data = response.data.data;
      this.pages.email_templates.total = response.data.count;
    });
  }

  setNotificationByType = (type = "member_verification") => {
    return this.setList(this.getNotificationsUrl(type))
    .then(response => {
      console.log(response.data.data);
    })
  }

  addNotification = (values) => {
    return this.addItem(values, this.getNotificationsUrl(values.type))
    .then(r => this.setNotifications());
  }

  editNotification = (values) => {
    return this.editItem(values, this.getNotificationsUrl(values.type))
    .then(r => this.setNotifications());
  }

  // User Emails
  getUserEmailsUrl = (userId) => {
    return `${this.apiUrl.replace("/emails", `/users/${userId}/emails`)}`;
  }

  setUserEmails = (userId, params = {}) => {
    return this.setList(this.getUserEmailsUrl(userId), params)
    .then(response => {
      this.pages.userEmails.data = response.data.data;
      this.pages.userEmails.total = response.data.count;
      return response;
    });
  }

  // Other
  exportEmailPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/emails", "/export/pdf/emails")
    )
    .finally(this.hideLoader);
  }
}

const store = window.EmailStore = new EmailStore;

export default store;

autorun(() => {
    // console.log(store);
});