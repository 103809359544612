import { autorun, observable, toJS } from "mobx";
import Store from "./Store.js";
import data from "../data/Offers";

class OffersStore extends Store {
  @observable pages = {
    offers: {
      data: null,
      offset: 0,
      total: 0,
      count_featured: 0,
      filterValues: {
        category: "",
        search: ""
      }
    }
  };
  @observable currentOffer = null;

  constructor() {
    super();
    this.categories = data.categories;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/offers`);
  }

  setPage = (page) => {
    this.pages.offers.offset = page * this.itemsPerPage;
    this.setOffers();
  }

  setOffers = (params = {}) => {
    let data = {
      offset: this.pages.offers.offset
    };

    return this.setList(null, params ? Object.assign(data, params) : data)
    .then(response => {
      this.pages.offers.data = response.data.data;
      this.pages.offers.total = response.data.count;
      this.pages.offers.count_featured = response.data.count_featured;
    });
  }

  setOffer = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentOffer = response.data;
      return response;
    });
  }

  setCurrentOffer = (item) => {
    this.currentOffer = item;
  }

  resetCurrentOffer = () => {
    this.currentOffer = null;
  }

  addOffer = (values) => {
    return this.addItem(values);
  }

  editOffer = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentOffer = response.data;
      return response;
    });
  }

  deleteOffer = () => {
    return this.deleteItem(this.currentOffer.id)
    .then(r => {
      this.currentOffer = null;
    });
  }
}

const store = window.OffersStore = new OffersStore;

export default store;

autorun(() => {
  // console.log(store);
});