import { autorun, observable, toJS } from "mobx";
import { translate } from "../helpers/Helper";
import userData from "../data/Users.js";
import ticketData from '../data/Tickets.js';
import Store from "./Store";
import axios from "axios";

class HelpDeskStore extends Store {
  @observable tickets = null;
  @observable currentTicket = null;
  @observable messages = null;
  @observable pageOffset = 0;
  @observable totalItems = 0;
  @observable pages = {
    "all": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      unread: 0,
      filterValues: {
        search: "",
        category: ""
      }
    },
    "open": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      unread: 0,
      filterValues: {
        search: "",
        category: ""
      }
    },
    "in_progress": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      unread: 0,
      filterValues: {
        search: "",
        category: ""
      }
    },
    "resolved": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      unread: 0,
      filterValues: {
        search: "",
        category: ""
      }
    },
    reports: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        status: "all",
        category: "",
        date_start: null,
        date_end: null
      }
    }
  };

  constructor() {
    super();
    this.t = translate;
    this.statuses = ticketData.statuses;
    this.categories = ticketData.categories;
    this.locations = ticketData.locations;
    this.actions = ticketData.actions;
    this.roles = userData.roles;
    this.roleNames = userData.roleNames;

    this.reportsLimit = 100;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/tickets`);
  }

  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    let params = {
      status: type,
      category: this.pages[type].filterValues.category,
      search: this.pages[type].filterValues.search
    };

    if (this.pages[type].filterValues.has_new_manager == "1") {
      params.has_new_manager = "1";
    }
    
    this.setTicketsByStatus(params);
  }

  setAllTickets = () => {
    const statuses = [ "all", "open", "in_progress", "resolved" ];        
    let promises = [];

    statuses.forEach(status => {
      promises.push(this.setTicketsByStatus({status: status}));
    });

    return axios.all(promises);
  }

  setTicketsByStatus = (params) => {
    params.offset = this.pages[params.status].offset;

    return this.setList(null, params)
    .then(response => {
      if (response.data) {
        this.pages[params.status].data = response.data.data;
        this.pages[params.status].total = response.data.count;
        this.pages[params.status].count = response.data.count;
        this.pages[params.status].unread = response.data.count_unread;
      }
    });
  }

  setTicket = (id) => {
    return this.setItem(id, null, { limit: 1000 })
    .then(response => {
      this.currentTicket = response.data;
      return response;
    });
  }

  addTicket = (values) => {
    return this.addItem(values).then(response => {
      this.setAllTickets();
      return response;
    });
  }

  editTicket = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentTicket = response.data;
      // this.currentTicket.status = response.data.status;
    });
  }

  getCurrentTicket = () => {
    return toJS(this.currentTicket);
  }

  resetTicket = () => {
    this.currentTicket = null;
  }

  deleteTicket = () => {
    return this.deleteItem(this.currentTicket.id)
    .then(r => {
      this.currentTicket = null;
      return this.setAllTickets();
    });
  }

  // Messages
  getMessageUrl = (id) => {
    return `${this.apiUrl}/${id}/message`;
  }

  addMessage = (id, message) => {
    let item = {
      description: message,
      type: "text"
    };

    return this.addItem(item, this.getMessageUrl(id))
    .then(r => {
      this.setTicket(id);
    });
  }

  addFileMessage = (id, files) => {
    const item = {
      filename: files[0],
      type: "file"
    };

    return this.addItem(item, this.getMessageUrl(id))
    .then(r => {
      this.setTicket(id);
    });
  }

  // Reporting
  setReportsPage = (page, params = {}) => {
    const offset = page * (params.limit ? params.limit : this.itemsPerPage);
    this.pages.reports.offset = offset;
    params = {...this.pages.reports.filterValues};

    return this.setReports(params);
  }

  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count;       
    });
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        status: "all",
        category: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    if (values.limit) {
      delete values.limit;
    }
    
    this.pages.reports.filterValues = values;
  }
    
  // Other   
  exportTickets = (params = {}) => {
    return this.export(`${this.apiUrl.replace('/tickets', '')}/export/tickets`, params).then(response => response.data);
  }

  exportTicketsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/tickets", "/export/pdf/tickets")
    )
    .finally(this.hideLoader);
  }
}

const store = window.HelpDeskStore = new HelpDeskStore;

export default store;

autorun(() => {
    // console.log(store);
});