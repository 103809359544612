import React from 'react';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { T } from 'lioness';
import Helper from '../../helpers/Helper';
import ToDoList from './ToDoList';
import UserMenu from './UserMenu';
import Notifications from './Notifications';

@inject('AuthStore', 'AccessStore', 'ProjectStore', 'LocalizationStore')
@observer
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.setData();

    this.state = {
      clientId: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params != this.props.match.params) {
      const { clientId } = this.props.match.params;

      if(prevProps.match.params.projectId != this.props.match.params.projectId) {
        this.setData();
      }

      this.setState({
        clientId: clientId
      });
    }
  }

  setData() {
    const { AuthStore, ProjectStore, LocalizationStore, match } = this.props;
    const { clientId, projectId } = match.params;
    this.clientId = clientId;

    this.images = Helper.importAllImages(require.context('../../assets/img/header/', false, /\.(png|jpe?g|svg)$/));

    if (projectId) {
      ProjectStore.setData(clientId, AuthStore.token);
      ProjectStore.setProjects(clientId, AuthStore.token)
      .then(r => {
        ProjectStore.setProject(projectId)
        .then(r => LocalizationStore.setViewLanguage());
      })
      .catch(Helper.handleError);
    }
  }

  render() {
    return (
      <header className="top-navbar">
        <Navbar color="light" light fixed="fixed" expand="md">
          {this.renderClentLinks(this.clientId)}
          <Nav className="ml-auto"> 
            <Notifications 
              images={this.images}
            />
            {this.renderProjectIcons(this.clientId)}
          </Nav>
          {this.renderUserMenu(this.clientId)}
        </Navbar>        
      </header>
    );
  }

  renderClentLinks = (id) => {
    if (!id) {
      return (
        <Nav>
          <NavItem className="sidebar-toogler">
            <NavLink href="#" onClick={this.onToggle}>
              <img src={this.images['menu-dark']} alt="Toggle sidebar"/>
            </NavLink>
          </NavItem>
        </Nav>
      );
    }

    return (
      <Nav>
        <NavItem>
          <NavLink href="#" onClick={this.onToggle}>
            <img src={this.images['menu-dark']} alt="Toggle sidebar"/>
          </NavLink>
        </NavItem>
        <NavItem className="d-none d-sm-block">
          {this.renderOverviewLink()}
        </NavItem>
        <NavItem className="d-none d-sm-block">
          {this.renderProjectsLink(id)}
        </NavItem>
      </Nav>
    );    
  }

  renderOverviewLink = () => {
    const { AuthStore, AccessStore } = this.props;

    if (!AccessStore.checkAccess("top_menu", "overview", AuthStore.user)) {
      return <div />;
    }

    return (
      <Link className="nav-link" to="/clients/">
        <T>Overview</T>
      </Link>
    );
  }

  renderProjectsLink = (id) => {
    const { AuthStore, AccessStore } = this.props;

    if (!AccessStore.checkAccess("top_menu", "projects", AuthStore.user)) {
      return <div />;
    }

    return (
      <Link className="nav-link" 
        to={`/clients/${id}`}
      >
        <T>Projects</T>
      </Link>
    );
  }

  renderProjectIcons = (id) => {
    if (!id || !AccessStore.checkAccess("top_menu", "quick_access_link", AuthStore.user)) {
      return <Nav className="ml-auto" />;
    }

    return (
      <NavItem>
        <ToDoList images={this.images}
          match={this.props.match}
        />
      </NavItem>
    );
  }

  renderUserMenu = (id) => {
    let className = "";

    // if (!id) {
    //   className = "ml-auto";
    // }

    return (
      <Nav className={className}>
        <NavItem>
          <UserMenu
            clientId={id} 
            images={this.images} 
            match={this.props.match}
          />
        </NavItem>
      </Nav>
    );
  }

  onToggle = (e) => {
    e.preventDefault();
    this.props.toggleSidebar();
  }
}