import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  microservices: {
    techconcierge: t("Techconcierge")
  },
  categories: {
    "fitness_wellbeing": {
      title: t("Fitness & Wellbeing"),
      list: {
        "home_services": t("Personal Trainer"),
        "yoga": t("Yoga"),
        "pilates": t("Pilates"),
        "massage": t("Massage"),
        "group_classes": t("Group Classes"),
        "day_spa": t("Day Spa")
      }
    },
    "home_services": {
      title: t("Home Services"),
      list: {
        "house_cleaning": t("House Cleaning"),
        "car_wash": t("Car Wash"),
        "rubbish_removal": t("Rubbish Removal"),
        "house_removalist": t("House Removalist"),
        "child_care": t("Child Care"),
        "dry_cleaning": t("Dry Cleaning & Laundry")
      }
    },
    "pets": {
      title: t("Pets"),
      list: {
        "dog_walker": t("Dog Walker"),
        "grooming": t("Grooming"),
        "pet_training": t("Pet Training"),
        "veterinarian": t("Veterinarian"),
        "pet_care": t("Pet Care")
      }
    },
    "it_services": {
      title: t("IT Services"),
      list: {
        "tech_concierge": t("Tech Concierge"),
        "it_consulting": t("IT Consulting"),
        "internet_services": t("Internet Services")
      }
    },
    "trades_maintenance": {
      title: t("Trades & Maintenance"),
      list: {
        "electrician": t("Electrician"),
        "plumber": t("Plumber"),
        "painter": t("Painter"),
        "tiling": t("Tiling"),
        "glazing": t("Glazing"),
        "handyman": t("Handyman"),
        "locksmith": t("Locksmith"),
        "curtains_blinds": t("Curtains & Blinds"),
        "heating_air_conditioning": t("Heating & Air Conditioning"),
        "gutters_roofing": t("Gutters and Roofing"),
        "gardening": t("Gardening")
      }
    },
    "health_medical": {
      title: t("Health & Medical"),
      list: {
        "doctor": t("Doctor"),
        "physiotherapy": t("Physiotherapy"),
        "chiropractor": t("Chiropractor"),
        "acupuncture": t("Acupuncture"),
        "dentist": t("Dentist"),
        "naturopath": t("Naturopath"),
        "weight_loss": t("Weight Loss")
      }
    },
    "rentals_hire": {
      title: t("Rentals & Hire"),
      list: {
        "bicycles": t("Bicycles"),
        "motor_bikes": t("Motor Bikes"),
        "cars": t("Cars"),
        "trucks": t("Trucks"),
        "boat": t("Boat"),
        "limos": t("Limos")
      }
    },
    "sport_recreation": {
      title: t("Sport & Recreation"),
      list: {
        "golf_lessons": t("Golf Lessons"),
        "tennis_lessons": t("Tennis Lessons"),
        "swimming_lessons": t("Swimming Lessons"),
        "ten_pin_bowling": t("Ten Pin Bowling"),
        "fishing": t("Fishing")
      }
    },
    "hair_beauty": {
      title: t("Hair & Beauty"),
      list: {
        "nail_salon": t("Nail Salon"),
        "hairdresser": t("Hairdresser"),
        "skin_care": t("Skin Care")
      }
    },
    "events": {
      title: t("Events"),
      list: {
        "photographer": t("Photographer"),
        "wedding_planner": t("Wedding Planner"),
        "party_planner": t("Party Planner"),
        "catering": t("Catering")
      }
    },
    "professional": {
      title: t("Professional"),
      list: {
        "accountant": t("Accountant"),
        "legal": t("Legal"),
        "real_estate_agents": t("Real Estate Agents"),
        "graphic_design": t("Graphic Design")
      }
    },
  },
  ticketCategories: {
    antivirus: "Antivirus",
    backup_recovery: "Backup / Recovery",
    internet: "Internet",
    nbn: "NBN",
    mobile: "Mobile",
    tablet: "Tablet",
    printing: "Printing",
    wifi: "WiFi",
    other: "Other"
  },
  statuses: {
		open: t("Open"),
		in_progress: t("In Progress"),
		resolved: t("Resolved")
	}
};
export default data;