import React from 'react';
import { T } from 'lioness';
import { observer, inject } from 'mobx-react';
import { Nav, NavItem, NavLink, Popover, PopoverBody } from 'reactstrap';

@inject('NavigationStore')
@observer
export default class ToDoList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToDoPopoerOpen: false
    }
  }

  render() {
    if (!this.props.match.params) {
      return <div />;
    }

    const projectUrl = this.props.NavigationStore.getUrl(this.props.match);

    return (
      <div>
        <NavLink id="to-do" href="#" onClick={this.toggleToDoPopover}>
          <img src={this.props.images['todo-dark']} alt="Todo list"/>
        </NavLink>

        <Popover 
          isOpen={this.state.isToDoPopoerOpen} 
          toggle={this.toggleToDoPopover}
          target="to-do" 
          placement="bottom"                
        >
          <PopoverBody className="wide">
            <Nav vertical className="to-do-menu">
              <NavItem>
                <NavLink href={`${projectUrl}/deliveries/add/incoming`}
                  onClick={e => this.toggleToDoPopover()}
                >
                  <img src={this.props.images['parcel']} className="menu-icon" />
                  <span>
                    <T>New Incoming Delivery</T>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${projectUrl}/deliveries/add/outgoing`}
                  onClick={e => this.toggleToDoPopover()}
                >
                  <img src={this.props.images['parcel']} className="menu-icon" />
                  <span>
                    <T>New Outgoing Delivery</T>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${projectUrl}/alerts/add`}
                  onClick={e => this.toggleToDoPopover()}
                >
                  <img src={this.props.images['ticket']} className="menu-icon" />
                  <T>New Alert</T>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${projectUrl}/bookings/add`}
                  onClick={e => this.toggleToDoPopover()}
                >	
                  <img src={this.props.images['event']} className="menu-icon" />
                  <T>Create Booking</T>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`${projectUrl}/work-orders/add`}
                  onClick={e => this.toggleToDoPopover()}
                >
                  <img src={this.props.images['case']} className="menu-icon" />
                  <T>Create Work Order</T>
                </NavLink>
              </NavItem>
            </Nav>
          </PopoverBody>
        </Popover>
      </div>
    );
  }

  toggleToDoPopover = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      isToDoPopoerOpen: !this.state.isToDoPopoerOpen
    });
  }
}