import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  adminMenu: [
    {
      "title": t("Clients"),
      "href": "/clients"
    },
    {
      "title": t("Keyvision Staff"),
      "href": "/staff"
    },
    {
      "title": t("Business Directories"),
      "href": "/business"
    },
    {
      "title": t("Event Calendar"),
      "href": "/global/event-calendar"
    },
    {
      "title": t("Neighbourhood Listing"),
      "href": "/neighbourhood-listing"
    },
    {
      "title": t("Kindred Reporting"),
      "href": "/app-reports"
    },
    {
      "title": t("Analytics"),
      "href": "/analytics"
    },
    {
      "title": t("Activities"),
      "href": "/activities"
    },
    {
      "title": t("Help Centre"),
      "href": "/help-centre",
      "icon": "project_settings",
      "isStick": true
    }
  ],
  menu: [
    {
      "title": t("Member Management"),
      "isExpanded": false,
      "menu": [
        {
          "name": "",
          "title": t("Member Registrations"),
          "icon": "account",
          "href": "/registrations/",
          "badge": {
            "name": "users",
            "color": "primary"
          }
        },
        {
          "name": "feature_members_keys",
          "title": t("Member Keys"),
          "icon": "member-keys",
          "href": "/member-keys/"
        },
        {
          "name": "feature_help_desk",
          "title": t("Help Desk"),
          "icon": "help_desk",
          "href": "/helpdesk/",
          "badge": {
            "name": "tickets",
            "color": "purple"
          }
        },
        {
          "name": "feature_deliveries",
          "title": t("Parcels & Deliveries"),
          "icon": "deliveries",
          "href": "/deliveries/",
          "badge": {
            "name": "deliveries",
            "color": "danger"
          }
        },
        {
          "name": "feature_resident_documents",
          "title": t("Member Documents"),
          "icon": "docuemnt",
          "href": "/member-documents/"
        },
        {
          "name": "feature_connections",
          "title": t("Connections"),
          "icon": "connections",
          "href": "/connections",
          "is_for_commercial": false
        },
        {
          "name": "feature_directories",
          "title": t("Companies Directory"),
          "icon": "company_directory",
          "href": "/company-directory",
          "is_for_commercial": true
        },
        {
          "name": "feature_retails",
          "title": t("Retail Directory"),
          "icon": "retail_directory",
          "href": "/retail-directories"
        }
      ]
    },
    {
      "title": t("Shifts"),
      "isExpanded": false,
      "name": "feature_shift_log",
      "menu": [
        {
          "name": "feature_shift_log",
          "title": t("Shifts"),
          "icon": "roster_shift",
          "href": "/shifts/"
        },
        {
          "name": "feature_shift_log",
          "title": t("Project Tasks"),
          "icon": "my_tasks",
          "href": "/tasks/"
        },
        {
          "name": "feature_shift_log",
          "title": t("Message Board"),
          "icon": "message_board",
          "href": "/message-board/",
          "badge": {
            "name": "message_board",
            "color": "purple"
          }
        }
      ]
    },
    {
      "title": t("Communication Management"),
      "isExpanded": false,
      "menu": [
        {
          "name": "feature_newsfeed",
          "title": t("Newsfeed"),
          "icon": "newsfeed",
          "href": "/news/"
        },
        {
          "name": "feature_surveys_polls",
          "title": t("Surveys / Polls"),
          "icon": "surveys",
          "href": "/surveys-polls"
        },
        {
          "name": "feature_online_forms",
          "title": t("Online Forms"),
          "icon": "online_forms",
          "href": "/online-forms"
        },
        {
          "name": "feature_emails",
          "title": t("Emails"),
          "icon": "email",
          "href": "/emails"
        },
        {
          "name": "feature_notifications",
          "title": t("Alerts and Notifications"),
          "icon": "alerts",
          "href": "/alerts/"
        },
        {
          "name": "feature_noticeboard",
          "title": t("Noticeboard"),
          "icon": "noticeboard",
          "href": "/noticeboard"
        },
        {
          "name": "feature_faq",
          "title": t("FAQs"),
          "icon": "faqs",
          "href": "/faqs"
        },
        {
          "name": "feature_contacts",
          "title": t("Important Contacts"),
          "icon": "important_contacts",
          "href": "/contacts"
        },
        {
          "name": "feature_chatbot",
          "title": t("ChatBot - Concierge"),
          "icon": "chatbot",
          "href": "/chatbot"
        },
        {
          "name": "feature_categories",
          "title": t("Categories"),
          "icon": "online_forms",
          "href": "/categories"
        }
      ]
    },
    {
      "title": t("Place Management"),
      "isExpanded": false,
      "menu": [
        {
          "name": "feature_subentities",
          "title": t("Apartments"),
          "icon": "real_estate",
          "href": "/subentities",
          "is_for_residential": true
        },
        
        {
          "name": "feature_subentities",
          "title": t("Departments"),
          "icon": "real_estate",
          "href": "/subentities",
          "is_for_commercial": true
        },
        {
          "name": "feature_subentities",
          "title": t("Addresses"),
          "icon": "real_estate",
          "href": "/subentities",
          "is_for_lifestyle": true
        },
        {
          "name": "feature_subentities",
          "title": t("Apartments "),
          "icon": "real_estate",
          "href": "/subentities",
          "is_for_build_to_rent": true
        },
        {
          "name": "feature_building_keys",
          "title": t("Building Keys"),
          "icon": "keys",
          "href": "/building-keys/"
        },
        {
          "name": "feature_keys",
          "title": t("Keys and FOBs Orders"),
          "icon": "key_orders",
          "href": "/key-orders/",
          "badge": {
            "name": "key_orders",
            "color": "light-primary"
          }
        },
        {
          "name": "feature_safetrust",
          "title": t("SafeTrust E-FOBs"),
          "icon": "keys",
          "href": "/efobs/"
        },
        {
          "name": "feature_lockers",
          "title": t("Lockers"),
          "icon": "locker",
          "href": "/lockers/",
          "badge": {
            "name": "lockers",
            "color": "danger"
          }
        },
        {
          "name": "",
          "title": t("Bookings Calendar"),
          "icon": "booking_calendar",
          "href": "/bookings-calendar/"
        },
        {
          "name": "feature_bookings",
          "title": t("Bookings"),
          "icon": "bookings",
          "badge": {
            "name": "bookings",
            "color": "primary-dark"
          },
          "href": "/bookings/"
        },
        {
          "name": "feature_bookings_reserved",
          "title": t("Reserved Bookings"),
          "icon": "bookings",
          "href": "/scheduled-bookings/"
        },
        {
          "name": "feature_project_calendar",
          "title": t("Project Calendar"),
          "icon": "calendar_project",
          "href": "/project-calendar/"
        },
        {
          "name": "feature_defects",
          "title": t("Defects"),
          "icon": "defects",
          "href": "/defects/"
        },
        {
          "name": "feature_inspections",
          "title": t("Inspections"),
          "icon": "inspections",
          "href": "/inspections"
        },
        {
          "name": "feature_incidents",
          "title": t("Incidents / Cases"),
          "icon": "incidents",
          "href": "/incidents/"
        },
        {
          "name": "feature_work_orders",
          "title": t("Work Orders"),
          "icon": "cases",
          "href": "/work-orders/"
        },
        {
          "name": "feature_schedule_maintainance",
          "title": t("Scheduled Maintenance"),
          "icon": "maintenance",
          "href": "/maintenance-events/"
        },
        {
          "name": "feature_assets",
          "title": t("Assets"),
          "icon": "assets",
          "href": "/assets/"
        },
        {
          "name": "feature_contractors",
          "title": t("Contractors"),
          "icon": "contractor",
          "href": "/contractors/"
        },
        {
          "name": "feature_documents_images",
          "title": t("Documents & Images"),
          "icon": "docuemnt",
          "href": "/documents/"
        },
        {
          "name": "feature_building_reports",
          "title": t("Project Reports"),
          "icon": "reports",
          "href": "/project-reports"
        }
      ]
    },
    {
      "title": t("Place Activation"),
      "isExpanded": false,
      "menu": [
        {
          "name": "feature_social_clubs",
          "title": t("Groups & Clubs"),
          "icon": "social_clubs",
          "href": "/social-groups"
        },
        {
          "name": "feature_event_calendar",
          "title": t("Event Calendar"),
          "icon": "calendar",
          "href": "/event-calendar"
        },
        {
          "name": "feature_local_area",
          "title": t("Local Area"),
          "icon": "local_area",
          "href": "/local-area"
        },
        {
          "name": "feature_prefered_suppliers",
          "title": t("Preferred Services"),
          "icon": "preferred_services",
          "href": "/preferred-services"
        },
        {
          "name": "feature_buy_sell",
          "title": t("Buy & Sell"),
          "icon": "buy_sell",
          "href": "/buys-sell"
        },
        {
          "name": "feature_lend_borrow",
          "title": t("Lend and Borrow"),
          "icon": "lend_burrow",
          "href": "/lend-borrow"
        },
        {
          "name": "feature_special_offers",
          "title": t("Special Offers"),
          "icon": "special_offers_2",
          "href": "/special-offers"
        },
        {
          "name": "feature_experiences",
          "title": t("Experiences"),
          "icon": "experiences",
          "href": "/experiences"
        },
        {
          "name": "feature_real_estate",
          "title": t("Real Estate"),
          "icon": "real_estate",
          "href": "/real-estate"
        }
      ]
    },
    {
      "title": t("Help Centre"),
      "href": "/help-centre",
      "icon": "project_settings",
      "isStick": true
    }
  ],
  comingSoon: {
    "chatbot": {
      "title": t("Chatbot - Concierge"),
      "message": ""
    },
    "connections": {
      "title": t("Connections"),
      "message": t("A turnkey SaaS solution that adds value to a new members journey by assisting them moving into a new building connecting their electricity, gas, internet, removalist,, insurance and much more.")
    },
    "shift-log": {
      "title": "Shift Log",
      "message": ""
    },
    "building-reports": {
      "title": t("Building Reports"),
      "message": t("Having instant access to Key Data and Daily Reports across management areas is essential in any project. The system has built in data analytics and can be used to actively oversee and manage key aspects of any project.")
    },
    "preferred-suppliers": {
      "title": t("Preferred Suppliers"),
      "message": t("Kindred provides clients the ability to set up their contact listings to assist with important contact and services providers that are connected to a project.")
    },
    "schedule-maintenance": {
      "title": t("Schedule Maintenance"),
      "message": ""
    },
    "inspections": {
      "title": t("Inspections"),
      "message": ""
    },
    "buys-sell": {
      "title": t("Buys and Sell"),
      "message": t("A secure and free area for members to sell and buy services and products within their local vicinity provides an important connection and service to the clients.")
    },
    "event-calendar": {
      "title": t("Event Calendar"),
      "message": t("Community events can be created and published for viewing by management. The portal can be configured if required to allow members to post events for approval and display.")
    },
    "local-area": {
      "title": t("Local area"),
      "href": "/local-area/"
    },
    // "local-area": {
    //     "title": t("Local Area"),
    //     "message": t("When moving to a new location, this is one of the first things new residents look for. Providing residents with local area information at their fingertips, provides added value.")
    // },
    "real-estate": {
      "title": t("Real Estate"),
      "message": ""
    },
    "email": {
      "title": t("Emails"),
      "message": t("Formatted templates are provided for fortnightly or monthly newsletters to assist with gentle reminders to members of any news, announcements or upcoming events.")
    },
    "faqs": {
      "title": t("FAQs"),
      "message": t("Reducing frustrations at the client level is critical to providing better services. Management can upload and provide access to information on common questions and issues, so their clients do not have to wait for answers.")
    },
    "inspections": {
      "title": t("Inspections")
    },
    "project-settings": {
      "title": t("Project Settings")
    }
  }
}

export default data;