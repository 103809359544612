import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  categories: {
    "council" : {
      title: t("Council"),
      list: {
        "parking_permits": t("Parking permits"), 
        "post_office": t("Post office"), 
        "religious_places": t("Religious places")
      }
    },
    "eat_drink" : {
      title: t("Eat & Drink"),
      list: {
        "bars_pubs": t("Bars & Pubs"), 
        "cafes": t("Cafes"),
        "family_friendly": t("Family Friendly"),
        "nightclubs": t("Nightclubs"),
        "restaurants": t("Restaurants"),
        "take_aways": t("Take-aways")
      }
    },
    "education_learning" : {
      title: t("Education & Learning"),
      list: {
        "arts_school": t("Arts school"), 
        "colleges": t("Colleges"), 
        "cooking_schools": t("Cooking schools"), 
        "driving_school": t("Driving school"), 
        "kindergarten": t("Kindergarten"), 
        "language_schools": t("Language schools"), 
        "preschool": t("Preschool"), 
        "schools": t("Schools"), 
        "universities": t("Universities")
      }
    },
    "entertainment" : {
      title: t("Entertainment"),
      list: {
        "amusement_parks": t("Amusement Parks"), 
        "aquariums": t("Aquariums"),
        "cinemas": t("Cinemas"),
        "music_venues": t("Music Venues"),
        "stadiums_arenas": t("Stadiums & Arenas"),
        "theatres": t("Theatres"),
        "zoos": t("Zoos")
      }
    },
    "family" : {
      title: t("Family"),
      list: {
        "child_care": t("Child care"), 
        "child_protection": t("Child protection"), 
        "disability": t("Disability"), 
        "family_services": t("Family services"), 
        "housing": t("Housing"), 
        "maternity": t("Maternity")
      }
    },    
    "heritage_culture" : {
      title: t("Heritage & Culture"),
      list: {
        "galleries": t("Galleries"), 
        "historic_buildings": t("Historic Buildings"),
        "landmarks": t("Landmarks"),
        "library": t("Library"),
        "museums": t("Museums")
      }
    },
    "medical" : {
      title: t("Medical"),
      list: {
        "chiropractors": t("Chiropractors"), 
        "counselling": t("Counselling"), 
        "dentist": t("Dentist"), 
        "doctors": t("Doctors"), 
        "hospitals": t("Hospitals"), 
        "medical_centres": t("Medical centres"),
        "opticians": t("Opticians"),
        "pathologist": t("Pathologist"),
        "physiotherapy": t("Physiotherapy"),
        "specialists": t("Specialists"),
        "sports_medicine": t("Sports medicine")
      }
    },
    "parks_gardens" : {
      title: t("Parks & Gardens"),
      list: {
        "dog_friendly": t("Dog friendly"), 
        "parks": t("Parks"),
        "picnic": t("Picnic"),
        "playgrounds": t("Playgrounds")
      }
    },
    "services" : {
      title: t("Services"),
      list: {
        "atms": t("ATMs"), 
        "automotive": t("Automotive"),
        "banks": t("Banks"),
        "consulate_embassies": t("Consulate & embassies"),
        "currency_exchange": t("Currency exchange"),
        "library": t("Library"),
        "sewing_alterations": t("Sewing & Alterations"),
        "shoe_repairs": t("Shoe repairs"),
        "storage": t("Storage"),
        "hair_dresser": t("Hair dresser"),
        "dog_grooming": t("Dog grooming"),
        "aged_care": t("Aged Care"),
        "beauty_wellbeing": t("Beauty & Wellbeing"),
        "tax_agency": t("Tax Agency")
      }
    },
    "shopping" : {
      title: t("Shopping"),
      list: {
        "antiques": t("Antiques"), 
        "arts_crafts": t("Arts & crafts"),
        "beauty_wellbeing": t("Beauty & Wellbeing"),
        "fashion": t("Fashion"),
        "florists": t("Florists"),
        "food_drinks": t("Food & Drinks"),
        "general_retailers": t("General Retailers"),
        "groceries": t("Groceries"),
        "home_furniture": t("Home & Furniture"),
        "kids": t("Kids"),
        "markets": t("Markets"),
        "outdoor_sports": t("Outdoor & Sports"),
        "pets": t("Pets"),
        "shopping_centres": t("Shopping centres"),
        "technology": t("Technology")
      }
    },
    "sports_leisure" : {
      title: t("Sports & Leisure"),
      list: {
        "bike_rentals": t("Bike rentals"), 
        "bowling": t("Bowling"), 
        "golf": t("Golf"), 
        "gyms": t("Gyms"), 
        "personal_trainers": t("Personal trainers"), 
        "racecourse": t("Racecourse"), 
        "sports_clubs": t("Sports clubs"), 
        "swimming_pools": t("Swimming pools"), 
        "various": t("Various"), 
        "yoga": t("Yoga")
      }
    },
    "travel_transport" : {
      title: t("Travel & Transport"),
      list: {
        "airport_shuttle": t("Airport shuttle"), 
        "airports": t("Airports"), 
        "car_rentals": t("Car rentals"), 
        "hotel": t("Hotel"),
        "public_transport": t("Public transport"), 
        "taxis": t("Taxis"), 
        "travel_agents": t("Travel agents")
      }
    }
  }
}

export default data;