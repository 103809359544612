import { observable } from "mobx";
import settings from "../data/Settings.json";
import Helper from "../helpers/Helper";
import axios from "axios";

/**
  * The main store of application. Contain main logic for server request management.
  * 
  */

export default class Store {
  // @observable itemsPerPage = 5;
  @observable itemsPerPage = settings.itemsPerPage;
  @observable notes = {
    current: null,
    data: null,
    offset: 0,
    total: 0
  };
  @observable logs = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable insights = {
    clicksTotal: null,
    clicksUnique: null,
    categoryCounters: null
  }
  @observable counters = {};

  constructor() {
    this.token = null;
    this.apiUrl = null;
    this.dateStampFormat = settings.dateStamp;
    this.timeStampFormat = settings.timeStamp;
    this.dateTimeStampFormat = settings.dateTimeStamp;
    this.currencies = settings.currencies;
    this.timezone = new Date().getTimezoneOffset();
    this.multipartHeader = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    this.documentsMimeTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/msword',
      'application/vnd.ms-excel',
      'application/octet-stream',
      'text/csv',
      'text/plain',
    ];
    this.imgMimeTypes = [
      'image/tiff',
      'image/pipeg',
      'image/jpeg',
      'image/jpg',
      'image/png'
    ];
    this.videoMimeTypes = [
      'video/quicktime',
      'video/mp4'
    ]
    this.filesMimeTypes = this.documentsMimeTypes.concat(this.imgMimeTypes);
    this.url = settings.apiUrl;
    this.noteType = "";
    this.logsType = "";
    this.projectUrl = "";
    this.currentEntityId = null;

    // console.log(env, " || ", process.env.NODE_ENV);

    if (env == "prod") {
      this.url = settings.apiUrlProduction;
    }
  }
  
  /**
   * Method for initialization store data
   * @param token
   * @param url
   * @returns 
   */
  setStoreData = (token, url) => {
    this.token = token;
    this.apiUrl = this.url + url;
    this.multipartHeader = {
      headers: { 
        'Authorization': this.token,
        'Content-Type': 'multipart/form-data' 
      }
    }
  }

  /**
   * Method for combine and run all promises alternately
   * @param promises
   * @returns Promise
   */
  all = (promises) => {
    this.showLoader();
    return axios.all(promises)
    .finally(this.hideLoader);
  }

  /**
   * Method for getting list of items
   * @param url
   * @param params
   * @returns Promise
   */
  setList = (url = null, params = null) => {
    let data = {
      headers: {
        'Authorization': this.token, 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {
        limit: params && params.limit ? params.limit : this.itemsPerPage
      }
    };

    if (params) {
      data.params = Object.assign(params, data.params);
    }

    // Check empty properties
    Object.keys(data.params).forEach(key => {
      if (!data.params || data.params[key] == "") {
        delete data.params[key];
      }
    });

    this.showLoader();
    return axios.get(this.checkUrl(url), data)
    .finally(this.hideLoader);
  }

  /**
   * Method for get single item
   * @param id
   * @param url
   * @param params
   * @returns Promise
   */
  setItem = (id, url = null, params = null) => {
    let data = { 
      headers: {
        'Authorization': this.token, 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: {}
    };

    if (params) {
      data.params = Object.assign(data.params, params);
    }

    this.showLoader();
    return axios.get(`${this.checkUrl(url)}/${id}`, data)
    .finally(this.hideLoader);
  }

  /**
   * Method for adding item to database
   * @param values - the data of item
   * @param url
   * @returns Promise
   */
  addItem = (values, url = null) => {
    const formData = Helper.getFormData(values);

    this.showLoader();
    return axios.post(this.checkUrl(url), formData, this.multipartHeader)
    .finally(this.hideLoader);
  }

  /**
   * Method for editing item
   * @param values - the data of item
   * @param url
   * @returns Promise
   */
  editItem = (values, url = null) => {
    const formData = Helper.getFormData(values);

    this.showLoader();
    return axios.post(`${this.checkUrl(url)}/${values.id}`, formData, this.multipartHeader)
    .finally(this.hideLoader);
  }

  /**
   * Method for deleting items from database
   * @param options
   * @param url
   * @returns Promise
   */
  deleteItems = (options, url) => {
    this.showLoader();
    return axios.delete(this.checkUrl(url), {
      headers: { 'Authorization': this.token },
      params: { ...options },
    })
    .finally(this.hideLoader);
  }

  /**
   * Method for deleting item from database
   * @param options
   * @param url
   * @returns Promise
   */
  deleteItem = (id, url = null) => {
    this.showLoader();
    return axios.delete(`${this.checkUrl(url)}/${id}`, {
      headers: { 'Authorization': this.token }
    })
    .finally(this.hideLoader)
  }

  /**
   * Method for exporting items in csv format
   * @param url
   * @param params 
   * @returns Promise
   */
  export = (url, params = {}) => {
    let data = { 
      headers: { 'Authorization': this.token },
      params: params 
    };

    this.showLoader();
    return axios.get(url, data)
    .finally(this.hideLoader);
  }

  /**
   * Method for getting data of sotring or filtration
   * @param data
   * @param params 
   * @returns Object
   */
  getParams = (data, params = {}) => {
    let result = { ...params };
    result.offset = Number.isInteger(params.offset) ? params.offset : data.offset;
    
    if (data.filterValues) {
      let isFilterEmpty = true;
      Object.assign(result, data.filterValues);

      Object.keys(data.filterValues).forEach(key => {
        if (data.filterValues[key] && data.filterValues[key] != "") {
          isFilterEmpty = false;
        }
      });

      data.offset = result.offset;
    }

    if (data.sortingValues && data.sortingValues.field) {
      result.sort = data.sortingValues.field;
      result.order = data.sortingValues.order;
    }       

    return result;
  }

  setParams = (data, value = "", type, subType = null) => {
    if (!subType) {
      data[type] = value;
    } else {
      data[type][subType] = value;
    }
  }

  // Additional functions
  exportPDF = (params, clientId, projectId) => {
    this.showLoader();
    return this.addItem(
      params,
      `${this.url}clients/${clientId}/projects/${projectId}/export/pdf`
    )
    .finally(this.hideLoader);
  }

  exportCSV = (type, clientId, projectId) => {
    this.showLoader();
    return this.addItem(
      {},
      `${this.url}clients/${clientId}/projects/${projectId}/export/${type}`
    )
    .finally(this.hideLoader);
  }

  checkUrl = (url) => {
    return !url ? this.apiUrl : url;
  }

  prepareFilterValues = (data) => {
    let filter = {};
    Object.keys(data).forEach(key => {
      if (data[key] != "") {
        filter[key] = data[key];
      }
    });

    return filter;
  }

  showLoader = () => {
    window.PreloaderStore.show();
  }

  hideLoader = () => {
    window.PreloaderStore.hide();
  }

  // Notes
  initialNotes = (type, clientId, projectId, currentEntityId) => {
    this.noteType = type;
    this.projectUrl = clientId ? `${this.url}clients/${clientId}/projects/${projectId}/` : this.url;
    this.currentEntityId = currentEntityId;
  }

  getNoteUrl = () => {
    return `${this.projectUrl}notes/${this.noteType}/${this.currentEntityId}`;
  }

  setNotesPage = (page) => {
    this.notes.offset = page * this.itemsPerPage;
    this.setNotes();
  }

  setNotes = (params = {}) => {
    params.offset = this.notes.offset;

    return this.setList(this.getNoteUrl(), params)
    .then(response => {                    
      this.notes.data = response.data.data;
      this.notes.total = response.data.count; 
    });
  }

  setCurrentNote = (note) => {
    this.notes.current = note;
  }

  resetCurrentNote = () => {
    this.notes.current = null;
  }

  addNote = (values) => {
    return this.addItem(values, this.getNoteUrl())
    .then(r => this.setNotes());
  }

  editNote = (values) => {
    return this.editItem(values, this.getNoteUrl())
    .then(r => this.setNotes());
  }

  deleteNote = () => {
    return this.deleteItem(this.notes.current.id, this.getNoteUrl())
    .then(r => this.setNotes());
  }

  // Logs
  initialLogs = (type, clientId, projectId, currentEntityId) => {
    this.logsType = type;
    this.projectUrl = clientId ? `${this.url}clients/${clientId}/projects/${projectId}/` : this.url;
    this.currentEntityId = currentEntityId;
  }

  getLogsUrl = () => {
    return `${this.projectUrl}entitylogs/${this.logsType}/${this.currentEntityId}`;
  }

  setLogs = (params = {}) => {
    params.offset = this.logs.offset;

    return this.setList(this.getLogsUrl(), params)
    .then(response => {                    
      this.logs.data = response.data.data;
      this.logs.total = response.data.count; 
    });
  }

  // Insights
  setClicksTotal = (url = "", params = {}) => {
    url = `${url}?sort=count_clicks_total&order=DESC`;

    return this.setList(url, params)
    .then(response => {                    
      this.insights.clicksTotal = response.data.data;
      return response;
    });
  }

  setClicksUnique = (url = "", params = {}) => {
    url = `${url}?sort=count_clicks_unique&order=DESC`;

    return this.setList(url, params)
    .then(response => {                    
      this.insights.clicksUnique = response.data.data;
      return response;
    });
  }

  setInsightCategories = (url = "", type = "", params = {}) => {
    url = `${url}/counters/${type}/categories`;

    return this.setList(url, params)
    .then(response => {                    
      this.insights.categoryCounters = response.data;
      return response;
    });
  }

  // Counters
  setCounters = (type = "", clientId = null, projectId = null, params = {}) => {
    const clientUrl = clientId ? `clients/${clientId}` : "";
    const projectUrl = projectId ? `/projects/${projectId}` : "";

    return this.setList(`${this.url}${clientUrl}${projectUrl}${clientId ? "/counters" : "counters"}${type ? "/" + type : ""}`, params)
    .then(response => {
      this.counters = response.data;
    })
    .catch(e => console.error(e));
  }

  resetCounters = () => {
    this.counters = {};
  }
}