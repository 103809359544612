import { autorun, observable, toJS } from "mobx";
import data from "../data/Notifications.js";
import Store from "./Store";

class AlertStore extends Store {
  @observable pages = {
    alerts: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    scheduledAlerts: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    users: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    reports: {
      data: null,
      total: 0,
      offset: 0,
      filters: null
    },
  };

  constructor() {
    super();
    this.roles = data.roles;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/alerts`);
  }

  setPage = (page, type, callback = "setAlerts") => {
    let data = this.pages[type];
    
    data.offset = page * this.itemsPerPage;
    this[callback]({}, type);   
  }

  setAlerts = (params = {}, type = "alerts") => {
    params.offset = this.pages[type].offset;

    if (type == "scheduledAlerts") {
      params.is_scheduled = 1;
    }

    return this.setList(null, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
    });
  }

  setAlert = (id) => {
    return this.setItem(id)
    .then(response => {
      this.pages.alerts.current = response.data;
    });
  }

  setCurrentAlert = (item) => {
    this.pages.alerts.current = item;
  }

  resetCurrentAlert = () => {
    this.pages.alerts.current = null;
  }

  addAlert = (values) => {
    return this.addItem(values);
  }

  editAlert = (values) => {
    return this.editItem(values);
  }

  deleteAlert = () => {
    return this.deleteItem(this.pages.alerts.current.id)
    .then(r => {
      this.pages.alerts.current = null;
      return this.setAlerts();
    });
  }

  // Users
  getUsersUrl = () => {
    return `${this.apiUrl}/${this.pages.alerts.current.id}/users`;
  }

  setUsers = (params = {}) => {
    params.offset = this.pages.users.offset;

    return this.setList(this.getUsersUrl(), params)
    .then(response => {
      this.pages.users.data = response.data.data;
      this.pages.users.total = response.data.count;
    });
  }

  // Reports
  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.reports.filters = params;
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count;
    });
  }

  exportAlertPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/alerts", "/export/pdf/alerts")
    )
    .finally(this.hideLoader);
  }
}

const store = window.AlertStore = new AlertStore;

export default store;

autorun(() => {
    // console.log(store);
});