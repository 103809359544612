import { translate } from "../helpers/Helper";

const t = translate;
const data = {
	types: [
		"apartment",
		"contractor"
	],
	keyTypes: {
		"swipe": t("Swipe"),
		"metal": t("Metal"),
		"garage": t("Garage")
	},
	statuses: {
		"key_in": t("Key In"),
		"key_out": t("Key Out"),
		"lost": t("Lost"),
		"request": t("Request"),
		"processing": t("Processing")
  },
  purposeOfVisit: {
    "call_out": t("Call out"),
    "planned_maintenance": t("Planned Maintenance"),
    "other": t("Other")
  },
  memberKeyStatuses: {
    "held": t("Held"),
    "collected": t("Collected")
  },
  safeTrustStaffRoles: {
    "Admin": t("Admin"),
    "People Management": t("People Management")
  }
};

export default data;