import { NotificationManager } from 'react-notifications';
import { autorun, observable, toJS } from "mobx";
import { translate } from "../helpers/Helper";
import settings from "../data/Settings.json";
import data from "../data/Keys.js";
import Store from "./Store.js";

class KeysFobsStore extends Store {
  @observable pages = {
    apartments: {
      data: null,
      offset: 0,
      total: 0
    },
    contractors: {
      data: null,
      offset: 0,
      total: 0
    },
    processing: {
      data: null,
      offset: 0,
      total: 0
    },
    requests: {
      data: null,
      offset: 0,
      total: 0
    },

    // Admin part
    buildingKeySets: {
      data: null,
      offset: 0,
      total: 0,
      count_keys: 0,
      filters: {
        search: "",
        key_type: null,
        building_id: null
      }
    },
    buildingKeysIn: {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        search: "",
        key_type: null,
        building_id: null
      }
    },
    buildingKeysOut: {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        search: "",
        key_type: null,
        building_id: null
      }
    },
    buildingKeyTypes: {
      data: null,
      searchList: null,
      offset: 0,
      total: 0,
      filters: null
    },
    deactivatedKeySets: {
      data: null,
      offset: 0,
      total: 0,
      count_keys: 0,
      filters: {
        search: "",
        key_type: null,
        building_id: null
      }
    },
    logs: {
      data: null,
      offset: 0,
      total: 0,
      sorting: {
        order: "ASC",
        sort: null
      },
      filters: {
        is_out: "",
        type_name: "",
        building_ids: "",
        date_start: null,
        date_end: null,
        search: ""
      }
    }
  };
  @observable residentKeys = null;
  @observable residentKeySetId = null;
  @observable currentResidentKey = null;
  @observable currentContractorKey = null;
  @observable keyTypes = null;
  @observable currentKeyType = null;
  @observable totalKeyTypes = 0;
  @observable offsetKeyTypes = 0;

  // Key sets
  @observable currentKeySet = null;
  @observable currentBuildingKeyType = null;
  @observable buildingCounts = 1;
  @observable buildingValues = [];
  @observable keySetCounts = 1;
  @observable keySetValues = [];
  @observable keySetSearchList = null;
  @observable currentHistory = null;


  constructor() {
    super();
    this.t = translate;
    this.token = null;
    this.apiUrl = null;
    this.clientId = null;
    this.projectId = null;
    this.keyTypesUrl = null;
    this.types = data.types;
    this.statuses = data.statuses;
    this.purposeOfVisit = data.purposeOfVisit;
    this.currencies = settings.currencies;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/keys`);
    this.keyTypesUrl = this.apiUrl.replace('keys', '') + "members-keys/types";
    this.clientId = clientId;
    this.projectId = projectId;
  }

  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    return this.setKeys(type);
  }

  setAllKeys = () => {
    let promises = [];
    const requests = [ 'apartments', 'contractors', 'processing', 'requests' ];

    requests.forEach(type => {
      promises.push(this.setKeys({ 
        type: type,
        offset: this.pages[type].offset
      }));
    });

    return this.all(promises);
  }

  setKeys = (params = {}) => {
    const type = params.type ? params.type : "apartments";
    params.offset = this.pages[type].offset;

    return this.setList(`${this.apiUrl}/${type}`, params)
    .then(response => {                    
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count; 
    });
  }

  setResidentKeys = (id) => {
    const url = `${this.apiUrl}/apartments/${id}`;
    return this.setList(url, { limit: 100 })
    .then(response => {
      this.residentKeys = response.data; 
    });
  }

  setResidentKey = (key) => {
    this.currentResidentKey = key;
  }

  getResidentKey = () => {
    return toJS(this.currentResidentKey);
  }

  resetResidentKey = () => {
    this.currentResidentKey = null;
  }

  addKey = (values) => {
    return this.addItem(values)
    .then(response => {
      if (values.asset_type == "apartment" && this.residentKeySetId) {
        this.setResidentKeys(this.residentKeySetId);
      } else {
        this.setContractorKey(response.data.id);
      }

      this.setAllKeys();
    });
  }

  editKey = (values) => {
    return this.editItem(values)
    .then(response => {
      if (values.asset_type == "apartment" && this.residentKeySetId) {
        this.setResidentKeys(this.residentKeySetId);
      } else {
        this.setContractorKey(response.data.id);
      }

      this.setAllKeys();
      return response;
    });
  }

  // Building keys sets
  getBuildingKeysUrl = (type = "keys") => {
    return `${this.url}clients/${this.clientId}/projects/${this.projectId}/building-keys/${type}`;
  }

  setAllBuildingKeys = () => {
    let promises = [];
    const requests = [ 'buildingKeySets', 'buildingKeyTypes', 'deactivatedKeySets' ];

    requests.forEach(type => {
      promises.push(this.setBuildingKeys({ 
        type: type
      }));
    });

    return this.all(promises);
  }

  setProjectBuildingKeys = (params) => {
    let promises = [];
    const requests = [ 'buildingKeySets', 'buildingKeysIn', 'buildingKeysOut' ];

    requests.forEach(type => {
      const settings = { type: type };
      promises.push(this.setBuildingKeys(params ? Object.assign(settings, params) : settings));
    });

    return this.all(promises);
  }

  setBuildingKeys = (params = {}) => {
    const pageType = params.type;
    params.offset = this.pages[pageType].offset;
    const type = pageType != "buildingKeyTypes" ? "keys" : "types";
    delete params.type;

    if (pageType == "deactivatedKeySets") {
      params.is_active = "0";
    }

    if (pageType == "buildingKeysIn") {
      params.is_out = "0";
    }

    if (pageType == "buildingKeysOut") {
      params.is_out = "1";
    }

    return this.setList(this.getBuildingKeysUrl(type), params)
    .then(response => {                    
      this.pages[pageType].data = response.data.data;
      this.pages[pageType].total = response.data.count; 

      if (pageType == "buildingKeySets" || pageType == "deactivatedKeySets") {
        this.pages[pageType].count_keys = response.data.count_keys;
      }
    });
  }

  setBuildingKeySet = (id) => {
    return this.setItem(id, this.getBuildingKeysUrl("keys"))
    .then(response => {
      this.currentKeySet = response.data;
      return response;
    });
  }

  reserCurrentKeySet = () => {
    this.currentKeySet = null;
  }

  searchKeySet = (params) => {
    return this.setList(this.getBuildingKeysUrl("keys"), params)
    .then(response => {
      this.keySetSearchList = response.data.data;
    })
  }

  addBuildingKey = (values) => {
    return this.addItem(values, this.getBuildingKeysUrl("keys"))
    .then(r => this.setAllBuildingKeys());
  }

  editBuildingKey = (values) => {
    return this.editItem(values, this.getBuildingKeysUrl("keys"))
    .then(r => this.setBuildingKeySet(values.id));
  }

  searchBuildingType = (params) => {
    return this.setList(this.getBuildingKeysUrl("types"), params)
    .then(response => {                    
      this.pages.buildingKeyTypes.searchList = response.data.data;
    });
  }

  // Key set Logs
  getLogsUrl = () => {
    return `${this.getBuildingKeysUrl("keys")}/${this.currentKeySet ? this.currentKeySet.id : 0}/history`;
  }

  setLogs = (params = {}) => {
    params.offset = this.pages.logs.offset;
    console.log(params);
    const data = Object.assign(this.pages.logs.filters, params);
    console.log(data);

    return this.setList(this.getLogsUrl(), data)
    .then(response => {                    
      this.pages.logs.data = response.data.data;
      this.pages.logs.total = response.data.count; 
    });
  }

  setCurrentHistory = (history) => {
    this.currentHistory = history;
  }

  resetCurrentHistory = () => {
    this.currentHistory = null;
  }

  // Building key types
  setCurrentBuildingKeyType = (type) => {
    this.currentBuildingKeyType = type;
  }

  resetCurrentBuildingKeyType = () => {
    this.currentBuildingKeyType = null;
  }

  addBuildingKeyType = (values) => {
    return this.addItem(values, this.getBuildingKeysUrl("types"))
    .then(r => this.setAllBuildingKeys());
  }

  editBuildingKeyType = (values) => {
    return this.editItem(values, this.getBuildingKeysUrl("types"))
    .then(r => this.setAllBuildingKeys());
  }

  deleteBuildinKeyType = () => {
    return this.deleteItem(this.currentBuildingKeyType.id, this.getBuildingKeysUrl("types"))
    .then(r => this.setAllBuildingKeys());
  }

  // Key Buildings
  setBuildingData = (count, ids, values) => {
    this.buildingCounts = count;
    this.buildingValues = values;
  }

  setKeyBuildingValue = (value, index) => {
    if (this.buildingValues.indexOf(value) == -1) {
      this.buildingValues[index] = value;
    } else {
      NotificationManager.error(this.t("This building was choosen before."));
    }
  }

  addKeyBuilding = () => {
    let count = this.buildingCounts;
    if (this.buildingValues[count - 1]) {
        this.buildingCounts = ++count;
    } else {
        NotificationManager.error(this.t("Please select building."));
    }
  }

  // Key Set Values
  setKeySetData = (count, values) => {
    this.keySetCounts = count;
    this.keySetValues = values;
  }

  setKeySetValue = (value, index) => {
    if (this.keySetValues.indexOf(value) == -1) {
      this.keySetValues[index] = value;
    } else {
      NotificationManager.error(this.t("This Key Set was choosen before."));
    }
  }

  addKeySet = () => {
    let count = this.keySetCounts;
    if (this.keySetValues[count - 1]) {
        this.keySetCounts = ++count;
    } else {
        NotificationManager.error(this.t("Please select key set."));
    }
  }

  // Contractors
  setContractorKey = (id) => {
    return setItem(id, `${this.apiUrl}/contractors`)
    .then(response => {
      this.currentContractorKey = response.data; 
    });
  }

  getContractorKey = () => {
    return toJS(this.currentContractorKey);
  }

  // Requests
  setKeyRequests = () => {
    return this.setList(`${this.apiUrl}/requests`, {limit: 100});
  }

  // Key Types
  setKeyTypes = (params = {}) => {
    params.offset = this.offsetKeyTypes;

    return this.setList(this.keyTypesUrl, params)
    .then(response => {          
      this.keyTypes = response.data.data;
      this.totalKeyTypes = response.data.count;
    });
  }

  getKeyTypes = () => {
    return this.keyTypes;
  }

  getCurrentKeyType = () => {
    return this.currentKeyType;
  }

  setCurrentKeyType = (keyType) => {
    this.currentKeyType = keyType;
  }

  resetCurrentKeyType = () => {
    this.currentKeyType = null;
  }

  addKeyType = (values) => {
    return this.addItem(values, this.keyTypesUrl)
    .then(r => this.setKeyTypes());
  }

  editKeyType = (values) => {
    return this.editItem(values, this.keyTypesUrl)
    .then(r => this.setKeyTypes());
  }

  deleteKeyType = () => {
    return this.deleteItem(this.currentKeyType.id, this.keyTypesUrl)
    .then(r => {
      this.currentKeyType = null;
      return this.setKeyTypes();
    });
  }

  getKeyTypeById = (id) => {
    return this.keyTypes.find((type) => {
      return type.id == id;
    });
  }

  exportKeysFobsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/keys", "/export/pdf/keys")
    )
    .finally(this.hideLoader);
  }
}

const store = window.KeysFobsStore = new KeysFobsStore;

export default store;

autorun(() => {
    // console.log(store);
});