import { NotificationManager } from 'react-notifications';
import { autorun, observable } from "mobx";
import Store from "./Store";
import Helper from "../helpers/Helper";

class OwnersCorpStore extends Store {
  @observable corporations = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable currentCorporation = null;
  @observable buildingCount = 1;
  @observable chozenBuildingsIds = [];
  @observable buildingValues = [];

  constructor() {
    super();
    this.t = Helper.translate;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/oc`);
  }

  setPage = (page) => {
    this.corporations.offset = page * this.itemsPerPage;
    return this.setCorporations();
  }

  setCorporations = (params = {}) => {
    let data = {
      offset: this.corporations.offset
    };
    
    return this.setList(null, Object.assign(data, params))
    .then(response => {
      this.corporations.data = response.data.data;
      this.corporations.total = response.data.count;
    });
  }

  setCorporation = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentCorporation = response.data;
    });
  }

  addCorporation = (values) => {
    return this.addItem(values).then(r => this.setCorporations());
  }

  editCorporation = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentCorporation = response.data;
      this.setCorporations();
    });
  }

  deleteCorporation = () => {
    return this.deleteItem(this.currentCorporation.id)
    .then(r => {
      this.currentCorporation = null;
      this.setCorporations();
    });
  }

  searchCorporation = (value) => {
    return this.setList(this.apiUrl, { search: value })
    .then(response => {
      this.corporations.data = response.data.data;
    });
  }

  // Building fields
  setBuildingData = (count, ids, values) => {
    this.buildingCount = count;
    this.chozenBuildingsIds = ids;
    this.buildingValues = values;
  }

  setBuildingValue = (value, index) => {
    if (this.buildingValues.indexOf(value) == -1) {
      this.buildingValues[index] = value;
    } else {
      NotificationManager.error(this.t("This building was choosen before."));
    }
  }

  addBuilding = () => {
    let count = this.buildingCount;
    if (this.buildingValues[count - 1]) {
      this.buildingCount = ++count;
    } else {
      NotificationManager.error(this.t("Please select building."));
    }
  }
}

const store = window.OwnersCorpStore = new OwnersCorpStore;

export default store;

autorun(() => {
    // console.log(store);
});