import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  statuses: {
    "pending": t("Pending"),
    "approved": t("Approved"),
    "declined": t("Declined"),
    "canceled": t("Canceled")
  },
  statusColors: {
    "pending": "#f69a5b",
    "approved": "#66bc62",
    "maintenance": "#5c74fe",
    "declined": "#ff8179",
    "canceled": "#ccc",
    "confirmed": "#5c74fe"
  }
}

export default data;