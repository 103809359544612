import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  australia: {
    title: t("Australia"),
    cities: {
      melbourne: t("Melbourne"),
      sydney: t("Sydney"),
      perth: t("Perth"),
      adelaide: t("Adelaide"),
      brisbane: t("Brisbane"),
      darwin: t("Darwin"),
      hobart: t("Hobart"),
      canberra: t("Canberra")
    }
  },
  UAE: {
    title: t("United Arab Emirates"),
    cities: {
      dubai: t("Dubai"),
      abu_dhabi: t("Abu Dhabi"),
      sharjah: t("Sharjah")
    }
  },
  japan: {
    title: t("Japan"),
    cities: {
      tokyo: t("Tokyo"),
      osaka: t("Osaka"),
      nagoya: t("Nagoya"),
      sendai: t("Sendai"),
      fukuoka: t("Fukuoka"),
    }
  }
};
export default data;