import { autorun, observable } from "mobx";
import data from "../data/Cases.js";
import Store from "./Store.js";
import axios from "axios";

class IncidentStore extends Store {
  @observable incidents = {
    all: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: "",
        type: ""
      }
    },
    incident: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: "",
        type: ""
      }
    },
    equipment: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: "",
        type: ""
      }
    },
    reports: {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        type: "all",
        subtype: "",
        date_start: null,
        date_end: null
      }
    },
    counters: null
  }
  @observable pages = {
    documents: {
      data: null,
      offset: 0,
      total: 0
    },
    emails: {
      data: null,
      offset: 0,
      total: 0
    },
  };
  @observable incidentDetails = {
    data: null,
    offset: 0,
    total: 0,
    count: 0,
    filterValues: {}
  }
  @observable currentIncident = null;
  @observable currentIncidentDetail = null;
  @observable lastIncidentId = null;
  @observable filterValues = {
    is_assigned: "",
    type: "",
    search: ""
  };

  constructor() {
    super();
    this.projectId = null;
    this.reportType = data.reportType;
    this.incidentTypes = data.incidentTypes;
    this.incidentSubTypes = data.incidentSubTypes;
    this.incidentClassification = data.incidentClassification;
    this.logsLimit = 500;
    this.reportsLimit = 100;
    this.incidentFilesMimeTypes = this.filesMimeTypes.concat(this.videoMimeTypes);
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/incidents`);
    this.clientId = clientId;
    this.projectId = projectId;
  }

  setPage = (page) => {
    this.incidents.offset = page * this.itemsPerPage;
    this.setAllReports();
  }

  // Incidents
  getIncidentUrl = () => {
    return `${this.url}clients/${this.clientId}/projects/${this.projectId}/incidents`;
  }

  setIncidentPage = (page, status) => {
    this.incidents[status].offset = page * this.itemsPerPage;
    this.setIncidents(status, this.prepareFilterValues(this.incidents[status].filterValues));
  }

  setAllReports = () => {
    const types = [ 'all', 'incident', 'equipment'];
    let promises = [];

    types.forEach(type => {
      promises.push(this.setIncidents(type));
    });

    return axios.all(promises);
  }

  setIncidents = (type, params = {}) => {
    params.offset = this.incidents[type].offset;
    if (type != "all") {
      params.category = type;
    }

    return this.setList(this.getIncidentUrl(), params)
    .then(response => {
      this.incidents[type].data = response.data.data;
      this.incidents[type].total = response.data.count;
      this.incidents[type].count = response.data.count;
    });
  }

  setLastIncident = (params = {}) => {
    params.category = "";
    return this.setList(this.getIncidentUrl(), { limit: 1, offset: 0 })
    .then(response => response.data.data[0]);
  }

  setIncident = (id) => {
    return this.setItem(id, this.getIncidentUrl())
    .then(response => {
      this.currentIncident = response.data;
    });
  }

  setCurrentIncident = (incident) => {
    this.currentIncident = incident;
  }

  resetCurrentIncident = () => {
    this.currentIncident = null;
  }

  addIncident = (values) => {
    return this.addItem(values, this.getIncidentUrl())
    .then(response => {
      this.setAllReports();
      return response;
    });
  }

  editIncident = (values) => {
    return this.editItem(values, this.getIncidentUrl())
    .then(response => {
      this.currentIncident = response.data;
      return this.setIncident(this.currentIncident.id);
    });
  }

  deleteIncident = () => {
    return this.deleteItem(this.currentIncident.id, this.getIncidentUrl());
  }

  prepareIncidentNumberId = (incident) => {
    this.lastIncidentId = `IC_${incident ? incident.id + 1 : 1}`;
  }

  setIncidentCounters = () => {
    const url = `${this.getIncidentUrl().replace('/incidents', '')}/counters/incidents`;
    return this.setList(url)
    .then(response => {
      this.incidents.counters = response.data;
    });
  }

  // Reports
  setReports = (params = {}) => {
    params.offset = this.incidents.reports.offset;
    params.limit = this.reportsLimit;

    return this.setList(this.getIncidentUrl(), params)
    .then(response => {                    
      this.incidents.reports.data = response.data.data;
      this.incidents.reports.total = response.data.count; 
    });
  }

  resetReports = () => {
    this.incidents.reports = {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        type: "all",
        subtype: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    this.incidents.reports.filters = values;
  }

  // Incident details report
  getDetailsUrl = () => {
    return `${this.getIncidentUrl()}/${this.currentIncident.id}/details`;
  }

  setDetailPage = (page) => {
    this.incidentDetails.offset = page * this.itemsPerPage;
    this.setDetails(this.prepareFilterValues(this.incidentDetails.filterValues));
  }

  setDetails = (sortObj = {}) => {
    sortObj.offset = this.incidentDetails.offset;

    return this.setList(this.getDetailsUrl(), sortObj)
    .then(response => {
      this.incidentDetails.data = response.data.data;
      this.incidentDetails.total = response.data.count;
    });
  }

  setCurrentDetails = (details) => {
    this.currentIncidentDetail = details;
  }

  resetCurrentDetails = () => {
    this.currentIncidentDetail = null;
  }

  addDetailReport = (values) => {
    return this.addItem(values, this.getDetailsUrl()).then(r => {
      return this.setDetails();
    });
  }

  editDetailReport = (values) => {
    return this.editItem(values, this.getDetailsUrl()).then(r => {
      return this.setDetails();
    });
  }

  deleteDetailsReport = () => {
    return this.deleteItem(this.currentIncidentDetail.id, this.getDetailsUrl())
    .then(r => this.setDetails());
  }

  // Documents
  getIncidentDocumentsUrl = () => {
    return `${this.getIncidentUrl()}/${this.currentIncident.id}/documents`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getIncidentDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  resetDocuments = () => {
    this.pages.documents.data = null;
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getIncidentDocumentsUrl())
    .then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getIncidentDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }

  // Emails
  sendEmail = (values) => {
    return this.addItem(values, `${this.apiUrl}/${this.currentIncident.id}/email`);
  }

  // Other
  exportIncidents = (params = {}) => {
    return this.setList(`${this.apiUrl.replace('/incidents', '')}/export/incidents`, params)
    .then(r => r.data);
  }

  exportIncidentsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/incidents", "/export/pdf/incidents")
    )
    .finally(this.hideLoader);
  }
}

const store = window.IncidentStore = new IncidentStore;

export default store;

autorun(() => {
    // console.log(store);
});