import { autorun, observable, toJS } from "mobx";
import Store from "./Store";
import Helper from "../helpers/Helper";

class ReportsStore extends Store {
  @observable pages = {
    all: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    new: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    in_progress: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    resolved: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    canceled: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    documents: {
      data: null,
      offset: 0,
      total: 0
    }
  }

  @observable currentReport = null;
  @observable currentDocument = null;

  constructor() {
    super();
    this.t = Helper.translate;
    this.statuses = {
      "new": this.t("Open"),
      "in_progress": this.t("In Progress"),
      "resolved": this.t("Closed"),
      "canceled": this.t("Canceled")
    };
  }

  setData = (token) => {
    this.setStoreData(token, `general/feedback`);
  }

  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    return this.setReports({ status: type }, type);
  }

  setAllReports = () => {
    const types = [ "all", "new", "in_progress", "resolved", "canceled" ];
    let promises = [];
 
    types.forEach(type => {
      promises.push(this.setReports({ status: type }, type));
    });
 
    return this.all(promises);
  }

  setReports = (params = {}, type = "all") => {
    params.offset = this.pages[type].offset;
    console.log(type);
    console.log(this.pages[type]);

    return this.setList(null, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
      this.pages[type].count = response.data.count;
    });
  }

  setReport = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentReport = response.data;
      return response;
    });
  }

  setCurrentReport = (service) => {
    this.currentReport = service;
  }

  getCurrentReport = () => {
    return toJS(this.currentReport);
  }

  resetCurrentReport = () => {
    this.currentReport = null;
  }

  addReport = (values) => {
    return this.addItem(values)
    .then(r => this.setAllReports());
  }

  editReport = (values) => {
    return this.editItem(values)
    .then(response => {
      this.setCurrentReport(response.data);
      return this.setAllReports();
    });
  }

  deleteReport = () => {
    return this.deleteItem(this.currentReport.id)
    .then(r => {
      this.currentReport = null;
      return this.setAllReports();
    })
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl}/${this.currentReport.id}/documents`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setAttachment = (response) => {
    let result = [];
    
    if (response.data.attachment) {
      response.data.attachment.forEach((item, index) => {
        result.push({
          id: index,
          name: "Attachment",
          path: item,
          date_created: response.data.date_created
        });
      });

      this.pages.documents.data = result;
    }
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }
}

const store = window.ReportsStore = new ReportsStore;

export default store;

autorun(() => {});