import { autorun, observable, toJS } from "mobx";
import Store from "./Store";
import Helper from "../helpers/Helper";

class ShiftLogsStore extends Store {
  @observable pages = {
    rosters: {
      data: null,
      searchList: null,
      current: null,
      offset: 0,
      total: 0
    },
    shifts: {
      data: null,
      current: null,
      offset: 0,
      total: 0
    },
    tasks: {
      current: null,

      all: {
        data: null,
        offset: 0,
        total: 0
      },
      open: {
        data: null,
        offset: 0,
        total: 0
      },
      in_progress: {
        data: null,
        offset: 0,
        total: 0
      },
      done: {
        data: null,
        offset: 0,
        total: 0
      }
    },
    documents: {
      data: null,
      current: null,
      offset: 0,
      total: 0
    },
    shiftReports: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {}
    },
    taskReports: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {}
    },
    messages: {
      data: null
    },
    taskCounters: null,
    shiftCounters: null,
  };

  @observable currentNote = null;
  @observable currentMessage = null;
  @observable currentDocument = null;

  constructor() {
    super();
    this.t = Helper.translate;

    this.shiftStatuses = {
      in_progress: this.t("In Progress"),
      submitted: this.t("Submitted")
    };
    this.taskStatuses = {
      open: this.t("Open"),
      in_progress: this.t("In Progress"),
      on_hold: this.t("On Hold"),
      done: this.t("Closed")
    };
    this.priority = {
      low: this.t("Low"),
      normal: this.t("Normal"),
      urgent: this.t("Urgent"),
    }
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}`);
  }

  setPage = (page, type, status = null) => {
    this.pages[type].offset = page * this.itemsPerPage;
    
    if (status) {
      this.pages[type][status].offset = page * this.itemsPerPage;
    }

    if (type == "rosters") {
      this.setRosters();
    } else if (type == "shifts") {
      this.setShifts();
    } else if (type == "tasks") {
      this.setTasks();
    } else if (type == "notes") {
      this.setNotes();
    } else if (type == "documents") {
      this.setDocuments();
    }
  }

  // Rosters
  getRostersUrl = () => {
    return `${this.apiUrl}/shift-rosters`;
  }

  setRosters = (params = {}, isSearch = false) => {
    params.offset = this.pages.rosters.offset;

    return this.setList(this.getRostersUrl(), params)
    .then(response => {
      if (isSearch) {
        this.pages.rosters.searchList = response.data.data;
      } else {
        this.pages.rosters.data = response.data.data;      
        this.pages.rosters.total = response.data.count;
      }
    })
  }

  setCurrentRoster = (item) => {
    this.pages.rosters.current = item;
  }

  resetCurrentRoster = () => {
    this.pages.rosters.current = null;
  }

  addRoster = (values) => {
    return this.addItem(values, this.getRostersUrl())
    .then(r => this.setRosters());
  }

  editRoster = (values) => {
    return this.editItem(values, this.getRostersUrl())
    .then(r => this.setRosters());
  }

  deleteRoster = () => {
    return this.deleteItem(this.pages.rosters.current.id, this.getRostersUrl())
    .then(r => {
      this.pages.rosters.current = null;
      return this.setRosters();
    });
  }

  // Shifts
  getShiftsUrl = () => {
    return `${this.apiUrl}/shifts`;
  }

  setShifts = (params = {}) => {
    params.offset = this.pages.shifts.offset;

    return this.setList(this.getShiftsUrl(), params)
    .then(response => {
      this.pages.shifts.data = response.data.data;
      this.pages.shifts.total = response.data.count;
    })
  }

  setShift = (id) => {
    return this.setItem(id, this.getShiftsUrl())
    .then(response => {
      this.pages.shifts.current = response.data;
    })
  }

  setCurrentShift = (item) => {
    this.pages.shifts.current = item;
  }

  resetCurrentShift = () => {
    this.pages.shifts.current = null;
  }

  addShift = (values) => {
    return this.addItem(values, this.getShiftsUrl())
    .then(r => this.setShifts());
  }

  editShift = (values) => {
    return this.editItem(values, this.getShiftsUrl())
    .then(r => this.setShifts());
  }

  deleteShift = () => {
    return this.deleteItem(this.pages.shifts.current.id, this.getShiftsUrl())
    .then(r => {
      this.pages.shifts.current = null;
      return this.setShifts();
    });
  }
  
  setShiftStatistic = () => {
    const url = `${this.apiUrl}/counters/shifts`;
    return this.setList(url)
    .then(response => {
      this.pages.shiftCounters = response.data;
    });
  }

  setShiftReports = (params = {}) => {
    this.pages.shiftReports.filterValues = params;
    params.offset = this.pages.shiftReports.offset;

    return this.setList(this.getShiftsUrl(), params)
    .then(response => {
      this.pages.shiftReports.data = response.data.data;
      this.pages.shiftReports.total = response.data.count;
    });
  }

  resetShiftReports = () => {
    this.pages.shiftReports = {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {}
    }
  }

  // Tasks
  getTasksUrl = () => {
    return `${this.apiUrl}/tasks`;
  }

  setAllTasks = () => {
    const statuses = ["all", "open", "in_progress", "done"];        
    let promises = [];

    statuses.forEach(status => {
      promises.push(this.setTasks({ status: status }));
    });

    return this.all(promises);
  }

  setTasks = (params = {}) => {
    params.offset = this.pages.tasks.offset;

    return this.setList(this.getTasksUrl(), params)
    .then(response => {
      this.pages.tasks[params.status || "all"].data = response.data.data;
      this.pages.tasks[params.status || "all"].total = response.data.count;
    })
  }

  setTask = (id) => {
    return this.setItem(id, this.getTasksUrl())
    .then(response => {
      this.pages.tasks.current = response.data;
    })
  }

  setCurrentTask = (item) => {
    this.pages.tasks.current = item;
  }

  resetCurrentTask = () => {
    this.pages.tasks.current = null;
  }

  addTask = (values) => {
    return this.addItem(values, this.getTasksUrl());
    // .then(r => this.setTasks());
  }

  editTask = (values) => {
    return this.editItem(values, this.getTasksUrl())
    .then(r => this.setTasks());
  }

  deleteTask = () => {
    return this.deleteItem(this.pages.tasks.current.id, this.getTasksUrl())
    .then(r => {
      this.pages.tasks.current = null;
    });
  }

  setTaskStatistic = () => {
    const url = `${this.apiUrl}/counters/tasks`;
    return this.setList(url)
    .then(response => {
      this.pages.taskCounters = response.data;
    });
  }

  setTaskReports = (params = {}) => {
    this.pages.taskReports.filterValues = params;
    params.offset = this.pages.taskReports.offset;

    return this.setList(this.getTasksUrl(), params)
    .then(response => {
      this.pages.taskReports.data = response.data.data;
      this.pages.taskReports.total = response.data.count;
    });
  }

  resetTaskReports = () => {
    this.pages.taskReports = {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {}
    }
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl}/documents/tasks/${this.pages.tasks.current.id || 0}`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setAttachment = (response) => {
    if (response.data.attachment) {
      this.pages.documents.data = [{
        id: "1",
        name: "Attachment",
        path: response.data.attachment
      }];
    }
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }

  // Messages
  getMessagesUrl = () => {
    return `${this.apiUrl}/message_board`;
  }

  setMessages = (options) => {
    return this.setList(this.getMessagesUrl(), options)
    .then(response => {
      this.pages.messages.data = response.data.data;
      return response;
    });
  }

  setCurrentMessage = (message) => {
    this.currentMessage = message;
  }

  resetCurrentMessage = () => {
    this.currentMessage = null;
  }

  addMessage = (message) => {
    let item = {
      description: message,
      type: "text"
    };

    return this.addItem(item, this.getMessagesUrl());
  }

  addFileMessage = (files) => {
    const item = {
      filename: files[0],
      type: "file"
    };

    return this.addItem(item, this.getMessagesUrl());
  }

  deleteMessage = () => {
    return this.deleteItem(this.currentMessage.id, this.getMessagesUrl())
    .then(response => {
      this.currentMessage = null;
      return response;
    });
  }

  // Other
  exportShiftsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl + "/export/pdf/shifts"
    )
    .finally(this.hideLoader);
  }

  exportTasksPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl + "/export/pdf/tasks"
    )
    .finally(this.hideLoader);
  }
}

const store = window.ShiftLogsStore = new ShiftLogsStore;

export default store;

autorun(() => {
    // console.log(store);
});