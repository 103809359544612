import { autorun, observable, toJS } from "mobx";
import Store from "./Store";
import data from '../data/Directories.js';

class DirectoryStore extends Store {
  @observable pages = {
    companyDirectory: {
      data: null,
      total: 0,
      count_featured: 0,
      offset: 0
    },
    retailDirectory: {
      data: null,
      total: 0,
      count_featured: 0,
      offset: 0
    },
    reports: {
      data: null,
      total: 0,
      offset: 0,
      filters: {
        industry: "",
        search: ""
      }
    }
  };
  @observable currentDirectory = null;

  constructor(props) {
    super(props);
    this.retailIndustries = data.retailIndustries;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/directories`);
    this.retailUrl = `${this.url}clients/${clientId}/projects/${projectId}/retails`;
  }

  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    return this.setDirectories(type);
  }

  getUrlByType = (type) => {
    return type == "retailDirectory" ? this.retailUrl : null;
  }

  setDirectories = (type, sortObj = null) => {
    let params = {
      offset: this.pages[type].offset
    };

    return this.setList(this.getUrlByType(type), sortObj ? Object.assign(params, sortObj) : params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
      this.pages[type].count_featured = response.data.count_featured;
    })
  }

  setDirectory = (id, type) => {
    return this.setItem(id, this.getUrlByType(type))
    .then(response => {
      this.currentDirectory = response.data;
      return response;
    });
  }

  setCurrentDirectory = (directory) => {
    this.currentDirectory = directory;
  }

  resetCurrentDirectory = () => {
    this.currentDirectory = null;
  }

  addDirectory = (values, type) => {
    return this.addItem(values, this.getUrlByType(type))
    .then(r => this.setDirectories(type));
  }

  editDirectory = (values, type) => {
    return this.editItem(values, this.getUrlByType(type))
    .then(response => {
      this.setCurrentDirectory(response.data);
      return this.setDirectories(type);
    });
  }

  deleteDirectory = (type) => {
    return this.deleteItem(this.currentDirectory.id,  this.getUrlByType(type))
    .then(r => {
      this.currentDirectory = null;
      return this.setDirectories(type);
    })
  }

  // Reports
  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;

    return this.setList(null, params)
    .then(response => {                    
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count; 
    });
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        industry: "",
        search: ""
      }
    };
  }

  setFilters = (values) => {
    this.pages.reports.filters = values;
  }

  // Insights 
  setInsightCategories = (url = "", type = "", params = {}) => {
    url = `${url}/counters/${type}/industries`;

    return this.setList(url, params)
    .then(response => {                    
      this.insights.categoryCounters = response.data;
      return response;
    });
  }

  setClicksTotal = (url = "", params = {}) => {
    url = `${this.retailUrl}?sort=count_clicks_total&order=DESC`;

    return this.setList(url, params)
    .then(response => {                    
      this.insights.clicksTotal = response.data.data;
      return response;
    });
  }

  setClicksUnique = (url = "", params = {}) => {
    url = `${this.retailUrl}?sort=count_clicks_unique&order=DESC`;

    return this.setList(url, params)
    .then(response => {                    
      this.insights.clicksUnique = response.data.data;
      return response;
    });
  }
}

const store = window.DirectoryStore = new DirectoryStore;

export default store;

autorun(() => {
    // console.log(store);
});