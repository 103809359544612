import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';
import { translate } from "../helpers/Helper";
import Store from "./Store";

class CategoryStore extends Store {
  @observable categoriesData = {
    data: null,
    searchList: null,
    additionalList: null,
    total: 0,
    count: 0,
    offset: 0,
    sortingValues: {
      field: "",
      order: "ASC"
    },
    filterValues: {
      search: ""
    }
  };
  @observable subcategories = null;
  @observable currentCategory = null;
  @observable categoryCount = 1;
  @observable chozenCategoryesIds = [];
  @observable categoriesValues = [ "" ];

  constructor() {
    super();
    this.t = translate;
    this.prefixes = {
      residential: {
        BLD: this.t("Building"),
        APT: this.t("Apartment"),
        MBR: this.t("Member")
      },
      commercial: {
        CMP: this.t("Company"),
        DPT: this.t("Department"),
        MBR: this.t("Member")
      },
      lifestyle: {
        STG: this.t("Stage"),
        ADR: this.t("Address"),
        MBR: this.t("Member")
      },
      build_to_rent: {
        BLD: this.t("Building"),
        APT: this.t("Apartment"),
        MBR: this.t("Member")
      }
    }
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/categories`);
  }

  setCustomData = (token, url) => {
    this.setStoreData(token, url);
  }

  resetData = () => {
    this.categoriesData = {
      data: null,
      searchList: null,
      total: 0,
      count: 0,
      offset: 0
    }
  }

  setPage = (page) => {
    this.categoriesData.offset = page * this.itemsPerPage;
    return this.setCategories({
      offset: this.categoriesData.offset,
    });
  }

  setCategories = (params = {}, isSearchList = false) => {
    params.offset = this.categoriesData.offset;
    params = this.getParams(this.categoriesData, params);

    return this.setList(null, params)
    .then(response => {
      if (!isSearchList) {
        this.categoriesData.data = response.data.data;
      } else {
        this.categoriesData.searchList = response.data.data;
      }
      
      this.categoriesData.total = response.data.count;
      this.categoriesData.count = response.data.count;
      return response;
    });
  }

  setCategoriesByType = (params, listType = "data") => {
    return this.setList(null, params)
    .then(response => {
      this.categoriesData[listType] = response.data.data;
      this.categoriesData.total = response.data.count;
      this.categoriesData.count = response.data.count;

      return response;
    });
  }

  setCategory = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentCategory = response.data;
      return response;
    })
  }

  setSubCategories = (params, id) => {
    return this.setList(`${this.apiUrl}/${id}/subcategories`, params)
    .then(response => {
      this.subcategories = response.data.data;
    });
  }

  resetCategories = () => {
    this.categoriesData.data = null;
    this.categoriesData.searchList = null;
    this.categoriesData.total = 0;
  }

  getCategories = () => {
    return toJS(this.categoriesData.data);
  }

  addCategory = (values) => {
    return this.addItem(values)
    .then(r => this.setCategories({ limit: this.itemsPerPage }));
  }

  editCategory = (values) => {
    return this.editItem(values)
    .then(r => this.setCategories({ limit: this.itemsPerPage }));
  }

  getCurrentCategory = () => {
    return toJS(this.currentCategory);
  }

  setCurrentCategory = (category) => {
    this.currentCategory = category;
  }

  resetCurrentCategory = () => {
    this.currentCategory = null;
  }

  deleteCategory = () => {
    return this.deleteItem(this.currentCategory.id)
    .then(r => {
      this.currentCategory = null;
      this.setCategories({
        limit: 50                
      });
    });
  }

  searchCategories = (value) => {
    const params = {
      limit: 500,
      search: value
    };

    return this.setList(null, params)
    .then(response => {
      this.categoriesData.searchList = response.data.data;
      return response.data.data;
    });
  }

  getCategoryNamesById = (ids, isSearch = false) => {
    let result = [];
    const data = isSearch ? this.categoriesData.searchList : this.categoriesData.data;

    if (!ids) {
      return [];
    }

    ids.forEach(id => {
      if (data) {
        data.forEach(category => {
          if (id == category.id) {
            result.push(category.name);
          }
        });
      }
    });

    return result;
  }

  searchCategoryById = (ids, isSearch = false) => {
    let result = [];
    const data = !isSearch ? this.categoriesData.additionalList : this.categoriesData.searchList;

    if (!ids || !data) {
      return [];
    }

    ids.forEach(id => {
      if (data) {
        data.forEach(category => {
          if (id == category.id) {
            result.push(category.name);
          }
        });
      }
    });

    return result;
  }

  getCategoryNameById = (id) => {
    let result = null;
    this.categoriesData.searchList.forEach(category => {
      if (id == category.id) {
        result = category.name;
      }
    });

    return result;
  }
  
  setCategoryData = (count, ids, values) => {
    this.categoryCount = count;
    this.chozenCategoryesIds = ids;
    this.categoriesValues = values;
  }

  setCategoryValue = (value, index) => {
    if (this.categoriesValues.indexOf(value + "") == -1) {
      this.categoriesValues[index] = value + "";
    } else {
      NotificationManager.error(this.t("This category was choosen before."));
    }
  }

  addSelectCategory = () => {
    let count = this.categoryCount;
    if (this.categoriesValues[count - 1] != "") {
      this.categoryCount = ++count;
      this.categoriesValues.push("");
    } else {
      NotificationManager.error(this.t("Please select category."));
    }
  }

  setRepeatedData = (name, count, values) => {
    this[name].count = count;
    this[name].values = values;
  }

  setRepeatedValue = (name, value, index) => {
    if (this[name].values.indexOf(value) == -1) {
      this[name].values[index] = value;
    } else {
      NotificationManager.error(this.t("Please item was choosen before."));
    }
  }

  addRepeatedItem = (name) => {
    let count = this[name].count;
    if (this[name].values[count]) {
      this[name].count = ++count;
    } else {
      NotificationManager.error(this.t("Please select item."));
    }
  }

  deleteRepeatedItem = (name, index) => {
    this[name].values.splice(index, 1);
		this.setRepeatedData(name, --this[name].count, this[name].values);	
  }
}

const store = window.CategoryStore = new CategoryStore;

export default store;

autorun(() => {
    // console.log(store);
});