import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';
import { translate } from "../helpers/Helper";
import data from "../data/Lockers";
import Store from "./Store.js";

class LockersStore extends Store {
  @observable pages = {
    lockers: {
      current: null,
      statusCounters: null,

      all: {
        data: null,
        offset: 0,
        total: 0
      },
      pin_pending: {
        data: null,
        offset: 0,
        total: 0
      },
      occupied: {
        data: null,
        offset: 0,
        total: 0
      },
      available: {
        data: null,
        offset: 0,
        total: 0
      },
      disabled: {
        data: null,
        offset: 0,
        total: 0
      }
    },
    lockerTypes: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    bookingPlans: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    holidays: [ {
      start_date: null,
      end_date: null,
      details: ""
    } ],
    holidayCounts: 1,
    bookings: {
      current: null,
      statusCounters: null,
      all: {
        data: null,
        offset: 0,
        total: 0
      },
      requested: {
        data: null,
        offset: 0,
        total: 0
      },
      waiting_for_payment: {
        data: null,
        offset: 0,
        total: 0
      },
      paid: {
        data: null,
        offset: 0,
        total: 0
      },
      active: {
        data: null,
        offset: 0,
        total: 0
      },
      expired: {
        data: null,
        offset: 0,
        total: 0
      },
      canceled: {
        data: null,
        offset: 0,
        total: 0
      },
      reports: {
        data: null,
        offset: 0,
        total: 0,
        filters: {}
      }
    }
  };
  
  constructor() {
    super();
    this.t = translate;
    this.bookingStatuses = data.bookingStatuses;
    this.bookingStatusColors = data.bookingStatusColors;
    this.lockerStatuses = data.lockerStatuses;
    this.lockerStatusColors = data.lockerStatusColors;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}`);
  }

  setPage = (callback, page, type, subtype = "") => {
    let data = this.pages[type];
    if (subtype != "") {
      data = data[subtype];
      data.offset = page * this.itemsPerPage;
      this[callback]({ status: subtype });
    } else {
      data.offset = page * this.itemsPerPage;
      this[callback]();
    }    
  }

  // Locker Types
  getLockerTypeUrl = () => {
    return `${this.apiUrl}/locker-types`;
  }

  setLockerTypes = (params = {}) => {
    params.offset = this.pages.lockerTypes.offset;

    return this.setList(this.getLockerTypeUrl(), params)
    .then(response => {
      this.pages.lockerTypes.data = response.data.data;
      this.pages.lockerTypes.total = response.data.count;
      this.pages.lockerTypes.count = response.data.count;
      return response;
    });
  }

  setLockerType = (id) => {
    return this.setItem(id, this.getLockerTypeUrl())
    .then(response => {
      this.pages.lockerTypes.current = response.data;
      return response;
    });
  }

  setCurrentLockerType = (item) => {
    this.pages.lockerTypes.current = item;
  }
 
  resetCurrentLockerType = () => {
    this.pages.lockerTypes.current = null;
  }
 
  addLockerType = (values) => {
    return this.addItem(values, this.getLockerTypeUrl());
  }
 
  editLockerType = (values) => {
    return this.editItem(values, this.getLockerTypeUrl());
  }
 
  deleteLockerType = () => {
    const id = this.pages.lockerTypes.current.id;
    
    return this.deleteItem(id, this.getLockerTypeUrl())
    .then(r => {
      this.pages.lockerTypes.current = null;
    });
  }

  assignLockerType = (values) => {
    const url = this.apiUrl + "/assign/lockers";
    return this.addItem(values, url);
  }

  // Locker Type Booking Plans
  getBookingPlansUrl = () => {
    return `${this.getLockerTypeUrl()}/${this.pages.lockerTypes.current.id}/plans`;
  }

  setBookingPlans = (params = {}) => {
    params.offset = this.pages.bookingPlans.offset;

    return this.setList(this.getBookingPlansUrl(), params)
    .then(response => {
      this.pages.bookingPlans.data = response.data.data;
      this.pages.bookingPlans.total = response.data.count;
      this.pages.bookingPlans.count = response.data.count;
      return response;
    });
  }

  setBookingPlanById = (id) => {
    return this.setItem(id, this.getBookingPlansUrl())
    .then(response => {
      this.pages.bookingPlans.current = response.data;
      return response;
    });
  }

  setBookingPlan = (item) => {
    this.pages.bookingPlans.current = item;
  }
 
  resetBookingPlan = () => {
    this.pages.bookingPlans.current = null;
  }
 
  addBookingPlan = (values) => {
    return this.addItem(values, this.getBookingPlansUrl());
  }
 
  editBookingPlan = (values) => {
    return this.editItem(values, this.getBookingPlansUrl());
  }
 
  deleteBookingPlan = () => {
    const id = this.pages.bookingPlans.current.id;
    
    return this.deleteItem(id, this.getBookingPlansUrl())
    .then(r => {
      this.pages.bookingPlans.current = null;
    });
  }

  // Holidays
  setHolidaysData = (count, values) => {
    this.pages.holidayCounts = count;
    this.pages.holidays = values;
  }

  setHolidayValue = (index, field, value) => {
    this.pages.holidays[index][field] = value;
  }

  addHoliday = () => {
    let count = this.pages.holidays.length;
    if (!this.pages.holidays[count - 1] || (this.pages.holidays[count - 1].start_date && this.pages.holidays[count - 1].end_date)) {
      this.pages.holidays.push({
        start_date: null,
        end_date: null,
        details: ""
      });
    } else {
      NotificationManager.error(this.t("Please fill holiday."));
    }
  }

  // Lockers
  getLockersUrl = () => {
    return `${this.apiUrl}/lockers`;
  }

  setLockers = (params = {}, unsetCounters = false) => {
    let type = params.status ? params.status : "all";
    params.offset = this.pages.lockers[type].offset;

    return this.setList(this.getLockersUrl(), params)
    .then(response => {
      this.pages.lockers[type].data = response.data.data;
      this.pages.lockers[type].total = response.data.count;
      this.pages.lockers[type].count = response.data.count;
      return response;
    })
    .then(response => {
      if (!unsetCounters) {
        this.setLockerCounters();
      }
      return response;
    });
  }

  setLockerCounters = () => {
    const { lockers } = this.pages;
    return this.setList(`${this.apiUrl}/counters/lockers`)
    .then(response => {
      const data = response.data;
      Object.keys(data).forEach(key => {
        if (key.indexOf("total") != -1) {
          lockers.all.total = data[key];
        } else {
          const status = key.replace("count_", "") == "total" ? "all" : key.replace("count_", "");

          if (lockers[status]) {
            lockers[status].total = data[key];
          }
        }
      });

      lockers.statusCounters = response.data;
    });
  }

  setAvailableLockers = (tid, userId, params = {}) => {
    const url = `${this.apiUrl}/available/locker-types/${tid}`;
    if (userId) {
      params.user_id = userId;
    }
  
    return this.setList(url, params)
    .then(response => {
      this.pages.lockers.data = response.data.data;
      this.pages.lockers.total = response.data.count;
      this.pages.lockers.count = response.data.count;
      return response;
    });
  }

  setLocker = (id) => {
    return this.setItem(id, this.getLockersUrl())
    .then(response => {
      this.pages.lockers.current = response.data;
      return response;
    });
  }

  setCurrentLocker = (item) => {
    this.pages.lockers.current = item;
  }
 
  resetCurrentLocker = () => {
    this.pages.lockers.current = null;
  }
 
  addLocker = (values) => {
    return this.addItem(values, this.getLockersUrl());
  }
 
  editLocker = (values) => {
    return this.editItem(values, this.getLockersUrl());
  }
 
  deleteLocker = () => {
    const id = this.pages.lockers.current.id;
    
    return this.deleteItem(id, this.getLockersUrl())
    .then(r => {
      this.pages.lockers.current = null;
    });
  }

  // Locker Bookings
  getBookingsUrl = () => {
    return `${this.apiUrl}/locker-bookings`;
  }

  setBookings = (params = {}, isReports = false) => {
    let type = params.status ? params.status : "all";
    params.offset = this.pages.bookings[type].offset;

    if (isReports) {
      type = "reports";
    }

    return this.setList(this.getBookingsUrl(), params)
    .then(response => {
      this.pages.bookings[type].data = response.data.data;
      this.pages.bookings[type].total = response.data.count;
      this.pages.bookings[type].count = response.data.count;
      return response;
    });
  }

  setBookingCounters = () => {
    return this.setList(`${this.apiUrl}/counters/locker-bookings`)
    .then(response => {
      const data = response.data;
      Object.keys(data).forEach(key => {
        if (key.indexOf("total") != -1) {
          this.pages.bookings.all.total = data[key];
        } else {
          this.pages.bookings[key.replace("count_", "")].total = data[key];
        }
      });

      this.pages.bookings.statusCounters = response.data;
    });
  }

  setBooking = (id) => {
    return this.setItem(id, this.getBookingsUrl())
    .then(response => {
      this.pages.bookings.current = response.data;
      return response;
    });
  }

  setCurrentBooking = (item) => {
    this.pages.bookings.current = item;
  }
 
  resetCurrentBooking = () => {
    this.pages.bookings.current = null;
  }
 
  addBooking = (values) => {
    return this.addItem(values, this.getBookingsUrl());
  }
 
  editBooking = (values) => {
    return this.editItem(values, this.getBookingsUrl());
  }

  // cancelBookings = () => {
  //   const id = this.pages.bookings.current.id;
  //   return this.addItem({}, `${this.getBookingsUrl()}/${id}/cancel`);
  // }
 
  deleteBooking = () => {
    const id = this.pages.bookings.current.id;
    
    return this.deleteItem(id, this.getBookingsUrl())
    .then(r => {
      this.pages.bookings.current = null;
    });
  }

  // Other
  exportLockersPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl + "/export/pdf/locker-bookings"
    )
    .finally(this.hideLoader);
  }
}

const store = window.LockersStore = new LockersStore;
export default store;
autorun(() => {});