import { NotificationManager } from 'react-notifications';
import { autorun, observable, toJS } from "mobx";
import Store from "./Store";

class FaqStore extends Store {
  @observable pages = {
    groups: {
      data: null,
      offset: 0,
      total: 0
    },
    questions: {
      data: null,
      offset: 0,
      total: 0
    }
  };
  @observable currentQuestion = null;
  @observable currentGroup = null;    
  @observable currentGroupId = null;
  @observable currentFaq = null;
  @observable categoryCount = 1;
  @observable chozenCategoryesIds = [];
  @observable categoriesValues = [];
  @observable searchValue = "";

  constructor() {
    super();
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/faq`);
  }

  setPage = (page, type, params = {}) => {
    this.pages[type].offset = page * this.itemsPerPage;

    if (type == "groups") {
      this.setGroups(params);
    } else {
      this.setQuestions(params);
    }
  }

  getGroupUrl = () => {
    return `${this.apiUrl}/groups`;
  }

  setGroups = (params = {}) => {
    params.offset = this.pages.groups.offset;

    return this.setList(this.getGroupUrl(), params)
    .then(response => {
      this.pages.groups.data = response.data.data;
      this.pages.groups.total = response.data.count;
      return response;
    });
  }

  addGroup = (values) => {
    return this.addItem(values, this.getGroupUrl())
    .then(r => this.setGroups());
  }

  editGroup = (values) => {
    return this.editItem(values, this.getGroupUrl())
    .then(response => {
      this.currentGroup = response.data;
      this.setGroups();
    });
  }

  setCurrentGroup = (group) => {
    this.currentGroup = group;
  }

  setCurrentGroupById = (id) => {
    this.currentGroupId = id;
    return this.setQuestions({ group_id: id });
  }

  getCurrentGroup = () => {
    return toJS(this.currentGroup);
  }

  resetCurrentGroup = () => {
    this.currentGroup = null;
    this.currentGroupId = null;
  }

  deleteGroup = (id) => {
    return this.deleteItem(id, this.getGroupUrl())
    .then(r => this.setGroups());
  }

  // Questions
  getQuestionUrl = () => {
    return `${this.apiUrl}/questions`;
  }

  setQuestions = (params = {}) => {
    params.offset = this.pages.questions.offset;

    return this.setList(this.getQuestionUrl(), params)
    .then(response => {
      this.pages.questions.data = response.data.data;
      this.pages.questions.total = response.data.count;
      return response;
    });
  }

  resetQuestions = () => {
    this.pages.questions.data = null;
  }

  addQuestion = (values) => {
    return this.addItem(values, this.getQuestionUrl())
    .then(response => {
      this.setQuestions({ group_id: values.group_id });
      return response;
    });
  }

  editQuestion = (values) => {
    return this.editItem(values, this.getQuestionUrl())
    .then(response => {
      this.setQuestions({ group_id: values.group_id });
      return response;
    });
  }

  getCurrentQuestion = () => {
    return toJS(this.currentQuestion);
  }

  setCurrentQuestion = (question) => {
    this.currentQuestion = question;
  }

  resetCurrentQuestion = () => {
    this.currentQuestion = null;
  }

  deleteQuestion = (params = {}) => {
    return this.deleteItem(this.currentQuestion.id, this.getQuestionUrl())
    .then(r => this.setQuestions(params));
  }

  // Apartment categories
  setCategoryValue = (value, index) => {
    if (this.categoriesValues.indexOf(value) == -1) {
      this.categoriesValues[index] = value;
    } else {
      NotificationManager.error("This category was chozen before.");
    }
  }

  addCategory = () => {
    let count = this.categoryCount;
    if (this.categoriesValues[count - 1]) {
      this.categoryCount = ++count;
    } else {
      NotificationManager.error("Please select category.");
    }
  }

  setCategoryData = (count, ids, values) => {
    this.categoryCount = count;
    this.chozenCategoryesIds = ids;
    this.categoriesValues = values;
  }
}

const store = window.FaqStore = new FaqStore;

export default store;

autorun(() => {
    // console.log(store);
});