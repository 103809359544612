import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  industries: {
    "access_control": t("Access Control"),
    "amenities": t("Amenities"),
    "appliances": t("Appliances"),
    "bmu": t("BMU"),
    "builder": t("Builder"),
    "cctv": t("CCTV"),
    "cleaning": t("Cleaning"),
    "consultants": t("Consultants"),
    "deodorising": t("Deodorising"),
    "doors": t("Doors"),
    "decking": t("Decking"),
    "duct_filter_cleaning": t("Duct / Filter cleaning"),
    "electrical": t("Electrical"),
    "facade": t("Facade"),
    "finishes": t("Finishes"),
    "fixtures": t("Fixtures"),
    "fire": t("Fire"),
    "first_aid": t("First Aid"),
    "flooring": t("Flooring"),
    "furniture": t("Furniture"),
    "garage_doors": t("Garage Doors"),
    "general_contractor": t("General Contractor"),
    "maintenance": t("Maintenance"),
    "general_maintenance": t("General Maintenance"),
    "generators_gensets": t("Generators / Gensets"),
    "glazing": t("Glazing"),
    "gymnasium": t("Gymnasium"),
    "hydraulic": t("Hydraulic"),
    "it_internet": t("I.T. / Internet"),
    "plumbing_drainage": t("Plumbing & Drainage"),
    "landscaping": t("Landscaping"),
    "locksmith": t("Locksmith"),
    "lighting": t("Lighting"),
    "lifts": t("Lifts"),
    "matv": t("MATV"),
    "mechanical": t("Mechanical/HVAC"),
    "painting": t("Painting"),
    "pool_spa": t("Pool/SPA"),
    "pest_control": t("Pest Control"),
    "rendering": t("Rendering"),
    "sauna": t("Sauna"),
    "steam": t("Steam"),
    "office": t("Office"),
    "safety_certification": t("Safety & Certification"),
    "security": t("Security"),
    "signage": t("Signage"),
    "tiling": t("Tiling"),
    "waste_management": t("Waste Management"),
    "other": t("Other")
  }
}

export default data;