import { NotificationManager } from 'react-notifications';
import { autorun, observable, toJS } from "mobx";
import data from "../data/Contractors.js";
import Store from "./Store";

class ContractorStore extends Store {
  @observable pages = {
    staff: {
      data: null,
      offset: 0,
      total: 0
    },
    companies: {
      current: null,
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        search: "",
        industry: ""
      }
    },
    companyList: {
      current: null,
      data: null,
      offset: 0,
      total: 0,
      sortingValues: {
        field: "",
        order: "ASC"
      },
      filterValues: {
        search: "",
        industry: ""
      }
    },
    reports: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        date_start: null,
        date_end: null
      }
    }
  };
  @observable companies = null;
  @observable currentCompany = null;
  @observable contractorList = null;
  @observable staffList = null;
  @observable currentStaff = null;
  @observable documents = null;
  @observable document = null;
  @observable pageOffset = 0;
  @observable totalItems = 0;
  @observable companyList = null;
  @observable industryValues = [];
  @observable industryCount = 1;

  constructor() {
    super();
    this.industries = data.industries;
  }

  setData = (clientId, projectId, token) => {
    if (projectId) {
      this.setStoreData(token, `clients/${clientId}/projects/${projectId}/contractors`);
    } else {
      this.setStoreData(token, `clients/${clientId}/contractors`);
    }
    this.clientUrl = `${this.url}clients/${clientId}/contractors`;
  }

  setPageByCallback = (page, type, callback = null) => {
    let data = this.pages[type];
    data.offset = page * this.itemsPerPage;

    if (callback) {
      callback();
    }
  }

  setPage = (page) => {
    this.pages.companies.offset = page * this.itemsPerPage;

    this.setCompanies();
  }

  setPageByType = (page, type, callback) => {
    this.pages[type].offset = page * this.itemsPerPage;
    return callback();
  }

  resetPage = () => {
    this.pageOffset = 0;
  }

  // Companies
  setCompanies = (params = {}, isSelectList = false) => {
    let url = this.apiUrl;
    const type = !isSelectList ? "companies" : "companyList"; 

    if (isSelectList) {
      url = this.clientUrl;
    }

    params = this.getParams(this.pages[type], params);

    return this.setList(url, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
    });
  }

  setCompaniesWithParams = (params = {}, isSelectList = false) => {
    let url = this.apiUrl;
    const type = !isSelectList ? "companies" : "companyList"; 

    if (isSelectList) {
      url = this.clientUrl;
    }

    return this.setList(url, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
    });
  }

  setCompany = (companyId, contractorId = null) => {
    return this.setItem(companyId, this.clientUrl)
    .then(response => {
      this.currentCompany = response.data;
      
      if (contractorId) {
        this.setContractorStaff(contractorId);
        this.setContractorStaff(companyId, true, {limit: 100});
        this.setDocuments(contractorId);
      } else {
        this.setContractorStaff(companyId);
      }
      
      return response;
    });
  }

  setContractor = (id) => {
    return this.setItem(id)
    .then(response => this.setCompany(response.data.contractor_id, id));
  }

  setContractorById = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentCompany = response.data;
    });
  }

  getCompanyList = () => {
    return toJS(this.companies);
  }

  addCompany = (values) => {
    return this.addItem(values)
    .then(r => this.setCompanies());
  }

  editCompany = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentCompany = response.data;
      this.setCompanies();
    });
  }

  deleteCompany = () => {
    return this.deleteItem(this.currentCompany.id)
    .then(r => {
      this.currentCompany = null;
      return this.setCompanies();
    });
  }

  setCurrentCompany = (company) => {
    this.currentCompany = company;
  }

  getCurrentCompany = () => {
    return toJS(this.currentCompany);
  }

  resetCurrentCompany = () => {
    this.currentCompany = null;
  }

  searchContractors = (value, isSelectList = false) => {
    let url = this.apiUrl;
    let params = {
      limit: 50,
      search: value
    };

    if (isSelectList) {
      url = this.clientUrl;
    }

    return this.setList(url, params)
    .then(response => {
      this.companies = response.data.data;
      this.totalItems = response.data.count;
    });
  }

  // Company industries
  setIndustries = (industries) => {
    this.industryValues = industries.split(',');
    this.industryCount = this.industryValues.length;
  }

  setIndustry = (value, index) => {
    if (this.industryValues.indexOf(value) == -1) {
      this.industryValues[index] = value;
    } else {
      NotificationManager.error("This industry was choosen before.");
    }
  }
  
  addIndustry = () => {
    let count = this.industryCount;

    if (this.industryValues[count - 1]) {
      this.industryCount = ++count;
    } else {
      NotificationManager.error("Please select industry.");
    }
  }

  resetIndustries = () => {
    this.industryValues = [];
    this.industryCount = 1;
  }

  // Contractors / Contractor staff
  getStaffUrl = (isComapnyStaff = false, companyId) => {
    if (isComapnyStaff) {
      return `${this.clientUrl}/${companyId}/staff`;
    }

    return `${this.apiUrl}/${companyId}/staff`;
  }

  setContractorStaff = (companyId, isComapnyStaff = false, params = {}) => {
    if (!isComapnyStaff) {
      params.offset = this.pages.staff.offset;
    }

    return this.setList(this.getStaffUrl(isComapnyStaff, companyId), params)
    .then(response => {
      if (!isComapnyStaff) {
        this.pages.staff.data = response.data.data;
        this.pages.staff.total = response.data.count;
      } else {
        this.contractorList = response.data.data;
      }

      return response;
    });
  }

  getStaffList = () => {
    return toJS(this.pages.staff.data);
  }

  getCurrentStaff = () => {
    return toJS(this.currentStaff);
  }

  setCurrentStaff = (staff) => {
    this.currentStaff = staff;
  }

  resetCurrentStaff = () => {
    this.currentStaff = null;
  }

  addContractorStaff = (values, isComapnyStaff = false) => {
    const id = isComapnyStaff ? values.contractor_id : this.currentCompany.id;

    return this.addItem(values, this.getStaffUrl(false, id))
    .then(response => {
      if (isComapnyStaff) {
        this.setContractorStaff(values.contractor_id, false);
      } else {
        this.setContractorStaff(this.currentCompany.id);
      }

      return response;
    });
  }

  editContractorStaff = (values, contractorId) => {
    return this.editItem(values, this.getStaffUrl(false, contractorId))
    .then(r => this.setContractorStaff(contractorId));
  }

  deleteContractorStaff = (id) => {
    return this.deleteItem(this.currentStaff.id, this.getStaffUrl(false, id))
    .then(r => {
      this.currentStaff = null;
      this.setContractorStaff(id);
    });
  }

  // Contractor documents
  getDocumentsUrl = (id) => {
    return `${this.apiUrl}/${id}/documents`;
  }

  setDocuments = (id) => {
    return this.setList(this.getDocumentsUrl(id))
    .then(response => {
      this.documents = response.data.data;
    });
  }

  setDocument = (document) => {
    this.document = document;
  }

  resetDocument = () => {
    this.document = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl(values.contractor_id))
    .then(r => this.setDocuments(values.contractor_id));
  }

  deleteDocument = (id) => {
    return this.deleteItem(this.document.id, this.getDocumentsUrl(id))
    .then(r => {
      this.document = null;
      return this.setDocuments(id);
    });
  }

  // Reporting
  setReportsPage = (page, params = {}) => {
    const offset = page * (params.limit ? params.limit : this.itemsPerPage);
    this.pages.reports.offset = offset;
    params = {...this.pages.reports.filterValues};

    return this.setReports(params);
  }

  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;

    if (params.company_id) {
      return this.setContractor(params.company_id)
      .then(r => this.setContractorStaff(params.company_id))
      .then(response => {
        this.pages.reports.data = response.data.data;
        this.pages.reports.total = response.data.count;       
      });
    }

    return null;   

    // return this.setList(null, params)
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        status: "all",
        category: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    if (values.limit) {
      delete values.limit;
    }
    
    this.pages.reports.filterValues = values;
  }

  // Other
  exportContractorPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/contractors", "/export/pdf/contractors/staff")
    )
    .finally(this.hideLoader);
  }
}

const store = window.ContractorStore = new ContractorStore;

export default store;

autorun(() => {
    // console.log(store);
});