import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  statuses: {
    "open": t("Open"),
    "in_progress": t("In Progress"),
    "resolved": t("Resolved")
  },
	categories: {
    "amenities": t("Amenities"),
    "assistance": t("Assistance"),
    "breach": t("Breach"),
    "common_area": t("Common Area"),
    "common_area_defect": t("Common Area Defect"),
    "complaint": t("Complaint"),
    "cleanliness": t("Cleanliness"),
    "deliveries": t("Deliveries"),
    "defects": t("Defects"),
    "maintenance": t("Maintenance"),
    "private_lot_defect": t("Private Lot Defect"),
    "security": t("Security"),
    "services": t("Services"),
    "renovations": t("Renovations"),
    "residence": t("Residence"),
    "tech_concierge": t("Tech Concierge"),
    "general": t("General"),
    "other": t("Other")
  },
  locations: {
    "common": t("Common Area"),
    "private": t("Private")
  },
  actions: {
    "breach": t("Breach"),
    "incident": t("Incident"),
    "qr": t("Quote"),
    "wo": t("Work Order")
  }
}

export default data;