import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  statuses: {
    "to_be_collected": t("To be collected"),
    "collected": t("Collected"),
    "disposal_alert": t("Disposal alert"),
    "disposed": t("Disposed"),
    "return_to_sender": t("Return to sender")
  },
  statusNames: {
    "incoming_held": t("Incoming Held"),
    "outgoing_held": t("Outgoing Held"),
    "to_be_collected": t("To be collected"),
    "collected": t("Collected"),
    "disposal_alert": t("Disposal alert"),
    "disposed": t("Disposed"),
    "returned": t("Return to sender")
  },
  types: {
    "parcel": t("Parcel"),
    "perishable": t("Perishable"),
    "satchel": t("Satchel"),
    "flowers": t("Flowers"),
    "large": t("Large"),
    "other": t("Other")
  },
  couriers: {
    "fedex": t("Fed Ex"),
    "toll": t("Toll")
  },
  colours: {
    "brown": t("Brown"),
    "red": t("Red"),
    "grey": t("Grey"),
    "yellow": t("Yellow"),
    "pink": t("Pink"),
    "blue": t("Blue"),
    "orange": t("Orange"),
    "green": t("Green"),
    "black": t("Black"),
    "gold": t("Gold"),
    "white": t("White"),
    "multiple": t("Multiple")
  },
  itemDescriptions: {
    "regular_parcel": t("Regular Parcel"),
    "large_parcel": t("Large parcel"),
    "small_parcel": t("Small parcel"),
    "tube": t("Tube"),
    "mail": t("Mail"),
    "extra_large_box": t("Extra Large Box"),
    "large_box": t("Large Box"),
    "regular_box": t("Regular Box"),
    "small_box": t("Small Box"),
    "registered_mail": t("Registered Mail"),    
    "flowers": t("Flowers"),
    "satchel": t("Satchel"),
    "perishable": t("Perishable"),
    "multiple": t("Multiple"),
    "dry_cleaning": t("Dry cleaning"),
    "other": t("Other")
  },
  locations: {
    "top_shelf": t("Top Shelf"),
    "middle_shelf": t("Middle Shelf"),
    "lower_shelf": t("Lower Shelf"),
    "cool_room": t("Cool room locker"),
    "other": t("Other")
  },
  notifications: {
    "push_email": t("Push Notification & Email"),
    "sms": t("SMS Notification")
  },
  directions: {
    "incoming": t("Incoming"),
    "outgoing": t("Outgoing")
  }
}

export default data;