import React, { useEffect } from "react";
import { toJS } from 'mobx';
import { Link } from "react-router-dom";
import { T, withTranslators } from "lioness";
import { inject, observer } from "mobx-react";
import { Scrollbars } from 'react-custom-scrollbars';
import { Nav, NavItem, Badge } from 'reactstrap';
import kindredLogo from 'assets/img/kindred-logo.png';

const Sidebar = function(props) {
  const { NavigationStore, ProjectStore, match } = props;
  const { getCurrentProject, currentProject } = ProjectStore;
  const { menuData, isAdminPage } = NavigationStore;
  const { projectId, clientId } = match.params;
  let menu = isAdminPage() ? toJS(menuData.adminMenu) : toJS(menuData.menu); 

  useEffect(() => {
    NavigationStore.setActiveLink(window.location.pathname);
  }, []);

  return(
    <div className="sidebar">
      <div className="sidebar-content">
        <div className="logo-wrapper">
          {renderLogo(isAdminPage())}
        </div>

        <Scrollbars className="sidebar-scrollbar">
          {renderMenu(menu)}
        </Scrollbars>

        <ul className="sidebar-menu stick-menu nav flex-column">
          {renderStickItems(menu)}
        </ul>
      </div>
    </div>
  );

  function renderLogo(isAdmin) {
    const { getUrl } = NavigationStore;
    const projectUrl = getUrl(match);

    let image = null;

    if (currentProject != null && currentProject.logo) {
      image = (
        <Link to={projectUrl}>
          <img src={currentProject.logo} />
        </Link>
      );
    }

    if (isAdmin) {      
      image = (
        <Link to="/clients">
          <img src={kindredLogo} />
        </Link>
      );
    }

    return image;
  }

  function renderMenu(data) {
    const project = getCurrentProject();

    return (
      <Nav vertical className="sidebar-menu">
        {data.map(item => renderMenuItem(item, project))}
      </Nav>
    );
  }

  function renderMenuItem(menuItem, project) {
    const counters = project ? project.counters : null;
    let navItemClass = "";
    let subMenu = null;

    if ((project && project.features[menuItem.name] == "0") || checkProjectAccess(project, menuItem) || menuItem.isStick) {
      return null;
    }

    if (menuItem.menu) {
      subMenu = menuItem.isExpanded ? (
        <Nav vertical className="sidebar-sub-menu">
          {menuItem.menu.map(item => renderMenuItem(item, project))}
        </Nav>
      ): null;

      navItemClass = menuItem.isExpanded ? "expanded" : "expand";
    }

    if (menuItem.isStick) {
      navItemClass += " stick";
    }

    return (
      <NavItem 
        key={menuItem.title}
        className={navItemClass}
      >
        <Link 
          className={menuItem.active ? 'nav-link active': 'nav-link'}
          to={getLink(menuItem)}
          onClick={(e) => onClick(e, menuItem)}
        >
          {menuItem.badge ?
            <div className="float-right">
            {counters && counters[menuItem.badge.name] != 0 ? (
              <Badge color={menuItem.badge.color ? menuItem.badge.color : "primary"}>
                {counters[menuItem.badge.name]}
              </Badge>
            ): null}
          </div>
          : null}
          {menuItem.icon ? 
            <i className={`menu-icon icon-${menuItem.icon}`}></i>
          : <span />}
          <span><T>{menuItem.title}</T></span>
        </Link>              

        {subMenu}
      </NavItem>
    );
  }

  function renderStickItems(menu) {
    return menu.map((item, index) => {
      if (!item.isStick) {
        return null;
      }

      return (
        <NavItem 
          key={`${item.title}-${index}`}
        >
          <Link 
            className={item.active ? 'nav-link active': 'nav-link'}
            to={getLink(item)}
            onClick={(e) => onClick(e, item)}
          >
            {item.icon ? 
              <i className={`menu-icon icon-${item.icon}`}></i>
            : <span />}
            <span><T>{item.title}</T></span>
          </Link>
        </NavItem>
      );
    });
  }

  function checkProjectAccess(project, menuItem) {
    if (project && typeof menuItem.is_for_commercial !== 'undefined') {
      return project.project_type == "commercial" ? menuItem.is_for_commercial == false : menuItem.is_for_commercial == true;
    }

    if (project && typeof menuItem.is_for_residential !== 'undefined') {
      return project.project_type == "residential" ? menuItem.is_for_residential == false : menuItem.is_for_residential == true;
    }

    if (project && typeof menuItem.is_for_lifestyle !== 'undefined') {
      return project.project_type == "lifestyle" ? menuItem.is_for_lifestyle == false : menuItem.is_for_lifestyle == true;
    }

    if (project && typeof menuItem.is_for_build_to_rent !== 'undefined') {
      return project.project_type == "build_to_rent" ? menuItem.is_for_build_to_rent == false : menuItem.is_for_build_to_rent == true;
    }

    return false;
  }

  function getLink(menuItem) {
    if (menuItem.href && projectId) {
      return menuItem.href ? `/client/${clientId}/project/${projectId}${menuItem.href}` : '/page404';
    }

    return menuItem.href ? menuItem.href : '/page404';    
  }

  function onClick(e, item) {
    if (item.menu) {
      e.preventDefault();
      NavigationStore.expandGroup(item.title);
    } else {
      NavigationStore.setActiveLink(item.href);
    }
  }
}

export default withTranslators(inject('NavigationStore', 'ProjectStore')(observer(Sidebar)));