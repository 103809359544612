import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  industries: {
    "accounting": t("Accounting"),
    "advertising": t("Advertising, Arts & Media"),
    "banking_financial": t("Banking & Financial"),
    "customer_support": t("Customer Support"),
    "community_service": t("Community Service"),
    "construction": t("Construction"),
    "consulting_strategy": t("Consulting & Strategy"),
    "design_architecture": t("Design & architecture"),
    "education_training": t("Education & Training"),
    "engineering": t("Engineering"),
    "government_defence": t("Government & Defence"),
    "healthcare_medical": t("Healthcare & Medical"),
    "hospitality_tourism": t("Hospitality & Tourism"),
    "recruitment": t("Recruitment"),
    "it_software": t("IT & Software"),
    "insurance": t("Insurance"),
    "superannuation": t("Superannuation"),
    "legal": t("Legal"),
    "transport": t("Transport"),
    "logistics": t("Logistics"),
    "marketing_sales": t("Marketing & Sales"),
    "real_estate": t("Real Estate"),
    "retail": t("Retail"),
    "science": t("Science"),
    "sports": t("Sports"),
    "other": t("Other")
  }
}

export default data;