import { autorun, observable } from "mobx";

class PreloaderStore {
  @observable isShowPreloader = false;

  show = () => {
    this.isShowPreloader = true;
  }

  hide = () => {
    this.isShowPreloader = false;
  }
}

const store = window.PreloaderStore = new PreloaderStore;

export default store;

autorun(() => {
    // console.log(store);
});