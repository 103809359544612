import { NotificationManager } from 'react-notifications';
import { autorun, observable, toJS } from "mobx";
import { translate } from "../helpers/Helper";
import Store from "./Store";

class DocumentStore extends Store {
	@observable documents = null;
	@observable docType = null;
	@observable category = "none";
	@observable isRoot = true;
	@observable currentDocument = null;
	@observable pageOffset = 0;
	@observable totalItems = 0;
	@observable currentFolderId = "";
  @observable parentFolderId = "0";
  @observable categoryCount = 1;
  @observable chozenCategoryesIds = [];
  @observable categoriesValues = [];
  @observable buildingCounters = {};

	constructor() {
    super();
    this.t = translate;		
		
    this.buildingCategories = {
      media_gallery: this.t("Media Gallery"),
      priority: this.t("Property"),
      building_manuals: this.t("Building manuals"),
      knowledge_base: this.t("Knowledge Base")
    };
    this.residentCategories = {
      apartment_manuals: this.t("Apartment Manuals"),
      warranties: this.t("Warranties"),
      building_manuals: this.t("Building manuals"),
      knowledge_base: this.t("Knowledge Base")
    };
	}

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/documents`);
  }

	setDocType = (type) => {
		this.docType = type;
	}

	setCategory = (category) => {
		this.category = category;
		return this.setDocuments();
	}

  setPage = (page, folder_id) => {
    this.pageOffset = page * this.itemsPerPage;
    this.setDocuments("", folder_id);
  }

	setDocuments = (search = "", folder_id = "0", sortObj = null) => {
    const url = `${this.apiUrl}/${this.docType}`;
    let params = { offset: this.pageOffset };

		if (search != "") {
			params.search = search;
		}

		if (folder_id != "") {
			params.folder_id = folder_id;
    }

		return this.setList(url, sortObj ? Object.assign(params, sortObj) : params)
		.then(response => {
			this.documents = response.data.data.sort(this.sortDocuments);
			this.totalItems = response.data.count;
			this.parentFolderId = response.data.parent_id;
		});
	}

  resetDocuments = () => {
    this.documents = null;
    this.totalItems = 0;
  }

	sortDocuments = (a, b) => {
		if (a.is_folder == "1") return -1;		
		if (b.is_folder == "0") return 1;
		return 0;
	}

	getCurrentDocument = () => {
		return toJS(this.currentDocument);
	}

	setCurrentDocument = (document) => {
		this.currentDocument = document;
	}

	resetCurrentDocument = () => {
		this.currentDocument = null;
  }
  
  getDocumentsUrl = () => {
    return `${this.apiUrl}/${this.docType}`;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl())
    .then(response => {
      this.pageOffset = 0;
      this.setDocuments("", response.data.folder_id);
    });
  }
	
  editDocument = (values) => {
    return this.editItem(values, this.getDocumentsUrl())
    .then(response => {
      this.pageOffset = 0;
      this.setDocuments("", response.data.folder_id);
    });
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments("", this.currentFolderId);
    });
  }

  // Folders
  getFolderUrl = () => {
    return `${this.apiUrl}/folders/${this.docType}`;
  }

	setFolder = (folderId) => {
		this.currentFolderId = folderId;
	}

  goToFolder = (folder) => {
    this.pageOffset = 0;
    return this.setDocuments("", folder.id)
    .then(response => {
      this.setFolder(folder.id);
      return response;
    })
  }

	addFolder = (values) => {
		return this.addItem(values, this.getFolderUrl())
    .then(r => this.setDocuments("", values.folder_id)); 
	}

	editFolder = (values) => {
		return this.editItem(values, this.getFolderUrl())
    .then(r => this.setDocuments("", values.folder_id)); 
  }
  
  // Apartment categories
  setCategoryValue = (value, index) => {
    if (this.categoriesValues.indexOf(value) == -1) {
      this.categoriesValues[index] = value;
    } else {
      NotificationManager.error("This category was chozen before.");
    }
  }

  addCategory = () => {
    let count = this.categoryCount;
    if (this.categoriesValues[count - 1]) {
      this.categoryCount = ++count;
    } else {
      NotificationManager.error("Please select category.");
    }
  }

  setCategoryData = (count, ids, values) => {
    this.categoryCount = count;
    this.chozenCategoryesIds = ids;
    this.categoriesValues = values;
  }

  // Other
  setBuildingDocumentCounters = (clientId = null, projectId = null) => {
    const clientUrl = clientId ? `clients/${clientId}` : "";
    const projectUrl = projectId ? `/projects/${projectId}` : "";

    return this.setList(`${this.url}${clientUrl}${projectUrl}/counters/documents/building`)
    .then(response => {
      this.buildingCounters = response.data;
    })
    .catch(e => console.error(e));
  }
}

const store = window.DocumentStore = new DocumentStore;

export default store;

autorun(() => {
    // console.log(store);
});