import { autorun, observable, toJS } from "mobx";
import Store from "./Store";
import data from '../data/Services.js';

class ServiceStore extends Store {
  @observable pages = {
    services: {
      data: null,
      total: 0,
      count_featured: 0,
      offset: 0
    }
  };
  @observable currentService = null;

  constructor(props) {
    super(props);
    this.industries = data.industries;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/suppliers`);
  }

  setPage = (page) => {
    this.pages.services.offset = page * this.itemsPerPage;
    return this.setServices();
  }

  setServices = (sortObj = null) => {
    let params = {
      offset: this.pages.services.offset
    };

    return this.setList(this.apiUrl, sortObj ? Object.assign(params, sortObj) : params)
    .then(response => {
      this.pages.services.data = response.data.data;
      this.pages.services.total = response.data.count;
      this.pages.services.count_featured = response.data.count_featured;
    })
  }

  setService = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentService = response.data;
      return response;
    });
  }

  setCurrentService = (service) => {
    this.currentService = service;
  }

  resetCurrentService = () => {
    this.currentService = null;
  }

  addService = (values) => {
    return this.addItem(values)
    .then(r => this.setServices());
  }

  editService = (values) => {
    return this.editItem(values)
    .then(response => {
      this.setCurrentService(response.data);
      return this.setServices();
    });
  }

  deleteService = () => {
    return this.deleteItem(this.currentService.id)
    .then(r => {
      this.currentService = null;
      return this.setServices();
    })
  }

  // Insights
  setInsightCategories = (url = "", type = "", params = {}) => {
    url = `${url}/counters/${type}/industries`;

    return this.setList(url, params)
    .then(response => {                    
      this.insights.categoryCounters = response.data;
      return response;
    });
  }
}

const store = window.ServiceStore = new ServiceStore;

export default store;

autorun(() => {
    // console.log(store);
});