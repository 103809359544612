import { autorun } from "mobx";
import data from "../data/Access.js";

class AccessStore {
  constructor() {
    this.accessData = data;
  }

  checkAccess = (feature, action, user) => {
    if (!feature || !action || !user || !user.role) {
      return false;
    }

    return this.accessData[feature][action][user.role];
  }
}

const store = window.AccessStore = new AccessStore;

export default store;

autorun(() => {
    // console.log(store);
});