import { autorun, observable, toJS } from "mobx";
import { translate } from "../helpers/Helper";
import data from '../data/News.js';
import userData from "../data/Users.js";
import Store from "./Store.js";
import axios from "axios";

class NewsStore extends Store {
  @observable newsList = null;
  @observable currentNews = null;
  @observable deletePostList = [];
  @observable statusCounters = null;
  @observable pages = {
    all: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    scheduled: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    featured: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    active: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    archived: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    reports: {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        date_start: null,
        date_end: null
      }
    }
  };

  constructor() {
    super();
    this.t = translate;
    this.types = data.types;
    this.statusNames = data.statusNames;
    this.categories = data.categories;
    this.internal_link = data.internal_link;     
    this.commercial_links = data.commercial_links;     
    this.userTypes = userData.roles;        
    this.rolesCommercial = userData.rolesCommercial;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/newsfeed`);
  }

  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    this.setNews({ status: type });
  }

  setNews = (params = {}) => {
    let type = params.status ? params.status : "all";
    params.offset = this.pages[type].offset;

    if (params.status == "featured") {
      type = "featured";
      params.is_featured = "1";
    }

    return this.setList(null, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
      this.pages[type].count = response.data.count;
    });
  }

  setCurrentNews = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentNews = response.data;
    });
  }

  resetCurrentNews = () => {
    this.currentNews = null;
  }

  getNews = (type = "all") => {
    return toJS(this.pages[type].data);
  }

  addPost = (values) => {
    return this.addItem(values);
  }

  editPost = (values) => {
    return this.editItem(values);
  }

  archivePost = (values) => {
    return this.addItem(values, `${this.apiUrl}/${this.currentNews.id}/archive`);
  }

  deletePost = (id) => {
    return this.deleteItem(id)
    .then(response => {
      this.currentNews = null;
      return response;
    });
  }

  deletePosts = (list) => {
    let promises = [];

    list.forEach(item => {
      const id = item.replace("item-", "");
      promises.push(this.deletePost(id));
    });

    return axios.all(promises);
  }

  // Reporting
  setReportsPage = (page, params = {}) => {
    const offset = page * (params.limit ? params.limit : this.itemsPerPage);
    this.pages.reports.offset = offset;
    params = {...this.pages.reports.filterValues};

    return this.setReports(params);
  }

  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count;       
    });
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        status: "all",
        category: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    if (values.limit) {
      delete values.limit;
    }
    
    this.pages.reports.filterValues = values;
  }

  // Other
  addToDeleteList = (list) => {
    this.deletePostList = list;
  }

  setStatusCounters = () => {
    return this.setList(`${this.apiUrl.replace("newsfeed", "counters/newsfeed")}`)
    .then(response => {
      const data = response.data;
      this.statusCounters = response.data;
      Object.keys(data).forEach(key => {
        if (key.indexOf("total") != -1) {
          this.pages.all.total = data[key];
        } else {
          this.pages[key.replace("count_", "")].total = data[key];
        }
      });
    });
  }

  exportNewsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/newsfeed", "/export/pdf/newsfeed")
    )
    .finally(this.hideLoader);
  }
}

const store = window.NewsStore = new NewsStore;

export default store;

autorun(() => {
    // console.log(store);
});