import { autorun, observable, toJS } from "mobx";
import data from "../data/Keys.js";
import Store from "./Store.js";

class SafeTrustStore extends Store {
  @observable pages = {
    staff: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    identities: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
    credentials: {
      current: null,
      statusCounters: null,
      all: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      scheduled: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      active: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      archived: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      expired: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      canceled: {
        data: null,
        offset: 0,
        total: 0,
        count: 0
      },
      reports: {
        data: null,
        offset: 0,
        count: 0,
        total: 0,
        filters: {}
      }
    }    
  }

  constructor() {
    super();    
    this.safeTrustStaffRoles = data.safeTrustStaffRoles;
    this.credentialStatuses = {
      scheduled: "Scheduled",
      active: "Active",
      expired: "Expired",
      canceled: "Canceled"
    };
    this.credentialStatusColors = {
      scheduled: "primary",
      active: "success",
      expired: "default",
      canceled: "danger"
    };
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/integrations/safetrust`);
  }

  setPage = (callback, page, type, subtype = "") => {
    let data = this.pages[type];
    if (subtype != "") {
      data = data[subtype];
    }

    data.offset = page * this.itemsPerPage;
    this[callback]();
  }

  // Identities
  getIdentitiesUrl = () => {
    return `${this.apiUrl}/identities`;
  }

  setIdentities = (params = {}) => {
    params.offset = this.pages.identities.offset;

    return this.setList(this.getIdentitiesUrl(), params)
    .then(response => {
      this.pages.identities.data = response.data.data;
      this.pages.identities.total = response.data.count;
      return response;
    });
  }

  // Credentials
  getCredentialsUrl = () => {
    return `${this.apiUrl}/credentials`;
  }

  getCurrentCredentials = () => {
    return toJS(this.pages.credentials.current);
  }

  setCredentials = (params = {}, isReports = false, isCountersOff = false) => {
    let type = params.status ? params.status : "all";
    params.offset = this.pages.credentials[type].offset;

    if (isReports) {
      type = "reports";
    }

    return this.setList(this.getCredentialsUrl(), params)
    .then(response => {
      this.pages.credentials[type].data = response.data.data;
      this.pages.credentials[type].total = response.data.count;
      this.pages.credentials[type].count = response.data.count;
      return response;
    })
    .then(response => {
      if (!isCountersOff) {
        this.setCredentialStatusCounters();
      }
      return response;
    });
  }

  setCredentialStatusCounters = () => {
    return this.setList(`${this.apiUrl}/counters/credentials`)
    .then(response => {
      const data = response.data;
      Object.keys(data).forEach(key => {
        if (key.indexOf("total") != -1) {
          this.pages.credentials.all.total = data[key];
        } else {
          this.pages.credentials[key.replace("count_", "")].total = data[key];
        }
      });

      this.pages.credentials.statusCounters = response.data;
    });
  }

  setCredential = (id) => {
    return this.setItem(id, this.getCredentialsUrl())
    .then(response => {
      this.pages.credentials.current = response.data;
      return response;
    });
  }

  setCurrentCredential = (item) => {
    this.pages.credentials.current = item;
  }
 
  resetCurrentCredential = () => {
    this.pages.credentials.current = null;
  }
 
  addCredential = (values) => {
    return this.addItem(values, this.getCredentialsUrl());
  }
 
  editCredential = (values) => {
    return this.editItem(values, this.getCredentialsUrl());
  }

  cancelCredentials = () => {
    const id = this.pages.credentials.current.id;
    return this.addItem({}, `${this.getCredentialsUrl()}/${id}/cancel`);
  }
 
  deleteCredential = () => {
    const id = this.pages.credentials.current.id;
    
    return this.deleteItem(id, this.getCredentialsUrl())
    .then(r => {
      this.pages.credentials.current = null;
    });
  }

  // Staff
  getStaffUrl = () => {
    return `${this.apiUrl}/staff`;
  }

  setStaff = (params = {}) => {
    params.offset = this.pages.staff.offset;

    return this.setList(this.getStaffUrl(), params)
    .then(response => {
      this.pages.staff.data = response.data.data;
      this.pages.staff.total = response.data.count;
    });
  }

  setCurrentStaff = (item) => {
    this.pages.staff.current = item;
  }

  resetCurrentStaff = () => {
    this.pages.staff.current = null;
  }

  addStaff = (values) => {
    return this.addItem(values, this.getStaffUrl())
    .then(r => this.setStaff());
  }

  editStaff = (values) => {
    return this.editItem(values, this.getStaffUrl())
    .then(r => this.setStaff());
  }

  deleteStaff = () => {
    return this.deleteItem(this.pages.staff.current.id, this.getStaffUrl())
    .then(r => this.setStaff());
  }

  // Other
  exportSafeTrustPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/integrations/safetrust", "/export/pdf/safetrust")
    )
    .finally(this.hideLoader);
  }
}

const store = window.SafeTrustStore = new SafeTrustStore;

export default store;

autorun(() => {
    // console.log(store);
});