import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  types: {
    "repair_maintenance": t("Repairs"),
    "maintenance": t("Maintenance"),
    "cleaning": t("Cleaning"),
    "defects": t("Defects"),
    "ohs": t("O.H.S."),
    "project": t("Project"),
    "other": t("Others")
  },
  statuses: {
    "quote_request": t("Quote Request"),
    "issued": t("To be Issued"),
    "issued_done": t("Issued"),
    "confirmed": t("Confirmed"),
    "completed": t("Completed"),
    "canceled": t("Canceled")
  },
  priorities: {
    "low": t("Low"),
    "medium": t("Medium"),
    "high": t("High")
  },
  hazardTypes: {
    "minor_no_injury": t("Minor – No Injury"),
    "minor_injury": t("Minor – Injury"),
    "moderate_incident": t("Moderate  Incident"),
    "major_incident": t("Major Incident"),
    "near_miss": t("Near Miss"),
    "serious": t("Serious"),
    "fatality": t("Fatality")
  },
  incidentTypes: {
    "minor_no_injury": t("Minor – No Injury"),
    "minor_injury": t("Minor – Injury"),
    "moderate_incident": t("Moderate  Incident"),
    "major_incident": t("Major Incident"),
    "near_miss": t("Near Miss"),
    "serious": t("Serious"),
    "fatality": t("Fatality"),
    "breach": t("Breach")
  },
  incidentSubTypes: {
    "biological": t("Biological"),
    "environmental": t("Environmental"),
    "injury": t("Injury"),
    "ergonomic": t("Ergonomic"),
    "unsafe_practice": t("Unsafe Practice"),
    "psychological": t("Psychological"),
    "safety": t("Safety"),
    "parking": t("Parking"),
    "noise": t("Noise"),
    "oc_rules": t("OC rules"),
    "behaviour": t("Behaviour"),
    "waste_disposal": t("Waste disposal"),
    "common_area": t("Common Area"),
    "general_etiquette": t("General Etiquette"),
    "private_lot_defect": t("Private Lot Defect"),
    "common_area_defect": t("Common Area Defect"),
    "criminal_activity": t("Criminal Activity"),
    "security_related": t("Security Related"),
    "resident_related": t("Resident Related")
  },
  incidentClassification: {
    "minimal": t("Minimal"),
    "medium": t("Medium"),
    "hight": t("High"),
    "catastrophic": t("Catastrophic"),
  },
  reportType: {
    incident: t("Incident"),
    equipment: t("Equipment failure")
  },
  areas: {
    "common": t("Common area"),
    "non_common": t("Non-common area")
  }
}

export default data;