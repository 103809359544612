import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  retailIndustries: {
    "banks_services": t("Banks & Services"),
    "cafes_restaurants": t("Cafes & Restaurants"),
    "cosmetics_perfume": t("Cosmetics & Perfume"),
    "entertainment": t("Entertainment"),
    "electronics": t("Electronics"),
    "family_kids": t("Family & Kids"),
    "fashion_accessories": t("Fashion & Accessories"),
    "food": t("Food"),
    "gifts_flowers": t("Gifts & Flowers"),
    "health_beauty": t("Health & Beauty"),
    "homeware": t("Homeware"),
    "newsagent": t("Newsagent"),
    "music_books": t("Music & Books"),
    "sports_leisure": t("Sports & Leisure"),
    "toys_hobbies": t("Toys & Hobbies"),
    "travel": t("Travel"),
    "variety_stores": t("Variety Stores")
  }
};

export default data;