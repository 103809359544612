import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  statuses: {
    "new": t("New"),
    "open": t("Open"),
    "in_progress": t("In Progress"),
    "on_hold": t("On-hold"),
    "resolved": t("Resolved"),
    "closed": t("Closed")
  },
  tutorialCategories: {
    "communication_management": t("Communication Management"),
    "member_management": t("Member Management"),
    "place_management": t("Place Management"),
    "place_activation": t("Place Activation"),
    "project_settings": t("Project Settings")
  },
  faqCategories: {
    "dashboard": t("Dashboard"),
    "features": t("Features"),
    "mobile_app": t("Mobile App"),
    "platform": t("Platform"),
    "troubleshooting": t("Troubleshooting"),
  },
  issueTypes: {
    "bug": t("Bug"),
    "improvement": t("Improvement"),
    "other": t("Other")
  },
  interfaces: {
    "dashboard": t("Dashboard"),
    "android": t("Mobile - Android"),
    "ios": t("Mobile - iOS"),
    "other": t("Other"),
  },
  priorities: {
    "critical": t("Critical"),
    "high": t("High"),
    "medium": t("Medium"),
    "low": t("Low")
  },
  features: {
    "addresses": t("Addresses"),
    "notifications": t("Alerts / Notifications"),
    "apartments": t("Apartments"),
    "assets": t("Assets"),
    "bookings_calendar": t("Bookings Calendar"),
    "building_keys": t("Building Keys"),
    "building_reports": t("Building Reports"),
    "buy_sell": t("Buy & Sell"),
    "chatbot": t("Chatbot"),
    "directories": t("Companies Directory"),
    "connections": t("Connections"),
    "contractors": t("Contractors"),
    "departments": t("Departments"),
    "documents_images": t("Documents & Images"),
    "keys_electronic": t("Electronic FOBs"),
    "emails": t("Emails"),
    "event_calendar": t("Event Calendar"),
    "experiences": t("Experiences"),
    "faq": t("FAQs"),
    "find_services": t("Find a Service (Micro Services)"),
    "bookings": t("General Bookings"),
    "social_clubs": t("Groups & Clubs"),
    "hid": t("HID"),
    "help_desk": t("Help Desk"),
    "contacts": t("Important Contacts"),
    "incidents": t("Incidents / Cases"),
    "inspections": t("Inspections"),
    "kone": t("KONE"),
    "keys": t("Keys / FOBs orders"),
    "lend_borrow": t("Lend and Borrow"),
    "local_area": t("Local Area"),
    "lockers": t("Lockers"),
    "resident_documents": t("Member Documents"),
    "members_keys": t("Member Keys"),
    "member_management": t("Member Management"),
    "members_directory": t("Members Directory"),
    "newsfeed": t("Newsfeed"),
    "noticeboard": t("Noticeboard"),
    "online_forms": t("Online Forms"),
    "other": t("Other"),
    "deliveries": t("Parcels & Deliveries"),
    "prefered_suppliers": t("Preferred Services"),
    "project_calendar": t("Project Calendar"),
    "real_estate": t("Real Estate"),
    "bookings_reserved": t("Reserved Bookings"),
    "retails": t("Retail Directory"),
    "safetrust": t("SafeTrust E-FOBs"),
    "schedule_maintainance": t("Schedule Maintenance"),
    "shift_log": t("Shift Log"),
    "special_offers": t("Special Offers"),
    "surveys_polls": t("Surveys / Polls"),
    "work_orders": t("Work Orders")
  },
  roles: {
    "tenant": t("Tenant"),
    "owner": t("Owner"),
    "landlord": t("Landlord"),
    "property_manager": t("Property Manager")
  }, 
  roleNames: {
    "tenant": t("Tenant"),
    "owner": t("Owner"),
    "landlord": t("Landlord"),
    "property_manager": t("Property Manager"),
    "company_rep": t("Company Rep"),
    "company_staff": t("Company Staff"),
    "service_provider": t("Service Provider"),
    "admin": t("Admin"),
    "client": t("Client"),
    "manager": t("Manager"),
    "project_manager": t("Project Manager"),
    "staff": t("Staff")
  },
  newsCategories: {
    "dashboard": t("Dashboard"),
    "features": t("Features"),
    "mobile_app": t("Mobile App"),
    "platform": t("Platform"),
    "publications": t("Publications"),
    "resources": t("Resources"),
    "technical_support": t("Technical Support"),
    "other": t("Other")
  },
  comingSoonCategories: {
    "feature_improvement": t("Feature Improvement"),
    "feature_update": t("Feature Update"),
    "new_feature": t("New Feature"),
    "integrations": t("Integrations"),
  },
  comingSoonInterfaces: {
    "dashboard": t("Dashboard"),
    "mobile_application": t("Mobile App")
  }
};

export default data;