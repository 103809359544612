import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';
import { translate } from "../helpers/Helper";
import Store from "./Store";

class FacilityStore extends Store {
  @observable pages = {
    services: {
      data: null,
      total: 0,
      offset: 0
    },
    documents: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    }
  };
  @observable facilities = null;
  @observable services = null;
  @observable pageOffset = 0;
  @observable totalItems = 0;
  @observable currentFacility = null;
  @observable currentService = null;
  @observable repeatFields = {
    count: 1,
    values: [ "" ]
  }

  // Operational Hours
  @observable operationalHours = {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null
  };
  @observable holidayCounts = 1;
  @observable holidays = [ {
    start_date: null,
    end_date: null,
    details: ""
  } ];

  constructor() {
    super();
    this.t = translate;
    this.dayNames = {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday"
    };
    this.dayShortNames = {
      monday: "Mon",
      tuesday: "Tue",
      wednesday: "Wed",
      thursday: "Thu",
      friday: "Fri",
      saturday: "Sat",
      sunday: "Sun"
    };
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/facilities`);
    this.setOperationalHours();
  }

  setPage = (page, type = "facility") => {
    if (type == "facility") {
      this.pageOffset = page * this.itemsPerPage;
      this.setFacilities();
    } else if (type == "services") {
      this.pages.services.offset = page * this.itemsPerPage;
      this.setServices();
    } 
  }

  setFacilities = (params = {}) => {
    params.offset = this.pageOffset;

    return this.setList(null, params)
    .then(response => {
      this.facilities = response.data.data;
      this.totalItems = response.data.count;
      return response;
    });
  }

  getFacilities = () => {
    return toJS(this.facilities);
  }

  setFacilityById = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentFacility = response.data;
      return response;
    });
  }

  setFacility = (facility) => {
   this.currentFacility = facility;
  }

  selectFacility = (id) => {
    return this.setItem(id);
  }

  getFacility = () => {
    return toJS(this.currentFacility);
  }

  resetFacility = () => {
    this.currentFacility = null;
  }

  addFacility = (values) => {
    return this.addItem(values)
    .then(response => {
      this.setFacilities();
      return response;
    });
  }

  editFacility = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentFacility = response.data;
      this.setFacilities();
      return response;
    });
  }

  deleteFacility = () => {
    return this.deleteItem(this.currentFacility.id)
    .then(r => {
      this.currentFacility = null;
      this.setFacilities();
    });
  }

  getFacilityById = (id) => {
    let result = null;
    this.facilities.forEach(facility => {
      if (facility.id + "" == id + "") {
        result = facility;
      }
    });

    return result;    
  }

  // Operational hours
  setOperationalHours = () => {
    Object.keys(this.operationalHours).forEach(key => {
      this.operationalHours[key] = {
        is_working: "0",
        start_time: null,
        end_time: null,
        time_slots: []
      };
    });
  }

  setOperationalHoursByData = (values) => {
    this.operationalHours = values;
  }

  resetOperationalHours = () => {
    this.operationalHours = {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null
    };
    this.setOperationalHours();
  }

  setOHValue = (day, field, value) => {
    this.operationalHours[day][field] = value;
  }

  setOHTimeSlotValue = (day, field, index, value) => {
    const items = this.operationalHours[day].time_slots;
    let fields = {
      duration: 30
    };
    fields[field] = value;

    if (items[index] == null) {
      items[index] = fields;
    } else {
      items[index][field] = value;      
    }
  }

  setOHTimeSlotRepeatItem = (day, index) => {
    if (!this.operationalHours[day].time_slots) {
      this.operationalHours[day].time_slots = [];
    }
    
    const timeslots = this.operationalHours[day].time_slots;
    if (timeslots[index - 1] == null || (timeslots[index - 1] && timeslots[index - 1].start_time != null)) {
      timeslots[index] = {
        start_time: null,
        duration: 30
      };
    } else {
      NotificationManager.error(this.t("Please fill book off."));
    }
  }

  duplicateOHValue = (duplicateDay, days = []) => {
    return new Promise((resolve, reject) => {
      const currentDay = toJS(this.operationalHours[duplicateDay]);
      days.forEach(day => {
        if (day && day != "") {
          this.operationalHours[day] = currentDay;
        }
      });

      resolve("");
    });
  }

  deleteOHTimeSlot = (day, index) => {
    this.operationalHours[day].time_slots[index].is_deleted = true;
  }

  // Holidays
  setHolidaysData = (count, values) => {
    this.holidayCounts = count;
    this.holidays = values;
  }

  setHolidayValue = (index, field, value) => {
    this.holidays[index][field] = value;
  }

  addHoliday = () => {
    let count = this.holidays.length;
    if (!this.holidays[count - 1] || (this.holidays[count - 1].start_date && this.holidays[count - 1].end_date)) {
      this.holidays.push({
        start_date: null,
        end_date: null,
        details: ""
      });
    } else {
      NotificationManager.error(this.t("Please fill holiday."));
    }
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl}/${this.currentFacility.id}/documents`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.pages.documents.current = document;
  }

  resetCurrentDocument = () => {
    this.pages.documents.current = null;
  }

	addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl())
    .then(r => this.setDocuments());
	}

  deleteDocument = () => {
    return this.deleteItem(this.pages.documents.current.id, this.getDocumentsUrl())
    .then(r => {
      this.pages.documents.current = null;
      return this.setDocuments();
    });
  }

  // Services
  getServiceUrl = () => {
    return `${this.apiUrl}/${this.currentFacility.id}/services`;
  }

  setServices = (params = {}) => {
    params.offset = this.pages.services.offset;

    return this.setList(this.getServiceUrl(), params)
    .then(response => {
      this.pages.services.data = response.data.data;
      this.pages.services.total = response.data.count;
    });
  }

  getServices = () => {
    return toJS(this.services);
  }

  resetServices = () => {
    this.services = null;
  }

  getCurrentService = () => {
    return toJS(this.currentService);
  }

  setCurrentService = (service) => {
    this.currentService = service;
  }

  resetCurrentService = () => {
    this.currentService = null;
  }

  addService = (values) => {
    return this.addItem(values, this.getServiceUrl())
    .then(r => this.setServices());
  }
    
  editService = (values) => {
    return this.editItem(values, this.getServiceUrl())
    .then(r => this.setServices());
  }

  deleteService = () => {
    this.deleteItem(this.currentService.id, this.getServiceUrl())
    .then(r => {
      this.resetCurrentService();
      return this.setServices();
    });
  }
    
  // Other
  getBookableDays = (facility) => {
    const operatingHours = facility.operating_hours ? JSON.parse(facility.operating_hours): null;
    let arr = [];
    let days = {
      "monday": this.t("Mon"), 
      "tuesday": this.t("Tue"), 
      "wednesday": this.t("Wed"), 
      "thursday": this.t("Thu"), 
      "friday": this.t("Fri"), 
      "saturday": this.t("Sat"), 
      "sunday": this.t("Sun")
    };

    if (operatingHours) {
      Object.keys(days).forEach(day => {
        if (operatingHours[day].is_working == "1") {
          arr.push(days[day]);
        }
      });
    }

    return arr.length > 0 ? arr.join(", "): this.t("N/A");
  }

  setFacilityData = (count, ids, values) => {
    this.repeatFields.count = count;
    this.repeatFields.values = values;
  }

  setFacilityValue = (value, index) => {
    if (this.repeatFields.values.indexOf(value + "") == -1) {
      this.repeatFields.values[index] = value + "";
    } else {
      NotificationManager.error(this.t("This facility was choosen before."));
    }
  }

  addSelectFacility = () => {
    let count = this.repeatFields.count;
    if (this.repeatFields.values[count - 1] != "") {
      this.repeatFields.count = ++count;
      this.repeatFields.values.push("");
    } else {
      NotificationManager.error(this.t("Please select facility."));
    }
  }
}

const store = window.FacilityStore = new FacilityStore;

export default store;

autorun(() => {
    // console.log(store);
});