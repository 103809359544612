import { translate } from "../helpers/Helper";

const t = translate;
const data = {
	"types": {
		"sms": t("SMS"),
		"alert": t("Alert")
	},
	"roles": {
		"landlord": t("Landlord"), 
		"owner": t("Owner"), 
		"tenant": t("Tenant"), 
		"property_manager": t("Property Managers")
	}
}

export default data;