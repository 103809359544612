import { autorun, observable } from "mobx";
import data from '../data/SidebarMenu.js';
import dataRouting from '../data/routing/Routing.json';

class NavigationStore {
  @observable menuData = data;

  constructor() {
    this.routing = dataRouting.routing;
    this.comingSoon = data.comingSoon;
    this.defaultProjectId = "0";
  }

  goToPage = (destination = null, match = null) => {
    if (match != null && match.params.projectId) {
      const projectId = match.params.projectId,
      clientId = match.params.clientId;

      window.appHistory.push(`/client/${clientId}/project/${projectId}/${destination}`);
    } else {
      window.appHistory.push(`/${destination}`);
    }

    this.setActiveLink(`/${destination}`);
  }

  setActiveLink = (link = "") => {
    let result = JSON.parse(JSON.stringify(data));

    if (this.isAdminPage()) {
      result.adminMenu.forEach(item => this.checkMenuItem(item, link));
    } else {
      result.menu.forEach(item => this.checkMenuItem(item, link));
    }

    this.menuData = result;
  }

  checkMenuItem = (menuItem, link = "") => {
    let href = menuItem.href ? menuItem.href : "";
    if (link == href)   {
      menuItem.active = true;
    }

    if (menuItem.menu) {
      let isExpand = false;
      menuItem.menu.forEach((item) => {
        href = item.href ? item.href : "";
        const condition = (link == href || link.indexOf(href) >= 0) && href != "";

        if (condition) {
          isExpand = true;
          item.active = true;
        }
      });

      menuItem.isExpanded = isExpand;
    }
  }

  expandGroup = (title = '') => {
    this.menuData.menu.forEach((item) => {
      if (item.title == title && item.menu) {
        item.isExpanded = !item.isExpanded;
      }
    });
  }

  isAdminPage = () => {
    const path = window.location.pathname;
    if (
      (path.indexOf("staff") != -1 && path.indexOf("staff-") == -1) || 
      path.indexOf("clients") != -1 || 
      path.indexOf("business") != -1 || 
      path.indexOf("global") != -1 || 
      path.indexOf("neighbourhood-listing") != -1 || 
      path.indexOf("app-report") != -1 || 
      path.indexOf("/profile/") == 0 ||
      path.indexOf("/help-centre") == 0 ||
      path.indexOf("/analytics") == 0 ||
      path.indexOf("/activities") == 0 ||
      path == "/"
    ) {
      return true;
    }

    return false;
  }

  getUrl = (match = null) => {
    if (match != null && match.params.projectId) {
      const projectId = match.params.projectId,
      clientId = match.params.clientId;

      return `/client/${clientId}/project/${projectId}`;
    }

    return "";
  }

  changeHistory = (destination = null, match = null) => {
    if (match != null && match.params.projectId) {
      const projectId = match.params.projectId,
      clientId = match.params.clientId;

      window.history.pushState(null, null, `/client/${clientId}/project/${projectId}/${destination}`);
    } else {
      window.history.pushState(null, null, `/${destination}`);
    }

    this.setActiveLink(`/${destination}`);
  }

  redirectUserByRole = (user) => {
    if (!user || !user.role) {
      this.goToPage("login");
    } else {
      if (user.role == 'admin') {
        this.goToPage('clients');
      } 
      else if ((user.role == 'project_manager' || user.role == 'staff') && user.project_id) { 
        this.goToPage(`client/${user.client_id}/project/${user.project_id}/`);
      } 
      else if (user.role == "business") {
        this.goToPage(`business/${user.business_id}`);
      }
      else {
        this.goToPage(`clients/${user.client_id}`);
      }
    }
  }

  checkAccess = (user, accessObj = {}, match = null) => {
    if (accessObj.login) {
      if (!user || !user.role) {
        this.goToPage('login');
        return null;
      }

      if (user.role == 'admin') {
        this.goToPage('clients');
      } 
      else if (user.role == 'project_manager' || user.role == "staff") { 
        this.goToPage(`client/${user.client_id}/project/${user.project_id}/`);
      } 
      else if (user.role == "business") {
        this.goToPage(`business/${user.business_id}`);
      }
      else {
        this.goToPage(`clients/${user.client_id}`);
      }
    }

    if (accessObj.admin) {
      this.checkAdminAccess(user);
    } else if (accessObj.staff) {
      this.checkStaffAccess(user);
    } else if (accessObj.pm) {
      this.checkPMAccess(user, match);
    }
  }

  checkAdminAccess = (user) => {
    if (user.role != "admin") {
      this.goToPage(`clients/${user.client_id}`);
    } 
  }

  checkStaffAccess = (user) => {
    if (user.role == 'project_manager') { 
      this.goToPage(`client/${user.client_id}/project/${user.project_id}/`);
    } 
  }

  checkPMAccess = (user, match) => {
    if (user && user.role == "project_manager" && match.params &&  match.params.projectId != user.project_id) {
      this.goToPage(`client/${user.client_id}/project/${user.project_id}/`);
    }
  }

  downloadFile = (url) => {
    window.open(url, '_blank');
  }

  downloadFileByUrl = (url) => {
    let a = document.createElement('A');
    a.href = url;
    a.download = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

const store = window.NavigationStore = new NavigationStore;

export default store;

autorun(() => {
    // console.log(store);
});