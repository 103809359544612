import { autorun, observable } from "mobx";
import { translate } from "../helpers/Helper";
import Helper from "../helpers/Helper";
import moment from "moment";

class LocalizationStore {
    @observable language = 'en';

    constructor() {
        this.t = translate;
        this.getCookie = Helper.getCookie;
        this.languages = [
            {
                "title": this.t("English"),
                "locale": "en"
            },
            {
                "title": this.t("Chinese"),
                "locale": "zh"
            },
            {
                "title": "Spanish (test)",
                "locale": "es"
            }
        ];

        this.momentLocale = {
            "en": "en",
            "zh": "zh-cn",
            "es": "es"
        };

        moment.locale(this.language);
    }

    getTranslations = (files) => {
        let result = {};
        const translations = files.keys().map(files);
        const locales = files.keys().map(fileName => {
            return fileName.replace('./', '').replace('.po', '');
        });

        locales.forEach((locale, index) => {
            result[locale] = translations[index];
        });

        return result;
    }

    getLanguage = () => {
        return this.language;
    }

    setLanguage = (language) => {
        this.language = language;
        moment.locale(this.momentLocale[language]);
    }

    setViewLanguage = () => {
        const userLang = this.getCookie("language"),
            projectLang = this.getCookie("projectLang");

        if (projectLang && userLang == "") {
            this.setLanguage(projectLang);
        } else {
            this.setLanguage(userLang);
        }
    }
}

const store = window.LocalizationStore = new LocalizationStore;

export default store;

autorun(() => {
    // console.log(store);
});