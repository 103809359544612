import { autorun, observable, toJS } from "mobx";
import { translate } from "../helpers/Helper";
import data from '../data/Deliveries.js';
import Store from "./Store";

class DeliveryStore extends Store {
  @observable deliveries = [];
  @observable currentParcel = null;
  @observable nextParcel = null;
  @observable prevParcel = null;
  @observable couriers = [];
  @observable groundFloor = null;
  @observable statusCounters = {};
  @observable pages = {
    "all": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "incoming": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "outgoing": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "to_be_collected": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "collected": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "disposal_alert": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "return_to_sender": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "disposed": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "done": {
      data: null,
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      },
      sortingValues: {
        field: "",
        order: "ASC"
      }
    },
    "reports": {
      data: null,
      total: 0,
      offset: 0,
      counters: null,
      filters: {
        description: "",
        status: "",
        date_start: null,
        date_end: null
      }
    }
  };

  constructor () {
    super();
    this.t = translate;    
    this.statuses = data.statuses;
    this.statusNames = data.statusNames;
    this.types = data.types;
    this.locations = data.locations;
    this.notifications = data.notifications;
    this.colours = data.colours;
    this.directions = data.directions;
    this.itemDescriptions = data.itemDescriptions;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/deliveries`);
  }

  setPage = (page, type) => {
    this.pages[type].offset = page * this.itemsPerPage;
    this.setDeliveries(type, {});
  }

  setDeliveries = (type, params = {}) => {
    let status = type != 'all' ? type : "all";

    if (type == "incoming" || type == "outgoing") {
      status = "to_be_collected";
      params.type = type;
    }

    params.status = status;
    params = this.getParams(this.pages[type], params);

    return this.setList(null, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
      this.pages[type].count = response.data.count;
    });
  }

  setParcel = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentParcel = response.data;
      return response;
    });
  }

  addDeliveryItem(values) {
    return this.addItem(values);
  }

  editDeliveryItem(values, status = "all") {
    return this.editItem(values)
    .then(response => {
      this.currentParcel = response.data;
      return response;
    });
  }

  getCurrentParcel = () => {
    return toJS(this.currentParcel);
  }

  setCurrentParcel = (parcel) => {
    this.currentParcel = parcel;
  }

  resetCurentParcel = () => {
    this.currentParcel = null; 
    this.prevParcel = null;
    this.nextParcel = null;
  }

  exportDeliveries = (params = {}) => {
    return this.setList(`${this.apiUrl.replace('/deliveries', '')}/export/deliveries`, params)
    .then(r => r.data);
  }

  openLocker = (id) => {
    this.addItem({}, `${this.apiUrl}/${id}/open`)
    .then(response => {
      this.currentParcel = response.data;
      this.setAllDeliveries();
    });
  }

  // Couriers
  getCouriersUrl = (id) => {
    return `${this.url}clients/${id}/couriers`;
  }

  setCouriers = (clientId) => {
    return this.setList(this.getCouriersUrl(clientId), { limit: 50 })
    .then(response => {
      this.couriers = response.data;
      return response;
    });
  }

  sortDeliveries = (field, order, status) => {
    return this.setDeliveries(status, {
      search: "",
      offset: 0,
      order: order,
      sort: field
    });
  }

  // Ground Floor
  getGroundFloorUrl = () => {
    return `${this.apiUrl.replace('/deliveries', '')}/ground-floor`;
  }

  setGroundFloorData = () => {
    return this.setList(this.getGroundFloorUrl())
    .then(response => {
      this.groundFloor = response.data;
    });
  }

  addGroundFloorData = (values) => {
    return this.addItem(values, this.getGroundFloorUrl())
    .then(response => {
      this.groundFloor = response.data;
    });
  }

  // Reports
  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;
    params.limit = 500;

    return this.setList(this.apiUrl, params)
    .then(response => {                    
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count; 
    });
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        description: "",
        status: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    this.pages.reports.filters = values;
  }

  //Other
  setStatusCounters = () => {
    return this.setList(`${this.apiUrl.replace("/deliveries", "")}/counters/deliveries-statuses`)
    .then(response => {
      this.statusCounters = response.data;
    });
  }

  exportDeliveryPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/deliveries", "/export/pdf/deliveries")
    )
    .finally(this.hideLoader);
  }
}

const store = window.DeliveryStore = new DeliveryStore;

export default store;

autorun(() => {
    // console.log(store);
});