import { autorun, observable, toJS } from "mobx";
import settings from "../data/Settings.json";
import Store from "./Store";

class ClientStore extends Store {
  @observable clients = null;
  @observable currentClient = null;
  @observable countries = null;
  @observable pageOffset = 0;
  @observable totalItems = 0;

  constructor() {
    super();
    this.apiUrl = settings.apiUrl;
  }

  setData = (token) => {
    this.setStoreData(token, `clients`);
  }

  setPage = (page) => {
    this.pageOffset = page * this.itemsPerPage;
    this.getClients();
  }

  filterClientsByCountry = (country) => {
    if (country != "") {
      return this.getClients(country);
    } else {
      return this.getClients();
    }    
  }

  getClients = (country = null) => {
    let params = {
      limit: this.itemsPerPage,
      offset: this.pageOffset
    };

    if (country) {
      params.country = country;
    }

    return this.setList(this.apiUrl, params)
    .then(response => {
      this.clients = response.data.data;
      this.totalItems = response.data.count;
    });
  }

  getCurrentClient = () => {
    return toJS(this.currentClient);
  }

  getCurrentClientId = () => {
    if (this.currentClient != null) {
      return this.currentClient.id;
    }
  }

  setCurrentClientById = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentClient = response.data;
    });
  }

  resetCurrentClient = () => {
    this.currentClient = null;
  }

  addClient = (values) => {
    return this.addItem(values)
    .then(r => this.getClients());
  }

  editClient = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentClient = response.data;
      this.getClients();
    });         
  }

  deleteClient = () => {
    return this.deleteItem(this.currentClient.id)
    .then(r => {
      this.currentClient = null;
      return this.getClients();
    });
  }

  // Countries
  setCountries = () => {
    const params = {
      limit: 50
    };

    return this.setList(this.url + "countries", params)
    .then(response => {
      this.countries = response.data;
    });
  }
}

const store = window.ClientStore = new ClientStore;

export default store;

autorun(() => {
    // console.log(store);
});