import React from 'react';
import { observer, inject } from 'mobx-react';
import loader from 'assets/img/loader.gif';

@inject('PreloaderStore')
@observer
export default class App extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (!this.props.PreloaderStore.isShowPreloader) {
			return <div />;
		}

		return (
			<div className="preloader-backdrop">
				<img src={loader} alt=""/>
			</div>
		);
	}
}