import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';
import Helper from "../helpers/Helper";
import Store from "./Store";

class BuildingStore extends Store {
  @observable pages = {
    buildings: {
      data: null,
      offset: 0,
      total: 0,
      current: null
    },
    documents: {
      data: null,
      offset: 0,
      total: 0
    },
    apartments: {
      data: null,
      offset: 0,
      total: 0,
      current: null
    },
    ownerHistory: {
      data: null,
      offset: 0,
      total: 0
    }
  };
  @observable apartments = null;
  @observable currentApartment = null;
  @observable categoryCount = 1;
  @observable chozenCategoryesIds = [];
  @observable categoriesValues = [];
  @observable currentOwner = null;
  @observable acList = {
    count: 0,
    values: []
  };
  @observable parkings = {
    count: 0,
    values: []
  };
  @observable buildingNames = [];

  constructor() {
    super();
    this.t = Helper.translate;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/buildings`);    
  }

  setPage = (page, type = "apartments", params = {}) => {
    this.pages[type].offset = page * this.itemsPerPage;

    if (type == "apartments") {
      return this.setApartments(params);
    } else if (type == "buildings") {
      return this.setBuildings(params);
    }
  }

  // Buildings
  setBuildings = (params = {}) => {
    params.offset = this.pages.buildings.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.buildings.data = response.data.data;
      this.pages.buildings.total = response.data.count;
      return response;
    });
  }

  setBuilding = (id) => {
    return this.setItem(id)
    .then(response => {
      this.pages.buildings.current = response.data;
      return response;
    });
  }

  getBuildings = () => {
    return toJS(this.pages.buildings.data);
  }

  getBuildingById = (id) => {
    let result = null;
    if (this.pages.buildings.data) {
      this.pages.buildings.data.forEach(building => {
        if (building.id == id) {
          result = building;
        }
      });
    }

    return result;
  }

  setCurrentBuilding = (building) => {
    this.pages.buildings.current = building;
  }

  getCurrentBuilding = () => {
    return toJS(this.pages.buildings.current);
  }

  resetCurrentBuilding = () => {
    this.pages.buildings.current = null;
  }

  addBuiding = (values) => {
    return this.addItem(values)
    .then(r =>  this.setBuildings());
  }

  editBuiding = (values) => {
    return this.editItem(values)
    .then(response => {
      this.pages.buildings.current = response.data;
      this.setBuildings();
    });
  }

  deleteBuilding = () => {
    return this.deleteItem(this.pages.buildings.current.id)
    .then(r => {
      this.pages.buildings.current = null;
      this.setBuildings();
    });
  }

  searchBuildings = (value) => {
    return this.setBuildings({ 
      search: value,
      limit: 100
    });
  }

  // Apartments
  getApartmentUrl = () => {
    return `${this.apiUrl.replace("/buildings", "")}/apartments`;
  }  

  setApartments = (params = {}) => {
    params.offset = this.pages.apartments.offset;
    params.entity_id = this.pages.buildings.current ? this.pages.buildings.current.id : null;

    return this.setList(this.getApartmentUrl(), params)
    .then(response => {
      this.pages.apartments.data = response.data.data;
      this.pages.apartments.total = response.data.count;
      return response;
    });
  }

  setApartmentById = (id) => {
    return this.setItem(id, this.getApartmentUrl())
    .then(response => {
      this.pages.apartments.current = response.data;
      return response;
    });
  }

  getApartments = () => {
    return toJS(this.pages.apartments.data);
  }

  getCurrentApartment = () => {
    return toJS(this.pages.apartments.current);
  }

  setCurrentApartment = (apartment) => {
    this.pages.apartments.current = apartment;
  }

  resetCurrentApartment = () => {
    this.pages.apartments.current = null;
  }

  addApartment = (values) => {
    return this.addItem( values, this.getApartmentUrl())
    .then(r => this.setApartments());
  }

  editApartment = (values) => {
    return this.editItem(values, this.getApartmentUrl())
    .then(response => {
      this.pages.apartments.current = response.data;
      return this.setApartments();
    });
  }

  deleteApartment = () => {
    return this.deleteItem(this.pages.apartments.current.id, this.getApartmentUrl())
    .then(r => {
      this.pages.apartments.current = null;
      return this.setApartments();
    });
  }

  searchApartments = (value, entityId = null) => {
    return this.setList(this.getApartmentUrl(), { 
      search: value,
      entity_id: entityId,
      limit: 100
    })
    .then(response => {
      this.pages.apartments.data = response.data.data;
      return response;
    });
  }

  uploadCSV = (values) => {
    const url = `${this.apiUrl}/${this.pages.buildings.current.id}/upload/apartments`;

    return this.addItem(values, url)
    .then(response => {
      this.setApartments();
      return response;
    });
  }

  // Apartment categories
  setCategoryData = (count, ids, values) => {
    this.categoryCount = count;
    this.chozenCategoryesIds = ids;
    this.categoriesValues = values;
  }

  setCategoryValue = (value, index) => {
    if (this.categoriesValues.indexOf(value) == -1) {
      this.categoriesValues[index] = value;
    } else {
      NotificationManager.error(this.t("This category was choosen before."));
    }
  }

  addCategory = () => {
    let count = this.categoryCount;
    if (this.categoriesValues[count - 1]) {
        this.categoryCount = ++count;
    } else {
        NotificationManager.error(this.t("Please select category."));
    }
  }

  setRepeatedData = (name, count, values) => {
    this[name].count = count;
    this[name].values = values;
  }

  setRepeatedValue = (name, value, index) => {
    if (this[name].values.indexOf(value) == -1) {
      this[name].values[index] = value;
    } else {
      NotificationManager.error(this.t("Please item was choosen before."));
    }
  }

  addRepeatedItem = (name) => {
    let count = this[name].count;
    if (this[name].values[count]) {
      this[name].count = ++count;
    } else {
      NotificationManager.error(this.t("Please select item."));
    }
  }

  deleteRepeatedItem = (name, index) => {
    this[name].values.splice(index, 1);
    this.setRepeatedData(name, --this[name].count, this[name].values);	
  }

  assignCategories = (values) => {
    return this.addItem(values, `${this.apiUrl.replace("/buildings", "")}/bulk-categories-sub-entities`);
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl.replace("/buildings", "")}/documents/apartments/${this.pages.apartments.current.id}`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }

  // Tenant History
  getOwnerUrl = () => {
    return `${this.apiUrl.replace("/buildings", "")}/apartments/${this.pages.apartments.current.id}/owners`;
  }

  setOwners = (params = {}) => {
    params.offset = this.pages.ownerHistory.offset;

    return this.setList(this.getOwnerUrl(), params)
    .then(response => {                    
      this.pages.ownerHistory.data = response.data.data;
      this.pages.ownerHistory.total = response.data.count; 
    });
  }

  setCurrentOwner = (note) => {
    this.currentOwner = note;
  }

  resetCurrentOwner = () => {
    this.currentOwner = null;
  }

  addOwner = (values) => {
    return this.addItem(values, this.getOwnerUrl())
    .then(r => this.setOwners());
  }

  editOwner = (values) => {
    return this.editItem(values, this.getOwnerUrl())
    .then(r => this.setOwners());
  }

  deleteOwner = () => {
    return this.deleteItem(this.currentOwner.id, this.getOwnerUrl())
    .then(r => this.setOwners());
  }

  // Others
  setBuildingNames = (response) => {
    let result = {};
    const buildings = response.data.data;
    buildings.forEach(building => {
      result[`building-${building.id}`] = building.name;
    });
    this.buildingNames = result;
  }
  
  getBuildingNames = (ids) => {
    let result = [];
    ids.forEach(id => {
      if (this.buildingNames[`building-${id}`]) {
        result.push(this.buildingNames[`building-${id}`]);
      }
    });

    return result.join(", ");
  }
}

const store = window.BuildingStore = new BuildingStore;

export default store;

autorun(() => {
    // console.log(store);
});