import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';
import moment from "moment";

import { translate } from "../helpers/Helper";
import data from '../data/Defects.js';
import Store from "./Store";

class DefectsStore extends Store {
  @observable pages = {
    defects: {
      current: null,
      statusCounters: null,

      all: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      open: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      in_dispute: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      rejected: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      approved: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      sent: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      to_inspect: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      resolved: {
        data: null,
        total: 0,
        offset: 0,
        filterValues: {
          search: "",
          category: "",
          location: ""
        },
        sortingValues: {
          field: "",
          order: "ASC"
        }
      },
      reports: {
        data: null,
        offset: 0,
        total: 0,
        filterValues: {}
      }
    },
    categories: {
      current: null,
      data: null,
      searchList: null,
      total: 0,
      offset: 0,
      categoryCount: 1,
      categoryValues: [ "" ],
      filterValues: {
        search: ""
      }
    },
    locations: {
      current: null,
      data: null,
      searchList: null,
      total: 0,
      offset: 0,
      filterValues: {
        search: ""
      }
    },
    documents: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    inspections: {
      current: null,
      data: null,
      total: 0,
      offset: 0
    },
    messages: null,

    // Project settings
    contractor: null
  };

  constructor(props) {
    super(props);
    this.t = translate;
    this.types = data.types;
    this.statuses = data.statuses;
    this.statusColors = data.statusColors;
    this.defectDetailLables = data.defectDetailLables;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/defects`);
  }

  setPage = (page, type = "categories", callback = "setCategories", subType = null, params = {}) => {
    let data = this.pages[type];

    if (subType) {
      data = this.pages[type][subType];

      if (data.sortingValues && data.sortingValues.field != "") {
        params.sort = data.sortingValues.field;
        params.order = data.sortingValues.order;
      }
    }
    
    data.offset = page * this.itemsPerPage;
    this[callback](params); 
  }

  // Defects
  setDefects = (params = {}) => {
    if (!params.status) {
      params.status = "all";
    }

    params.offset = this.pages.defects[params.status].offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.defects[params.status].data = response.data.data;
      this.pages.defects[params.status].total = response.data.count;
    });
  }

  setAllDefects = (params = {}) => {
    params.offset = this.pages.defects.all.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.defects.all.data = response.data.data;
      this.pages.defects.all.total = response.data.count;
    });
  }

  resetDefects = (status) => {
    this.pages.defects[status].data = null;
  }

  setDefectById = (id, params = {}) => {
    return this.setItem(id, null, params)
    .then(response => {
      this.pages.defects.current = response.data;
      return response;
    });
  }

  setDefect = (item) => {
    this.pages.defects.current = item;
  }

  resetDefect = () => {
    this.pages.defects.current = null;
  }

  addDefect = (values = {}) => {
    return this.addItem(values);
  }

  editDefect = (values = {}) => {
    return this.editItem(values);
  }

  deleteDefect = (id) => {
    return this.deleteItem(id)
    .then(r => {
      this.pages.defects.current = null;
      this.setDefects();
    });
  }

  getDefectNumber = (value = null) => {
    if (value) {
      return value;
    } else {
      return `DF_${moment().format("YYMM")}`;
    }
  }

  // Project settings - categories
  getCategoriesUrl = () => {
    return `${this.apiUrl}-categories`;
  }

  setCategories = (params = {}, type = "data") => {
    params.offset = this.pages.categories.offset;
    params.search = this.pages.categories.filterValues.search;
    
    return this.setList(this.getCategoriesUrl(), params)
    .then(response => {
      this.pages.categories[type] = response.data.data;
      
      if (type == "data") {
        this.pages.categories.total = response.data.count;
      }

      return response;
    });
  }

  setCategory = (item) => {
    this.pages.categories.current = item;
  }

  resetCategory = () => {
    this.pages.categories.current = null;
  }

  addCategory = (values = {}) => {
    return this.addItem(values, this.getCategoriesUrl())
    .then(r => this.setCategories());
  }

  editCategory = (values = {}) => {
    return this.editItem(values, this.getCategoriesUrl())
    .then(r => this.setCategories());
  }

  deleteCategory = (id) => {
    return this.deleteItem(id, this.getCategoriesUrl())
    .then(r => {
      this.pages.categories.current = null;
      this.setCategories();
    });
  }

  setCategoryFilters = (params = {}) => {
    this.pages.categories.filterValues = params;
  }

  searchCategories = (value, type = "private") => {
    const params = {
      limit: 500,
      search: value,
      type
    };

    return this.setList(this.getCategoriesUrl(), params)
    .then(response => {
      this.pages.categories.searchList = response.data.data;
      return response.data.data;
    });
  }

  // Categries repeat fields
  setCategoryData = (count, values) => {
    this.pages.categories.categoryCount = count;
    this.pages.categories.categoryValues = values;
  }

  setCategoryValue = (value, index) => {
    if (this.pages.categories.categoryValues.indexOf(value + "") == -1) {
      this.pages.categories.categoryValues[index] = value + "";
    } else {
      NotificationManager.error(this.t("This category was choosen before."));
    }
  }

  addSelectCategory = () => {
    let count = this.pages.categories.categoryCount;
    if (this.pages.categories.categoryValues[count - 1] != "") {
      this.pages.categories.categoryCount = ++count;
      this.pages.categories.categoryValues.push("");
    } else {
      NotificationManager.error(this.t("Please select category."));
    }
  }

  // Project settings - locations
  getLocationsUrl = () => {
    return `${this.apiUrl}-locations`;
  }

  setLocations = (params = {}, type = "data") => {
    params.offset = this.pages.locations.offset;
    params.search = this.pages.locations.filterValues.search;
    
    return this.setList(this.getLocationsUrl(), params)
    .then(response => {
      this.pages.locations[type] = response.data.data;

      if (type == "data") {
        this.pages.locations.total = response.data.count;
      }

      return response;
    });
  }

  setLocation = (item) => {
    this.pages.locations.current = item;
  }

  resetLocation = () => {
    this.pages.locations.current = null;
  }

  addLocation = (values = {}) => {
    return this.addItem(values, this.getLocationsUrl())
    .then(r => this.setLocations());
  }

  editLocation = (values = {}) => {
    return this.editItem(values, this.getLocationsUrl())
    .then(r => this.setLocations());
  }

  deleteLocation = (id) => {
    return this.deleteItem(id, this.getLocationsUrl())
    .then(r => {
      this.pages.categories.current = null;
      this.setLocations();
    });
  }

  setLocationFilters = (params = {}) => {
    this.pages.locations.filterValues = params;
  }

  // Inspections
  getInspectionUrl = () => {
    return `${this.apiUrl}/${this.pages.defects.current.id}/inspections`;
  }

  setInspections = (params = {}) => {
    params.offset = this.pages.inspections.offset;

    return this.setList(this.getInspectionUrl(), params)
    .then(response => {
      this.pages.inspections.data = response.data.data;
      this.pages.inspections.total = response.data.count;
    });
  }

  setInspection = (item) => {
    this.pages.inspections.current = item;
  }

  resetInspection = () => {
    this.pages.inspections.current = null;
  }

  addInspection = (values = {}) => {
    return this.addItem(values, this.getInspectionUrl())
    .then(r => this.setInspections());
  }

  editInspection = (values = {}) => {
    return this.editItem(values, this.getInspectionUrl())
    .then(r => this.setInspections());
  }

  deleteInspection = (id) => {
    return this.deleteItem(id, this.getInspectionUrl())
    .then(r => {
      this.pages.inspections.current = null;
      this.setInspections();
    });
  }

  // Documents
  getDocumentsUrl = () => {
    return this.apiUrl.replace("/defects", `/documents/defects/${this.pages.defects.current.id}`);
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.pages.documents.current = document;
  }

  resetCurrentDocument = () => {
    this.pages.documents.current = null;
  }

	addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl())
    .then(r => this.setDocuments());
	}

  deleteDocument = () => {
    return this.deleteItem(this.pages.documents.current.id, this.getDocumentsUrl())
    .then(r => {
      this.pages.documents.current = null;
      return this.setDocuments();
    });
  }

  // Messages
  getMessageUrl = () => {
    return `${this.apiUrl}/${this.pages.defects.current.id}/discussion`;
  }

  setMessages = (params = {}) => {
    return this.setList(this.getMessageUrl(), params)
    .then(response => {
      this.pages.messages = response.data.data;
    });
  }

  addMessage = (message) => {
    return this.addItem({ description: message }, this.getMessageUrl())
    .then(r => this.setMessages());
  }

  addFileMessage = (id, files) => {
    return this.addItem({ filename: files[0] }, this.getMessageUrl())
    .then(r => this.setMessages());
  }

  // Emails
  getEmailUrl = () => {
    return `${this.apiUrl.replace("/defects", "/email/defects")}`;
  }

  sendEmail = (values = {}) => {
    return this.addItem(values, this.getEmailUrl());
  }

  // Reports
  setReports = (params = {}) => {
    return this.setList(null, params)
    .then(response => {
      this.pages.defects.reports.filterValues = params;
      this.pages.defects.reports.data = response.data.data;
      this.pages.defects.reports.total = response.data.count;
    });
  }

  // Export PDF
  exportDefectsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/defects", "/export/pdf/defects")
    )
    .finally(this.hideLoader);
  }

  // Other
  setStatusCounters = () => {
    return this.setList(`${this.apiUrl.replace("/defects", "/counters/defects")}`)
    .then(response => {
      this.pages.defects.statusCounters = response.data;
    });
  }

  exportDefects = (params = {}) => {
    return this.export(`${this.apiUrl.replace('/defects', '')}/export/defects`, params).then(response => response.data);
  }
}

const store = window.DefectsStore = new DefectsStore;

export default store;

autorun(() => {
    // console.log(store);
});