import { translate } from "../helpers/Helper";

const t = translate;
const data = {
	statuses: {
		"all": t("All"),
		"requested": t("Requested"),
		"waiting_for_payment": t("Waiting for payment"),
		"paid": t("Paid"),
		"canceled": t("Canceled"),
		"processing": t("Processing"),
		"to_be_collected": t("To be Collected"),
		"collected": t("Collected"),
	}
};
export default data;