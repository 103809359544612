import { translate } from "../helpers/Helper";

const t = translate;

const data = {
  features: {
    residential: {
      "communicationManagement": [
        "feature_newsfeed",
        "feature_surveys_polls",
        "feature_online_forms",
        "feature_emails",
        "feature_notifications",
        "feature_noticeboard",
        "feature_faq",
        "feature_contacts",
        "feature_chatbot",
        "feature_enplug"
      ],
      "member_management": [
        "feature_members_keys",
        "feature_help_desk",
        "feature_deliveries",
        "feature_resident_documents",
        "feature_connections",
        "feature_retails"
      ],
      "place_management": [
        "feature_building_keys",
        "feature_safetrust",
        "feature_keys",
        "feature_bookings_calendar",
        "feature_bookings",
        "feature_bookings_reserved",
        "feature_project_calendar",
        "feature_inspections",
        "feature_incidents",
        "feature_work_orders",
        "feature_schedule_maintainance",
        "feature_assets",         
        "feature_contractors",
        "feature_defects",
        "feature_shift_log",
        "feature_documents_images",
        "feature_building_reports",
        "feature_lockers",
        "feature_mpl",
        "feature_facilities",
        "feature_hid",
        "feature_kone"
      ],
      "place_activation": [
        "feature_social_clubs",
        "feature_event_calendar",
        "feature_local_area",
        "feature_buy_sell",
        "feature_lend_borrow",
        "feature_special_offers",
        "feature_experiences",
        "feature_prefered_suppliers",
        "feature_find_services",
        "feature_real_estate",
      ]
    },
    commercial: {
      "communicationManagement": [
        "feature_newsfeed",
        "feature_surveys_polls",
        "feature_online_forms",
        "feature_emails",
        "feature_notifications",
        "feature_noticeboard",
        "feature_faq",
        "feature_contacts",
        "feature_chatbot",
        "feature_enplug"
      ],
      "member_management": [
        "feature_members_keys",
        "feature_help_desk",
        "feature_deliveries",
        "feature_resident_documents",
        "feature_directories",
        "feature_retails",
        "feature_members_directory"
      ],
      "place_management": [
        "feature_building_keys",
        "feature_safetrust",
        "feature_keys",
        "feature_bookings_calendar",
        "feature_bookings",
        "feature_bookings_reserved",
        "feature_project_calendar",
        "feature_inspections",
        "feature_incidents",
        "feature_work_orders",
        "feature_schedule_maintainance",
        "feature_assets",         
        "feature_contractors",
        "feature_defects",
        "feature_shift_log",
        "feature_documents_images",
        "feature_building_reports",
        "feature_lockers",
        "feature_mpl",
        "feature_facilities",
        "feature_hid",
        "feature_kone"
      ],
      "place_activation": [
        "feature_social_clubs",
        "feature_event_calendar",
        "feature_local_area",
        "feature_buy_sell",
        "feature_lend_borrow",
        "feature_special_offers",
        "feature_experiences",
        "feature_prefered_suppliers",
        "feature_find_services",
        "feature_real_estate",
      ]
    },
    lifestyle: {
      "communicationManagement": [
        "feature_newsfeed",
        "feature_surveys_polls",
        "feature_online_forms",
        "feature_emails",
        "feature_notifications",
        "feature_noticeboard",
        "feature_faq",
        "feature_contacts",
        "feature_chatbot",
        "feature_enplug"
      ],
      "member_management": [
        "feature_members_keys",
        "feature_help_desk",
        "feature_deliveries",
        "feature_resident_documents",
        "feature_connections",
        "feature_retails"
      ],
      "place_management": [
        "feature_building_keys",
        "feature_safetrust",
        "feature_keys",
        "feature_bookings_calendar",
        "feature_bookings",
        "feature_bookings_reserved",
        "feature_project_calendar",
        "feature_inspections",
        "feature_incidents",
        "feature_work_orders",
        "feature_schedule_maintainance",
        "feature_assets",         
        "feature_contractors",
        "feature_defects",
        "feature_shift_log",
        "feature_documents_images",
        "feature_building_reports",
        "feature_lockers",
        "feature_mpl",
        "feature_facilities",
        "feature_hid",
        "feature_kone"
      ],
      "place_activation": [
        "feature_social_clubs",
        "feature_event_calendar",
        "feature_local_area",
        "feature_buy_sell",
        "feature_lend_borrow",
        "feature_special_offers",
        "feature_experiences",
        "feature_prefered_suppliers",
        "feature_find_services",
        "feature_real_estate",
      ]
    },
    build_to_rent: {
      "communicationManagement": [
        "feature_newsfeed",
        "feature_surveys_polls",
        "feature_online_forms",
        "feature_emails",
        "feature_notifications",
        "feature_noticeboard",
        "feature_faq",
        "feature_contacts",
        "feature_chatbot",
        "feature_enplug"
      ],
      "member_management": [
        "feature_members_keys",
        "feature_help_desk",
        "feature_deliveries",
        "feature_resident_documents",
        "feature_connections",
        "feature_retails"
      ],
      "place_management": [
        "feature_building_keys",
        "feature_safetrust",
        "feature_keys",
        "feature_bookings_calendar",
        "feature_bookings",
        "feature_bookings_reserved",
        "feature_project_calendar",
        "feature_inspections",
        "feature_incidents",
        "feature_work_orders",
        "feature_schedule_maintainance",
        "feature_assets",         
        "feature_contractors",
        "feature_defects",
        "feature_shift_log",
        "feature_documents_images",
        "feature_building_reports",
        "feature_lockers",
        "feature_mpl",
        "feature_facilities",
        "feature_hid",
        "feature_kone"
      ],
      "place_activation": [
        "feature_social_clubs",
        "feature_event_calendar",
        "feature_local_area",
        "feature_buy_sell",
        "feature_lend_borrow",
        "feature_special_offers",
        "feature_experiences",
        "feature_prefered_suppliers",
        "feature_find_services",
        "feature_real_estate",
      ]
    },
  },
  featureLabels: {
    "projectSettings": t("Project Settings"),
    "feature_light_theme": t("Light theme"),

    "communicationManagement": t("Communication Management"),
    "feature_newsfeed": t("Newsfeed"),
    "feature_surveys_polls": t("Surveys / Polls"),
    "feature_online_forms": t("Online Forms"),
    "feature_emails": t("Emails"),
    "feature_notifications": t("Alerts / Notifications"),
    "feature_noticeboard": t("Noticeboard"),
    "feature_faq": t("FAQs"),
    "feature_contacts": t("Important Contacts"),
    "feature_chatbot": t("Chatbot"),
    "feature_enplug": t("Enplug"),

    "member_management": t("Member Management"),
    "feature_member_registration": t("Member Registration"),
    "feature_members_keys": t("Member Keys"),
    "feature_help_desk": t("Help Desk"),
    "feature_deliveries": t("Parcels & Deliveries"),
    "feature_resident_documents": t("Member Documents"),
    "feature_connections": t("Connections"),
    "feature_directories": t("Companies Directory"),
    "feature_retails": t("Retail Directory"),
    "feature_members_directory": t("Members Directory"),

    "place_management": t("Place Management"),
    "feature_building_keys": t("Building Keys"),
    "feature_safetrust": t("SafeTrust E-FOBs"),
    // "feature_keys": t("Keys / FOBs management"),
    "feature_keys": t("Keys / FOBs orders"),
    "feature_keys_electronic": t("Electronic FOBs"),
    "feature_bookings_calendar": t("Bookings Calendar"),
    "feature_bookings": t("General Bookings"),
    "feature_bookings_reserved": t("Reserved Bookings"),
    "feature_work_orders": t("Work Orders"),
    "feature_schedule_maintainance": t("Schedule Maintenance"),
    "feature_inspections": t("Inspections"),
    "feature_assets": t("Assets"),
    "feature_contractors": t("Contractors"),
    "feature_defects": t("Defects"),
    "feature_shift_log": t("Shift Log"),
    "feature_building_reports": t("Building Reports"),
    "feature_documents_images": t("Documents & Images"),
    "feature_incidents": t("Incidents / Cases"),
    "feature_project_calendar": t("Project Calendar"),
    "feature_lockers": t("Lockers"),
    "feature_mpl": t("My Parcel Locker"),
    "feature_facilities": t("Facilities"),
    "feature_hid": t("HID E-FOBs"),
    "feature_kone": t("KONE"),

    "place_activation": t("Place Activation"),
    "feature_social_clubs": t("Groups & Clubs"),
    "feature_event_calendar": t("Event Calendar"),
    "feature_local_area": t("Local Area"),
    "feature_buy_sell": t("Buy & Sell"),
    "feature_lend_borrow": t("Lend and Borrow"),
    "feature_special_offers": t("Special Offers"),
    "feature_experiences": t("Experiences"),
    "feature_real_estate": t("Real Estate"),
    "feature_prefered_suppliers": t("Preferred Services"),
    "feature_find_services": t("Find a Service (Micro Services)")
  },
  timezones: {
    "-12": t("(GMT-12:00) International Date Line West"),
    "-11": t("(GMT-11:00) Midway Island, Samoa"),
    "-10": t("(GMT-10:00) Hawaii"),
    "-9": t("(GMT-09:00) Alaska"),
    "-8": t("(GMT-08:00) Pacific Time (US & Canada)"),
    "-7": t("(GMT-07:00) Arizona"),
    "-6": t("(GMT-06:00) Central America"),
    "-5": t("(GMT-05:00) Eastern Time (US & Canada)"),
    "-4": t("(GMT-04:00) Atlantic Time (Canada)"),
    "-3": t("(GMT-03:00) Brasilia"),
    "-2": t("(GMT-02:00) Mid-Atlantic"),
    "-1": t("(GMT-01:00) Cape Verde Is."),
    "0": t("(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London"),
    "1": t("(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"),
    "2": t("(GMT+02:00) Athens, Bucharest, Istanbul"),
    "3": t("(GMT+03:00) Moscow, St. Petersburg, Volgograd"),
    "4": t("(GMT+04:00) Abu Dhabi, Muscat"),
    "5": t("(GMT+05:00) Islamabad, Karachi, Tashkent"),
    "6": t("(GMT+06:00) Almaty, Novosibirsk"),
    "7": t("(GMT+07:00) Bangkok, Hanoi, Jakarta"),
    "8": t("(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi"),
    "9": t("(GMT+09:00) Osaka, Sapporo, Tokyo"),
    "9.5": t("(GMT+09:30) Australian Central Standard Time"),
    "10": t("(GMT+10:00) Canberra, Melbourne, Sydney"),
    "11": t("(GMT+11:00) Magadan, Solomon Is., New Caledonia"),
    "12": t("(GMT+12:00) Auckland, Wellington"),
    "13": t("(GMT+13:00) Nuku'alofa")
  }
};

export default data;