import { autorun, observable } from "mobx";
import Store from "./Store.js";

class NoticeboardStore extends Store {
  @observable pages = {
    messages: {
      data: null,
      current: null
    },
    staffReminders: {
      data: null,
      offset: 0,
      total: 0,
      current: null
    }
  };

  constructor() {
    super();
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/noticeboard`);
  }

  setPage = (callback, page, type, subtype = "") => {
    let data = this.pages[type];
    if (subtype != "") {
      data = data[subtype];
    }

    data.offset = page * this.itemsPerPage;
    this[callback]();
  }

  // Messages
  setMessages = (options) => {
    return this.setList(null, options)
    .then(response => {
      this.pages.messages.data = response.data.data;
      return response;
    });
  }

  setCurrentMessage = (message) => {
    this.pages.messages.current = message;
  }

  resetCurrentMessage = () => {
    this.pages.messages.current = null;
  }

  addMessage = (values) => {
    values.type = "text";
    return this.addItem(values);
  }

  editMessage = (values) => {
    values.type = "text";
    return this.editItem(values);
  }

  addFileMessage = (files) => {
    const item = {
      filename: files[0],
      type: "file"
    };

    return this.addItem(item);
  }

  deleteMessage = () => {
    return this.deleteItem(this.pages.messages.current.id)
    .then(response => {
      this.pages.messages.current = null;
      return response;
    });
  }

  // Reminders
  getRemindersUrl = () => {
    return `${this.apiUrl}-reminders`;
  }

  setReminders = (params = {}) => {
    params.offset = this.pages.staffReminders.offset;

    return this.setList(this.getRemindersUrl(), params)
    .then(response => {
      this.pages.staffReminders.data = response.data.data;
      this.pages.staffReminders.total = response.data.count;
      return response;
    });
  }

  setReminder = (item) => {
    this.pages.staffReminders.current = item;
  }

  setReminderById = (id) => {
    return this.setItem(id, this.getRemindersUrl())
    .then(response => {
      this.pages.staffReminders.current = response.data;
      return response;
    });
  }

  resetReminder = () => {
    this.pages.staffReminders.current = null;
  }

  addReminder = (values) => {
    return this.addItem(values, this.getRemindersUrl())
    .then(response => {
      this.setReminders();
      return response;
    });
  }

  editReminder = (values) => {
    return this.editItem(values, this.getRemindersUrl())
    .then(response => {
      this.pages.staffReminders.current = response.data;
      this.setReminders();
      return response;
    });
  }

  deleteReminder = () => {
    return this.deleteItem(this.pages.staffReminders.current.id, this.getRemindersUrl())
    .then(r => {
      this.pages.staffReminders.current = null;
      return this.setReminders();
    });
  }
}

const store = window.NoticeboardStore = new NoticeboardStore;

export default store;

autorun(() => {
  // console.log(store);
});