export default {
  admin_sidebar: {
    review: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false,
      business: false
    }
  },
  clients: {
    create: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    }
  },
  projects: {
    create: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    strip_payment_connections: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  staff: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  business: {
    review: {
      admin: true,
      business: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    create: {
      admin: true,
      business: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      business: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      business: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
  },
  reporting: {
    review: {
      admin: true,
      business: false,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    create: {
      admin: true,
      business: false,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      business: false,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      business: false,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
  },

  // Project settings
  building_details: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  apartments: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    upload_csv: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    add_categories: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  categories: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  companies: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  departments: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  stages: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  addresses: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  features: {
    view: {
      admin: true,
      client: true,
      manager: false,
      project_manager: false,
      staff: false
    },
    language_settings: {
      admin: true,
      client: true,
      manager: false,
      project_manager: false,
      staff: false
    },
    color_scheme: {
      admin: true,
      client: true,
      manager: false,
      project_manager: false,
      staff: false
    },
    switch_features: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    }
  },
  facilities: {
    create: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    operational_hours: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  keys_setup: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  services: {
    review: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    create: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    }
  },

  // Admin dashboard
  user_profile: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true,
      business: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true,
      business: true
    },
    switch_between_project: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    }
  },
  user_signature: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  top_menu: {
    overview: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    projects: {
      admin: true,
      client: true,
      manager: false,
      project_manager: false,
      staff: false
    },
    quick_access_link: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  help_centre: {
    create: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    }
  },
  newsfeed: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    filter: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  importantContacts: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    }
  },
  companyDirectories: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    }
  },
  retailDirectories: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    }
  },
  preferredServices: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    }
  },
  emails: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    filter: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: false,
      project_manager: false,
      staff: false
    }
  },
  alerts: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: false,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: false,
      project_manager: false,
      staff: false
    }
  },
  member_registrations: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    approve: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    decline: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    block: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    export: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    }
  },
  helpdesk: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    filter_search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  deliveries: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    filter_search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  member_documents: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    upload: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  keys_fobs: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    sign_in_out: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    create_building: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    processing_request: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  work_orders: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    filter: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  bookings: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  scheduledBookings: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  assets: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    filter: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  localArea: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  contractors: {
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: false,
      staff: false
    },
    filter: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
  },
  documents: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    delete: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: false
    },
    upload: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    search: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    }
  },
  helpcentre_tickets: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
  },
  helpcentre_newsfeed: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
  },
  helpcentre_comingsoon: {
    create: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    review: {
      admin: true,
      client: true,
      manager: true,
      project_manager: true,
      staff: true
    },
    update: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
    delete: {
      admin: true,
      client: false,
      manager: false,
      project_manager: false,
      staff: false
    },
  },

};