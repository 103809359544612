import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  statusNames: {
    scheduled: t("Scheduled"),
    active: t("Active"),
    featured: t("Featured"),
    archived: t("Archive")
  },
  types: {
    "is_urgent": t("Urgent News"),
    "is_important": t("Important News"),
    "is_general": t("General News"),
    "is_enplug": t("Push to Enplug screens")
  },
  categories: {
    "amenities": t("Amenities"),
    "car_parking": t("Car Parking"),
    "cleanliness": t("Cleanliness"),
    "common_area": t("Common Area"),
    "competitions": t("Competitions"),
    "deliveries": t("Deliveries"),
    "diet_nutrition": t("Diet and Nutrition"),
    "exhibitions": t("Exhibitions"),
    "facilities": t("Facilities"),	
    "festivals": t("Festivals"),
    "films": t("Films"),
    "fitness": t("Fitness"),
    "food_wine": t("Food & Wine"),
    "health_wellbeing": t("Health and Wellbeing"),
    "kids_family": t("Kids & Family"),
    "maintenance": t("Maintenance"),
    "major_events": t("Major events"),
    "markets": t("Markets"),
    "music": t("Music"),
    "performing_arts": t("Performing Arts"),
    "security": t("Security"),
    "services": t("Services"),	
    "special_offers": t("Special Offers"),
    "sport": t("Sports"),
    "tours": t("Tours"),
    "workshops": t("Workshops"),
    "other": t("Other")
  },
  internal_link: {
    "alerts": t("Alerts"),
    "facilities": t("All Facilities"),
    "services": t("All Services"),
    "bookings_general": t("Bookings - General"),
    "bookings_reserved": t("Bookings - Reserved"),
    "lockers": t("Bookings - Lockers"),
    "retail_directory": t("Directory - Retail"),
    "documents": t("Documents"),
    "event_calendar": t("Event Calendar"),
    "faqs": t("FAQs"),
    "find_services": t("Find Services (micro-services)"),
    "goups_clubs": t("Groups & Clubs"),
    "help_desk": t("Help Desk"),
    "important_contacts": t("Important Contacts"),
    "keys_fobs_orders": t("Keys & FOBs Orders"),
    "local_area": t("Local Area"),
    "online_forms": t("Online Forms"),
    "deliveries": t("Parcels Deliveries"),
    "preferred_services": t("Preferred Services"),
    "push_notification": t("Push notification (app settings)"),
    "bug_report": t("Report a bug"),
    "special_offers": t("Special Offers"),
    "surveys_polls": t("Surveys & polls"),
    "user_profile": t("User Profile")
  },
  commercial_links: {
    "alerts": t("Alerts"),
    "facilities": t("All Facilities"),
    "services": t("All Services"),
    "bookings_general": t("Bookings - General"),
    "bookings_reserved": t("Bookings - Reserved"),
    "lockers": t("Bookings - Lockers"),
    "companies_directory": t("Directory - Companies"),
    "members_directory": t("Directory - Members"),
    "retail_directory": t("Directory - Retail"),
    "documents": t("Documents"),
    "event_calendar": t("Event Calendar"),
    "faqs": t("FAQs"),
    "find_services": t("Find Services (micro-services)"),
    "goups_clubs": t("Groups & Clubs"),
    "help_desk": t("Help Desk"),
    "important_contacts": t("Important Contacts"),
    "keys_fobs_orders": t("Keys & FOBs Orders"),
    "local_area": t("Local Area"),
    "online_forms": t("Online Forms"),
    "deliveries": t("Parcels Deliveries"),
    "preferred_services": t("Preferred Services"),
    "push_notification": t("Push notification (app settings)"),
    "bug_report": t("Report a bug"),
    "special_offers": t("Special Offers"),
    "surveys_polls": t("Surveys & polls"),
    "user_profile": t("User Profile")
  }
};
export default data;