import React from 'react';
import { observer, inject } from 'mobx-react';
import { Route, withRouter, Switch } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import { loadIntercom, initIntercomWindow } from "next-intercom";
import { LionessProvider } from 'lioness';
import LazyRoute from "lazy-route";

import Sidebar from "./sidebar/Sidebar";
import Header from './header/Header';
import Preloader from './Preloader';
import Helper from "../helpers/Helper";

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/main.scss';

// Fonts
import 'assets/fonts/icomoon.ttf';
import 'assets/fonts/icomoon.woff';
import 'assets/fonts/icomoon.eot';
import 'assets/fonts/icomoon.svg';
import 'assets/fonts/notification.ttf';
import 'assets/fonts/notification.woff';
import 'assets/fonts/notification.eot';
import 'assets/fonts/notification.svg';

@inject('RoutingStore', 'AuthStore', 'NavigationStore', 'NotificationStore', 'ProjectStore', 'PreloaderStore', 'LocalizationStore')
@withRouter
@observer
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.translations = props.LocalizationStore.getTranslations(
      require.context('../../languages/', false, /\.po$/)
    );

    this.state = {
      isSidebarShow: true
    };

    this.setData();
  }

  setData = () => {
    const { AuthStore, LocalizationStore, NotificationStore, location } = this.props;
    AuthStore.checkToken(location);
    LocalizationStore.setViewLanguage();

    if (window && window.location && window.location.href.indexOf("localhost") == -1) {
      loadIntercom({
        appId: "dvuhe1xg",
        apiBase: "https://api-iam.intercom.io",
        ssr: false,
        initWindow: true,
        delay: 3
      });
    }

    AuthStore.setUser()
    .then(r => {
      const { user, intercomId } = AuthStore;

      if (window && window.location && window.location.href.indexOf("localhost") == -1) {
        initIntercomWindow({
          appId: intercomId,
          name: user.first_name,
          email: user.email
        });
      }
    })
    .catch(Helper.handleError);
  }

  render() {
    let wrapperClass = this.state.isSidebarShow ? "wrapper in" : "wrapper in toggled";
    let content = null;

    const { user, token } = this.props.AuthStore;
    const { isAdminPage, routing } = this.props.NavigationStore;
    const { currentProject } = this.props.ProjectStore;

    if (isAdminPage() && user && user.role != "admin") {
      wrapperClass = "wrapper in toggled not-admin";
    }

    if ((currentProject != null && currentProject.features.feature_light_theme != '0') && !isAdminPage()) {
      wrapperClass += " light-theme";
    }

    if (token) {
      content = (
        <div>
          <Switch>
            <Route 
              path="/client/:clientId/project/:projectId"
              component={Sidebar}
            />
            <Route 
              path="/"
              component={Sidebar}
            />       
          </Switch>
          <Switch>
            <Route 
              path="/client/:clientId/project/:projectId"
              render={props => (
                <Header {...props} toggleSidebar={this.toggleSidebar} />
              )}
            />                       
            <Route 
              path="/"
              render={props => (
                <Header {...props} toggleSidebar={this.toggleSidebar} />
              )}
            /> 
          </Switch>
        </div>
      );
    } else {
      content = <div />;
    }

    const result = routing.map(this.renderRoute);

    return (
      <LionessProvider
        messages={this.translations}
        locale={this.props.LocalizationStore.getLanguage()}
        debug={true}
      >
        <div className={wrapperClass}>
          {content}
          {result}
          <NotificationContainer />
          <Preloader />
        </div>
      </LionessProvider>
    );
  }

  renderRoute = (route, index) => {
    const component = route.componentPath ? import('' + route.componentPath) : import('./pages/ComimgSoon'),
      exact = route.exact ? route.exact : false;

    return (
      <Route exact={exact} 
        path={route.path}
        key={`route-${index}`}
        render={props => (
          <LazyRoute {...props} 
            component={component} 
          />
        )} 
      /> 
    );
  }

  toggleSidebar = () => {
    this.setState({
      isSidebarShow: !this.state.isSidebarShow
    });
  }
};
 