import { autorun, observable } from "mobx";
import Store from "./Store";
import { translate } from "../helpers/Helper";

class NotificationStore extends Store {
  @observable notifications = null;
  @observable currentNotification = null;
  @observable count = 0;
  @observable notReadCount = 0;

  constructor() {
    super();
    this.t = translate;
    this.types = {
      help_centre_coming_soon: this.t("Coming Soon"),
      help_centre_newsfeed: this.t("Newsfeed"),
      help_centre_tickets: this.t("Tickets")
    };

    this.urls = {
      help_centre_coming_soon: "help-centre/coming-soon",
      help_centre_newsfeed: "help-centre/newsfeed",
      help_centre_tickets: "help-centre/ticket"
    };
  }

  setData = (clientId, projectId, token) => {
    let url = "notifications";

    if (clientId && projectId) {
      url = `clients/${clientId}/projects/${projectId}/notifications`;
    }
    this.setStoreData(token, url);
  }

  setNotifications = (params = {}) => {
    return this.setList(null, params)
    .then(response => {
      this.notifications = response.data.data;
      this.notReadCount = response.data.count_not_read;
      this.count = response.data.count;
    });
  }

  appendNotifications = (params = {}) => {
    return this.setList(null, params)
    .then(response => {
      this.notifications = this.notifications.concat(response.data.data);
      this.notReadCount = response.data.count_not_read;
      this.count = response.data.count;
    });
  }

  setNotification = (item) => {
    this.currentNotification = item;
  }

  resetNotification = () => {
    this.currentNotification = null;
  }

  readNotification = (values) => {
    return this.editItem(values);
  }

  deleteNotification = () => {
    return this.deleteItem(this.currentNotification.id);
  }

  readAllNotifications = () => {
    return this.deleteItems();
  }
}

const store = window.NotificationStore = new NotificationStore;

export default store;

autorun(() => {
    // console.log(store);
});