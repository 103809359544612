import { autorun, observable, toJS } from "mobx";
import data from "../data/Business.js";
import Store from "./Store";
import axios from "axios";

class BusinessStore extends Store {
  @observable businesses = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable staffList = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable allocatedProjects = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable services = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable transactions = {
    data: null,
    offset: 0,
    total: 0
  };
  @observable tickets = {
    all: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    open: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    in_progress: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    },
    resolved: {
      data: null,
      offset: 0,
      total: 0,
      count: 0
    }
  };
  @observable messages = null;
  @observable searchList = null;
  @observable currentBusiness = null;
  @observable currentStaff = null;
  @observable currentTicket = null;
  @observable currentService = null;
  @observable customer = null;

  constructor() {
    super();
    this.statuses = data.statuses;
    this.categories = data.categories;
    this.microservices = data.microservices;
    this.ticketCategories = data.ticketCategories;
    this.roles = {
      tenant: "Tenant",
      owner: "Owner",
      landlord: "Landlord",
      property_manager: "Property Manager"
    };
  }

  setData = (token, pid = null, cid = null) => {    
    if (!pid) {
      this.setStoreData(token, `businesses`);
    } else {
      this.setStoreData(token, `clients/${cid}/projects/${pid}/businesses`);
    }
  }

  setPage = (page, type = "businesses") => {
    if (type == "businesses") {
      this.businesses.offset = page * this.itemsPerPage;
      this.setBusinesses();
    } else {
      this.staffList.offset = page * this.itemsPerPage;
      this.setStaffList();
    }
  }

  setBusinesses = (sortObj = null) => {
    let params = {
      offset: this.businesses.offset
    };

    return this.setList(null, sortObj ? Object.assign(params, sortObj) : params)
    .then(response => {
      this.businesses.data = response.data.data;
      this.businesses.total = response.data.count;
    });
  }

  setBusiness = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentBusiness = response.data;
    });
  }

  setCurrentBusiness = (business) => {
    this.currentBusiness = business;
  }

  resetCurrentBusiness = () => {
    this.currentBusiness = null;
  }

  getBusiness = () => {
    return this.currentBusiness;
  }

  addBusiness = (values) => {
    return this.addItem(values)
    .then(r => this.setBusinesses());
  }

  editBusiness = (values) => {
    return this.editItem(values)
    .then(response => {
      this.setBusinesses();
      this.currentBusiness = response.data;
    });
  }

  deleteBusiness = () => {
    return this.deleteItem(this.currentBusiness.id)
    .then(r => {
      this.currentBusiness = null;
      this.setBusinesses();
    });
  }

  searchBusinesses = (params) => {
    return this.setList(`${this.url}businesses`, params)
    .then(response => {
      this.searchList = response.data.data;
      return response;
    });
  }

  resetSearchList = () => {
    this.searchList = null;
  }

  // Staff
  getStaffUrl = () => {
    return `${this.apiUrl}/${this.currentBusiness.id}/users`;
  }

  setStaffList = () => {
    const params = { 
      offset: this.staffList.offset
    };

    return this.setList(this.getStaffUrl(), params)
    .then(response => {
      this.staffList.data = response.data.data;
      this.staffList.total = response.data.count;
      return response;
    });
  }

  getStaffList = () => {
    return toJS(this.staffList.data);
  }

  setCurrentStaff = (staff) => {
    this.currentStaff = staff;
  }

  getCurrentStaff = () => {
    return toJS(this.currentStaff);
  }

  resetCurrentStaff = () => {
    this.currentStaff = null;
  }

  addStaff = (values) => {
    return this.addItem(values, this.getStaffUrl())
    .then(r => this.setStaffList());
  }

  editStaff = (values) => {
    return this.editItem(values, this.getStaffUrl())
    .then(response => {
      this.setStaffList();
      this.currentStaff = response.data;
    });
  }

  deleteCurrentStaff = () => {
    return this.deleteItem(this.currentStaff.id, this.getStaffUrl())
    .then(r => {
      this.currentStaff = null;
      return this.setStaffList();
    });
  }

  // Allocated projects
  getProjectUrl = () => {
    return `${this.apiUrl}/${this.currentBusiness.id}/projects`;
  }

  setProjects = () => {
    const params = {
      offset: this.allocatedProjects.offset
    };

    return this.setList(this.getProjectUrl(), params)
    .then(response => {
      this.allocatedProjects.data = response.data.data;
      this.allocatedProjects.total = response.data.count;
      return response;
    });
  }

  // Tickets
  getTicketUrl = () => {
    return `${this.apiUrl}/${this.currentBusiness.id}/tickets`;
  }

  setTicketPage = (page, type) => {
    this.tickets[type].offset = page * this.itemsPerPage;
    this.setAllTickets();
  }

  setAllTickets = () => {
    let promises = [];
    const statuses = [ "all", "open", "in_progress", "resolved" ];

    statuses.forEach(status => {
      promises.push(this.setTicketsByStatus({ status: status }));
    });

    return axios.all(promises);
  }

  setTicketsByStatus = (sortObj = {}) => {
    const status = sortObj.status || "all";
    const params = {
      offset: this.tickets[status].offset
    };

    return this.setList(this.getTicketUrl(), sortObj ? Object.assign(params, sortObj) : params)
    .then(response => {
      this.tickets[status].data = response.data.data;
      this.tickets[status].total = response.data.count;
      this.tickets[status].count = response.data.count;
      return response;
    });
  }

  setTicket = (id) => {
    return this.setItem(id, this.getTicketUrl())
    .then(response => {
      this.currentTicket = response.data;
      return response;
    });
  }

  getCurrentTicket = () => {
    return toJS(this.currentTicket);
  }

  editTicket = (values) => {
    return this.editItem(values, this.getTicketUrl())
    .then(response => {
      this.currentTicket.status = response.data.status;
    });
  }

  // Messages
  getMessagesUrl = (id = null) => {
    return `${this.apiUrl}/${this.currentBusiness.id}/tickets/${id || this.currentTicket.id}/message`;
  }

  setMessages = () => {
    return this.setList(this.getMessagesUrl());
  }

  setMessage = (id, message) => {
    this.tickets.forEach((ticket) => {
      if (id == toJS(ticket).id) {
        ticket.messages.push(message);
      }
    });
  }

  addMessage = (id, message) => {
    const params = {
      description: message,
      type: "text"
    };

    return this.addItem(params, this.getMessagesUrl(id))
    .then(r => {
      if (this.currentTicket) {
        this.setTicket(this.currentTicket.id);
      }
    });
  }

  addFileMessage = (id, files) => {
    const params = {
      filename: files[0],
      type: "file"
    };

    return this.addItem(params, this.getMessagesUrl(id))
    .then(r => {
      if (this.currentTicket) {
        this.setTicket(this.currentTicket.id);
      }
    });
  }

  // Balance 
  setBalance = (userId) => {
    return this.setItem(userId, `${this.apiUrl}/${this.currentBusiness.id}/customers`)
    .then(response => {
      this.customer = response.data.customer;
      this.transactions.data = response.data.transactions;
    });
  }

  // Services
  getServicesUrl = () => {
    return `${this.apiUrl}/${this.currentBusiness.id}/services`;
  }

  setServicePage = (page) => {
    this.services.offset = page * this.itemsPerPage;
    this.setServices();
  }

  setServices = () => {
    const params = {
      offset: this.services.offset    
    };

    return this.setList(this.getServicesUrl(), params)
    .then(response => {
      this.services.data = response.data.data;
      this.services.total = response.data.count;
    });
  }

  setCurrentService = (service) => {
    this.currentService = service;
  }

  getCurrentService = () => {
    return toJS(this.currentService);
  }

  resetCurrentService = () => {
    this.currentService = null;
  }

  addService = (values) => {
    return this.addItem(values, this.getServicesUrl())
    .then(r => this.setServices());
  }

  editService = (values) => {
    return this.editItem(values, this.getServicesUrl())
    .then(response => {
      this.setServices();
      this.currentService = response.data;
    });
  }

  deleteService = () => {
    return this.deleteItem(this.currentService.id, this.getServicesUrl())
    .then(r => {
      this.currentService = null;
      return this.setServices();
    });
  }
}

const store = window.BusinessStore = new BusinessStore;

export default store;

autorun(() => {
    // console.log(store);
});