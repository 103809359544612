import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  userLabels: {
    "documents": t("Documents"),
    "personal_info": t("Personal info"),
    "first_name": t("Name"),
    "email": t("Email"),
    "nationality": t("Nationality / Country"),
    "date_birth": t("Date of Birth"),
    "phone_mobile": t("Mobile number"),
    "phone": t("Phone number"),
    "date_approved": t("Approval Date"),
    "approved_by_staff_name": t("Approved By"),

    "building": t("Building"),
    "building_name": t("Building"),
    "apartment_number": t("Apartment Number"),
    "real_estate_agent": t("Real Estate Agent"),
    "real_estate_agent_phone": t("Real Estate Agent Contact Number"),
    "real_estate_company": t("Real Estate Company Name"),
    "real_estate_company_phone": t("Real Estate Company Phone Number"),
    "date_lease_start": t("Lease Start Date"),
    "date_lease_end": t("Lease End Date"),
    "pets": t("Pets"),
    "cars": t("Cars"),

    "department_name": t("Department"),
    "company_name": t("Company"),

    "pet_type": t("Pet Type"),
    "pet_reg_number": t("Registration Number"),

    "car_type": t("Car Type"),
    "car_reg_number": t("Registration Number"),
    "car_park_number": t("Carpark Number")
  },

  personalInfo: {
    avatar: t("Avatar"),
    name: t("Name"),
    role: t("Account Type"),
    email: t("Email"),
    phone_mobile: t("Mobile Number"),
    phone: t("Phone Number"),
    nationality: t("Nationality / Country"),
    date_birth: t("Year of birth"),
    language: t("Language Spoken"),
    emergency_name: t("Emergency Contact Name"),
    emergency_phone: t("Emergency Contact Number"),
    emergency_relation: t("Emergency Contact Relationship"),
    approved_by_staff_name: t("Approved by"),
    date_last_active: t("Logged In"),
    date_approved: t("Approved"),
    comment: t("Comments")
  },

  roleNames: {
    "tenant": t("Tenant"),
    "owner": t("Owner"),
    "landlord": t("Landlord"),
    "property_manager": t("Property Manager"),
    "company_rep": t("Company Rep"),
    "company_staff": t("Company Staff"),
    "service_provider": t("Service Provider"),
    "admin": t("Admin"),
    "client": t("Client"),
    "manager": t("Manager"),
    "project_manager": t("Project Manager"),
    "staff": t("Staff")
  },

  roles: {
    "owner": t("Owner Occupier"),
    "landlord": t("Owner Landlord"),
    "property_manager": t("Property Manager"),
    "tenant": t("Tenant"),
  }, 
  
  rolesCommercial: {
    "company_rep": t("Company Rep"),
    "company_staff": t("Company Staff"),
    "service_provider": t("Service Provider")
  },
  
  rolesRent: {
    "tenant": t("Tenant"),
    "short_term_tenant": t("Short term tenant")
  },

  statuses: {
    "pending": t("Pending"),
    "need_info": t("More info"),
    "approved": t("Approved"),
    "offline": t("Offline"),
    "declined": t("Declined"),
    "archived": t("Archived")
  },

  sortingFileds: [
    "apartment_number", "building", "role", "company_name", "department_name", "stage_name", "address_name"
  ],
  
  tableFields: [
    "apartment_number", "building", "name",
    "role", "phone_mobile", "email", "date", "status"
  ],

  commercailTableFields: [
    "company_name", "department_name", "name",
    "role", "phone_mobile", "email", "date", "status"
  ],

  tableColumns: {
    "stage_name": t("Stage Name"),
    "address_name": t("Address"),
    "department_name": t("Department"),
    "company_name": t("Company"),
    "department_name": t("Department"),
    "apartment_number": t("Apartment number"),
    "building": t("Building"),
    "name": t("Name"),
    "role": t("Account Type"),
    "phone_mobile": t("Mobile number"),
    "email": t("Email"),
    "date": t("Date"),
    "status": t("Status")
  },

  buildingInfo: {
    "building": t("Building"),
    "building_name": t("Building"),
    "apartment_number": t("Apartment Number"),
    "real_estate_agent": t("Real Estate Agent"),
    "real_estate_agent_phone": t("Real Estate Agent Contact Number"),
    "real_estate_company": t("Real Estate Company Name"),
    "real_estate_company_phone": t("Real Estate Company Phone Number"),
    "real_estate_managing_property": t("Real Estate Managing Property"),
    "date_lease_start": t("Lease Start Date"),
    "date_lease_end": t("Lease End Date"),
    "pets": t("Pets"),
    "cars": t("Cars")
  },

  departmentInfo: {
    "department_name": t("Department"),
    "company_name": t("Company"),
  },

  lifestyleInfo: {
    "stage_name": t("Stage"), 
    "address_name": t("Address"),
    "real_estate_agent": t("Real Estate Agent"),
    "real_estate_agent_phone": t("Real Estate Agent Contact Number"),
    "real_estate_company": t("Real Estate Company Name"),
    "real_estate_company_phone": t("Real Estate Company Phone Number"),
    "real_estate_managing_property": t("Real Estate Managing Property"),
    "date_lease_start": t("Lease Start Date"),
    "date_lease_end": t("Lease End Date"),
  },

  countryCodes: {
    "AF": {
      "currency": [
        "AFN"
      ],
      "callingCode": [
        "93"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-af",
      "name": {
        "common": "Afghanistan",
        "ces": "Afghánistán",
        "cym": "Affganistan",
        "deu": "Afghanistan",
        "fra": "Afghanistan",
        "hrv": "Afganistan",
        "ita": "Afghanistan",
        "jpn": "アフガニスタン",
        "nld": "Afghanistan",
        "por": "Afeganistão",
        "rus": "Афганистан",
        "slk": "Afganistan",
        "spa": "Afganistán",
        "fin": "Afganistan",
        "est": "Afganistan",
        "zho": "阿富汗",
        "pol": "Afganistan",
        "urd": "افغانستان",
        "kor": "아프가니스탄"
      }
    },
    "AL": {
      "currency": [
        "ALL"
      ],
      "callingCode": [
        "355"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-al",
      "name": {
        "common": "Albania",
        "ces": "Albánie",
        "cym": "Albania",
        "deu": "Albanien",
        "fra": "Albanie",
        "hrv": "Albanija",
        "ita": "Albania",
        "jpn": "アルバニア",
        "nld": "Albanië",
        "por": "Albânia",
        "rus": "Албания",
        "slk": "Albánsko",
        "spa": "Albania",
        "fin": "Albania",
        "est": "Albaania",
        "zho": "阿尔巴尼亚",
        "pol": "Albania",
        "urd": "البانیا",
        "kor": "알바니아"
      }
    },
    "DZ": {
      "currency": [
        "DZD"
      ],
      "callingCode": [
        "213"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "flag": "flag-dz",
      "name": {
        "common": "Algeria",
        "ces": "Alžírsko",
        "cym": "Algeria",
        "deu": "Algerien",
        "fra": "Algérie",
        "hrv": "Alžir",
        "ita": "Algeria",
        "jpn": "アルジェリア",
        "nld": "Algerije",
        "por": "Argélia",
        "rus": "Алжир",
        "slk": "Alžírsko",
        "spa": "Argelia",
        "fin": "Algeria",
        "est": "Alžeeria",
        "zho": "阿尔及利亚",
        "pol": "Algieria",
        "urd": "الجزائر",
        "kor": "알제리"
      }
    },
    "AS": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1684"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-as",
      "name": {
        "common": "American Samoa",
        "ces": "Americká Samoa",
        "deu": "Amerikanisch-Samoa",
        "fra": "Samoa américaines",
        "hrv": "Američka Samoa",
        "ita": "Samoa Americane",
        "jpn": "アメリカ領サモア",
        "nld": "Amerikaans Samoa",
        "por": "Samoa Americana",
        "rus": "Американское Самоа",
        "slk": "Americká Samoa",
        "spa": "Samoa Americana",
        "fin": "Amerikan Samoa",
        "est": "Ameerika Samoa",
        "zho": "美属萨摩亚",
        "pol": "Samoa Amerykańskie",
        "urd": "امریکی سمووا",
        "kor": "아메리칸사모아"
      }
    },
    "AD": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "376"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-ad",
      "name": {
        "common": "Andorra",
        "ces": "Andorra",
        "cym": "Andorra",
        "deu": "Andorra",
        "fra": "Andorre",
        "hrv": "Andora",
        "ita": "Andorra",
        "jpn": "アンドラ",
        "nld": "Andorra",
        "por": "Andorra",
        "rus": "Андорра",
        "slk": "Andorra",
        "spa": "Andorra",
        "fin": "Andorra",
        "est": "Andorra",
        "zho": "安道尔",
        "pol": "Andora",
        "urd": "انڈورا",
        "kor": "안도라"
      }
    },
    "AO": {
      "currency": [
        "AOA"
      ],
      "callingCode": [
        "244"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-ao",
      "name": {
        "common": "Angola",
        "ces": "Angola",
        "cym": "Angola",
        "deu": "Angola",
        "fra": "Angola",
        "hrv": "Angola",
        "ita": "Angola",
        "jpn": "アンゴラ",
        "nld": "Angola",
        "por": "Angola",
        "rus": "Ангола",
        "slk": "Angola",
        "spa": "Angola",
        "fin": "Angola",
        "est": "Angola",
        "zho": "安哥拉",
        "pol": "Angola",
        "urd": "انگولہ",
        "kor": "앙골라"
      }
    },
    "AI": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1264"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-ai",
      "name": {
        "common": "Anguilla",
        "ces": "Anguilla",
        "deu": "Anguilla",
        "fra": "Anguilla",
        "hrv": "Angvila",
        "ita": "Anguilla",
        "jpn": "アンギラ",
        "nld": "Anguilla",
        "por": "Anguilla",
        "rus": "Ангилья",
        "slk": "Anguilla",
        "spa": "Anguilla",
        "fin": "Anguilla",
        "est": "Anguilla",
        "zho": "安圭拉",
        "pol": "Anguilla",
        "urd": "اینگویلا",
        "kor": "앵귈라"
      }
    },
    "AQ": {
      "currency": [],
      "callingCode": [],
      "region": "Antarctic",
      "subregion": "",
      "flag": "flag-aq",
      "name": {
        "common": "Antarctica",
        "ces": "Antarktida",
        "cym": "Yr Antarctig",
        "deu": "Antarktis",
        "fra": "Antarctique",
        "hrv": "Antarktika",
        "ita": "Antartide",
        "jpn": "南極",
        "nld": "Antarctica",
        "por": "Antártida",
        "rus": "Антарктида",
        "slk": "Antarktída",
        "spa": "Antártida",
        "fin": "Etelämanner",
        "est": "Antarktika",
        "zho": "南极洲",
        "pol": "Antarktyka",
        "urd": "انٹارکٹکا",
        "kor": "남극"
      }
    },
    "AG": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1268"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-ag",
      "name": {
        "common": "Antigua and Barbuda",
        "ces": "Antigua a Barbuda",
        "cym": "Antigwa a Barbiwda",
        "deu": "Antigua und Barbuda",
        "fra": "Antigua-et-Barbuda",
        "hrv": "Antigva i Barbuda",
        "ita": "Antigua e Barbuda",
        "jpn": "アンティグア・バーブーダ",
        "nld": "Antigua en Barbuda",
        "por": "Antígua e Barbuda",
        "rus": "Антигуа и Барбуда",
        "slk": "Antigua a Barbuda",
        "spa": "Antigua y Barbuda",
        "fin": "Antigua ja Barbuda",
        "est": "Antigua ja Barbuda",
        "zho": "安提瓜和巴布达",
        "pol": "Antigua i Barbuda",
        "urd": "اینٹیگوا و باربوڈا",
        "kor": "앤티가 바부다"
      }
    },
    "AR": {
      "currency": [
        "ARS"
      ],
      "callingCode": [
        "54"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-ar",
      "name": {
        "common": "Argentina",
        "ces": "Argentina",
        "cym": "Ariannin",
        "deu": "Argentinien",
        "fra": "Argentine",
        "hrv": "Argentina",
        "ita": "Argentina",
        "jpn": "アルゼンチン",
        "nld": "Argentinië",
        "por": "Argentina",
        "rus": "Аргентина",
        "slk": "Argentína",
        "spa": "Argentina",
        "fin": "Argentiina",
        "est": "Argentina",
        "zho": "阿根廷",
        "pol": "Argentyna",
        "urd": "ارجنٹائن",
        "kor": "아르헨티나"
      }
    },
    "AM": {
      "currency": [
        "AMD"
      ],
      "callingCode": [
        "374"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-am",
      "name": {
        "common": "Armenia",
        "ces": "Arménie",
        "cym": "Armenia",
        "deu": "Armenien",
        "fra": "Arménie",
        "hrv": "Armenija",
        "ita": "Armenia",
        "jpn": "アルメニア",
        "nld": "Armenië",
        "por": "Arménia",
        "rus": "Армения",
        "slk": "Arménsko",
        "spa": "Armenia",
        "fin": "Armenia",
        "est": "Armeenia",
        "zho": "亚美尼亚",
        "pol": "Armenia",
        "urd": "آرمینیا",
        "kor": "아르메니아"
      }
    },
    "AW": {
      "currency": [
        "AWG"
      ],
      "callingCode": [
        "297"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-aw",
      "name": {
        "common": "Aruba",
        "ces": "Aruba",
        "deu": "Aruba",
        "fra": "Aruba",
        "hrv": "Aruba",
        "ita": "Aruba",
        "jpn": "アルバ",
        "nld": "Aruba",
        "por": "Aruba",
        "rus": "Аруба",
        "slk": "Aruba",
        "spa": "Aruba",
        "fin": "Aruba",
        "est": "Aruba",
        "zho": "阿鲁巴",
        "pol": "Aruba",
        "urd": "اروبا",
        "kor": "아루바"
      }
    },
    "AU": {
      "currency": [
        "AUD"
      ],
      "callingCode": [
        "61"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "flag": "flag-au",
      "name": {
        "common": "Australia",
        "ces": "Austrálie",
        "cym": "Awstralia",
        "deu": "Australien",
        "fra": "Australie",
        "hrv": "Australija",
        "ita": "Australia",
        "jpn": "オーストラリア",
        "nld": "Australië",
        "por": "Austrália",
        "rus": "Австралия",
        "slk": "Austrália",
        "spa": "Australia",
        "fin": "Australia",
        "est": "Austraalia",
        "zho": "澳大利亚",
        "pol": "Australia",
        "urd": "آسٹریلیا",
        "kor": "호주"
      }
    },
    "AT": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "43"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-at",
      "name": {
        "common": "Austria",
        "ces": "Rakousko",
        "cym": "Awstria",
        "deu": "Österreich",
        "fra": "Autriche",
        "hrv": "Austrija",
        "ita": "Austria",
        "jpn": "オーストリア",
        "nld": "Oostenrijk",
        "por": "Áustria",
        "rus": "Австрия",
        "slk": "Rakúsko",
        "spa": "Austria",
        "fin": "Itävalta",
        "est": "Austria",
        "zho": "奥地利",
        "pol": "Austria",
        "urd": "آسٹریا",
        "kor": "오스트리아"
      }
    },
    "AZ": {
      "currency": [
        "AZN"
      ],
      "callingCode": [
        "994"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-az",
      "name": {
        "common": "Azerbaijan",
        "ces": "Ázerbájdžán",
        "cym": "Aserbaijan",
        "deu": "Aserbaidschan",
        "fra": "Azerbaïdjan",
        "hrv": "Azerbajdžan",
        "ita": "Azerbaijan",
        "jpn": "アゼルバイジャン",
        "nld": "Azerbeidzjan",
        "por": "Azerbeijão",
        "rus": "Азербайджан",
        "slk": "AzerbajǇan",
        "spa": "Azerbaiyán",
        "fin": "Azerbaidzan",
        "est": "Aserbaidžaan",
        "zho": "阿塞拜疆",
        "pol": "Azerbejdżan",
        "urd": "آذربائیجان",
        "kor": "아제르바이잔"
      }
    },
    "BS": {
      "currency": [
        "BSD"
      ],
      "callingCode": [
        "1242"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-bs",
      "name": {
        "common": "Bahamas",
        "ces": "Bahamy",
        "cym": "Bahamas",
        "deu": "Bahamas",
        "fra": "Bahamas",
        "hrv": "Bahami",
        "ita": "Bahamas",
        "jpn": "バハマ",
        "nld": "Bahama’s",
        "por": "Bahamas",
        "rus": "Багамские Острова",
        "slk": "Bahamy",
        "spa": "Bahamas",
        "fin": "Bahamasaaret",
        "est": "Bahama",
        "zho": "巴哈马",
        "pol": "Bahamy",
        "urd": "بہاماس",
        "kor": "바하마"
      }
    },
    "BH": {
      "currency": [
        "BHD"
      ],
      "callingCode": [
        "973"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-bh",
      "name": {
        "common": "Bahrain",
        "ces": "Bahrajn",
        "cym": "Bahrain",
        "deu": "Bahrain",
        "fra": "Bahreïn",
        "hrv": "Bahrein",
        "ita": "Bahrein",
        "jpn": "バーレーン",
        "nld": "Bahrein",
        "por": "Bahrein",
        "rus": "Бахрейн",
        "slk": "Bahrajn",
        "spa": "Bahrein",
        "fin": "Bahrain",
        "est": "Bahrein",
        "zho": "巴林",
        "pol": "Bahrajn",
        "urd": "بحرین",
        "kor": "바레인"
      }
    },
    "BD": {
      "currency": [
        "BDT"
      ],
      "callingCode": [
        "880"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-bd",
      "name": {
        "common": "Bangladesh",
        "ces": "Bangladéš",
        "cym": "Bangladesh",
        "deu": "Bangladesch",
        "fra": "Bangladesh",
        "hrv": "Bangladeš",
        "ita": "Bangladesh",
        "jpn": "バングラデシュ",
        "nld": "Bangladesh",
        "por": "Bangladesh",
        "rus": "Бангладеш",
        "slk": "Bangladéš",
        "spa": "Bangladesh",
        "fin": "Bangladesh",
        "est": "Bangladesh",
        "zho": "孟加拉国",
        "pol": "Bangladesz",
        "urd": "بنگلہ دیش",
        "kor": "방글라데시"
      }
    },
    "BB": {
      "currency": [
        "BBD"
      ],
      "callingCode": [
        "1246"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-bb",
      "name": {
        "common": "Barbados",
        "ces": "Barbados",
        "cym": "Barbados",
        "deu": "Barbados",
        "fra": "Barbade",
        "hrv": "Barbados",
        "ita": "Barbados",
        "jpn": "バルバドス",
        "nld": "Barbados",
        "por": "Barbados",
        "rus": "Барбадос",
        "slk": "Barbados",
        "spa": "Barbados",
        "fin": "Barbados",
        "est": "Barbados",
        "zho": "巴巴多斯",
        "pol": "Barbados",
        "urd": "بارباڈوس",
        "kor": "바베이도스"
      }
    },
    "BY": {
      "currency": [
        "BYN"
      ],
      "callingCode": [
        "375"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-by",
      "name": {
        "common": "Belarus",
        "ces": "Bělorusko",
        "cym": "Belarws",
        "deu": "Weißrussland",
        "fra": "Biélorussie",
        "hrv": "Bjelorusija",
        "ita": "Bielorussia",
        "jpn": "ベラルーシ",
        "nld": "Wit-Rusland",
        "por": "Bielorússia",
        "rus": "Беларусь",
        "slk": "Bielorusko",
        "spa": "Bielorrusia",
        "fin": "Valko-Venäjä",
        "est": "Valgevene",
        "zho": "白俄罗斯",
        "pol": "Białoruś",
        "urd": "بیلاروس",
        "kor": "벨라루스"
      }
    },
    "BE": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "32"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-be",
      "name": {
        "common": "Belgium",
        "ces": "Belgie",
        "cym": "Gwlad Belg",
        "deu": "Belgien",
        "fra": "Belgique",
        "hrv": "Belgija",
        "ita": "Belgio",
        "jpn": "ベルギー",
        "nld": "België",
        "por": "Bélgica",
        "rus": "Бельгия",
        "slk": "Belgicko",
        "spa": "Bélgica",
        "fin": "Belgia",
        "est": "Belgia",
        "zho": "比利时",
        "pol": "Belgia",
        "urd": "بلجئیم",
        "kor": "벨기에"
      }
    },
    "BZ": {
      "currency": [
        "BZD"
      ],
      "callingCode": [
        "501"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "flag": "flag-bz",
      "name": {
        "common": "Belize",
        "ces": "Belize",
        "cym": "Belîs",
        "deu": "Belize",
        "fra": "Belize",
        "hrv": "Belize",
        "ita": "Belize",
        "jpn": "ベリーズ",
        "nld": "Belize",
        "por": "Belize",
        "rus": "Белиз",
        "slk": "Belize",
        "spa": "Belice",
        "fin": "Belize",
        "est": "Belize",
        "zho": "伯利兹",
        "pol": "Belize",
        "urd": "بیلیز",
        "kor": "벨리즈"
      }
    },
    "BJ": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "229"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-bj",
      "name": {
        "common": "Benin",
        "ces": "Benin",
        "cym": "Benin",
        "deu": "Benin",
        "fra": "Bénin",
        "hrv": "Benin",
        "ita": "Benin",
        "jpn": "ベナン",
        "nld": "Benin",
        "por": "Benin",
        "rus": "Бенин",
        "slk": "Benin",
        "spa": "Benín",
        "fin": "Benin",
        "est": "Benin",
        "zho": "贝宁",
        "pol": "Benin",
        "urd": "بینن",
        "kor": "베냉"
      }
    },
    "BM": {
      "currency": [
        "BMD"
      ],
      "callingCode": [
        "1441"
      ],
      "region": "Americas",
      "subregion": "North America",
      "flag": "flag-bm",
      "name": {
        "common": "Bermuda",
        "ces": "Bermudy",
        "cym": "Bermiwda",
        "deu": "Bermuda",
        "fra": "Bermudes",
        "hrv": "Bermudi",
        "ita": "Bermuda",
        "jpn": "バミューダ",
        "nld": "Bermuda",
        "por": "Bermudas",
        "rus": "Бермудские Острова",
        "slk": "Bermudy",
        "spa": "Bermudas",
        "fin": "Bermuda",
        "est": "Bermuda",
        "zho": "百慕大",
        "pol": "Bermudy",
        "urd": "برمودا",
        "kor": "버뮤다"
      }
    },
    "BT": {
      "currency": [
        "BTN",
        "INR"
      ],
      "callingCode": [
        "975"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-bt",
      "name": {
        "common": "Bhutan",
        "ces": "Bhútán",
        "cym": "Bhwtan",
        "deu": "Bhutan",
        "fra": "Bhoutan",
        "hrv": "Butan",
        "ita": "Bhutan",
        "jpn": "ブータン",
        "nld": "Bhutan",
        "por": "Butão",
        "rus": "Бутан",
        "slk": "Bhután",
        "spa": "Bután",
        "fin": "Bhutan",
        "est": "Bhutan",
        "zho": "不丹",
        "pol": "Bhutan",
        "urd": "بھوٹان",
        "kor": "부탄"
      }
    },
    "BO": {
      "currency": [
        "BOB"
      ],
      "callingCode": [
        "591"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-bo",
      "name": {
        "common": "Bolivia",
        "ces": "Bolívie",
        "cym": "Bolifia",
        "deu": "Bolivien",
        "fra": "Bolivie",
        "hrv": "Bolivija",
        "ita": "Bolivia",
        "jpn": "ボリビア多民族国",
        "nld": "Bolivia",
        "por": "Bolívia",
        "rus": "Боливия",
        "slk": "Bolívia",
        "spa": "Bolivia",
        "fin": "Bolivia",
        "est": "Boliivia",
        "zho": "玻利维亚",
        "pol": "Boliwia",
        "urd": "بولیویا",
        "kor": "볼리비아"
      }
    },
    "BA": {
      "currency": [
        "BAM"
      ],
      "callingCode": [
        "387"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-ba",
      "name": {
        "common": "Bosnia and Herzegovina",
        "ces": "Bosna a Hercegovina",
        "cym": "Bosnia a Hercegovina",
        "deu": "Bosnien und Herzegowina",
        "fra": "Bosnie-Herzégovine",
        "hrv": "Bosna i Hercegovina",
        "ita": "Bosnia ed Erzegovina",
        "jpn": "ボスニア・ヘルツェゴビナ",
        "nld": "Bosnië en Herzegovina",
        "por": "Bósnia e Herzegovina",
        "rus": "Босния и Герцеговина",
        "slk": "Bosna a Hercegovina",
        "spa": "Bosnia y Herzegovina",
        "fin": "Bosnia ja Hertsegovina",
        "est": "Bosnia ja Hertsegoviina",
        "zho": "波斯尼亚和黑塞哥维那",
        "pol": "Bośnia i Hercegowina",
        "urd": "بوسنیا و ہرزیگووینا",
        "kor": "보스니아 헤르체고비나"
      }
    },
    "BW": {
      "currency": [
        "BWP"
      ],
      "callingCode": [
        "267"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "flag": "flag-bw",
      "name": {
        "common": "Botswana",
        "ces": "Botswana",
        "deu": "Botswana",
        "fra": "Botswana",
        "hrv": "Bocvana",
        "ita": "Botswana",
        "jpn": "ボツワナ",
        "nld": "Botswana",
        "por": "Botswana",
        "rus": "Ботсвана",
        "slk": "Botswana",
        "spa": "Botswana",
        "fin": "Botswana",
        "est": "Botswana",
        "zho": "博茨瓦纳",
        "pol": "Botswana",
        "urd": "بوٹسوانا",
        "kor": "보츠와나"
      }
    },
    "BV": {
      "currency": [
        "NOK"
      ],
      "callingCode": [],
      "region": "Antarctic",
      "subregion": "",
      "flag": "flag-bv",
      "name": {
        "common": "Bouvet Island",
        "ces": "Bouvetův ostrov",
        "deu": "Bouvetinsel",
        "fra": "Île Bouvet",
        "hrv": "Otok Bouvet",
        "ita": "Isola Bouvet",
        "jpn": "ブーベ島",
        "nld": "Bouveteiland",
        "por": "Ilha Bouvet",
        "rus": "Остров Буве",
        "slk": "Bouvetov ostrov",
        "spa": "Isla Bouvet",
        "fin": "Bouvet'nsaari",
        "est": "Bouvet’ saar",
        "zho": "布维岛",
        "pol": "Wyspa Bouveta",
        "urd": "جزیرہ بووہ",
        "kor": "부베 섬"
      }
    },
    "BR": {
      "currency": [
        "BRL"
      ],
      "callingCode": [
        "55"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-br",
      "name": {
        "common": "Brazil",
        "ces": "Brazílie",
        "cym": "Brasil",
        "deu": "Brasilien",
        "fra": "Brésil",
        "hrv": "Brazil",
        "ita": "Brasile",
        "jpn": "ブラジル",
        "nld": "Brazilië",
        "por": "Brasil",
        "rus": "Бразилия",
        "slk": "Brazília",
        "spa": "Brasil",
        "fin": "Brasilia",
        "est": "Brasiilia",
        "zho": "巴西",
        "pol": "Brazylia",
        "urd": "برازیل",
        "kor": "브라질"
      }
    },
    "IO": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "246"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-io",
      "name": {
        "common": "British Indian Ocean Territory",
        "ces": "Britské indickooceánské území",
        "cym": "Tiriogaeth Brydeinig Cefnfor India",
        "deu": "Britisches Territorium im Indischen Ozean",
        "fra": "Territoire britannique de l'océan Indien",
        "hrv": "Britanski Indijskooceanski teritorij",
        "ita": "Territorio britannico dell'oceano indiano",
        "jpn": "イギリス領インド洋地域",
        "nld": "Britse Gebieden in de Indische Oceaan",
        "por": "Território Britânico do Oceano Índico",
        "rus": "Британская территория в Индийском океане",
        "slk": "Britské indickooceánske územie",
        "spa": "Territorio Británico del Océano Índico",
        "fin": "Brittiläinen Intian valtameren alue",
        "est": "Briti India ookeani ala",
        "zho": "英属印度洋领地",
        "pol": "Brytyjskie Terytorium Oceanu Indyjskiego",
        "urd": "برطانوی بحرہند خطہ",
        "kor": "인도"
      }
    },
    "VG": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1284"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-vg",
      "name": {
        "common": "British Virgin Islands",
        "ces": "Britské Panenské ostrovy",
        "deu": "Britische Jungferninseln",
        "fra": "Îles Vierges britanniques",
        "hrv": "Britanski Djevičanski Otoci",
        "ita": "Isole Vergini Britanniche",
        "jpn": "イギリス領ヴァージン諸島",
        "nld": "Britse Maagdeneilanden",
        "por": "Ilhas Virgens",
        "rus": "Британские Виргинские острова",
        "slk": "Panenské ostrovy",
        "spa": "Islas Vírgenes del Reino Unido",
        "fin": "Neitsytsaaret",
        "est": "Briti Neitsisaared",
        "zho": "英属维尔京群岛",
        "pol": "Brytyjskie Wyspy Dziewicze",
        "urd": "برطانوی جزائر ورجن",
        "kor": "영국령 버진아일랜드"
      }
    },
    "BN": {
      "currency": [
        "BND"
      ],
      "callingCode": [
        "673"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-bn",
      "name": {
        "common": "Brunei",
        "ces": "Brunej",
        "cym": "Brunei",
        "deu": "Brunei",
        "fra": "Brunei",
        "hrv": "Brunej",
        "ita": "Brunei",
        "jpn": "ブルネイ・ダルサラーム",
        "nld": "Brunei",
        "por": "Brunei",
        "rus": "Бруней",
        "slk": "Brunej",
        "spa": "Brunei",
        "fin": "Brunei",
        "est": "Brunei",
        "zho": "文莱",
        "pol": "Brunei",
        "urd": "برونائی",
        "kor": "브루나이"
      }
    },
    "BG": {
      "currency": [
        "BGN"
      ],
      "callingCode": [
        "359"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-bg",
      "name": {
        "common": "Bulgaria",
        "ces": "Bulharsko",
        "cym": "Bwlgaria",
        "deu": "Bulgarien",
        "fra": "Bulgarie",
        "hrv": "Bugarska",
        "ita": "Bulgaria",
        "jpn": "ブルガリア",
        "nld": "Bulgarije",
        "por": "Bulgária",
        "rus": "Болгария",
        "slk": "Bulharsko",
        "spa": "Bulgaria",
        "fin": "Bulgaria",
        "est": "Bulgaaria",
        "zho": "保加利亚",
        "pol": "Bułgaria",
        "urd": "بلغاریہ",
        "kor": "불가리아"
      }
    },
    "BF": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "226"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-bf",
      "name": {
        "common": "Burkina Faso",
        "ces": "Burkina Faso",
        "cym": "Bwrcina Ffaso",
        "deu": "Burkina Faso",
        "fra": "Burkina Faso",
        "hrv": "Burkina Faso",
        "ita": "Burkina Faso",
        "jpn": "ブルキナファソ",
        "nld": "Burkina Faso",
        "por": "Burkina Faso",
        "rus": "Буркина-Фасо",
        "slk": "Burkina Faso",
        "spa": "Burkina Faso",
        "fin": "Burkina Faso",
        "est": "Burkina Faso",
        "zho": "布基纳法索",
        "pol": "Burkina Faso",
        "urd": "برکینا فاسو",
        "kor": "부르키나파소"
      }
    },
    "BI": {
      "currency": [
        "BIF"
      ],
      "callingCode": [
        "257"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-bi",
      "name": {
        "common": "Burundi",
        "ces": "Burundi",
        "cym": "Bwrwndi",
        "deu": "Burundi",
        "fra": "Burundi",
        "hrv": "Burundi",
        "ita": "Burundi",
        "jpn": "ブルンジ",
        "nld": "Burundi",
        "por": "Burundi",
        "rus": "Бурунди",
        "slk": "Burundi",
        "spa": "Burundi",
        "fin": "Burundi",
        "est": "Burundi",
        "zho": "布隆迪",
        "pol": "Burundi",
        "urd": "برونڈی",
        "kor": "부룬디"
      }
    },
    "KH": {
      "currency": [
        "KHR"
      ],
      "callingCode": [
        "855"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-kh",
      "name": {
        "common": "Cambodia",
        "ces": "Kambodža",
        "cym": "Cambodia",
        "deu": "Kambodscha",
        "fra": "Cambodge",
        "hrv": "Kambodža",
        "ita": "Cambogia",
        "jpn": "カンボジア",
        "nld": "Cambodja",
        "por": "Camboja",
        "rus": "Камбоджа",
        "slk": "Kamboǆa",
        "spa": "Camboya",
        "fin": "Kambodža",
        "est": "Kambodža",
        "zho": "柬埔寨",
        "pol": "Kambodża",
        "urd": "کمبوڈیا",
        "kor": "캄보디아"
      }
    },
    "CM": {
      "currency": [
        "XAF"
      ],
      "callingCode": [
        "237"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-cm",
      "name": {
        "common": "Cameroon",
        "ces": "Kamerun",
        "cym": "Camerŵn",
        "deu": "Kamerun",
        "fra": "Cameroun",
        "hrv": "Kamerun",
        "ita": "Camerun",
        "jpn": "カメルーン",
        "nld": "Kameroen",
        "por": "Camarões",
        "rus": "Камерун",
        "slk": "Kamerun",
        "spa": "Camerún",
        "fin": "Kamerun",
        "est": "Kamerun",
        "zho": "喀麦隆",
        "pol": "WybrzeŻe Kości Słoniowej",
        "urd": "کیمرون",
        "kor": "카메룬"
      }
    },
    "CA": {
      "currency": [
        "CAD"
      ],
      "callingCode": [
        "1"
      ],
      "region": "Americas",
      "subregion": "North America",
      "flag": "flag-ca",
      "name": {
        "common": "Canada",
        "ces": "Kanada",
        "cym": "Canada",
        "deu": "Kanada",
        "fra": "Canada",
        "hrv": "Kanada",
        "ita": "Canada",
        "jpn": "カナダ",
        "nld": "Canada",
        "por": "Canadá",
        "rus": "Канада",
        "slk": "Kanada",
        "spa": "Canadá",
        "fin": "Kanada",
        "est": "Kanada",
        "zho": "加拿大",
        "pol": "Kanada",
        "urd": "کینیڈا",
        "kor": "캐나다"
      }
    },
    "CV": {
      "currency": [
        "CVE"
      ],
      "callingCode": [
        "238"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-cv",
      "name": {
        "common": "Cape Verde",
        "ces": "Kapverdy",
        "cym": "Penrhyn Verde",
        "deu": "Kap Verde",
        "fra": "Îles du Cap-Vert",
        "hrv": "Zelenortska Republika",
        "ita": "Capo Verde",
        "jpn": "カーボベルデ",
        "nld": "Kaapverdië",
        "por": "Cabo Verde",
        "rus": "Кабо-Верде",
        "slk": "Kapverdy",
        "spa": "Cabo Verde",
        "fin": "Kap Verde",
        "est": "Roheneemesaared",
        "zho": "佛得角",
        "pol": "Republika Zielonego Przylądka",
        "urd": "کیپ ورڈی",
        "kor": "카보베르데"
      }
    },
    "BQ": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "599"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-bq",
      "name": {
        "common": "Caribbean Netherlands",
        "ces": "Karibské Nizozemsko",
        "deu": "Karibische Niederlande",
        "fra": "Pays-Bas caribéens",
        "hrv": "Bonaire, Sint Eustatius i Saba",
        "ita": "Paesi Bassi caraibici",
        "jpn": "ボネール、シント・ユースタティウスおよびサバ",
        "nld": "Caribisch Nederland",
        "por": "Países Baixos Caribenhos",
        "rus": "Карибские Нидерланды",
        "slk": "Bonaire, Sint Eustatius a Saba",
        "spa": "Caribe Neerlandés",
        "fin": "Bonaire, Sint Eustatius ja Saba",
        "est": "Bonaire, Sint Eustatius ja Saba",
        "zho": "荷蘭加勒比區",
        "pol": "Antyle Holenderskie",
        "urd": "کیریبین نیدرلینڈز",
        "kor": "카리브 네덜란드"
      }
    },
    "KY": {
      "currency": [
        "KYD"
      ],
      "callingCode": [
        "1345"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-ky",
      "name": {
        "common": "Cayman Islands",
        "ces": "Kajmanské ostrovy",
        "cym": "Ynysoedd Cayman",
        "deu": "Kaimaninseln",
        "fra": "Îles Caïmans",
        "hrv": "Kajmanski otoci",
        "ita": "Isole Cayman",
        "jpn": "ケイマン諸島",
        "nld": "Caymaneilanden",
        "por": "Ilhas Caimão",
        "rus": "Каймановы острова",
        "slk": "Kajmanie ostrovy",
        "spa": "Islas Caimán",
        "fin": "Caymansaaret",
        "est": "Kaimanisaared",
        "zho": "开曼群岛",
        "pol": "Kajmany",
        "urd": "جزائر کیمین",
        "kor": "케이맨 제도"
      }
    },
    "CF": {
      "currency": [
        "XAF"
      ],
      "callingCode": [
        "236"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-cf",
      "name": {
        "common": "Central African Republic",
        "ces": "Středoafrická republika",
        "cym": "Gweriniaeth Canolbarth Affrica",
        "deu": "Zentralafrikanische Republik",
        "fra": "République centrafricaine",
        "hrv": "Srednjoafrička Republika",
        "ita": "Repubblica Centrafricana",
        "jpn": "中央アフリカ共和国",
        "nld": "Centraal-Afrikaanse Republiek",
        "por": "República Centro-Africana",
        "rus": "Центральноафриканская Республика",
        "slk": "Stredoafrická republika",
        "spa": "República Centroafricana",
        "fin": "Keski-Afrikan tasavalta",
        "est": "Kesk-Aafrika Vabariik",
        "zho": "中非共和国",
        "pol": "Republika Środkowoafrykańska",
        "urd": "وسطی افریقی جمہوریہ",
        "kor": "중앙아프리카 공화국"
      }
    },
    "TD": {
      "currency": [
        "XAF"
      ],
      "callingCode": [
        "235"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-td",
      "name": {
        "common": "Chad",
        "ces": "Čad",
        "cym": "Tsiad",
        "deu": "Tschad",
        "fra": "Tchad",
        "hrv": "Čad",
        "ita": "Ciad",
        "jpn": "チャド",
        "nld": "Tsjaad",
        "por": "Chade",
        "rus": "Чад",
        "slk": "Čad",
        "spa": "Chad",
        "fin": "Tšad",
        "est": "Tšaad",
        "zho": "乍得",
        "pol": "Czad",
        "urd": "چاڈ",
        "kor": "차드"
      }
    },
    "CL": {
      "currency": [
        "CLP"
      ],
      "callingCode": [
        "56"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-cl",
      "name": {
        "common": "Chile",
        "ces": "Chile",
        "cym": "Chile",
        "deu": "Chile",
        "fra": "Chili",
        "hrv": "Čile",
        "ita": "Cile",
        "jpn": "チリ",
        "nld": "Chili",
        "por": "Chile",
        "rus": "Чили",
        "slk": "Čile",
        "spa": "Chile",
        "fin": "Chile",
        "est": "Tšiili",
        "zho": "智利",
        "pol": "Chile",
        "urd": "چلی",
        "kor": "칠레"
      }
    },
    "CN": {
      "currency": [
        "CNY"
      ],
      "callingCode": [
        "86"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-cn",
      "name": {
        "common": "China",
        "ces": "Čína",
        "cym": "Tsieina",
        "deu": "China",
        "fra": "Chine",
        "hrv": "Kina",
        "ita": "Cina",
        "jpn": "中国",
        "nld": "China",
        "por": "China",
        "rus": "Китай",
        "slk": "Čína",
        "spa": "China",
        "fin": "Kiina",
        "est": "Hiina",
        "pol": "Chiny",
        "urd": "چین",
        "kor": "중국"
      }
    },
    "CX": {
      "currency": [
        "AUD"
      ],
      "callingCode": [
        "61"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "flag": "flag-cx",
      "name": {
        "common": "Christmas Island",
        "ces": "Vánoční ostrov",
        "cym": "Ynys y Nadolig",
        "deu": "Weihnachtsinsel",
        "fra": "Île Christmas",
        "hrv": "Božićni otok",
        "ita": "Isola di Natale",
        "jpn": "クリスマス島",
        "nld": "Christmaseiland",
        "por": "Ilha do Natal",
        "rus": "Остров Рождества",
        "slk": "Vianočnú ostrov",
        "spa": "Isla de Navidad",
        "fin": "Joulusaari",
        "est": "Jõulusaar",
        "zho": "圣诞岛",
        "pol": "Wyspa Bożego Narodzenia",
        "urd": "جزیرہ کرسمس",
        "kor": "크리스마스 섬"
      }
    },
    "CC": {
      "currency": [
        "AUD"
      ],
      "callingCode": [
        "61"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "flag": "flag-cc",
      "name": {
        "common": "Cocos (Keeling) Islands",
        "ces": "Kokosové ostrovy",
        "cym": "Ynysoedd Cocos",
        "deu": "Kokosinseln",
        "fra": "Îles Cocos",
        "hrv": "Kokosovi Otoci",
        "ita": "Isole Cocos e Keeling",
        "jpn": "ココス（キーリング）諸島",
        "nld": "Cocoseilanden",
        "por": "Ilhas Cocos (Keeling)",
        "rus": "Кокосовые острова",
        "slk": "Kokosové ostrovy",
        "spa": "Islas Cocos o Islas Keeling",
        "fin": "Kookossaaret",
        "est": "Kookossaared",
        "zho": "科科斯",
        "pol": "Wyspy Kokosowe",
        "urd": "جزائر کوکوس",
        "kor": "코코스 제도"
      }
    },
    "CO": {
      "currency": [
        "COP"
      ],
      "callingCode": [
        "57"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-co",
      "name": {
        "common": "Colombia",
        "ces": "Kolumbie",
        "cym": "Colombia",
        "deu": "Kolumbien",
        "fra": "Colombie",
        "hrv": "Kolumbija",
        "ita": "Colombia",
        "jpn": "コロンビア",
        "nld": "Colombia",
        "por": "Colômbia",
        "rus": "Колумбия",
        "slk": "Kolumbia",
        "spa": "Colombia",
        "fin": "Kolumbia",
        "est": "Colombia",
        "zho": "哥伦比亚",
        "pol": "Kolumbia",
        "urd": "کولمبیا",
        "kor": "콜롬비아"
      }
    },
    "KM": {
      "currency": [
        "KMF"
      ],
      "callingCode": [
        "269"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-km",
      "name": {
        "common": "Comoros",
        "ces": "Komory",
        "cym": "Y Comoros",
        "deu": "Komoren",
        "fra": "Comores",
        "hrv": "Komori",
        "ita": "Comore",
        "jpn": "コモロ",
        "nld": "Comoren",
        "por": "Comores",
        "rus": "Коморы",
        "slk": "Komory",
        "spa": "Comoras",
        "fin": "Komorit",
        "est": "Komoorid",
        "zho": "科摩罗",
        "pol": "Komory",
        "urd": "القمری",
        "kor": "코모로"
      }
    },
    "CK": {
      "currency": [
        "NZD",
        "CKD"
      ],
      "callingCode": [
        "682"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-ck",
      "name": {
        "common": "Cook Islands",
        "ces": "Cookovy ostrovy",
        "cym": "Ynysoedd Cook",
        "deu": "Cookinseln",
        "fra": "Îles Cook",
        "hrv": "Cookovo Otočje",
        "ita": "Isole Cook",
        "jpn": "クック諸島",
        "nld": "Cookeilanden",
        "por": "Ilhas Cook",
        "rus": "Острова Кука",
        "slk": "Cookove ostrovy",
        "spa": "Islas Cook",
        "fin": "Cookinsaaret",
        "est": "Cooki saared",
        "zho": "库克群岛",
        "pol": "Wyspy Cooka",
        "urd": "جزائر کک",
        "kor": "쿡 제도"
      }
    },
    "CR": {
      "currency": [
        "CRC"
      ],
      "callingCode": [
        "506"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "flag": "flag-cr",
      "name": {
        "common": "Costa Rica",
        "ces": "Kostarika",
        "cym": "Costa Rica",
        "deu": "Costa Rica",
        "fra": "Costa Rica",
        "hrv": "Kostarika",
        "ita": "Costa Rica",
        "jpn": "コスタリカ",
        "nld": "Costa Rica",
        "por": "Costa Rica",
        "rus": "Коста-Рика",
        "slk": "Kostarika",
        "spa": "Costa Rica",
        "fin": "Costa Rica",
        "est": "Costa Rica",
        "zho": "哥斯达黎加",
        "pol": "Kostaryka",
        "urd": "کوسٹاریکا",
        "kor": "코스타리카"
      }
    },
    "HR": {
      "currency": [
        "HRK"
      ],
      "callingCode": [
        "385"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-hr",
      "name": {
        "common": "Croatia",
        "ces": "Chorvatsko",
        "cym": "Croatia",
        "deu": "Kroatien",
        "fra": "Croatie",
        "hrv": "Hrvatska",
        "ita": "Croazia",
        "jpn": "クロアチア",
        "nld": "Kroatië",
        "por": "Croácia",
        "rus": "Хорватия",
        "slk": "Chorvátsko",
        "spa": "Croacia",
        "fin": "Kroatia",
        "est": "Horvaatia",
        "zho": "克罗地亚",
        "pol": "Chorwacja",
        "urd": "کرویئشا",
        "kor": "크로아티아"
      }
    },
    "CU": {
      "currency": [
        "CUC",
        "CUP"
      ],
      "callingCode": [
        "53"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-cu",
      "name": {
        "common": "Cuba",
        "ces": "Kuba",
        "cym": "Ciwba",
        "deu": "Kuba",
        "fra": "Cuba",
        "hrv": "Kuba",
        "ita": "Cuba",
        "jpn": "キューバ",
        "nld": "Cuba",
        "por": "Cuba",
        "rus": "Куба",
        "slk": "Kuba",
        "spa": "Cuba",
        "fin": "Kuuba",
        "est": "Kuuba",
        "zho": "古巴",
        "pol": "Kuba",
        "urd": "کیوبا",
        "kor": "쿠바"
      }
    },
    "CW": {
      "currency": [
        "ANG"
      ],
      "callingCode": [
        "5999"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-cw",
      "name": {
        "common": "Curaçao",
        "ces": "Curaçao",
        "deu": "Curaçao",
        "fra": "Curaçao",
        "nld": "Curaçao",
        "por": "ilha da Curação",
        "rus": "Кюрасао",
        "slk": "Curacao",
        "spa": "Curazao",
        "fin": "Curaçao",
        "est": "Curaçao",
        "zho": "库拉索",
        "pol": "Curaçao",
        "urd": "کیوراساؤ",
        "kor": "퀴라소"
      }
    },
    "CY": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "357"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-cy",
      "name": {
        "common": "Cyprus",
        "ces": "Kypr",
        "cym": "Cyprus",
        "deu": "Zypern",
        "fra": "Chypre",
        "hrv": "Cipar",
        "ita": "Cipro",
        "jpn": "キプロス",
        "nld": "Cyprus",
        "por": "Chipre",
        "rus": "Кипр",
        "slk": "Cyprus",
        "spa": "Chipre",
        "fin": "Kypros",
        "est": "Küpros",
        "zho": "塞浦路斯",
        "pol": "Cypr",
        "urd": "قبرص",
        "kor": "키프로스"
      }
    },
    "CZ": {
      "currency": [
        "CZK"
      ],
      "callingCode": [
        "420"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-cz",
      "name": {
        "common": "Czechia",
        "ces": "Česko",
        "cym": "Y Weriniaeth Tsiec",
        "deu": "Tschechien",
        "fra": "Tchéquie",
        "hrv": "Češka",
        "ita": "Cechia",
        "jpn": "チェコ",
        "nld": "Tsjechië",
        "por": "Chéquia",
        "rus": "Чехия",
        "slk": "Česko",
        "spa": "Chequia",
        "fin": "Tšekki",
        "est": "Tšehhi",
        "zho": "捷克",
        "pol": "Czechy",
        "urd": "چيک",
        "kor": "체코"
      }
    },
    "CD": {
      "currency": [
        "CDF"
      ],
      "callingCode": [
        "243"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-cd",
      "name": {
        "common": "DR Congo",
        "ces": "DR Kongo",
        "cym": "Gweriniaeth Ddemocrataidd Congo",
        "deu": "Kongo (Dem. Rep.)",
        "fra": "Congo (Rép. dém.)",
        "hrv": "Kongo, Demokratska Republika",
        "ita": "Congo (Rep. Dem.)",
        "jpn": "コンゴ民主共和国",
        "nld": "Congo (DRC)",
        "por": "República Democrática do Congo",
        "rus": "Демократическая Республика Конго",
        "slk": "Kongo",
        "spa": "Congo (Rep. Dem.)",
        "fin": "Kongon demokraattinen tasavalta",
        "est": "Kongo DV",
        "zho": "民主刚果",
        "pol": "Demokratyczna Republika Konga",
        "urd": "\nکانگو",
        "kor": "콩고 민주 공화국"
      }
    },
    "DK": {
      "currency": [
        "DKK"
      ],
      "callingCode": [
        "45"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-dk",
      "name": {
        "common": "Denmark",
        "ces": "Dánsko",
        "cym": "Denmarc",
        "deu": "Dänemark",
        "fra": "Danemark",
        "hrv": "Danska",
        "ita": "Danimarca",
        "jpn": "デンマーク",
        "nld": "Denemarken",
        "por": "Dinamarca",
        "rus": "Дания",
        "slk": "Dánsko",
        "spa": "Dinamarca",
        "fin": "Tanska",
        "est": "Taani",
        "zho": "丹麦",
        "pol": "Dania",
        "urd": "ڈنمارک",
        "kor": "덴마크"
      }
    },
    "DJ": {
      "currency": [
        "DJF"
      ],
      "callingCode": [
        "253"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-dj",
      "name": {
        "common": "Djibouti",
        "ces": "Džibutsko",
        "cym": "Jibwti",
        "deu": "Dschibuti",
        "fra": "Djibouti",
        "hrv": "Džibuti",
        "ita": "Gibuti",
        "jpn": "ジブチ",
        "nld": "Djibouti",
        "por": "Djibouti",
        "rus": "Джибути",
        "slk": "ǅibutsko",
        "spa": "Djibouti",
        "fin": "Dijibouti",
        "est": "Djibouti",
        "zho": "吉布提",
        "pol": "Dżibuti",
        "urd": "جبوتی",
        "kor": "지부티"
      }
    },
    "DM": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1767"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-dm",
      "name": {
        "common": "Dominica",
        "ces": "Dominika",
        "cym": "Dominica",
        "deu": "Dominica",
        "fra": "Dominique",
        "hrv": "Dominika",
        "ita": "Dominica",
        "jpn": "ドミニカ国",
        "nld": "Dominica",
        "por": "Dominica",
        "rus": "Доминика",
        "slk": "Dominika",
        "spa": "Dominica",
        "fin": "Dominica",
        "est": "Dominica",
        "zho": "多米尼加",
        "pol": "Dominika",
        "urd": "ڈومینیکا",
        "kor": "도미니카 공화국"
      }
    },
    "DO": {
      "currency": [
        "DOP"
      ],
      "callingCode": [
        "1809",
        "1829",
        "1849"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-do",
      "name": {
        "common": "Dominican Republic",
        "ces": "Dominikánská republika",
        "cym": "Gweriniaeth Dominica",
        "deu": "Dominikanische Republik",
        "fra": "République dominicaine",
        "hrv": "Dominikanska Republika",
        "ita": "Repubblica Dominicana",
        "jpn": "ドミニカ共和国",
        "nld": "Dominicaanse Republiek",
        "por": "República Dominicana",
        "rus": "Доминиканская Республика",
        "slk": "Dominikánska republika",
        "spa": "República Dominicana",
        "fin": "Dominikaaninen tasavalta",
        "est": "Dominikaani Vabariik",
        "zho": "多明尼加",
        "pol": "Dominikana",
        "urd": "ڈومینیکن",
        "kor": "도미니카 공화국"
      }
    },
    "EC": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "593"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-ec",
      "name": {
        "common": "Ecuador",
        "ces": "Ekvádor",
        "cym": "Ecwador",
        "deu": "Ecuador",
        "fra": "Équateur",
        "hrv": "Ekvador",
        "ita": "Ecuador",
        "jpn": "エクアドル",
        "nld": "Ecuador",
        "por": "Equador",
        "rus": "Эквадор",
        "slk": "Ekvádor",
        "spa": "Ecuador",
        "fin": "Ecuador",
        "est": "Ecuador",
        "zho": "厄瓜多尔",
        "pol": "Ekwador",
        "urd": "ایکواڈور",
        "kor": "에콰도르"
      }
    },
    "EG": {
      "currency": [
        "EGP"
      ],
      "callingCode": [
        "20"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "flag": "flag-eg",
      "name": {
        "common": "Egypt",
        "ces": "Egypt",
        "cym": "Yr Aifft",
        "deu": "Ägypten",
        "fra": "Égypte",
        "hrv": "Egipat",
        "ita": "Egitto",
        "jpn": "エジプト",
        "nld": "Egypte",
        "por": "Egito",
        "rus": "Египет",
        "slk": "Egypt",
        "spa": "Egipto",
        "fin": "Egypti",
        "est": "Egiptus",
        "zho": "埃及",
        "pol": "Egipt",
        "urd": "مصر",
        "kor": "이집트"
      }
    },
    "SV": {
      "currency": [
        "SVC",
        "USD"
      ],
      "callingCode": [
        "503"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "flag": "flag-sv",
      "name": {
        "common": "El Salvador",
        "ces": "Salvador",
        "cym": "El Salfador",
        "deu": "El Salvador",
        "fra": "Salvador",
        "hrv": "Salvador",
        "ita": "El Salvador",
        "jpn": "エルサルバドル",
        "nld": "El Salvador",
        "por": "El Salvador",
        "rus": "Сальвадор",
        "slk": "Salvádor",
        "spa": "El Salvador",
        "fin": "El Salvador",
        "est": "El Salvador",
        "zho": "萨尔瓦多",
        "pol": "Salwador",
        "urd": "ایل سیلواڈور",
        "kor": "엘살바도르"
      }
    },
    "GQ": {
      "currency": [
        "XAF"
      ],
      "callingCode": [
        "240"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-gq",
      "name": {
        "common": "Equatorial Guinea",
        "ces": "Rovníková Guinea",
        "cym": "Gini Gyhydeddol",
        "deu": "Äquatorialguinea",
        "fra": "Guinée équatoriale",
        "hrv": "Ekvatorijalna Gvineja",
        "ita": "Guinea Equatoriale",
        "jpn": "赤道ギニア",
        "nld": "Equatoriaal-Guinea",
        "por": "Guiné Equatorial",
        "rus": "Экваториальная Гвинея",
        "slk": "Rovníková Guinea",
        "spa": "Guinea Ecuatorial",
        "fin": "Päiväntasaajan Guinea",
        "est": "Ekvatoriaal-Guinea",
        "zho": "赤道几内亚",
        "pol": "Gwinea Równikowa",
        "urd": "استوائی گنی",
        "kor": "적도 기니"
      }
    },
    "ER": {
      "currency": [
        "ERN"
      ],
      "callingCode": [
        "291"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-er",
      "name": {
        "common": "Eritrea",
        "ces": "Eritrea",
        "cym": "Eritrea",
        "deu": "Eritrea",
        "fra": "Érythrée",
        "hrv": "Eritreja",
        "ita": "Eritrea",
        "jpn": "エリトリア",
        "nld": "Eritrea",
        "por": "Eritreia",
        "rus": "Эритрея",
        "slk": "Eritrea",
        "spa": "Eritrea",
        "fin": "Eritrea",
        "est": "Eritrea",
        "zho": "厄立特里亚",
        "pol": "Erytrea",
        "urd": "ارتریا",
        "kor": "에리트레아"
      }
    },
    "EE": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "372"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-ee",
      "name": {
        "common": "Estonia",
        "ces": "Estonsko",
        "cym": "Estonia",
        "deu": "Estland",
        "fra": "Estonie",
        "hrv": "Estonija",
        "ita": "Estonia",
        "jpn": "エストニア",
        "nld": "Estland",
        "por": "Estónia",
        "rus": "Эстония",
        "slk": "Estónsko",
        "spa": "Estonia",
        "fin": "Viro",
        "est": "Eesti",
        "zho": "爱沙尼亚",
        "pol": "Estonia",
        "urd": "اسٹونیا",
        "kor": "에스토니아"
      }
    },
    "SZ": {
      "currency": [
        "SZL"
      ],
      "callingCode": [
        "268"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "flag": "flag-sz",
      "name": {
        "common": "Eswatini",
        "ces": "Svazijsko",
        "deu": "Swasiland",
        "fra": "Swaziland",
        "hrv": "Svazi",
        "ita": "Swaziland",
        "jpn": "スワジランド",
        "nld": "Swaziland",
        "por": "Suazilândia",
        "rus": "Свазиленд",
        "slk": "Svazijsko",
        "spa": "Suazilandia",
        "fin": "Swazimaa",
        "est": "Svaasimaa",
        "pol": "Suazi",
        "zho": "斯威士兰",
        "urd": "سوازی لینڈ",
        "kor": "에스와티니"
      }
    },
    "ET": {
      "currency": [
        "ETB"
      ],
      "callingCode": [
        "251"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-et",
      "name": {
        "common": "Ethiopia",
        "ces": "Etiopie",
        "cym": "Ethiopia",
        "deu": "Äthiopien",
        "fra": "Éthiopie",
        "hrv": "Etiopija",
        "ita": "Etiopia",
        "jpn": "エチオピア",
        "nld": "Ethiopië",
        "por": "Etiópia",
        "rus": "Эфиопия",
        "slk": "Etiópia",
        "spa": "Etiopía",
        "fin": "Etiopia",
        "est": "Etioopia",
        "zho": "埃塞俄比亚",
        "pol": "Etiopia",
        "urd": "ایتھوپیا",
        "kor": "에티오피아"
      }
    },
    "FK": {
      "currency": [
        "FKP"
      ],
      "callingCode": [
        "500"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-fk",
      "name": {
        "common": "Falkland Islands",
        "ces": "Falklandy",
        "deu": "Falklandinseln",
        "fra": "Îles Malouines",
        "hrv": "Falklandski Otoci",
        "ita": "Isole Falkland o Isole Malvine",
        "jpn": "フォークランド（マルビナス）諸島",
        "nld": "Falklandeilanden",
        "por": "Ilhas Malvinas",
        "rus": "Фолклендские острова",
        "slk": "Falklandy",
        "spa": "Islas Malvinas",
        "fin": "Falkandinsaaret",
        "est": "Falklandi saared",
        "zho": "福克兰群岛",
        "pol": "Falklandy",
        "urd": "جزائر فاکلینڈ",
        "kor": "포클랜드 제도"
      }
    },
    "FO": {
      "currency": [
        "DKK"
      ],
      "callingCode": [
        "298"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-fo",
      "name": {
        "common": "Faroe Islands",
        "ces": "Faerské ostrovy",
        "deu": "Färöer-Inseln",
        "fra": "Îles Féroé",
        "hrv": "Farski Otoci",
        "ita": "Isole Far Oer",
        "jpn": "フェロー諸島",
        "nld": "Faeröer",
        "por": "Ilhas Faroé",
        "rus": "Фарерские острова",
        "slk": "Faerské ostrovy",
        "spa": "Islas Faroe",
        "fin": "Färsaaret",
        "est": "Fääri saared",
        "zho": "法罗群岛",
        "pol": "Wyspy Owcze",
        "urd": "جزائر فارو",
        "kor": "페로 제도"
      }
    },
    "FJ": {
      "currency": [
        "FJD"
      ],
      "callingCode": [
        "679"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "flag": "flag-fj",
      "name": {
        "common": "Fiji",
        "ces": "Fidži",
        "deu": "Fidschi",
        "fra": "Fidji",
        "hrv": "Fiđi",
        "ita": "Figi",
        "jpn": "フィジー",
        "nld": "Fiji",
        "por": "Fiji",
        "rus": "Фиджи",
        "slk": "Fiǆi",
        "spa": "Fiyi",
        "fin": "Fidži",
        "est": "Fidži",
        "zho": "斐济",
        "pol": "Fidżi",
        "urd": "فجی",
        "kor": "피지"
      }
    },
    "FI": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "358"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-fi",
      "name": {
        "common": "Finland",
        "ces": "Finsko",
        "deu": "Finnland",
        "fra": "Finlande",
        "hrv": "Finska",
        "ita": "Finlandia",
        "jpn": "フィンランド",
        "nld": "Finland",
        "por": "Finlândia",
        "rus": "Финляндия",
        "slk": "Fínsko",
        "spa": "Finlandia",
        "fin": "Suomi",
        "est": "Soome",
        "zho": "芬兰",
        "pol": "Finlandia",
        "urd": "فن لینڈ",
        "kor": "핀란드"
      }
    },
    "FR": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "33"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-fr",
      "name": {
        "common": "France",
        "ces": "Francie",
        "deu": "Frankreich",
        "fra": "France",
        "hrv": "Francuska",
        "ita": "Francia",
        "jpn": "フランス",
        "nld": "Frankrijk",
        "por": "França",
        "rus": "Франция",
        "slk": "Francúzsko",
        "spa": "Francia",
        "fin": "Ranska",
        "est": "Prantsusmaa",
        "zho": "法国",
        "pol": "Francja",
        "urd": "فرانس",
        "kor": "프랑스"
      }
    },
    "GF": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "594"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-gf",
      "name": {
        "common": "French Guiana",
        "ces": "Francouzská Guyana",
        "deu": "Französisch-Guayana",
        "fra": "Guyane",
        "hrv": "Francuska Gvajana",
        "ita": "Guyana francese",
        "jpn": "フランス領ギアナ",
        "nld": "Frans-Guyana",
        "por": "Guiana Francesa",
        "rus": "Французская Гвиана",
        "slk": "Guyana",
        "spa": "Guayana Francesa",
        "fin": "Ranskan Guayana",
        "est": "Prantsuse Guajaana",
        "zho": "法属圭亚那",
        "pol": "Gujana Francuska",
        "urd": "فرانسیسی گیانا",
        "kor": "프랑스령 기아나"
      }
    },
    "PF": {
      "currency": [
        "XPF"
      ],
      "callingCode": [
        "689"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-pf",
      "name": {
        "common": "French Polynesia",
        "ces": "Francouzská Polynésie",
        "deu": "Französisch-Polynesien",
        "fra": "Polynésie française",
        "hrv": "Francuska Polinezija",
        "ita": "Polinesia Francese",
        "jpn": "フランス領ポリネシア",
        "nld": "Frans-Polynesië",
        "por": "Polinésia Francesa",
        "rus": "Французская Полинезия",
        "slk": "Francúzska Polynézia",
        "spa": "Polinesia Francesa",
        "fin": "Ranskan Polynesia",
        "est": "Prantsuse Polüneesia",
        "zho": "法属波利尼西亚",
        "pol": "Polinezja Francuska",
        "urd": "فرانسیسی پولینیشیا",
        "kor": "프랑스령 폴리네시아"
      }
    },
    "TF": {
      "currency": [
        "EUR"
      ],
      "callingCode": [],
      "region": "Antarctic",
      "subregion": "",
      "flag": "flag-tf",
      "name": {
        "common": "French Southern and Antarctic Lands",
        "ces": "Francouzská jižní a antarktická území",
        "deu": "Französische Süd- und Antarktisgebiete",
        "fra": "Terres australes et antarctiques françaises",
        "hrv": "Francuski južni i antarktički teritoriji",
        "ita": "Territori Francesi del Sud",
        "jpn": "フランス領南方・南極地域",
        "nld": "Franse Gebieden in de zuidelijke Indische Oceaan",
        "por": "Terras Austrais e Antárticas Francesas",
        "rus": "Французские Южные и Антарктические территории",
        "slk": "Francúzske juŽné a antarktické územia",
        "spa": "Tierras Australes y Antárticas Francesas",
        "fin": "Ranskan eteläiset ja antarktiset alueet",
        "est": "Prantsuse Lõunaalad",
        "zho": "法国南部和南极土地",
        "pol": "Francuskie Terytoria Południowe i Antarktyczne",
        "urd": "سرزمین جنوبی فرانسیسیہ و انٹارکٹیکا",
        "kor": "프랑스령 남부와 남극 지역"
      }
    },
    "GA": {
      "currency": [
        "XAF"
      ],
      "callingCode": [
        "241"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-ga",
      "name": {
        "common": "Gabon",
        "ces": "Gabon",
        "deu": "Gabun",
        "fra": "Gabon",
        "hrv": "Gabon",
        "ita": "Gabon",
        "jpn": "ガボン",
        "nld": "Gabon",
        "por": "Gabão",
        "rus": "Габон",
        "slk": "Gabon",
        "spa": "Gabón",
        "fin": "Gabon",
        "est": "Gabon",
        "zho": "加蓬",
        "pol": "Gabon",
        "urd": "گیبون",
        "kor": "가봉"
      }
    },
    "GM": {
      "currency": [
        "GMD"
      ],
      "callingCode": [
        "220"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-gm",
      "name": {
        "common": "Gambia",
        "ces": "Gambie",
        "deu": "Gambia",
        "fra": "Gambie",
        "hrv": "Gambija",
        "ita": "Gambia",
        "jpn": "ガンビア",
        "nld": "Gambia",
        "por": "Gâmbia",
        "rus": "Гамбия",
        "slk": "Gambia",
        "spa": "Gambia",
        "fin": "Gambia",
        "est": "Gambia",
        "zho": "冈比亚",
        "pol": "Gambia",
        "urd": "گیمبیا",
        "kor": "감비아"
      }
    },
    "GE": {
      "currency": [
        "GEL"
      ],
      "callingCode": [
        "995"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-ge",
      "name": {
        "common": "Georgia",
        "ces": "Gruzie",
        "deu": "Georgien",
        "fra": "Géorgie",
        "hrv": "Gruzija",
        "ita": "Georgia",
        "jpn": "グルジア",
        "nld": "Georgië",
        "por": "Geórgia",
        "rus": "Грузия",
        "slk": "Gruzínsko",
        "spa": "Georgia",
        "fin": "Georgia",
        "est": "Gruusia",
        "zho": "格鲁吉亚",
        "pol": "Gruzja",
        "urd": "جارجیا",
        "kor": "조지아"
      }
    },
    "DE": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "49"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-de",
      "name": {
        "common": "Germany",
        "ces": "Německo",
        "deu": "Deutschland",
        "fra": "Allemagne",
        "hrv": "Njemačka",
        "ita": "Germania",
        "jpn": "ドイツ",
        "nld": "Duitsland",
        "por": "Alemanha",
        "rus": "Германия",
        "slk": "Nemecko",
        "spa": "Alemania",
        "fin": "Saksa",
        "est": "Saksamaa",
        "zho": "德国",
        "pol": "Niemcy",
        "urd": "جرمنی",
        "kor": "독일"
      }
    },
    "GH": {
      "currency": [
        "GHS"
      ],
      "callingCode": [
        "233"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-gh",
      "name": {
        "common": "Ghana",
        "ces": "Ghana",
        "deu": "Ghana",
        "fra": "Ghana",
        "hrv": "Gana",
        "ita": "Ghana",
        "jpn": "ガーナ",
        "nld": "Ghana",
        "por": "Gana",
        "rus": "Гана",
        "slk": "Ghana",
        "spa": "Ghana",
        "fin": "Ghana",
        "est": "Ghana",
        "zho": "加纳",
        "pol": "Ghana",
        "urd": "گھانا",
        "kor": "가나"
      }
    },
    "GI": {
      "currency": [
        "GIP"
      ],
      "callingCode": [
        "350"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-gi",
      "name": {
        "common": "Gibraltar",
        "ces": "Gibraltar",
        "deu": "Gibraltar",
        "fra": "Gibraltar",
        "hrv": "Gibraltar",
        "ita": "Gibilterra",
        "jpn": "ジブラルタル",
        "nld": "Gibraltar",
        "por": "Gibraltar",
        "rus": "Гибралтар",
        "slk": "Gibraltár",
        "spa": "Gibraltar",
        "fin": "Gibraltar",
        "est": "Gibraltar",
        "zho": "直布罗陀",
        "pol": "Gibraltar",
        "urd": "جبل الطارق",
        "kor": "지브롤터"
      }
    },
    "GR": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "30"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-gr",
      "name": {
        "common": "Greece",
        "ces": "Řecko",
        "deu": "Griechenland",
        "fra": "Grèce",
        "hrv": "Grčka",
        "ita": "Grecia",
        "jpn": "ギリシャ",
        "nld": "Griekenland",
        "por": "Grécia",
        "rus": "Греция",
        "slk": "Greécko",
        "spa": "Grecia",
        "fin": "Kreikka",
        "est": "Kreeka",
        "zho": "希腊",
        "pol": "Grecja",
        "urd": "یونان",
        "kor": "그리스"
      }
    },
    "GL": {
      "currency": [
        "DKK"
      ],
      "callingCode": [
        "299"
      ],
      "region": "Americas",
      "subregion": "North America",
      "flag": "flag-gl",
      "name": {
        "common": "Greenland",
        "ces": "Grónsko",
        "deu": "Grönland",
        "fra": "Groenland",
        "hrv": "Grenland",
        "ita": "Groenlandia",
        "jpn": "グリーンランド",
        "nld": "Groenland",
        "por": "Gronelândia",
        "rus": "Гренландия",
        "slk": "Grónsko",
        "spa": "Groenlandia",
        "fin": "Groönlanti",
        "est": "Gröönimaa",
        "zho": "格陵兰",
        "pol": "Grenlandia",
        "urd": "گرین لینڈ",
        "kor": "그린란드"
      }
    },
    "GD": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1473"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-gd",
      "name": {
        "common": "Grenada",
        "ces": "Grenada",
        "deu": "Grenada",
        "fra": "Grenade",
        "hrv": "Grenada",
        "ita": "Grenada",
        "jpn": "グレナダ",
        "nld": "Grenada",
        "por": "Granada",
        "rus": "Гренада",
        "slk": "Grenada",
        "spa": "Grenada",
        "fin": "Grenada",
        "est": "Grenada",
        "zho": "格林纳达",
        "pol": "Grenada",
        "urd": "گریناڈا",
        "kor": "그레나다"
      }
    },
    "GP": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "590"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-gp",
      "name": {
        "common": "Guadeloupe",
        "ces": "Guadeloupe",
        "deu": "Guadeloupe",
        "fra": "Guadeloupe",
        "hrv": "Gvadalupa",
        "ita": "Guadeloupa",
        "jpn": "グアドループ",
        "nld": "Guadeloupe",
        "por": "Guadalupe",
        "rus": "Гваделупа",
        "slk": "Guadeloupe",
        "spa": "Guadalupe",
        "fin": "Guadeloupe",
        "est": "Guadeloupe",
        "zho": "瓜德罗普岛",
        "pol": "Gwadelupa",
        "urd": "گواڈیلوپ",
        "kor": "과들루프"
      }
    },
    "GU": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1671"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "flag": "flag-gu",
      "name": {
        "common": "Guam",
        "ces": "Guam",
        "deu": "Guam",
        "fra": "Guam",
        "hrv": "Guam",
        "ita": "Guam",
        "jpn": "グアム",
        "nld": "Guam",
        "por": "Guam",
        "rus": "Гуам",
        "slk": "Guam",
        "spa": "Guam",
        "fin": "Guam",
        "est": "Guam",
        "zho": "关岛",
        "pol": "Guam",
        "urd": "گوام",
        "kor": "괌"
      }
    },
    "GT": {
      "currency": [
        "GTQ"
      ],
      "callingCode": [
        "502"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "flag": "flag-gt",
      "name": {
        "common": "Guatemala",
        "ces": "Guatemala",
        "deu": "Guatemala",
        "fra": "Guatemala",
        "hrv": "Gvatemala",
        "ita": "Guatemala",
        "jpn": "グアテマラ",
        "nld": "Guatemala",
        "por": "Guatemala",
        "rus": "Гватемала",
        "slk": "Guatemala",
        "spa": "Guatemala",
        "fin": "Guatemala",
        "est": "Guatemala",
        "zho": "危地马拉",
        "pol": "Gwatemala",
        "urd": "گواتیمالا",
        "kor": "과테말라"
      }
    },
    "GG": {
      "currency": [
        "GBP"
      ],
      "callingCode": [
        "44"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-gg",
      "name": {
        "common": "Guernsey",
        "ces": "Guernsey",
        "deu": "Guernsey",
        "fra": "Guernesey",
        "hrv": "Guernsey",
        "ita": "Guernsey",
        "jpn": "ガーンジー",
        "nld": "Guernsey",
        "por": "Guernsey",
        "rus": "Гернси",
        "slk": "Guernsey",
        "spa": "Guernsey",
        "fin": "Guernsey",
        "est": "Guernsey",
        "zho": "根西岛",
        "pol": "Guernsey",
        "urd": "گرنزی",
        "kor": "건지 섬"
      }
    },
    "GN": {
      "currency": [
        "GNF"
      ],
      "callingCode": [
        "224"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-gn",
      "name": {
        "common": "Guinea",
        "ces": "Guinea",
        "deu": "Guinea",
        "fra": "Guinée",
        "hrv": "Gvineja",
        "ita": "Guinea",
        "jpn": "ギニア",
        "nld": "Guinee",
        "por": "Guiné",
        "rus": "Гвинея",
        "slk": "Guinea",
        "spa": "Guinea",
        "fin": "Guinea",
        "est": "Guinea",
        "zho": "几内亚",
        "pol": "Gwinea",
        "urd": "گنی",
        "kor": "기니"
      }
    },
    "GW": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "245"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-gw",
      "name": {
        "common": "Guinea-Bissau",
        "ces": "Guinea-Bissau",
        "deu": "Guinea-Bissau",
        "fra": "Guinée-Bissau",
        "hrv": "Gvineja Bisau",
        "ita": "Guinea-Bissau",
        "jpn": "ギニアビサウ",
        "nld": "Guinee-Bissau",
        "por": "Guiné-Bissau",
        "rus": "Гвинея-Бисау",
        "slk": "Guinea-Bissau",
        "spa": "Guinea-Bisáu",
        "fin": "Guinea-Bissau",
        "est": "Guinea-Bissau",
        "zho": "几内亚比绍",
        "pol": "Gwinea Bissau",
        "urd": "گنی بساؤ",
        "kor": "기니비사우"
      }
    },
    "GY": {
      "currency": [
        "GYD"
      ],
      "callingCode": [
        "592"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-gy",
      "name": {
        "common": "Guyana",
        "ces": "Guyana",
        "deu": "Guyana",
        "fra": "Guyana",
        "hrv": "Gvajana",
        "ita": "Guyana",
        "jpn": "ガイアナ",
        "nld": "Guyana",
        "por": "Guiana",
        "rus": "Гайана",
        "slk": "Guyana",
        "spa": "Guyana",
        "fin": "Guayana",
        "est": "Guyana",
        "zho": "圭亚那",
        "pol": "Gujana",
        "urd": "گیانا",
        "kor": "가이아나"
      }
    },
    "HT": {
      "currency": [
        "HTG",
        "USD"
      ],
      "callingCode": [
        "509"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-ht",
      "name": {
        "common": "Haiti",
        "ces": "Haiti",
        "deu": "Haiti",
        "fra": "Haïti",
        "hrv": "Haiti",
        "ita": "Haiti",
        "jpn": "ハイチ",
        "nld": "Haïti",
        "por": "Haiti",
        "rus": "Гаити",
        "slk": "Haiti",
        "spa": "Haiti",
        "fin": "Haiti",
        "est": "Haiti",
        "zho": "海地",
        "pol": "Haiti",
        "urd": "ہیٹی",
        "kor": "아이티"
      }
    },
    "HM": {
      "currency": [
        "AUD"
      ],
      "callingCode": [],
      "region": "Antarctic",
      "subregion": "",
      "flag": "flag-hm",
      "name": {
        "common": "Heard Island and McDonald Islands",
        "ces": "Heardův ostrov a McDonaldovy ostrovy",
        "deu": "Heard und die McDonaldinseln",
        "fra": "Îles Heard-et-MacDonald",
        "hrv": "Otok Heard i otočje McDonald",
        "ita": "Isole Heard e McDonald",
        "jpn": "ハード島とマクドナルド諸島",
        "nld": "Heard-en McDonaldeilanden",
        "por": "Ilha Heard e Ilhas McDonald",
        "rus": "Остров Херд и острова Макдональд",
        "slk": "Heardov ostrov",
        "spa": "Islas Heard y McDonald",
        "fin": "Heard ja McDonaldinsaaret",
        "est": "Heard ja McDonald",
        "zho": "赫德岛和麦当劳群岛",
        "pol": "Wyspy Heard i McDonalda",
        "urd": "جزیرہ ہرڈ و جزائر مکڈونلڈ",
        "kor": "허드 맥도널드 제도"
      }
    },
    "HN": {
      "currency": [
        "HNL"
      ],
      "callingCode": [
        "504"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "flag": "flag-hn",
      "name": {
        "common": "Honduras",
        "ces": "Honduras",
        "deu": "Honduras",
        "fra": "Honduras",
        "hrv": "Honduras",
        "ita": "Honduras",
        "jpn": "ホンジュラス",
        "nld": "Honduras",
        "por": "Honduras",
        "rus": "Гондурас",
        "slk": "Honduras",
        "spa": "Honduras",
        "fin": "Honduras",
        "est": "Honduras",
        "zho": "洪都拉斯",
        "pol": "Honduras",
        "urd": "ہونڈوراس",
        "kor": "온두라스"
      }
    },
    "HK": {
      "currency": [
        "HKD"
      ],
      "callingCode": [
        "852"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-hk",
      "name": {
        "common": "Hong Kong",
        "ces": "Hongkong",
        "deu": "Hongkong",
        "fra": "Hong Kong",
        "hrv": "Hong Kong",
        "ita": "Hong Kong",
        "jpn": "香港",
        "nld": "Hongkong",
        "por": "Hong Kong",
        "rus": "Гонконг",
        "slk": "Hongkong",
        "spa": "Hong Kong",
        "fin": "Hongkong",
        "est": "Hongkong",
        "pol": "Hongkong",
        "urd": "ہانگ کانگ",
        "kor": "홍콩"
      }
    },
    "HU": {
      "currency": [
        "HUF"
      ],
      "callingCode": [
        "36"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-hu",
      "name": {
        "common": "Hungary",
        "ces": "Maďarsko",
        "deu": "Ungarn",
        "fra": "Hongrie",
        "hrv": "Mađarska",
        "ita": "Ungheria",
        "jpn": "ハンガリー",
        "nld": "Hongarije",
        "por": "Hungria",
        "rus": "Венгрия",
        "slk": "Maďarsko",
        "spa": "Hungría",
        "fin": "Unkari",
        "est": "Ungari",
        "zho": "匈牙利",
        "pol": "Węgry",
        "urd": "مجارستان",
        "kor": "헝가리"
      }
    },
    "IS": {
      "currency": [
        "ISK"
      ],
      "callingCode": [
        "354"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-is",
      "name": {
        "common": "Iceland",
        "ces": "Island",
        "deu": "Island",
        "fra": "Islande",
        "hrv": "Island",
        "ita": "Islanda",
        "jpn": "アイスランド",
        "nld": "IJsland",
        "por": "Islândia",
        "rus": "Исландия",
        "slk": "Island",
        "spa": "Islandia",
        "fin": "Islanti",
        "est": "Island",
        "zho": "冰岛",
        "pol": "Islandia",
        "urd": "آئس لینڈ",
        "kor": "아이슬란드"
      }
    },
    "IN": {
      "currency": [
        "INR"
      ],
      "callingCode": [
        "91"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-in",
      "name": {
        "common": "India",
        "ces": "Indie",
        "deu": "Indien",
        "fra": "Inde",
        "hrv": "Indija",
        "ita": "India",
        "jpn": "インド",
        "nld": "India",
        "por": "Índia",
        "rus": "Индия",
        "slk": "India",
        "spa": "India",
        "fin": "Intia",
        "est": "India",
        "zho": "印度",
        "pol": "Indie",
        "urd": "بھارت",
        "kor": "인도"
      }
    },
    "ID": {
      "currency": [
        "IDR"
      ],
      "callingCode": [
        "62"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-id",
      "name": {
        "common": "Indonesia",
        "ces": "Indonésie",
        "deu": "Indonesien",
        "fra": "Indonésie",
        "hrv": "Indonezija",
        "ita": "Indonesia",
        "jpn": "インドネシア",
        "nld": "Indonesië",
        "por": "Indonésia",
        "rus": "Индонезия",
        "slk": "Indonézia",
        "spa": "Indonesia",
        "fin": "Indonesia",
        "est": "Indoneesia",
        "zho": "印度尼西亚",
        "pol": "Indonezja",
        "urd": "انڈونیشیا",
        "kor": "인도네시아"
      }
    },
    "IR": {
      "currency": [
        "IRR"
      ],
      "callingCode": [
        "98"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-ir",
      "name": {
        "common": "Iran",
        "ces": "Írán",
        "deu": "Iran",
        "fra": "Iran",
        "hrv": "Iran",
        "ita": "Iran",
        "jpn": "イラン・イスラム共和国",
        "nld": "Iran",
        "por": "Irão",
        "rus": "Иран",
        "slk": "Irán",
        "spa": "Iran",
        "fin": "Iran",
        "est": "Iraan",
        "zho": "伊朗",
        "pol": "Iran",
        "urd": "ایران",
        "kor": "이란"
      }
    },
    "IQ": {
      "currency": [
        "IQD"
      ],
      "callingCode": [
        "964"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-iq",
      "name": {
        "common": "Iraq",
        "ces": "Irák",
        "deu": "Irak",
        "fra": "Irak",
        "hrv": "Irak",
        "ita": "Iraq",
        "jpn": "イラク",
        "nld": "Irak",
        "por": "Iraque",
        "rus": "Ирак",
        "slk": "Irak",
        "spa": "Irak",
        "fin": "Irak",
        "est": "Iraak",
        "zho": "伊拉克",
        "pol": "Irak",
        "urd": "عراق",
        "kor": "이라크"
      }
    },
    "IE": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "353"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-ie",
      "name": {
        "common": "Ireland",
        "ces": "Irsko",
        "deu": "Irland",
        "fra": "Irlande",
        "hrv": "Irska",
        "ita": "Irlanda",
        "jpn": "アイルランド",
        "nld": "Ierland",
        "por": "Irlanda",
        "rus": "Ирландия",
        "slk": "Írsko",
        "spa": "Irlanda",
        "fin": "Irlanti",
        "est": "Iirimaa",
        "zho": "爱尔兰",
        "pol": "Irlandia",
        "urd": "جزیرہ آئرلینڈ",
        "kor": "아일랜드"
      }
    },
    "IM": {
      "currency": [
        "GBP"
      ],
      "callingCode": [
        "44"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-im",
      "name": {
        "common": "Isle of Man",
        "ces": "Ostrov Man",
        "deu": "Insel Man",
        "fra": "Île de Man",
        "hrv": "Otok Man",
        "ita": "Isola di Man",
        "jpn": "マン島",
        "nld": "Isle of Man",
        "por": "Ilha de Man",
        "rus": "Остров Мэн",
        "slk": "Man",
        "spa": "Isla de Man",
        "fin": "Mansaari",
        "est": "Mani saar",
        "zho": "马恩岛",
        "pol": "Wyspa Man",
        "urd": "آئل آف مین",
        "kor": "맨섬"
      }
    },
    "IL": {
      "currency": [
        "ILS"
      ],
      "callingCode": [
        "972"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-il",
      "name": {
        "common": "Israel",
        "ces": "Izrael",
        "deu": "Israel",
        "fra": "Israël",
        "hrv": "Izrael",
        "ita": "Israele",
        "jpn": "イスラエル",
        "nld": "Israël",
        "por": "Israel",
        "rus": "Израиль",
        "slk": "Izrael",
        "spa": "Israel",
        "fin": "Israel",
        "est": "Iisrael",
        "zho": "以色列",
        "pol": "Izrael",
        "urd": "اسرائیل",
        "kor": "이스라엘"
      }
    },
    "IT": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "39"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-it",
      "name": {
        "common": "Italy",
        "ces": "Itálie",
        "deu": "Italien",
        "fra": "Italie",
        "hrv": "Italija",
        "ita": "Italia",
        "jpn": "イタリア",
        "nld": "Italië",
        "por": "Itália",
        "rus": "Италия",
        "slk": "Taliansko",
        "spa": "Italia",
        "fin": "Italia",
        "est": "Itaalia",
        "zho": "意大利",
        "pol": "Włochy",
        "urd": "اطالیہ",
        "kor": "이탈리아"
      }
    },
    "CI": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "225"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-ci",
      "name": {
        "common": "Ivory Coast",
        "ces": "Pobřeží slonoviny",
        "deu": "Elfenbeinküste",
        "fra": "Côte d'Ivoire",
        "hrv": "Obala Bjelokosti",
        "ita": "Costa d'Avorio",
        "jpn": "コートジボワール",
        "nld": "Ivoorkust",
        "por": "Costa do Marfim",
        "rus": "Кот-д’Ивуар",
        "slk": "Pobržie Slonoviny",
        "spa": "Costa de Marfil",
        "fin": "Norsunluurannikko",
        "est": "Elevandiluurannik",
        "zho": "科特迪瓦",
        "pol": "WybrzeŻe Kości Słoniowej",
        "urd": "آئیوری کوسٹ",
        "kor": "코트디부아르"
      }
    },
    "JM": {
      "currency": [
        "JMD"
      ],
      "callingCode": [
        "1876"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-jm",
      "name": {
        "common": "Jamaica",
        "ces": "Jamajka",
        "deu": "Jamaika",
        "fra": "Jamaïque",
        "hrv": "Jamajka",
        "ita": "Giamaica",
        "jpn": "ジャマイカ",
        "nld": "Jamaica",
        "por": "Jamaica",
        "rus": "Ямайка",
        "slk": "Jamajka",
        "spa": "Jamaica",
        "fin": "Jamaika",
        "est": "Jamaica",
        "zho": "牙买加",
        "pol": "Jamajka",
        "urd": "جمیکا",
        "kor": "자메이카"
      }
    },
    "JP": {
      "currency": [
        "JPY"
      ],
      "callingCode": [
        "81"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-jp",
      "name": {
        "common": "Japan",
        "ces": "Japonsko",
        "deu": "Japan",
        "fra": "Japon",
        "hrv": "Japan",
        "ita": "Giappone",
        "jpn": "日本",
        "nld": "Japan",
        "por": "Japão",
        "rus": "Япония",
        "slk": "Japonsko",
        "spa": "Japón",
        "fin": "Japani",
        "est": "Jaapan",
        "zho": "日本",
        "pol": "Japonia",
        "urd": "جاپان",
        "kor": "일본"
      }
    },
    "JE": {
      "currency": [
        "GBP"
      ],
      "callingCode": [
        "44"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-je",
      "name": {
        "common": "Jersey",
        "ces": "Jersey",
        "deu": "Jersey",
        "fra": "Jersey",
        "hrv": "Jersey",
        "ita": "Isola di Jersey",
        "jpn": "ジャージー",
        "nld": "Jersey",
        "por": "Jersey",
        "rus": "Джерси",
        "slk": "Jersey",
        "spa": "Jersey",
        "fin": "Jersey",
        "est": "Jersey",
        "zho": "泽西岛",
        "pol": "Jersey",
        "urd": "جرزی",
        "kor": "저지 섬"
      }
    },
    "JO": {
      "currency": [
        "JOD"
      ],
      "callingCode": [
        "962"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-jo",
      "name": {
        "common": "Jordan",
        "ces": "Jordánsko",
        "deu": "Jordanien",
        "fra": "Jordanie",
        "hrv": "Jordan",
        "ita": "Giordania",
        "jpn": "ヨルダン",
        "nld": "Jordanië",
        "por": "Jordânia",
        "rus": "Иордания",
        "slk": "Jordánsko",
        "spa": "Jordania",
        "fin": "Jordania",
        "est": "Jordaania",
        "zho": "约旦",
        "pol": "Jordania",
        "urd": "اردن",
        "kor": "요르단"
      }
    },
    "KZ": {
      "currency": [
        "KZT"
      ],
      "callingCode": [
        "7"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "flag": "flag-kz",
      "name": {
        "common": "Kazakhstan",
        "ces": "Kazachstán",
        "deu": "Kasachstan",
        "fra": "Kazakhstan",
        "hrv": "Kazahstan",
        "ita": "Kazakistan",
        "jpn": "カザフスタン",
        "nld": "Kazachstan",
        "por": "Cazaquistão",
        "rus": "Казахстан",
        "slk": "Kazachstan",
        "spa": "Kazajistán",
        "fin": "Kazakstan",
        "est": "Kasahstan",
        "zho": "哈萨克斯坦",
        "pol": "Kazachstan",
        "urd": "قازقستان",
        "kor": "카자흐스탄"
      }
    },
    "KE": {
      "currency": [
        "KES"
      ],
      "callingCode": [
        "254"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-ke",
      "name": {
        "common": "Kenya",
        "ces": "Keňa",
        "deu": "Kenia",
        "fra": "Kenya",
        "hrv": "Kenija",
        "ita": "Kenya",
        "jpn": "ケニア",
        "nld": "Kenia",
        "por": "Quénia",
        "rus": "Кения",
        "slk": "Keňa",
        "spa": "Kenia",
        "fin": "Kenia",
        "est": "Keenia",
        "zho": "肯尼亚",
        "pol": "Kenia",
        "urd": "کینیا",
        "kor": "케냐"
      }
    },
    "KI": {
      "currency": [
        "AUD"
      ],
      "callingCode": [
        "686"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "flag": "flag-ki",
      "name": {
        "common": "Kiribati",
        "ces": "Kiribati",
        "deu": "Kiribati",
        "fra": "Kiribati",
        "hrv": "Kiribati",
        "ita": "Kiribati",
        "jpn": "キリバス",
        "nld": "Kiribati",
        "por": "Kiribati",
        "rus": "Кирибати",
        "slk": "Kiribati",
        "spa": "Kiribati",
        "fin": "Kiribati",
        "est": "Kiribati",
        "zho": "基里巴斯",
        "pol": "Kiribati",
        "urd": "کیریباتی",
        "kor": "키리바시"
      }
    },
    "XK": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "383"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-xk",
      "name": {
        "common": "Kosovo",
        "ces": "Kosovo",
        "deu": "Kosovo",
        "fra": "Kosovo",
        "hrv": "Kosovo",
        "ita": "Kosovo",
        "nld": "Kosovo",
        "por": "Kosovo",
        "rus": "Республика Косово",
        "slk": "Kosovo",
        "spa": "Kosovo",
        "fin": "Kosovo",
        "est": "Kosovo",
        "zho": "科索沃",
        "pol": "Kosowo",
        "urd": "کوسووہ",
        "kor": "코소보"
      }
    },
    "KW": {
      "currency": [
        "KWD"
      ],
      "callingCode": [
        "965"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-kw",
      "name": {
        "common": "Kuwait",
        "ces": "Kuvajt",
        "deu": "Kuwait",
        "fra": "Koweït",
        "hrv": "Kuvajt",
        "ita": "Kuwait",
        "jpn": "クウェート",
        "nld": "Koeweit",
        "por": "Kuwait",
        "rus": "Кувейт",
        "slk": "Kuvajt",
        "spa": "Kuwait",
        "fin": "Kuwait",
        "est": "Kuveit",
        "zho": "科威特",
        "pol": "Kuwejt",
        "urd": "کویت",
        "kor": "쿠웨이트"
      }
    },
    "KG": {
      "currency": [
        "KGS"
      ],
      "callingCode": [
        "996"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "flag": "flag-kg",
      "name": {
        "common": "Kyrgyzstan",
        "ces": "Kyrgyzstán",
        "deu": "Kirgisistan",
        "fra": "Kirghizistan",
        "hrv": "Kirgistan",
        "ita": "Kirghizistan",
        "jpn": "キルギス",
        "nld": "Kirgizië",
        "por": "Quirguistão",
        "rus": "Киргизия",
        "slk": "Kirgizsko",
        "spa": "Kirguizistán",
        "fin": "Kirgisia",
        "est": "Kõrgõzstan",
        "zho": "吉尔吉斯斯坦",
        "pol": "Kirgistan",
        "urd": "کرغیزستان",
        "kor": "키르기스스탄"
      }
    },
    "LA": {
      "currency": [
        "LAK"
      ],
      "callingCode": [
        "856"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-la",
      "name": {
        "common": "Laos",
        "ces": "Laos",
        "deu": "Laos",
        "fra": "Laos",
        "hrv": "Laos",
        "ita": "Laos",
        "jpn": "ラオス人民民主共和国",
        "nld": "Laos",
        "por": "Laos",
        "rus": "Лаос",
        "slk": "Laos",
        "spa": "Laos",
        "fin": "Laos",
        "est": "Laos",
        "zho": "老挝",
        "pol": "Laos",
        "urd": "لاؤس",
        "kor": "라오스"
      }
    },
    "LV": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "371"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-lv",
      "name": {
        "common": "Latvia",
        "ces": "Lotyšsko",
        "deu": "Lettland",
        "fra": "Lettonie",
        "hrv": "Latvija",
        "ita": "Lettonia",
        "jpn": "ラトビア",
        "nld": "Letland",
        "por": "Letónia",
        "rus": "Латвия",
        "slk": "Lotyšsko",
        "spa": "Letonia",
        "fin": "Latvia",
        "est": "Läti",
        "zho": "拉脱维亚",
        "pol": "Łotwa",
        "urd": "لٹویا",
        "kor": "라트비아"
      }
    },
    "LB": {
      "currency": [
        "LBP"
      ],
      "callingCode": [
        "961"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-lb",
      "name": {
        "common": "Lebanon",
        "ces": "Libanon",
        "deu": "Libanon",
        "fra": "Liban",
        "hrv": "Libanon",
        "ita": "Libano",
        "jpn": "レバノン",
        "nld": "Libanon",
        "por": "Líbano",
        "rus": "Ливан",
        "slk": "Libanon",
        "spa": "Líbano",
        "fin": "Libanon",
        "est": "Liibanon",
        "zho": "黎巴嫩",
        "pol": "Liban",
        "urd": "لبنان",
        "kor": "레바논"
      }
    },
    "LS": {
      "currency": [
        "LSL",
        "ZAR"
      ],
      "callingCode": [
        "266"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "flag": "flag-ls",
      "name": {
        "common": "Lesotho",
        "ces": "Lesotho",
        "deu": "Lesotho",
        "fra": "Lesotho",
        "hrv": "Lesoto",
        "ita": "Lesotho",
        "jpn": "レソト",
        "nld": "Lesotho",
        "por": "Lesoto",
        "rus": "Лесото",
        "slk": "Lesotho",
        "spa": "Lesotho",
        "fin": "Lesotho",
        "est": "Lesotho",
        "zho": "莱索托",
        "pol": "Lesotho",
        "urd": "لیسوتھو",
        "kor": "레소토"
      }
    },
    "LR": {
      "currency": [
        "LRD"
      ],
      "callingCode": [
        "231"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-lr",
      "name": {
        "common": "Liberia",
        "ces": "Libérie",
        "deu": "Liberia",
        "fra": "Liberia",
        "hrv": "Liberija",
        "ita": "Liberia",
        "jpn": "リベリア",
        "nld": "Liberia",
        "por": "Libéria",
        "rus": "Либерия",
        "slk": "Libéria",
        "spa": "Liberia",
        "fin": "Liberia",
        "est": "Libeeria",
        "zho": "利比里亚",
        "pol": "Liberia",
        "urd": "لائبیریا",
        "kor": "라이베리아"
      }
    },
    "LY": {
      "currency": [
        "LYD"
      ],
      "callingCode": [
        "218"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "flag": "flag-ly",
      "name": {
        "common": "Libya",
        "ces": "Libye",
        "deu": "Libyen",
        "fra": "Libye",
        "hrv": "Libija",
        "ita": "Libia",
        "jpn": "リビア",
        "nld": "Libië",
        "por": "Líbia",
        "rus": "Ливия",
        "slk": "Líbya",
        "spa": "Libia",
        "fin": "Libya",
        "est": "Liibüa",
        "zho": "利比亚",
        "pol": "Libia",
        "urd": "لیبیا",
        "kor": "리비아"
      }
    },
    "LI": {
      "currency": [
        "CHF"
      ],
      "callingCode": [
        "423"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-li",
      "name": {
        "common": "Liechtenstein",
        "ces": "Lichtenštejnsko",
        "deu": "Liechtenstein",
        "fra": "Liechtenstein",
        "hrv": "Lihtenštajn",
        "ita": "Liechtenstein",
        "jpn": "リヒテンシュタイン",
        "nld": "Liechtenstein",
        "por": "Liechtenstein",
        "rus": "Лихтенштейн",
        "slk": "Lichtenštajnsko",
        "spa": "Liechtenstein",
        "fin": "Liechenstein",
        "est": "Liechtenstein",
        "zho": "列支敦士登",
        "pol": "Liechtenstein",
        "urd": "لیختینستائن",
        "kor": "리히텐슈타인"
      }
    },
    "LT": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "370"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-lt",
      "name": {
        "common": "Lithuania",
        "ces": "Litva",
        "deu": "Litauen",
        "fra": "Lituanie",
        "hrv": "Litva",
        "ita": "Lituania",
        "jpn": "リトアニア",
        "nld": "Litouwen",
        "por": "Lituânia",
        "rus": "Литва",
        "slk": "Litva",
        "spa": "Lituania",
        "fin": "Liettua",
        "est": "Leedu",
        "zho": "立陶宛",
        "pol": "Litwa",
        "urd": "لتھووینیا",
        "kor": "리투아니아"
      }
    },
    "LU": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "352"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-lu",
      "name": {
        "common": "Luxembourg",
        "ces": "Lucembursko",
        "deu": "Luxemburg",
        "fra": "Luxembourg",
        "hrv": "Luksemburg",
        "ita": "Lussemburgo",
        "jpn": "ルクセンブルク",
        "nld": "Luxemburg",
        "por": "Luxemburgo",
        "rus": "Люксембург",
        "slk": "Luxembursko",
        "spa": "Luxemburgo",
        "fin": "Luxemburg",
        "est": "Luksemburg",
        "zho": "卢森堡",
        "pol": "Luksemburg",
        "urd": "لکسمبرگ",
        "kor": "룩셈부르크"
      }
    },
    "MO": {
      "currency": [
        "MOP"
      ],
      "callingCode": [
        "853"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-mo",
      "name": {
        "common": "Macau",
        "ces": "Macao",
        "deu": "Macao",
        "fra": "Macao",
        "hrv": "Makao",
        "ita": "Macao",
        "jpn": "マカオ",
        "nld": "Macao",
        "por": "Macau",
        "rus": "Макао",
        "slk": "Macao",
        "spa": "Macao",
        "fin": "Macao",
        "est": "Macau",
        "pol": "Makau",
        "urd": "مکاؤ",
        "kor": "마카오"
      }
    },
    "MK": {
      "currency": [
        "MKD"
      ],
      "callingCode": [
        "389"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-mk",
      "name": {
        "common": "Macedonia",
        "ces": "Makedonie",
        "deu": "Mazedonien",
        "fra": "Macédoine",
        "hrv": "Makedonija",
        "ita": "Macedonia",
        "jpn": "マケドニア旧ユーゴスラビア共和国",
        "nld": "Macedonië",
        "por": "Macedónia",
        "rus": "Республика Македония",
        "slk": "Macedónsko",
        "spa": "Macedonia",
        "fin": "Makedonia",
        "est": "Makedoonia",
        "zho": "马其顿",
        "pol": "Macedonia",
        "urd": "مقدونیہ",
        "kor": "마케도니아"
      }
    },
    "MG": {
      "currency": [
        "MGA"
      ],
      "callingCode": [
        "261"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-mg",
      "name": {
        "common": "Madagascar",
        "ces": "Madagaskar",
        "deu": "Madagaskar",
        "fra": "Madagascar",
        "hrv": "Madagaskar",
        "ita": "Madagascar",
        "jpn": "マダガスカル",
        "nld": "Madagaskar",
        "por": "Madagáscar",
        "rus": "Мадагаскар",
        "slk": "Madagaskar",
        "spa": "Madagascar",
        "fin": "Madagaskar",
        "est": "Madagaskar",
        "zho": "马达加斯加",
        "pol": "Madagaskar",
        "urd": "مڈغاسکر",
        "kor": "마다가스카르"
      }
    },
    "MW": {
      "currency": [
        "MWK"
      ],
      "callingCode": [
        "265"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-mw",
      "name": {
        "common": "Malawi",
        "ces": "Malawi",
        "deu": "Malawi",
        "fra": "Malawi",
        "hrv": "Malavi",
        "ita": "Malawi",
        "jpn": "マラウイ",
        "nld": "Malawi",
        "por": "Malawi",
        "rus": "Малави",
        "slk": "Malawi",
        "spa": "Malawi",
        "fin": "Malawi",
        "est": "Malawi",
        "zho": "马拉维",
        "pol": "Malawi",
        "urd": "ملاوی",
        "kor": "말라위"
      }
    },
    "MY": {
      "currency": [
        "MYR"
      ],
      "callingCode": [
        "60"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-my",
      "name": {
        "common": "Malaysia",
        "ces": "Malajsie",
        "deu": "Malaysia",
        "fra": "Malaisie",
        "hrv": "Malezija",
        "ita": "Malesia",
        "jpn": "マレーシア",
        "nld": "Maleisië",
        "por": "Malásia",
        "rus": "Малайзия",
        "slk": "Malajzia",
        "spa": "Malasia",
        "fin": "Malesia",
        "est": "Malaisia",
        "zho": "马来西亚",
        "pol": "Malezja",
        "urd": "ملائیشیا",
        "kor": "말레이시아"
      }
    },
    "MV": {
      "currency": [
        "MVR"
      ],
      "callingCode": [
        "960"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-mv",
      "name": {
        "common": "Maldives",
        "ces": "Maledivy",
        "deu": "Malediven",
        "fra": "Maldives",
        "hrv": "Maldivi",
        "ita": "Maldive",
        "jpn": "モルディブ",
        "nld": "Maldiven",
        "por": "Maldivas",
        "spa": "Maldivas",
        "rus": "Мальдивы",
        "slk": "Maldivy",
        "fin": "Malediivit",
        "est": "Maldiivid",
        "zho": "马尔代夫",
        "pol": "Malediwy",
        "urd": "مالدیپ",
        "kor": "몰디브"
      }
    },
    "ML": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "223"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-ml",
      "name": {
        "common": "Mali",
        "ces": "Mali",
        "deu": "Mali",
        "fra": "Mali",
        "hrv": "Mali",
        "ita": "Mali",
        "jpn": "マリ",
        "nld": "Mali",
        "por": "Mali",
        "rus": "Мали",
        "slk": "Mali",
        "spa": "Mali",
        "fin": "Mali",
        "est": "Mali",
        "zho": "马里",
        "pol": "Mali",
        "urd": "مالی",
        "kor": "말리"
      }
    },
    "MT": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "356"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-mt",
      "name": {
        "common": "Malta",
        "ces": "Malta",
        "deu": "Malta",
        "fra": "Malte",
        "hrv": "Malta",
        "ita": "Malta",
        "jpn": "マルタ",
        "nld": "Malta",
        "por": "Malta",
        "rus": "Мальта",
        "slk": "Malta",
        "spa": "Malta",
        "fin": "Malta",
        "est": "Malta",
        "zho": "马耳他",
        "pol": "Malta",
        "urd": "مالٹا",
        "kor": "몰타"
      }
    },
    "MH": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "692"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "flag": "flag-mh",
      "name": {
        "common": "Marshall Islands",
        "ces": "Marshallovy ostrovy",
        "deu": "Marshallinseln",
        "fra": "Îles Marshall",
        "hrv": "Maršalovi Otoci",
        "ita": "Isole Marshall",
        "jpn": "マーシャル諸島",
        "nld": "Marshalleilanden",
        "por": "Ilhas Marshall",
        "rus": "Маршалловы Острова",
        "slk": "Marshallove ostrovy",
        "spa": "Islas Marshall",
        "fin": "Marshallinsaaret",
        "est": "Marshalli Saared",
        "zho": "马绍尔群岛",
        "pol": "Wyspy Marshalla",
        "urd": "جزائر مارشل",
        "kor": "마셜 제도"
      }
    },
    "MQ": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "596"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-mq",
      "name": {
        "common": "Martinique",
        "ces": "Martinik",
        "deu": "Martinique",
        "fra": "Martinique",
        "hrv": "Martinique",
        "ita": "Martinica",
        "jpn": "マルティニーク",
        "nld": "Martinique",
        "por": "Martinica",
        "rus": "Мартиника",
        "spa": "Martinica",
        "slk": "Martinique",
        "fin": "Martinique",
        "est": "Martinique",
        "zho": "马提尼克",
        "pol": "Martynika",
        "urd": "مارٹینیک",
        "kor": "마르티니크"
      }
    },
    "MR": {
      "currency": [
        "MRO"
      ],
      "callingCode": [
        "222"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-mr",
      "name": {
        "common": "Mauritania",
        "ces": "Mauritánie",
        "deu": "Mauretanien",
        "fra": "Mauritanie",
        "hrv": "Mauritanija",
        "ita": "Mauritania",
        "jpn": "モーリタニア",
        "nld": "Mauritanië",
        "por": "Mauritânia",
        "rus": "Мавритания",
        "slk": "Mauritánia",
        "spa": "Mauritania",
        "fin": "Mauritania",
        "est": "Mauritaania",
        "zho": "毛里塔尼亚",
        "pol": "Mauretania",
        "urd": "موریتانیہ",
        "kor": "모리타니"
      }
    },
    "MU": {
      "currency": [
        "MUR"
      ],
      "callingCode": [
        "230"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-mu",
      "name": {
        "common": "Mauritius",
        "ces": "Mauricius",
        "deu": "Mauritius",
        "fra": "Île Maurice",
        "hrv": "Mauricijus",
        "ita": "Mauritius",
        "jpn": "モーリシャス",
        "nld": "Mauritius",
        "por": "Maurício",
        "rus": "Маврикий",
        "slk": "Maurícius",
        "spa": "Mauricio",
        "fin": "Mauritius",
        "est": "Mauritius",
        "zho": "毛里求斯",
        "pol": "Mauritius",
        "urd": "موریشس",
        "kor": "모리셔스"
      }
    },
    "YT": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "262"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-yt",
      "name": {
        "common": "Mayotte",
        "ces": "Mayotte",
        "deu": "Mayotte",
        "fra": "Mayotte",
        "hrv": "Mayotte",
        "ita": "Mayotte",
        "jpn": "マヨット",
        "nld": "Mayotte",
        "por": "Mayotte",
        "rus": "Майотта",
        "slk": "Mayotte",
        "spa": "Mayotte",
        "fin": "Mayotte",
        "est": "Mayotte",
        "zho": "马约特",
        "pol": "Majotta",
        "urd": "مایوٹ",
        "kor": "마요트"
      }
    },
    "MX": {
      "currency": [
        "MXN"
      ],
      "callingCode": [
        "52"
      ],
      "region": "Americas",
      "subregion": "North America",
      "flag": "flag-mx",
      "name": {
        "common": "Mexico",
        "ces": "Mexiko",
        "deu": "Mexiko",
        "fra": "Mexique",
        "hrv": "Meksiko",
        "ita": "Messico",
        "jpn": "メキシコ",
        "nld": "Mexico",
        "por": "México",
        "rus": "Мексика",
        "slk": "Mexiko",
        "spa": "México",
        "fin": "Meksiko",
        "est": "Mehhiko",
        "zho": "墨西哥",
        "pol": "Meksyk",
        "urd": "میکسیکو",
        "kor": "멕시코"
      }
    },
    "FM": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "691"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "flag": "flag-fm",
      "name": {
        "common": "Micronesia",
        "ces": "Mikronésie",
        "deu": "Mikronesien",
        "fra": "Micronésie",
        "hrv": "Mikronezija",
        "ita": "Micronesia",
        "jpn": "ミクロネシア連邦",
        "nld": "Micronesië",
        "por": "Micronésia",
        "rus": "Федеративные Штаты Микронезии",
        "slk": "Mikronézia",
        "spa": "Micronesia",
        "fin": "Mikronesia",
        "est": "Mikroneesia",
        "zho": "密克罗尼西亚",
        "pol": "Mikronezja",
        "urd": "مائکرونیشیا",
        "kor": "미크로네시아"
      }
    },
    "MD": {
      "currency": [
        "MDL"
      ],
      "callingCode": [
        "373"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-md",
      "name": {
        "common": "Moldova",
        "ces": "Moldavsko",
        "deu": "Moldawien",
        "fra": "Moldavie",
        "hrv": "Moldova",
        "ita": "Moldavia",
        "jpn": "モルドバ共和国",
        "nld": "Moldavië",
        "por": "Moldávia",
        "rus": "Молдавия",
        "slk": "Moldavsko",
        "spa": "Moldavia",
        "fin": "Moldova",
        "est": "Moldova",
        "zho": "摩尔多瓦",
        "pol": "Mołdawia",
        "urd": "مالدووا",
        "kor": "몰도바"
      }
    },
    "MC": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "377"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-mc",
      "name": {
        "common": "Monaco",
        "ces": "Monako",
        "deu": "Monaco",
        "fra": "Monaco",
        "hrv": "Monako",
        "ita": "Principato di Monaco",
        "jpn": "モナコ",
        "nld": "Monaco",
        "por": "Mónaco",
        "rus": "Монако",
        "slk": "Monako",
        "spa": "Mónaco",
        "fin": "Monaco",
        "est": "Monaco",
        "zho": "摩纳哥",
        "pol": "Monako",
        "urd": "موناکو",
        "kor": "모나코"
      }
    },
    "MN": {
      "currency": [
        "MNT"
      ],
      "callingCode": [
        "976"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-mn",
      "name": {
        "common": "Mongolia",
        "ces": "Mongolsko",
        "deu": "Mongolei",
        "fra": "Mongolie",
        "hrv": "Mongolija",
        "ita": "Mongolia",
        "jpn": "モンゴル",
        "nld": "Mongolië",
        "por": "Mongólia",
        "rus": "Монголия",
        "slk": "Mongolsko",
        "spa": "Mongolia",
        "fin": "Mongolia",
        "est": "Mongoolia",
        "zho": "蒙古",
        "pol": "Mongolia",
        "urd": "منگولیا",
        "kor": "몽골국"
      }
    },
    "ME": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "382"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-me",
      "name": {
        "common": "Montenegro",
        "ces": "Černá Hora",
        "deu": "Montenegro",
        "fra": "Monténégro",
        "hrv": "Crna Gora",
        "ita": "Montenegro",
        "jpn": "モンテネグロ",
        "nld": "Montenegro",
        "por": "Montenegro",
        "rus": "Черногория",
        "slk": "Čierna Hora",
        "spa": "Montenegro",
        "fin": "Montenegro",
        "est": "Montenegro",
        "zho": "黑山",
        "pol": "Czarnogóra",
        "urd": "مونٹینیگرو",
        "kor": "몬테네그로"
      }
    },
    "MS": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1664"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-ms",
      "name": {
        "common": "Montserrat",
        "ces": "Montserrat",
        "deu": "Montserrat",
        "fra": "Montserrat",
        "hrv": "Montserrat",
        "ita": "Montserrat",
        "jpn": "モントセラト",
        "nld": "Montserrat",
        "por": "Montserrat",
        "rus": "Монтсеррат",
        "slk": "Montserrat",
        "spa": "Montserrat",
        "fin": "Montserrat",
        "est": "Montserrat",
        "zho": "蒙特塞拉特",
        "pol": "Montserrat",
        "urd": "مانٹسریٹ",
        "kor": "몬트세랫"
      }
    },
    "MA": {
      "currency": [
        "MAD"
      ],
      "callingCode": [
        "212"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "flag": "flag-ma",
      "name": {
        "common": "Morocco",
        "ces": "Maroko",
        "deu": "Marokko",
        "fra": "Maroc",
        "hrv": "Maroko",
        "ita": "Marocco",
        "jpn": "モロッコ",
        "nld": "Marokko",
        "por": "Marrocos",
        "rus": "Марокко",
        "slk": "Maroko",
        "spa": "Marruecos",
        "fin": "Marokko",
        "est": "Maroko",
        "zho": "摩洛哥",
        "pol": "Maroko",
        "urd": "مراکش",
        "kor": "모로코"
      }
    },
    "MZ": {
      "currency": [
        "MZN"
      ],
      "callingCode": [
        "258"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-mz",
      "name": {
        "common": "Mozambique",
        "ces": "Mosambik",
        "deu": "Mosambik",
        "fra": "Mozambique",
        "hrv": "Mozambik",
        "ita": "Mozambico",
        "jpn": "モザンビーク",
        "nld": "Mozambique",
        "por": "Moçambique",
        "rus": "Мозамбик",
        "slk": "Mozambik",
        "spa": "Mozambique",
        "fin": "Mosambik",
        "est": "Mosambiik",
        "zho": "莫桑比克",
        "pol": "Mozambik",
        "urd": "موزمبیق",
        "kor": "모잠비크"
      }
    },
    "MM": {
      "currency": [
        "MMK"
      ],
      "callingCode": [
        "95"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-mm",
      "name": {
        "common": "Myanmar",
        "ces": "Myanmar",
        "deu": "Myanmar",
        "fra": "Birmanie",
        "hrv": "Mijanmar",
        "ita": "Birmania",
        "jpn": "ミャンマー",
        "nld": "Myanmar",
        "por": "Myanmar",
        "rus": "Мьянма",
        "slk": "Mjanmarsko",
        "spa": "Myanmar",
        "fin": "Myanmar",
        "est": "Myanmar",
        "zho": "缅甸",
        "pol": "Mjanma",
        "urd": "میانمار",
        "kor": "미얀마"
      }
    },
    "NA": {
      "currency": [
        "NAD",
        "ZAR"
      ],
      "callingCode": [
        "264"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "flag": "flag-na",
      "name": {
        "common": "Namibia",
        "ces": "Namibie",
        "deu": "Namibia",
        "fra": "Namibie",
        "hrv": "Namibija",
        "ita": "Namibia",
        "jpn": "ナミビア",
        "nld": "Namibië",
        "por": "Namíbia",
        "rus": "Намибия",
        "slk": "Namíbia",
        "spa": "Namibia",
        "fin": "Namibia",
        "est": "Namiibia",
        "zho": "纳米比亚",
        "pol": "Namibia",
        "urd": "نمیبیا",
        "kor": "나미비아"
      }
    },
    "NR": {
      "currency": [
        "AUD"
      ],
      "callingCode": [
        "674"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "flag": "flag-nr",
      "name": {
        "common": "Nauru",
        "ces": "Nauru",
        "deu": "Nauru",
        "fra": "Nauru",
        "hrv": "Nauru",
        "ita": "Nauru",
        "jpn": "ナウル",
        "nld": "Nauru",
        "por": "Nauru",
        "rus": "Науру",
        "slk": "Nauru",
        "spa": "Nauru",
        "fin": "Nauru",
        "est": "Nauru",
        "zho": "瑙鲁",
        "pol": "Nauru",
        "urd": "ناورو",
        "kor": "나우루"
      }
    },
    "NP": {
      "currency": [
        "NPR"
      ],
      "callingCode": [
        "977"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-np",
      "name": {
        "common": "Nepal",
        "ces": "Nepál",
        "deu": "Nepal",
        "fra": "Népal",
        "hrv": "Nepal",
        "ita": "Nepal",
        "jpn": "ネパール",
        "nld": "Nepal",
        "por": "Nepal",
        "rus": "Непал",
        "slk": "Nepál",
        "spa": "Nepal",
        "fin": "Nepal",
        "est": "Nepal",
        "zho": "尼泊尔",
        "pol": "Nepal",
        "urd": "نیپال",
        "kor": "네팔"
      }
    },
    "NL": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "31"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-nl",
      "name": {
        "common": "Netherlands",
        "ces": "Nizozemsko",
        "deu": "Niederlande",
        "fra": "Pays-Bas",
        "hrv": "Nizozemska",
        "ita": "Paesi Bassi",
        "jpn": "オランダ",
        "nld": "Nederland",
        "por": "Holanda",
        "rus": "Нидерланды",
        "slk": "Holansko",
        "spa": "Países Bajos",
        "fin": "Alankomaat",
        "est": "Holland",
        "zho": "荷兰",
        "pol": "Holandia",
        "urd": "نیدرلینڈز",
        "kor": "네덜란드"
      }
    },
    "NC": {
      "currency": [
        "XPF"
      ],
      "callingCode": [
        "687"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "flag": "flag-nc",
      "name": {
        "common": "New Caledonia",
        "ces": "Nová Kaledonie",
        "deu": "Neukaledonien",
        "fra": "Nouvelle-Calédonie",
        "hrv": "Nova Kaledonija",
        "ita": "Nuova Caledonia",
        "jpn": "ニューカレドニア",
        "nld": "Nieuw-Caledonië",
        "por": "Nova Caledónia",
        "rus": "Новая Каледония",
        "slk": "Nová Kaledónia",
        "spa": "Nueva Caledonia",
        "fin": "Uusi-Kaledonia",
        "est": "Uus-Kaledoonia",
        "zho": "新喀里多尼亚",
        "pol": "Nowa Kaledonia",
        "urd": "نیو کیلیڈونیا",
        "kor": "누벨칼레도니"
      }
    },
    "NZ": {
      "currency": [
        "NZD"
      ],
      "callingCode": [
        "64"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "flag": "flag-nz",
      "name": {
        "common": "New Zealand",
        "ces": "Nový Zéland",
        "deu": "Neuseeland",
        "fra": "Nouvelle-Zélande",
        "hrv": "Novi Zeland",
        "ita": "Nuova Zelanda",
        "jpn": "ニュージーランド",
        "nld": "Nieuw-Zeeland",
        "por": "Nova Zelândia",
        "rus": "Новая Зеландия",
        "slk": "Nový Zéland",
        "spa": "Nueva Zelanda",
        "fin": "Uusi-Seelanti",
        "est": "Uus-Meremaa",
        "zho": "新西兰",
        "pol": "Nowa Zelandia",
        "urd": "نیوزی لینڈ",
        "kor": "뉴질랜드"
      }
    },
    "NI": {
      "currency": [
        "NIO"
      ],
      "callingCode": [
        "505"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "flag": "flag-ni",
      "name": {
        "common": "Nicaragua",
        "ces": "Nikaragua",
        "deu": "Nicaragua",
        "fra": "Nicaragua",
        "hrv": "Nikaragva",
        "ita": "Nicaragua",
        "jpn": "ニカラグア",
        "nld": "Nicaragua",
        "por": "Nicarágua",
        "rus": "Никарагуа",
        "slk": "Nikaragua",
        "spa": "Nicaragua",
        "fin": "Nicaragua",
        "est": "Nicaragua",
        "zho": "尼加拉瓜",
        "pol": "Nikaragua",
        "urd": "نکاراگوا",
        "kor": "니카라과"
      }
    },
    "NE": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "227"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-ne",
      "name": {
        "common": "Niger",
        "ces": "Niger",
        "deu": "Niger",
        "fra": "Niger",
        "hrv": "Niger",
        "ita": "Niger",
        "jpn": "ニジェール",
        "nld": "Niger",
        "por": "Níger",
        "rus": "Нигер",
        "slk": "Niger",
        "spa": "Níger",
        "fin": "Niger",
        "est": "Niger",
        "zho": "尼日尔",
        "pol": "Niger",
        "urd": "نائجر",
        "kor": "니제르"
      }
    },
    "NG": {
      "currency": [
        "NGN"
      ],
      "callingCode": [
        "234"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-ng",
      "name": {
        "common": "Nigeria",
        "ces": "Nigérie",
        "deu": "Nigeria",
        "fra": "Nigéria",
        "hrv": "Nigerija",
        "ita": "Nigeria",
        "jpn": "ナイジェリア",
        "nld": "Nigeria",
        "por": "Nigéria",
        "rus": "Нигерия",
        "slk": "Nigéria",
        "spa": "Nigeria",
        "fin": "Nigeria",
        "est": "Nigeeria",
        "zho": "尼日利亚",
        "pol": "Nigeria",
        "urd": "نائجیریا",
        "kor": "나이지리아"
      }
    },
    "NU": {
      "currency": [
        "NZD"
      ],
      "callingCode": [
        "683"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-nu",
      "name": {
        "common": "Niue",
        "ces": "Niue",
        "deu": "Niue",
        "fra": "Niue",
        "hrv": "Niue",
        "ita": "Niue",
        "jpn": "ニウエ",
        "nld": "Niue",
        "por": "Niue",
        "rus": "Ниуэ",
        "slk": "Niue",
        "spa": "Niue",
        "fin": "Niue",
        "est": "Niue",
        "zho": "纽埃",
        "pol": "Niue",
        "urd": "نیووے",
        "kor": "니우에"
      }
    },
    "NF": {
      "currency": [
        "AUD"
      ],
      "callingCode": [
        "672"
      ],
      "region": "Oceania",
      "subregion": "Australia and New Zealand",
      "flag": "flag-nf",
      "name": {
        "common": "Norfolk Island",
        "ces": "Norfolk",
        "deu": "Norfolkinsel",
        "fra": "Île Norfolk",
        "hrv": "Otok Norfolk",
        "ita": "Isola Norfolk",
        "jpn": "ノーフォーク島",
        "nld": "Norfolkeiland",
        "por": "Ilha Norfolk",
        "rus": "Норфолк",
        "slk": "Norfolk",
        "spa": "Isla de Norfolk",
        "fin": "Norfolkinsaari",
        "est": "Norfolk",
        "zho": "诺福克岛",
        "pol": "Wyspa Norfolk",
        "urd": "جزیرہ نورفک",
        "kor": "노퍽 섬"
      }
    },
    "KP": {
      "currency": [
        "KPW"
      ],
      "callingCode": [
        "850"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-kp",
      "name": {
        "common": "North Korea",
        "ces": "Severní Korea",
        "deu": "Nordkorea",
        "fra": "Corée du Nord",
        "hrv": "Sjeverna Koreja",
        "ita": "Corea del Nord",
        "jpn": "朝鮮民主主義人民共和国",
        "nld": "Noord-Korea",
        "por": "Coreia do Norte",
        "rus": "Северная Корея",
        "slk": "Kórejská ľudovodemokratická republika (KĽR, Severná Kórea)",
        "spa": "Corea del Norte",
        "fin": "Pohjois-Korea",
        "est": "Põhja-Korea",
        "zho": "朝鲜",
        "pol": "Korea Północna",
        "urd": "شمالی کوریا",
        "kor": "조선"
      }
    },
    "MP": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1670"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "flag": "flag-mp",
      "name": {
        "common": "Northern Mariana Islands",
        "ces": "Severní Mariany",
        "deu": "Nördliche Marianen",
        "fra": "Îles Mariannes du Nord",
        "hrv": "Sjevernomarijanski otoci",
        "ita": "Isole Marianne Settentrionali",
        "jpn": "北マリアナ諸島",
        "nld": "Noordelijke Marianeneilanden",
        "por": "Marianas Setentrionais",
        "rus": "Северные Марианские острова",
        "slk": "Severné Mariány",
        "spa": "Islas Marianas del Norte",
        "fin": "Pohjois-Mariaanit",
        "est": "Põhja-Mariaanid",
        "zho": "北马里亚纳群岛",
        "pol": "Mariany Północne",
        "urd": "جزائر شمالی ماریانا",
        "kor": "북마리아나 제도"
      }
    },
    "NO": {
      "currency": [
        "NOK"
      ],
      "callingCode": [
        "47"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-no",
      "name": {
        "common": "Norway",
        "ces": "Norsko",
        "deu": "Norwegen",
        "fra": "Norvège",
        "hrv": "Norveška",
        "ita": "Norvegia",
        "jpn": "ノルウェー",
        "nld": "Noorwegen",
        "por": "Noruega",
        "rus": "Норвегия",
        "slk": "Nórsko",
        "spa": "Noruega",
        "fin": "Norja",
        "est": "Norra",
        "zho": "挪威",
        "pol": "Norwegia",
        "urd": "ناروے",
        "kor": "노르웨이"
      }
    },
    "OM": {
      "currency": [
        "OMR"
      ],
      "callingCode": [
        "968"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-om",
      "name": {
        "common": "Oman",
        "ces": "Omán",
        "deu": "Oman",
        "fra": "Oman",
        "hrv": "Oman",
        "ita": "oman",
        "jpn": "オマーン",
        "nld": "Oman",
        "por": "Omã",
        "rus": "Оман",
        "slk": "Omán",
        "spa": "Omán",
        "fin": "Oman",
        "est": "Omaan",
        "zho": "阿曼",
        "pol": "Oman",
        "urd": "عمان",
        "kor": "오만"
      }
    },
    "PK": {
      "currency": [
        "PKR"
      ],
      "callingCode": [
        "92"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-pk",
      "name": {
        "common": "Pakistan",
        "ces": "Pákistán",
        "deu": "Pakistan",
        "fra": "Pakistan",
        "hrv": "Pakistan",
        "ita": "Pakistan",
        "jpn": "パキスタン",
        "nld": "Pakistan",
        "por": "Paquistão",
        "rus": "Пакистан",
        "slk": "Pakistan",
        "spa": "Pakistán",
        "fin": "Pakistan",
        "est": "Pakistan",
        "zho": "巴基斯坦",
        "pol": "Pakistan",
        "urd": "پاکستان",
        "kor": "파키스탄"
      }
    },
    "PW": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "680"
      ],
      "region": "Oceania",
      "subregion": "Micronesia",
      "flag": "flag-pw",
      "name": {
        "common": "Palau",
        "ces": "Palau",
        "deu": "Palau",
        "fra": "Palaos (Palau)",
        "hrv": "Palau",
        "ita": "Palau",
        "jpn": "パラオ",
        "nld": "Palau",
        "por": "Palau",
        "rus": "Палау",
        "slk": "Palau",
        "spa": "Palau",
        "fin": "Palau",
        "est": "Belau",
        "zho": "帕劳",
        "pol": "Palau",
        "urd": "پلاؤ",
        "kor": "팔라우"
      }
    },
    "PS": {
      "currency": [
        "ILS"
      ],
      "callingCode": [
        "970"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-ps",
      "name": {
        "common": "Palestine",
        "ces": "Palestina",
        "deu": "Palästina",
        "fra": "Palestine",
        "hrv": "Palestina",
        "ita": "Palestina",
        "jpn": "パレスチナ",
        "nld": "Palestijnse gebieden",
        "por": "Palestina",
        "rus": "Палестина",
        "slk": "Palestína",
        "spa": "Palestina",
        "fin": "Palestiina",
        "est": "Palestiina",
        "zho": "巴勒斯坦",
        "pol": "Palestyna",
        "urd": "فلسطین",
        "kor": "팔레스타인"
      }
    },
    "PA": {
      "currency": [
        "PAB",
        "USD"
      ],
      "callingCode": [
        "507"
      ],
      "region": "Americas",
      "subregion": "Central America",
      "flag": "flag-pa",
      "name": {
        "common": "Panama",
        "ces": "Panama",
        "deu": "Panama",
        "fra": "Panama",
        "hrv": "Panama",
        "ita": "Panama",
        "jpn": "パナマ",
        "nld": "Panama",
        "por": "Panamá",
        "rus": "Панама",
        "slk": "Panama",
        "spa": "Panamá",
        "fin": "Panama",
        "est": "Panama",
        "zho": "巴拿马",
        "pol": "Panama",
        "urd": "پاناما",
        "kor": "파나마"
      }
    },
    "PG": {
      "currency": [
        "PGK"
      ],
      "callingCode": [
        "675"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "flag": "flag-pg",
      "name": {
        "common": "Papua New Guinea",
        "ces": "Papua-Nová Guinea",
        "deu": "Papua-Neuguinea",
        "fra": "Papouasie-Nouvelle-Guinée",
        "hrv": "Papua Nova Gvineja",
        "ita": "Papua Nuova Guinea",
        "jpn": "パプアニューギニア",
        "nld": "Papoea-Nieuw-Guinea",
        "por": "Papua Nova Guiné",
        "rus": "Папуа — Новая Гвинея",
        "slk": "Papua-Nová Guinea",
        "spa": "Papúa Nueva Guinea",
        "fin": "Papua-Uusi-Guinea",
        "est": "Paapua Uus-Guinea",
        "zho": "巴布亚新几内亚",
        "pol": "Papua-Nowa Gwinea",
        "urd": "پاپوا نیو گنی",
        "kor": "파푸아뉴기니"
      }
    },
    "PY": {
      "currency": [
        "PYG"
      ],
      "callingCode": [
        "595"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-py",
      "name": {
        "common": "Paraguay",
        "ces": "Paraguay",
        "deu": "Paraguay",
        "fra": "Paraguay",
        "hrv": "Paragvaj",
        "ita": "Paraguay",
        "jpn": "パラグアイ",
        "nld": "Paraguay",
        "por": "Paraguai",
        "rus": "Парагвай",
        "slk": "Paraguaj",
        "spa": "Paraguay",
        "fin": "Paraguay",
        "est": "Paraguay",
        "zho": "巴拉圭",
        "pol": "Paragwaj",
        "urd": "پیراگوئے",
        "kor": "파라과이"
      }
    },
    "PE": {
      "currency": [
        "PEN"
      ],
      "callingCode": [
        "51"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-pe",
      "name": {
        "common": "Peru",
        "ces": "Peru",
        "deu": "Peru",
        "fra": "Pérou",
        "hrv": "Peru",
        "ita": "Perù",
        "jpn": "ペルー",
        "nld": "Peru",
        "por": "Perú",
        "rus": "Перу",
        "slk": "Peru",
        "spa": "Perú",
        "fin": "Peru",
        "est": "Peruu",
        "zho": "秘鲁",
        "pol": "Peru",
        "urd": "پیرو",
        "kor": "페루"
      }
    },
    "PH": {
      "currency": [
        "PHP"
      ],
      "callingCode": [
        "63"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-ph",
      "name": {
        "common": "Philippines",
        "ces": "Filipíny",
        "deu": "Philippinen",
        "fra": "Philippines",
        "hrv": "Filipini",
        "ita": "Filippine",
        "jpn": "フィリピン",
        "nld": "Filipijnen",
        "por": "Filipinas",
        "rus": "Филиппины",
        "slk": "Filipíny",
        "spa": "Filipinas",
        "fin": "Filippiinit",
        "est": "Filipiinid",
        "zho": "菲律宾",
        "pol": "Filipiny",
        "urd": "فلپائن",
        "kor": "필리핀"
      }
    },
    "PN": {
      "currency": [
        "NZD"
      ],
      "callingCode": [
        "64"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-pn",
      "name": {
        "common": "Pitcairn Islands",
        "ces": "Pitcairnovy ostrovy",
        "deu": "Pitcairninseln",
        "fra": "Îles Pitcairn",
        "hrv": "Pitcairnovo otočje",
        "ita": "Isole Pitcairn",
        "jpn": "ピトケアン",
        "nld": "Pitcairneilanden",
        "por": "Ilhas Pitcairn",
        "rus": "Острова Питкэрн",
        "slk": "Pitcairnove ostrovy",
        "spa": "Islas Pitcairn",
        "fin": "Pitcairn",
        "est": "Pitcairn",
        "zho": "皮特凯恩群岛",
        "pol": "Pitcairn",
        "urd": "جزائر پٹکیرن",
        "kor": "핏케언 제도"
      }
    },
    "PL": {
      "currency": [
        "PLN"
      ],
      "callingCode": [
        "48"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-pl",
      "name": {
        "common": "Poland",
        "ces": "Polsko",
        "deu": "Polen",
        "fra": "Pologne",
        "hrv": "Poljska",
        "ita": "Polonia",
        "jpn": "ポーランド",
        "nld": "Polen",
        "por": "Polónia",
        "rus": "Польша",
        "slk": "Poľsko",
        "spa": "Polonia",
        "fin": "Puola",
        "est": "Poola",
        "zho": "波兰",
        "pol": "Polska",
        "urd": "پولینڈ",
        "kor": "폴란드"
      }
    },
    "PT": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "351"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-pt",
      "name": {
        "common": "Portugal",
        "ces": "Portugalsko",
        "deu": "Portugal",
        "fra": "Portugal",
        "hrv": "Portugal",
        "ita": "Portogallo",
        "jpn": "ポルトガル",
        "nld": "Portugal",
        "por": "Portugal",
        "rus": "Португалия",
        "slk": "Portugalsko",
        "spa": "Portugal",
        "fin": "Portugali",
        "est": "Portugal",
        "zho": "葡萄牙",
        "pol": "Portugalia",
        "urd": "پرتگال",
        "kor": "포르투갈"
      }
    },
    "PR": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1787",
        "1939"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-pr",
      "name": {
        "common": "Puerto Rico",
        "ces": "Portoriko",
        "deu": "Puerto Rico",
        "fra": "Porto Rico",
        "hrv": "Portoriko",
        "ita": "Porto Rico",
        "jpn": "プエルトリコ",
        "nld": "Puerto Rico",
        "por": "Porto Rico",
        "rus": "Пуэрто-Рико",
        "slk": "Portoriko",
        "spa": "Puerto Rico",
        "fin": "Puerto Rico",
        "est": "Puerto Rico",
        "zho": "波多黎各",
        "pol": "Portoryko",
        "urd": "پورٹو ریکو",
        "kor": "푸에르토리코"
      }
    },
    "QA": {
      "currency": [
        "QAR"
      ],
      "callingCode": [
        "974"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-qa",
      "name": {
        "common": "Qatar",
        "ces": "Katar",
        "deu": "Katar",
        "fra": "Qatar",
        "hrv": "Katar",
        "ita": "Qatar",
        "jpn": "カタール",
        "nld": "Qatar",
        "por": "Catar",
        "rus": "Катар",
        "slk": "Katar",
        "spa": "Catar",
        "fin": "Qatar",
        "est": "Katar",
        "zho": "卡塔尔",
        "pol": "Katar",
        "urd": "قطر",
        "kor": "카타르"
      }
    },
    "CG": {
      "currency": [
        "XAF"
      ],
      "callingCode": [
        "242"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-cg",
      "name": {
        "common": "Republic of the Congo",
        "ces": "Kongo",
        "cym": "Gweriniaeth y Congo",
        "deu": "Kongo",
        "fra": "Congo",
        "hrv": "Kongo",
        "ita": "Congo",
        "jpn": "コンゴ共和国",
        "nld": "Congo",
        "por": "Congo",
        "rus": "Республика Конго",
        "slk": "Kongo",
        "spa": "Congo",
        "fin": "Kongo-Brazzaville",
        "est": "Kongo Vabariik",
        "zho": "刚果",
        "pol": "Kongo",
        "urd": "جمہوریہ کانگو",
        "kor": "콩고"
      }
    },
    "RO": {
      "currency": [
        "RON"
      ],
      "callingCode": [
        "40"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-ro",
      "name": {
        "common": "Romania",
        "ces": "Rumunsko",
        "deu": "Rumänien",
        "fra": "Roumanie",
        "hrv": "Rumunjska",
        "ita": "Romania",
        "jpn": "ルーマニア",
        "nld": "Roemenië",
        "por": "Roménia",
        "rus": "Румыния",
        "slk": "Rumunsko",
        "spa": "Rumania",
        "fin": "Romania",
        "est": "Rumeenia",
        "zho": "罗马尼亚",
        "pol": "Rumunia",
        "urd": "رومانیہ",
        "kor": "루마니아"
      }
    },
    "RU": {
      "currency": [
        "RUB"
      ],
      "callingCode": [
        "7"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-ru",
      "name": {
        "common": "Russia",
        "ces": "Rusko",
        "deu": "Russland",
        "fra": "Russie",
        "hrv": "Rusija",
        "ita": "Russia",
        "jpn": "ロシア連邦",
        "nld": "Rusland",
        "por": "Rússia",
        "rus": "Россия",
        "slk": "Rusko",
        "spa": "Rusia",
        "fin": "Venäjä",
        "est": "Venemaa",
        "zho": "俄罗斯",
        "pol": "Rosja",
        "urd": "روس",
        "kor": "러시아"
      }
    },
    "RW": {
      "currency": [
        "RWF"
      ],
      "callingCode": [
        "250"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-rw",
      "name": {
        "common": "Rwanda",
        "ces": "Rwanda",
        "deu": "Ruanda",
        "fra": "Rwanda",
        "hrv": "Ruanda",
        "ita": "Ruanda",
        "jpn": "ルワンダ",
        "nld": "Rwanda",
        "por": "Ruanda",
        "rus": "Руанда",
        "slk": "Rwanda",
        "spa": "Ruanda",
        "fin": "Ruanda",
        "est": "Rwanda",
        "zho": "卢旺达",
        "pol": "Rwanda",
        "urd": "روانڈا",
        "kor": "르완다"
      }
    },
    "RE": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "262"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-re",
      "name": {
        "common": "Réunion",
        "ces": "Réunion",
        "deu": "Réunion",
        "fra": "Réunion",
        "hrv": "Réunion",
        "ita": "Riunione",
        "jpn": "レユニオン",
        "nld": "Réunion",
        "por": "Reunião",
        "rus": "Реюньон",
        "slk": "Réunion",
        "spa": "Reunión",
        "fin": "Réunion",
        "est": "Réunion",
        "zho": "留尼旺岛",
        "pol": "Reunion",
        "urd": "رے یونیوں",
        "kor": "레위니옹"
      }
    },
    "BL": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "590"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-bl",
      "name": {
        "common": "Saint Barthélemy",
        "ces": "Svatý Bartoloměj",
        "deu": "Saint-Barthélemy",
        "fra": "Saint-Barthélemy",
        "hrv": "Saint Barthélemy",
        "ita": "Antille Francesi",
        "jpn": "サン・バルテルミー",
        "nld": "Saint Barthélemy",
        "por": "São Bartolomeu",
        "rus": "Сен-Бартелеми",
        "slk": "Svätý Bartolomej",
        "spa": "San Bartolomé",
        "fin": "Saint-Barthélemy",
        "est": "Saint-Barthélemy",
        "zho": "圣巴泰勒米",
        "pol": "Saint-Barthélemy",
        "urd": "سینٹ بارتھیملے",
        "kor": "생바르텔레미"
      }
    },
    "SH": {
      "currency": [
        "SHP",
        "GBP"
      ],
      "callingCode": [
        "290",
        "247"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-sh",
      "name": {
        "common": "Saint Helena, Ascension and Tristan da Cunha",
        "ces": "Svatá Helena, Ascension a Tristan da Cunha",
        "deu": "St. Helena, Ascension und Tristan da Cunha",
        "fra": "Sainte-Hélène, Ascension et Tristan da Cunha",
        "hrv": "Sveta Helena",
        "ita": "Sant'Elena, Ascensione e Tristan da Cunha",
        "jpn": "セントヘレナ・アセンションおよびトリスタンダクーニャ",
        "nld": "Sint-Helena, Ascension en Tristan da Cunha",
        "por": "Santa Helena, Ascensão e Tristão da Cunha",
        "rus": "Острова Святой Елены, Вознесения и Тристан-да-Кунья",
        "slk": "Svätá Helena (zámorské územie)",
        "spa": "Santa Elena, Ascensión y Tristán de Acuña",
        "fin": "Saint Helena, Ascension ja Tristan da Cunha",
        "est": "Saint Helena, Ascension ja Tristan da Cunha",
        "zho": "圣赫勒拿、阿森松和特里斯坦-达库尼亚",
        "pol": "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
        "urd": "سینٹ ہلینا، اسینشن و ترسٹان دا کونیا",
        "kor": "세인트헬레나"
      }
    },
    "KN": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1869"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-kn",
      "name": {
        "common": "Saint Kitts and Nevis",
        "ces": "Svatý Kryštof a Nevis",
        "deu": "St. Kitts und Nevis",
        "fra": "Saint-Christophe-et-Niévès",
        "hrv": "Sveti Kristof i Nevis",
        "ita": "Saint Kitts e Nevis",
        "jpn": "セントクリストファー・ネイビス",
        "nld": "Saint Kitts en Nevis",
        "por": "São Cristóvão e Nevis",
        "rus": "Сент-Китс и Невис",
        "slk": "Svätý Krištof a Nevis",
        "spa": "San Cristóbal y Nieves",
        "fin": "Saint Kitts ja Nevis",
        "est": "Saint Kitts ja Nevis",
        "zho": "圣基茨和尼维斯",
        "pol": "Saint Kitts i Nevis",
        "urd": "سینٹ کیٹز و ناویس",
        "kor": "세인트키츠 네비스"
      }
    },
    "LC": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1758"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-lc",
      "name": {
        "common": "Saint Lucia",
        "ces": "Svatá Lucie",
        "deu": "St. Lucia",
        "fra": "Sainte-Lucie",
        "hrv": "Sveta Lucija",
        "ita": "Santa Lucia",
        "jpn": "セントルシア",
        "nld": "Saint Lucia",
        "por": "Santa Lúcia",
        "rus": "Сент-Люсия",
        "slk": "Svätá Lucia",
        "spa": "Santa Lucía",
        "fin": "Saint Lucia",
        "est": "Saint Lucia",
        "zho": "圣卢西亚",
        "pol": "Saint Lucia",
        "urd": "سینٹ لوسیا",
        "kor": "세인트루시아"
      }
    },
    "MF": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "590"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-mf",
      "name": {
        "common": "Saint Martin",
        "ces": "Svatý Martin (Francie)",
        "deu": "Saint-Martin",
        "fra": "Saint-Martin",
        "hrv": "Sveti Martin",
        "ita": "Saint Martin",
        "jpn": "サン・マルタン（フランス領）",
        "nld": "Saint-Martin",
        "por": "São Martinho",
        "rus": "Сен-Мартен",
        "slk": "Saint-Martin",
        "spa": "Saint Martin",
        "fin": "Saint-Martin",
        "est": "Saint-Martin",
        "zho": "圣马丁",
        "pol": "Saint-Martin",
        "urd": "سینٹ مارٹن",
        "kor": "생마르탱"
      }
    },
    "PM": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "508"
      ],
      "region": "Americas",
      "subregion": "North America",
      "flag": "flag-pm",
      "name": {
        "common": "Saint Pierre and Miquelon",
        "ces": "Saint-Pierre a Miquelon",
        "deu": "St. Pierre und Miquelon",
        "fra": "Saint-Pierre-et-Miquelon",
        "hrv": "Sveti Petar i Mikelon",
        "ita": "Saint-Pierre e Miquelon",
        "jpn": "サンピエール島・ミクロン島",
        "nld": "Saint Pierre en Miquelon",
        "por": "Saint-Pierre e Miquelon",
        "rus": "Сен-Пьер и Микелон",
        "slk": "Saint Pierre a Miquelon",
        "spa": "San Pedro y Miquelón",
        "fin": "Saint-Pierre ja Miquelon",
        "est": "Saint-Pierre ja Miquelon",
        "zho": "圣皮埃尔和密克隆",
        "pol": "Saint-Pierre i Miquelon",
        "urd": "سینٹ پیئر و میکیلون",
        "kor": "생피에르 미클롱"
      }
    },
    "VC": {
      "currency": [
        "XCD"
      ],
      "callingCode": [
        "1784"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-vc",
      "name": {
        "common": "Saint Vincent and the Grenadines",
        "ces": "Svatý Vincenc a Grenadiny",
        "deu": "St. Vincent und die Grenadinen",
        "fra": "Saint-Vincent-et-les-Grenadines",
        "hrv": "Sveti Vincent i Grenadini",
        "ita": "Saint Vincent e Grenadine",
        "jpn": "セントビンセントおよびグレナディーン諸島",
        "nld": "Saint Vincent en de Grenadines",
        "por": "São Vincente e Granadinas",
        "rus": "Сент-Винсент и Гренадины",
        "slk": "Svätý Vincent a Grenadíny",
        "spa": "San Vicente y Granadinas",
        "fin": "Saint Vincent ja Grenadiinit",
        "est": "Saint Vincent",
        "zho": "圣文森特和格林纳丁斯",
        "pol": "Saint Vincent i Grenadyny",
        "urd": "سینٹ وینسینٹ و گریناڈائنز",
        "kor": "세인트빈센트 그레나딘"
      }
    },
    "WS": {
      "currency": [
        "WST"
      ],
      "callingCode": [
        "685"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-ws",
      "name": {
        "common": "Samoa",
        "ces": "Samoa",
        "deu": "Samoa",
        "fra": "Samoa",
        "hrv": "Samoa",
        "ita": "Samoa",
        "jpn": "サモア",
        "nld": "Samoa",
        "por": "Samoa",
        "rus": "Самоа",
        "slk": "Samoa",
        "spa": "Samoa",
        "fin": "Samoa",
        "est": "Samoa",
        "zho": "萨摩亚",
        "pol": "Samoa",
        "urd": "سامووا",
        "kor": "사모아"
      }
    },
    "SM": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "378"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-sm",
      "name": {
        "common": "San Marino",
        "ces": "San Marino",
        "deu": "San Marino",
        "fra": "Saint-Marin",
        "hrv": "San Marino",
        "ita": "San Marino",
        "jpn": "サンマリノ",
        "nld": "San Marino",
        "por": "San Marino",
        "rus": "Сан-Марино",
        "slk": "San Maríno",
        "spa": "San Marino",
        "fin": "San Marino",
        "est": "San Marino",
        "zho": "圣马力诺",
        "pol": "San Marino",
        "urd": "سان مارینو",
        "kor": "산마리노"
      }
    },
    "SA": {
      "currency": [
        "SAR"
      ],
      "callingCode": [
        "966"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-sa",
      "name": {
        "common": "Saudi Arabia",
        "ces": "Saúdská Arábie",
        "deu": "Saudi-Arabien",
        "fra": "Arabie Saoudite",
        "hrv": "Saudijska Arabija",
        "ita": "Arabia Saudita",
        "jpn": "サウジアラビア",
        "nld": "Saoedi-Arabië",
        "por": "Arábia Saudita",
        "rus": "Саудовская Аравия",
        "slk": "Saudská Arábia",
        "spa": "Arabia Saudí",
        "fin": "Saudi-Arabia",
        "est": "Saudi Araabia",
        "zho": "沙特阿拉伯",
        "pol": "Arabia Saudyjska",
        "urd": "سعودی عرب",
        "kor": "사우디아라비아"
      }
    },
    "SN": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "221"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-sn",
      "name": {
        "common": "Senegal",
        "ces": "Senegal",
        "deu": "Senegal",
        "fra": "Sénégal",
        "hrv": "Senegal",
        "ita": "Senegal",
        "jpn": "セネガル",
        "nld": "Senegal",
        "por": "Senegal",
        "rus": "Сенегал",
        "slk": "Senegal",
        "spa": "Senegal",
        "fin": "Senegal",
        "est": "Senegal",
        "zho": "塞内加尔",
        "pol": "Senegal",
        "urd": "سینیگال",
        "kor": "세네갈"
      }
    },
    "RS": {
      "currency": [
        "RSD"
      ],
      "callingCode": [
        "381"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-rs",
      "name": {
        "common": "Serbia",
        "ces": "Srbsko",
        "deu": "Serbien",
        "fra": "Serbie",
        "hrv": "Srbija",
        "ita": "Serbia",
        "jpn": "セルビア",
        "nld": "Servië",
        "por": "Sérvia",
        "rus": "Сербия",
        "slk": "Srbsko",
        "spa": "Serbia",
        "fin": "Serbia",
        "est": "Serbia",
        "zho": "塞尔维亚",
        "pol": "Serbia",
        "urd": "سربیا",
        "kor": "세르비아"
      }
    },
    "SC": {
      "currency": [
        "SCR"
      ],
      "callingCode": [
        "248"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-sc",
      "name": {
        "common": "Seychelles",
        "ces": "Seychely",
        "deu": "Seychellen",
        "fra": "Seychelles",
        "hrv": "Sejšeli",
        "ita": "Seychelles",
        "jpn": "セーシェル",
        "nld": "Seychellen",
        "por": "Seicheles",
        "rus": "Сейшельские Острова",
        "slk": "Seychely",
        "spa": "Seychelles",
        "fin": "Seychellit",
        "est": "Seišellid",
        "zho": "塞舌尔",
        "pol": "Seszele",
        "urd": "سیچیلیس",
        "kor": "세이셸"
      }
    },
    "SL": {
      "currency": [
        "SLL"
      ],
      "callingCode": [
        "232"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-sl",
      "name": {
        "common": "Sierra Leone",
        "ces": "Sierra Leone",
        "deu": "Sierra Leone",
        "fra": "Sierra Leone",
        "hrv": "Sijera Leone",
        "ita": "Sierra Leone",
        "jpn": "シエラレオネ",
        "nld": "Sierra Leone",
        "por": "Serra Leoa",
        "rus": "Сьерра-Леоне",
        "slk": "Sierra Leone",
        "spa": "Sierra Leone",
        "fin": "Sierra Leone",
        "est": "Sierra Leone",
        "zho": "塞拉利昂",
        "pol": "Sierra Leone",
        "urd": "سیرالیون",
        "kor": "시에라리온"
      }
    },
    "SG": {
      "currency": [
        "SGD"
      ],
      "callingCode": [
        "65"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-sg",
      "name": {
        "common": "Singapore",
        "ces": "Singapur",
        "deu": "Singapur",
        "fra": "Singapour",
        "hrv": "Singapur",
        "ita": "Singapore",
        "jpn": "シンガポール",
        "nld": "Singapore",
        "por": "Singapura",
        "rus": "Сингапур",
        "slk": "Singapur",
        "spa": "Singapur",
        "fin": "Singapore",
        "est": "Singapur",
        "pol": "Singapur",
        "urd": "سنگاپور",
        "kor": "싱가포르"
      }
    },
    "SX": {
      "currency": [
        "ANG"
      ],
      "callingCode": [
        "1721"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-sx",
      "name": {
        "common": "Sint Maarten",
        "ces": "Svatý Martin (Nizozemsko)",
        "deu": "Sint Maarten",
        "fra": "Saint-Martin",
        "hrv": "Sveti Martin",
        "ita": "Sint Maarten",
        "jpn": "シント・マールテン",
        "nld": "Sint Maarten",
        "por": "São Martinho",
        "rus": "Синт-Мартен",
        "slk": "Sint Maarten",
        "spa": "Sint Maarten",
        "fin": "Sint Maarten",
        "est": "Sint Maarten",
        "zho": "圣马丁岛",
        "pol": "Sint Maarten",
        "urd": "سنٹ مارٹن",
        "kor": "신트마르턴"
      }
    },
    "SK": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "421"
      ],
      "region": "Europe",
      "subregion": "Central Europe",
      "flag": "flag-sk",
      "name": {
        "common": "Slovakia",
        "ces": "Slovensko",
        "deu": "Slowakei",
        "fra": "Slovaquie",
        "hrv": "Slovačka",
        "ita": "Slovacchia",
        "jpn": "スロバキア",
        "nld": "Slowakije",
        "por": "Eslováquia",
        "rus": "Словакия",
        "slk": "Slovensko",
        "spa": "República Eslovaca",
        "fin": "Slovakia",
        "est": "Slovakkia",
        "zho": "斯洛伐克",
        "pol": "Słowacja",
        "urd": "سلوواکیہ",
        "kor": "슬로바키아"
      }
    },
    "SI": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "386"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-si",
      "name": {
        "common": "Slovenia",
        "ces": "Slovinsko",
        "deu": "Slowenien",
        "fra": "Slovénie",
        "hrv": "Slovenija",
        "ita": "Slovenia",
        "jpn": "スロベニア",
        "nld": "Slovenië",
        "por": "Eslovénia",
        "rus": "Словения",
        "slk": "Slovinsko",
        "spa": "Eslovenia",
        "fin": "Slovenia",
        "est": "Sloveenia",
        "zho": "斯洛文尼亚",
        "pol": "Słowenia",
        "urd": "سلووینیا",
        "kor": "슬로베니아"
      }
    },
    "SB": {
      "currency": [
        "SBD"
      ],
      "callingCode": [
        "677"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "flag": "flag-sb",
      "name": {
        "common": "Solomon Islands",
        "ces": "Šalamounovy ostrovy",
        "deu": "Salomonen",
        "fra": "Îles Salomon",
        "hrv": "Solomonski Otoci",
        "ita": "Isole Salomone",
        "jpn": "ソロモン諸島",
        "nld": "Salomonseilanden",
        "por": "Ilhas Salomão",
        "rus": "Соломоновы Острова",
        "slk": "Salomonove ostrovy",
        "spa": "Islas Salomón",
        "fin": "Salomonsaaret",
        "est": "Saalomoni Saared",
        "zho": "所罗门群岛",
        "pol": "Wyspy Salomona",
        "urd": "جزائر سلیمان",
        "kor": "솔로몬 제도"
      }
    },
    "SO": {
      "currency": [
        "SOS"
      ],
      "callingCode": [
        "252"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-so",
      "name": {
        "common": "Somalia",
        "ces": "Somálsko",
        "deu": "Somalia",
        "fra": "Somalie",
        "hrv": "Somalija",
        "ita": "Somalia",
        "jpn": "ソマリア",
        "nld": "Somalië",
        "por": "Somália",
        "rus": "Сомали",
        "slk": "Somálsko",
        "spa": "Somalia",
        "fin": "Somalia",
        "est": "Somaalia",
        "zho": "索马里",
        "pol": "Somalia",
        "urd": "صومالیہ",
        "kor": "소말리아"
      }
    },
    "ZA": {
      "currency": [
        "ZAR"
      ],
      "callingCode": [
        "27"
      ],
      "region": "Africa",
      "subregion": "Southern Africa",
      "flag": "flag-za",
      "name": {
        "common": "South Africa",
        "ces": "Jihoafrická republika",
        "deu": "Südafrika",
        "fra": "Afrique du Sud",
        "hrv": "Južnoafrička Republika",
        "ita": "Sud Africa",
        "jpn": "南アフリカ",
        "nld": "Zuid-Afrika",
        "por": "África do Sul",
        "rus": "Южно-Африканская Республика",
        "slk": "Juhoafrická republika",
        "spa": "República de Sudáfrica",
        "fin": "Etelä-Afrikka",
        "est": "Lõuna-Aafrika Vabariik",
        "zho": "南非",
        "pol": "Południowa Afryka",
        "urd": "جنوبی افریقا",
        "kor": "남아프리카"
      }
    },
    "GS": {
      "currency": [
        "GBP"
      ],
      "callingCode": [
        "500"
      ],
      "region": "Antarctic",
      "subregion": "",
      "flag": "flag-gs",
      "name": {
        "common": "South Georgia",
        "ces": "Jižní Georgie a Jižní Sandwichovy ostrovy",
        "deu": "Südgeorgien und die Südlichen Sandwichinseln",
        "fra": "Géorgie du Sud-et-les Îles Sandwich du Sud",
        "hrv": "Južna Georgija i otočje Južni Sandwich",
        "ita": "Georgia del Sud e Isole Sandwich Meridionali",
        "jpn": "サウスジョージア・サウスサンドウィッチ諸島",
        "nld": "Zuid-Georgia en Zuidelijke Sandwicheilanden",
        "por": "Ilhas Geórgia do Sul e Sandwich do Sul",
        "rus": "Южная Георгия и Южные Сандвичевы острова",
        "slk": "Južná Georgia a Južné Sandwichove ostrovy",
        "spa": "Islas Georgias del Sur y Sandwich del Sur",
        "fin": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
        "est": "Lõuna-Georgia ja Lõuna-Sandwichi saared",
        "zho": "南乔治亚",
        "pol": "Georgia Południowa i Sandwich Południowy",
        "urd": "جنوبی جارجیا",
        "kor": "조지아"
      }
    },
    "KR": {
      "currency": [
        "KRW"
      ],
      "callingCode": [
        "82"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-kr",
      "name": {
        "common": "South Korea",
        "ces": "Jižní Korea",
        "deu": "Südkorea",
        "fra": "Corée du Sud",
        "hrv": "Južna Koreja",
        "ita": "Corea del Sud",
        "jpn": "韓国",
        "nld": "Zuid-Korea",
        "por": "Coreia do Sul",
        "rus": "Южная Корея",
        "slk": "Južná Kórea",
        "spa": "Corea del Sur",
        "fin": "Etelä-Korea",
        "est": "Lõuna-Korea",
        "zho": "韩国",
        "pol": "Korea Południowa",
        "urd": "جنوبی کوریا",
        "kor": "한국"
      }
    },
    "SS": {
      "currency": [
        "SSP"
      ],
      "callingCode": [
        "211"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-ss",
      "name": {
        "common": "South Sudan",
        "ces": "Jižní Súdán",
        "deu": "Südsudan",
        "fra": "Soudan du Sud",
        "hrv": "Južni Sudan",
        "ita": "Sudan del sud",
        "jpn": "南スーダン",
        "nld": "Zuid-Soedan",
        "por": "Sudão do Sul",
        "rus": "Южный Судан",
        "slk": "Južný Sudán",
        "spa": "Sudán del Sur",
        "fin": "Etelä-Sudan",
        "est": "Lõuna-Sudaan",
        "zho": "南苏丹",
        "pol": "Sudan",
        "urd": "جنوبی سوڈان",
        "kor": "남수단"
      }
    },
    "ES": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "34"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-es",
      "name": {
        "common": "Spain",
        "ces": "Španělsko",
        "deu": "Spanien",
        "fra": "Espagne",
        "hrv": "Španjolska",
        "ita": "Spagna",
        "jpn": "スペイン",
        "nld": "Spanje",
        "por": "Espanha",
        "rus": "Испания",
        "slk": "Španielsko",
        "spa": "España",
        "fin": "Espanja",
        "est": "Hispaania",
        "zho": "西班牙",
        "pol": "Hiszpania",
        "urd": "ہسپانیہ",
        "kor": "스페인"
      }
    },
    "LK": {
      "currency": [
        "LKR"
      ],
      "callingCode": [
        "94"
      ],
      "region": "Asia",
      "subregion": "Southern Asia",
      "flag": "flag-lk",
      "name": {
        "common": "Sri Lanka",
        "ces": "Srí Lanka",
        "deu": "Sri Lanka",
        "fra": "Sri Lanka",
        "hrv": "Šri Lanka",
        "ita": "Sri Lanka",
        "jpn": "スリランカ",
        "nld": "Sri Lanka",
        "por": "Sri Lanka",
        "rus": "Шри-Ланка",
        "slk": "Srí Lanka",
        "spa": "Sri Lanka",
        "fin": "Sri Lanka",
        "est": "Sri Lanka",
        "zho": "斯里兰卡",
        "pol": "Sri Lanka",
        "urd": "سری لنکا",
        "kor": "스리랑카"
      }
    },
    "SD": {
      "currency": [
        "SDG"
      ],
      "callingCode": [
        "249"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "flag": "flag-sd",
      "name": {
        "common": "Sudan",
        "ces": "Súdán",
        "deu": "Sudan",
        "fra": "Soudan",
        "hrv": "Sudan",
        "ita": "Sudan",
        "jpn": "スーダン",
        "nld": "Soedan",
        "por": "Sudão",
        "rus": "Судан",
        "slk": "Sudán",
        "spa": "Sudán",
        "fin": "Sudan",
        "est": "Sudaan",
        "zho": "苏丹",
        "pol": "Sudan",
        "urd": "سودان",
        "kor": "수단"
      }
    },
    "SR": {
      "currency": [
        "SRD"
      ],
      "callingCode": [
        "597"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-sr",
      "name": {
        "common": "Suriname",
        "ces": "Surinam",
        "deu": "Suriname",
        "fra": "Surinam",
        "hrv": "Surinam",
        "ita": "Suriname",
        "jpn": "スリナム",
        "nld": "Suriname",
        "por": "Suriname",
        "rus": "Суринам",
        "slk": "Surinam",
        "spa": "Surinam",
        "fin": "Suriname",
        "est": "Suriname",
        "zho": "苏里南",
        "pol": "Surinam",
        "urd": "سرینام",
        "kor": "수리남"
      }
    },
    "SJ": {
      "currency": [
        "NOK"
      ],
      "callingCode": [
        "4779"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-sj",
      "name": {
        "common": "Svalbard and Jan Mayen",
        "ces": "Špicberky a Jan Mayen",
        "deu": "Spitzbergen und Jan Mayen",
        "fra": "Svalbard et Jan Mayen",
        "hrv": "Svalbard i Jan Mayen",
        "ita": "Svalbard e Jan Mayen",
        "jpn": "スヴァールバル諸島およびヤンマイエン島",
        "nld": "Svalbard en Jan Mayen",
        "por": "Ilhas Svalbard e Jan Mayen",
        "rus": "Шпицберген и Ян-Майен",
        "slk": "Svalbard a Jan Mayen",
        "spa": "Islas Svalbard y Jan Mayen",
        "fin": "Huippuvuoret",
        "est": "Svalbard",
        "zho": "斯瓦尔巴特",
        "pol": "Svalbard i Jan Mayen",
        "urd": "سوالبارڈ اور جان میئن",
        "kor": "스발바르 얀마옌 제도"
      }
    },
    "SE": {
      "currency": [
        "SEK"
      ],
      "callingCode": [
        "46"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-se",
      "name": {
        "common": "Sweden",
        "ces": "Švédsko",
        "deu": "Schweden",
        "fra": "Suède",
        "hrv": "Švedska",
        "ita": "Svezia",
        "jpn": "スウェーデン",
        "nld": "Zweden",
        "por": "Suécia",
        "rus": "Швеция",
        "slk": "Švédsko",
        "spa": "Suecia",
        "fin": "Ruotsi",
        "est": "Rootsi",
        "zho": "瑞典",
        "pol": "Szwecja",
        "urd": "سویڈن",
        "kor": "스웨덴"
      }
    },
    "CH": {
      "currency": [
        "CHF"
      ],
      "callingCode": [
        "41"
      ],
      "region": "Europe",
      "subregion": "Western Europe",
      "flag": "flag-ch",
      "name": {
        "common": "Switzerland",
        "ces": "Švýcarsko",
        "deu": "Schweiz",
        "fra": "Suisse",
        "hrv": "Švicarska",
        "ita": "Svizzera",
        "jpn": "スイス",
        "nld": "Zwitserland",
        "por": "Suíça",
        "rus": "Швейцария",
        "slk": "Švajčiarsko",
        "spa": "Suiza",
        "fin": "Sveitsi",
        "est": "Šveits",
        "zho": "瑞士",
        "pol": "Szwajcaria",
        "urd": "سویٹذرلینڈ",
        "kor": "스위스"
      }
    },
    "SY": {
      "currency": [
        "SYP"
      ],
      "callingCode": [
        "963"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-sy",
      "name": {
        "common": "Syria",
        "ces": "Sýrie",
        "deu": "Syrien",
        "fra": "Syrie",
        "hrv": "Sirija",
        "ita": "Siria",
        "jpn": "シリア・アラブ共和国",
        "nld": "Syrië",
        "por": "Síria",
        "rus": "Сирия",
        "slk": "Sýria",
        "spa": "Siria",
        "fin": "Syyria",
        "est": "Süüria",
        "zho": "叙利亚",
        "pol": "Syria",
        "urd": "سوریہ",
        "kor": "시리아"
      }
    },
    "ST": {
      "currency": [
        "STD"
      ],
      "callingCode": [
        "239"
      ],
      "region": "Africa",
      "subregion": "Middle Africa",
      "flag": "flag-st",
      "name": {
        "common": "São Tomé and Príncipe",
        "ces": "Svatý Tomáš a Princův ostrov",
        "deu": "São Tomé und Príncipe",
        "fra": "São Tomé et Príncipe",
        "hrv": "Sveti Toma i Princip",
        "ita": "São Tomé e Príncipe",
        "jpn": "サントメ・プリンシペ",
        "nld": "Sao Tomé en Principe",
        "por": "São Tomé e Príncipe",
        "spa": "Santo Tomé y Príncipe",
        "rus": "Сан-Томе и Принсипи",
        "slk": "Svätý Tomáš a Princov ostrov",
        "fin": "São Téme ja Príncipe",
        "est": "São Tomé ja Príncipe",
        "zho": "圣多美和普林西比",
        "pol": "Wyspy Świętego Tomasza i Książęca",
        "urd": "ساؤ ٹومے و پرنسپے",
        "kor": "상투메 프린시페"
      }
    },
    "TW": {
      "currency": [
        "TWD"
      ],
      "callingCode": [
        "886"
      ],
      "region": "Asia",
      "subregion": "Eastern Asia",
      "flag": "flag-tw",
      "name": {
        "common": "Taiwan",
        "ces": "Tchaj-wan",
        "deu": "Taiwan",
        "fra": "Taïwan",
        "hrv": "Tajvan",
        "ita": "Taiwan",
        "jpn": "台湾",
        "nld": "Taiwan",
        "por": "Ilha Formosa",
        "rus": "Тайвань",
        "slk": "Taiwan",
        "spa": "Taiwán",
        "fin": "Taiwan",
        "est": "Taiwan",
        "pol": "Tajwan",
        "urd": "تائیوان",
        "kor": "대만"
      }
    },
    "TJ": {
      "currency": [
        "TJS"
      ],
      "callingCode": [
        "992"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "flag": "flag-tj",
      "name": {
        "common": "Tajikistan",
        "ces": "Tádžikistán",
        "deu": "Tadschikistan",
        "fra": "Tadjikistan",
        "hrv": "Tađikistan",
        "ita": "Tagikistan",
        "jpn": "タジキスタン",
        "nld": "Tadzjikistan",
        "por": "Tajiquistão",
        "rus": "Таджикистан",
        "slk": "Taǆikistan",
        "spa": "Tayikistán",
        "fin": "Tadžikistan",
        "est": "Tadžikistan",
        "zho": "塔吉克斯坦",
        "pol": "Tadżykistan",
        "urd": "تاجکستان",
        "kor": "타지키스탄"
      }
    },
    "TZ": {
      "currency": [
        "TZS"
      ],
      "callingCode": [
        "255"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-tz",
      "name": {
        "common": "Tanzania",
        "ces": "Tanzanie",
        "deu": "Tansania",
        "fra": "Tanzanie",
        "hrv": "Tanzanija",
        "ita": "Tanzania",
        "jpn": "タンザニア",
        "nld": "Tanzania",
        "por": "Tanzânia",
        "rus": "Танзания",
        "slk": "Tanzánia",
        "spa": "Tanzania",
        "fin": "Tansania",
        "est": "Tansaania",
        "zho": "坦桑尼亚",
        "pol": "Tanzania",
        "urd": "تنزانیہ",
        "kor": "탄자니아"
      }
    },
    "TH": {
      "currency": [
        "THB"
      ],
      "callingCode": [
        "66"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-th",
      "name": {
        "common": "Thailand",
        "ces": "Thajsko",
        "deu": "Thailand",
        "fra": "Thaïlande",
        "hrv": "Tajland",
        "ita": "Tailandia",
        "jpn": "タイ",
        "nld": "Thailand",
        "por": "Tailândia",
        "rus": "Таиланд",
        "slk": "Thajsko",
        "spa": "Tailandia",
        "fin": "Thaimaa",
        "est": "Tai",
        "zho": "泰国",
        "pol": "Tajlandia",
        "urd": "تھائی لینڈ",
        "kor": "태국"
      }
    },
    "TL": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "670"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-tl",
      "name": {
        "common": "Timor-Leste",
        "ces": "Východní Timor",
        "deu": "Osttimor",
        "fra": "Timor oriental",
        "hrv": "Istočni Timor",
        "ita": "Timor Est",
        "jpn": "東ティモール",
        "nld": "Oost-Timor",
        "por": "Timor-Leste",
        "rus": "Восточный Тимор",
        "slk": "Východný Timor",
        "spa": "Timor Oriental",
        "fin": "Itä-Timor",
        "est": "Ida-Timor",
        "zho": "东帝汶",
        "pol": "Timor Wschodni",
        "urd": "مشرقی تیمور",
        "kor": "동티모르"
      }
    },
    "TG": {
      "currency": [
        "XOF"
      ],
      "callingCode": [
        "228"
      ],
      "region": "Africa",
      "subregion": "Western Africa",
      "flag": "flag-tg",
      "name": {
        "common": "Togo",
        "ces": "Togo",
        "deu": "Togo",
        "fra": "Togo",
        "hrv": "Togo",
        "ita": "Togo",
        "jpn": "トーゴ",
        "nld": "Togo",
        "por": "Togo",
        "rus": "Того",
        "slk": "Togo",
        "spa": "Togo",
        "fin": "Togo",
        "est": "Togo",
        "zho": "多哥",
        "pol": "Togo",
        "urd": "ٹوگو",
        "kor": "토고"
      }
    },
    "TK": {
      "currency": [
        "NZD"
      ],
      "callingCode": [
        "690"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-tk",
      "name": {
        "common": "Tokelau",
        "ces": "Tokelau",
        "deu": "Tokelau",
        "fra": "Tokelau",
        "hrv": "Tokelau",
        "ita": "Isole Tokelau",
        "jpn": "トケラウ",
        "nld": "Tokelau",
        "por": "Tokelau",
        "rus": "Токелау",
        "slk": "Tokelau",
        "spa": "Islas Tokelau",
        "fin": "Tokelau",
        "est": "Tokelau",
        "zho": "托克劳",
        "pol": "Tokelau",
        "urd": "ٹوکیلاؤ",
        "kor": "토켈라우"
      }
    },
    "TO": {
      "currency": [
        "TOP"
      ],
      "callingCode": [
        "676"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-to",
      "name": {
        "common": "Tonga",
        "ces": "Tonga",
        "deu": "Tonga",
        "fra": "Tonga",
        "hrv": "Tonga",
        "ita": "Tonga",
        "jpn": "トンガ",
        "nld": "Tonga",
        "por": "Tonga",
        "rus": "Тонга",
        "slk": "Tonga",
        "spa": "Tonga",
        "fin": "Tonga",
        "est": "Tonga",
        "zho": "汤加",
        "pol": "Tonga",
        "urd": "ٹونگا",
        "kor": "통가"
      }
    },
    "TT": {
      "currency": [
        "TTD"
      ],
      "callingCode": [
        "1868"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-tt",
      "name": {
        "common": "Trinidad and Tobago",
        "ces": "Trinidad a Tobago",
        "deu": "Trinidad und Tobago",
        "fra": "Trinité-et-Tobago",
        "hrv": "Trinidad i Tobago",
        "ita": "Trinidad e Tobago",
        "jpn": "トリニダード・トバゴ",
        "nld": "Trinidad en Tobago",
        "por": "Trinidade e Tobago",
        "rus": "Тринидад и Тобаго",
        "slk": "Trinidad a Tobago",
        "spa": "Trinidad y Tobago",
        "fin": "Trinidad ja Tobago",
        "est": "Trinidad ja Tobago",
        "zho": "特立尼达和多巴哥",
        "pol": "Trynidad i Tobago",
        "urd": "ٹرینیڈاڈ و ٹوباگو",
        "kor": "트리니다드 토바고"
      }
    },
    "TN": {
      "currency": [
        "TND"
      ],
      "callingCode": [
        "216"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "flag": "flag-tn",
      "name": {
        "common": "Tunisia",
        "ces": "Tunisko",
        "deu": "Tunesien",
        "fra": "Tunisie",
        "hrv": "Tunis",
        "ita": "Tunisia",
        "jpn": "チュニジア",
        "nld": "Tunesië",
        "por": "Tunísia",
        "rus": "Тунис",
        "slk": "Tunisko",
        "spa": "Túnez",
        "fin": "Tunisia",
        "est": "Tuneesia",
        "zho": "突尼斯",
        "pol": "Tunezja",
        "urd": "تونس",
        "kor": "튀니지"
      }
    },
    "TR": {
      "currency": [
        "TRY"
      ],
      "callingCode": [
        "90"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-tr",
      "name": {
        "common": "Turkey",
        "ces": "Turecko",
        "deu": "Türkei",
        "fra": "Turquie",
        "hrv": "Turska",
        "ita": "Turchia",
        "jpn": "トルコ",
        "nld": "Turkije",
        "por": "Turquia",
        "rus": "Турция",
        "slk": "Turecko",
        "spa": "Turquía",
        "fin": "Turkki",
        "est": "Türgi",
        "zho": "土耳其",
        "pol": "Turcja",
        "urd": "ترکی",
        "kor": "터키"
      }
    },
    "TM": {
      "currency": [
        "TMT"
      ],
      "callingCode": [
        "993"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "flag": "flag-tm",
      "name": {
        "common": "Turkmenistan",
        "ces": "Turkmenistán",
        "deu": "Turkmenistan",
        "fra": "Turkménistan",
        "hrv": "Turkmenistan",
        "ita": "Turkmenistan",
        "jpn": "トルクメニスタン",
        "nld": "Turkmenistan",
        "por": "Turquemenistão",
        "rus": "Туркмения",
        "slk": "Turkménsko",
        "spa": "Turkmenistán",
        "fin": "Turkmenistan",
        "est": "Türkmenistan",
        "zho": "土库曼斯坦",
        "pol": "Turkmenistan",
        "urd": "ترکمانستان",
        "kor": "투르크메니스탄"
      }
    },
    "TC": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1649"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-tc",
      "name": {
        "common": "Turks and Caicos Islands",
        "ces": "Turks a Caicos",
        "deu": "Turks-und Caicosinseln",
        "fra": "Îles Turques-et-Caïques",
        "hrv": "Otoci Turks i Caicos",
        "ita": "Isole Turks e Caicos",
        "jpn": "タークス・カイコス諸島",
        "nld": "Turks-en Caicoseilanden",
        "por": "Ilhas Turks e Caicos",
        "rus": "Теркс и Кайкос",
        "slk": "Turks a Caicos",
        "spa": "Islas Turks y Caicos",
        "fin": "Turks-ja Caicossaaret",
        "est": "Turks ja Caicos",
        "zho": "特克斯和凯科斯群岛",
        "pol": "Turks i Caicos",
        "urd": "جزائر کیکس و ترکیہ",
        "kor": "터크스 케이커스 제도"
      }
    },
    "TV": {
      "currency": [
        "AUD"
      ],
      "callingCode": [
        "688"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-tv",
      "name": {
        "common": "Tuvalu",
        "ces": "Tuvalu",
        "deu": "Tuvalu",
        "fra": "Tuvalu",
        "hrv": "Tuvalu",
        "ita": "Tuvalu",
        "jpn": "ツバル",
        "nld": "Tuvalu",
        "por": "Tuvalu",
        "rus": "Тувалу",
        "slk": "Tuvalu",
        "spa": "Tuvalu",
        "fin": "Tuvalu",
        "est": "Tuvalu",
        "zho": "图瓦卢",
        "pol": "Tuvalu",
        "urd": "تووالو",
        "kor": "투발루"
      }
    },
    "UG": {
      "currency": [
        "UGX"
      ],
      "callingCode": [
        "256"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-ug",
      "name": {
        "common": "Uganda",
        "ces": "Uganda",
        "deu": "Uganda",
        "fra": "Ouganda",
        "hrv": "Uganda",
        "ita": "Uganda",
        "jpn": "ウガンダ",
        "nld": "Oeganda",
        "por": "Uganda",
        "rus": "Уганда",
        "slk": "Uganda",
        "spa": "Uganda",
        "fin": "Uganda",
        "est": "Uganda",
        "zho": "乌干达",
        "pol": "Uganda",
        "urd": "یوگنڈا",
        "kor": "우간다"
      }
    },
    "UA": {
      "currency": [
        "UAH"
      ],
      "callingCode": [
        "380"
      ],
      "region": "Europe",
      "subregion": "Eastern Europe",
      "flag": "flag-ua",
      "name": {
        "common": "Ukraine",
        "ces": "Ukrajina",
        "deu": "Ukraine",
        "fra": "Ukraine",
        "hrv": "Ukrajina",
        "ita": "Ucraina",
        "jpn": "ウクライナ",
        "nld": "Oekraïne",
        "por": "Ucrânia",
        "rus": "Украина",
        "slk": "Ukrajina",
        "spa": "Ucrania",
        "fin": "Ukraina",
        "est": "Ukraina",
        "zho": "乌克兰",
        "pol": "Ukraina",
        "urd": "یوکرین",
        "kor": "우크라이나"
      }
    },
    "AE": {
      "currency": [
        "AED"
      ],
      "callingCode": [
        "971"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-ae",
      "name": {
        "common": "United Arab Emirates",
        "ces": "Spojené arabské emiráty",
        "deu": "Vereinigte Arabische Emirate",
        "fra": "Émirats arabes unis",
        "hrv": "Ujedinjeni Arapski Emirati",
        "ita": "Emirati Arabi Uniti",
        "jpn": "アラブ首長国連邦",
        "nld": "Verenigde Arabische Emiraten",
        "por": "Emirados Árabes Unidos",
        "rus": "Объединённые Арабские Эмираты",
        "slk": "Spojené arabské emiráty",
        "spa": "Emiratos Árabes Unidos",
        "fin": "Arabiemiraatit",
        "est": "Araabia Ühendemiraadid",
        "zho": "阿拉伯联合酋长国",
        "pol": "Zjednoczone Emiraty Arabskie",
        "urd": "متحدہ عرب امارات",
        "kor": "아랍에미리트"
      }
    },
    "GB": {
      "currency": [
        "GBP"
      ],
      "callingCode": [
        "44"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-gb",
      "name": {
        "common": "United Kingdom",
        "ces": "Spojené království",
        "deu": "Vereinigtes Königreich",
        "fra": "Royaume-Uni",
        "hrv": "Ujedinjeno Kraljevstvo",
        "ita": "Regno Unito",
        "jpn": "イギリス",
        "nld": "Verenigd Koninkrijk",
        "por": "Reino Unido",
        "rus": "Великобритания",
        "slk": "Veľká Británia (Spojené kráľovstvo)",
        "spa": "Reino Unido",
        "fin": "Yhdistynyt kuningaskunta",
        "est": "Suurbritannia",
        "zho": "英国",
        "pol": "Zjednoczone Krłlestwo",
        "urd": "مملکتِ متحدہ",
        "kor": "영국"
      }
    },
    "US": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1"
      ],
      "region": "Americas",
      "subregion": "North America",
      "flag": "flag-us",
      "name": {
        "common": "United States",
        "ces": "Spojené státy",
        "deu": "Vereinigte Staaten",
        "fra": "États-Unis",
        "hrv": "Sjedinjene Američke Države",
        "ita": "Stati Uniti d'America",
        "jpn": "アメリカ合衆国",
        "nld": "Verenigde Staten",
        "por": "Estados Unidos",
        "rus": "Соединённые Штаты Америки",
        "slk": "Spojené štáty americké",
        "spa": "Estados Unidos",
        "fin": "Yhdysvallat",
        "est": "Ameerika Ühendriigid",
        "zho": "美国",
        "pol": "Stany Zjednoczone",
        "urd": "ریاستہائے متحدہ",
        "kor": "미국"
      }
    },
    "UM": {
      "currency": [
        "USD"
      ],
      "callingCode": [],
      "region": "Americas",
      "subregion": "North America",
      "flag": "flag-um",
      "name": {
        "common": "United States Minor Outlying Islands",
        "ces": "Menší odlehlé ostrovy USA",
        "deu": "Kleinere Inselbesitzungen der Vereinigten Staaten",
        "fra": "Îles mineures éloignées des États-Unis",
        "hrv": "Mali udaljeni otoci SAD-a",
        "ita": "Isole minori esterne degli Stati Uniti d'America",
        "jpn": "合衆国領有小離島",
        "nld": "Kleine afgelegen eilanden van de Verenigde Staten",
        "por": "Ilhas Menores Distantes dos Estados Unidos",
        "rus": "Внешние малые острова США",
        "slk": "Menšie odľahlé ostrovy USA",
        "spa": "Islas Ultramarinas Menores de Estados Unidos",
        "fin": "Yhdysvaltain asumattomat saaret",
        "est": "Ühendriikide hajasaared",
        "zho": "美国本土外小岛屿",
        "pol": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
        "urd": "امریکی چھوٹے بیرونی جزائر",
        "kor": "미국령 군소 제도"
      }
    },
    "VI": {
      "currency": [
        "USD"
      ],
      "callingCode": [
        "1340"
      ],
      "region": "Americas",
      "subregion": "Caribbean",
      "flag": "flag-vi",
      "name": {
        "common": "United States Virgin Islands",
        "ces": "Americké Panenské ostrovy",
        "deu": "Amerikanische Jungferninseln",
        "fra": "Îles Vierges des États-Unis",
        "hrv": "Američki Djevičanski Otoci",
        "ita": "Isole Vergini americane",
        "jpn": "アメリカ領ヴァージン諸島",
        "nld": "Amerikaanse Maagdeneilanden",
        "por": "Ilhas Virgens dos Estados Unidos",
        "rus": "Виргинские Острова",
        "slk": "Americké Panenské ostrovy",
        "spa": "Islas Vírgenes de los Estados Unidos",
        "fin": "Neitsytsaaret",
        "est": "Neitsisaared, USA",
        "zho": "美属维尔京群岛",
        "pol": "Wyspy Dziewicze Stanów Zjednoczonych",
        "urd": "امریکی جزائر ورجن",
        "kor": "미국령 버진아일랜드"
      }
    },
    "UY": {
      "currency": [
        "UYU"
      ],
      "callingCode": [
        "598"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-uy",
      "name": {
        "common": "Uruguay",
        "ces": "Uruguay",
        "deu": "Uruguay",
        "fra": "Uruguay",
        "hrv": "Urugvaj",
        "ita": "Uruguay",
        "jpn": "ウルグアイ",
        "nld": "Uruguay",
        "por": "Uruguai",
        "rus": "Уругвай",
        "slk": "Uruguaj",
        "spa": "Uruguay",
        "fin": "Uruguay",
        "est": "Uruguay",
        "zho": "乌拉圭",
        "pol": "Urugwaj",
        "urd": "یوراگوئے",
        "kor": "우루과이"
      }
    },
    "UZ": {
      "currency": [
        "UZS"
      ],
      "callingCode": [
        "998"
      ],
      "region": "Asia",
      "subregion": "Central Asia",
      "flag": "flag-uz",
      "name": {
        "common": "Uzbekistan",
        "ces": "Uzbekistán",
        "deu": "Usbekistan",
        "fra": "Ouzbékistan",
        "hrv": "Uzbekistan",
        "ita": "Uzbekistan",
        "jpn": "ウズベキスタン",
        "nld": "Oezbekistan",
        "por": "Uzbequistão",
        "rus": "Узбекистан",
        "slk": "Uzbekistan",
        "spa": "Uzbekistán",
        "fin": "Uzbekistan",
        "est": "Usbekistan",
        "zho": "乌兹别克斯坦",
        "pol": "Uzbekistan",
        "urd": "ازبکستان",
        "kor": "우즈베키스탄"
      }
    },
    "VU": {
      "currency": [
        "VUV"
      ],
      "callingCode": [
        "678"
      ],
      "region": "Oceania",
      "subregion": "Melanesia",
      "flag": "flag-vu",
      "name": {
        "common": "Vanuatu",
        "ces": "Vanuatu",
        "deu": "Vanuatu",
        "fra": "Vanuatu",
        "hrv": "Vanuatu",
        "ita": "Vanuatu",
        "jpn": "バヌアツ",
        "nld": "Vanuatu",
        "por": "Vanuatu",
        "rus": "Вануату",
        "slk": "Vanuatu",
        "spa": "Vanuatu",
        "fin": "Vanuatu",
        "est": "Vanuatu",
        "zho": "瓦努阿图",
        "pol": "Vanuatu",
        "urd": "وانواتو",
        "kor": "바누아투"
      }
    },
    "VA": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "3906698",
        "379"
      ],
      "region": "Europe",
      "subregion": "Southern Europe",
      "flag": "flag-va",
      "name": {
        "common": "Vatican City",
        "ces": "Vatikán",
        "deu": "Vatikanstadt",
        "fra": "Cité du Vatican",
        "hrv": "Vatikan",
        "ita": "Città del Vaticano",
        "jpn": "バチカン市国",
        "nld": "Vaticaanstad",
        "por": "Cidade do Vaticano",
        "rus": "Ватикан",
        "slk": "Vatikán",
        "spa": "Ciudad del Vaticano",
        "fin": "Vatikaani",
        "est": "Vatikan",
        "zho": "梵蒂冈",
        "pol": "Watykan",
        "urd": "ویٹیکن سٹی",
        "kor": "바티칸"
      }
    },
    "VE": {
      "currency": [
        "VEF"
      ],
      "callingCode": [
        "58"
      ],
      "region": "Americas",
      "subregion": "South America",
      "flag": "flag-ve",
      "name": {
        "common": "Venezuela",
        "ces": "Venezuela",
        "deu": "Venezuela",
        "fra": "Venezuela",
        "hrv": "Venezuela",
        "ita": "Venezuela",
        "jpn": "ベネズエラ・ボリバル共和国",
        "nld": "Venezuela",
        "por": "Venezuela",
        "rus": "Венесуэла",
        "slk": "Venezuela",
        "spa": "Venezuela",
        "fin": "Venezuela",
        "est": "Venezuela",
        "zho": "委内瑞拉",
        "pol": "Wenezuela",
        "urd": "وینیزویلا",
        "kor": "베네수엘라"
      }
    },
    "VN": {
      "currency": [
        "VND"
      ],
      "callingCode": [
        "84"
      ],
      "region": "Asia",
      "subregion": "South-Eastern Asia",
      "flag": "flag-vn",
      "name": {
        "common": "Vietnam",
        "ces": "Vietnam",
        "deu": "Vietnam",
        "fra": "Viêt Nam",
        "hrv": "Vijetnam",
        "ita": "Vietnam",
        "jpn": "ベトナム",
        "nld": "Vietnam",
        "por": "Vietname",
        "rus": "Вьетнам",
        "slk": "Vietnam",
        "spa": "Vietnam",
        "fin": "Vietnam",
        "est": "Vietnam",
        "zho": "越南",
        "pol": "Wietnam",
        "urd": "ویتنام",
        "kor": "베트남"
      }
    },
    "WF": {
      "currency": [
        "XPF"
      ],
      "callingCode": [
        "681"
      ],
      "region": "Oceania",
      "subregion": "Polynesia",
      "flag": "flag-wf",
      "name": {
        "common": "Wallis and Futuna",
        "ces": "Wallis a Futuna",
        "deu": "Wallis und Futuna",
        "fra": "Wallis-et-Futuna",
        "hrv": "Wallis i Fortuna",
        "ita": "Wallis e Futuna",
        "jpn": "ウォリス・フツナ",
        "nld": "Wallis en Futuna",
        "por": "Wallis e Futuna",
        "rus": "Уоллис и Футуна",
        "slk": "Wallis a Futuna",
        "spa": "Wallis y Futuna",
        "fin": "Wallis ja Futuna",
        "est": "Wallis ja Futuna",
        "zho": "瓦利斯和富图纳群岛",
        "pol": "Wallis i Futuna",
        "urd": "والس و فتونہ",
        "kor": ""
      }
    },
    "EH": {
      "currency": [
        "MAD",
        "DZD",
        "MRO"
      ],
      "callingCode": [
        "212"
      ],
      "region": "Africa",
      "subregion": "Northern Africa",
      "flag": "flag-eh",
      "name": {
        "common": "Western Sahara",
        "ces": "Západní Sahara",
        "deu": "Westsahara",
        "fra": "Sahara Occidental",
        "hrv": "Zapadna Sahara",
        "ita": "Sahara Occidentale",
        "jpn": "西サハラ",
        "nld": "Westelijke Sahara",
        "por": "Saara Ocidental",
        "rus": "Западная Сахара",
        "slk": "Západná Sahara",
        "spa": "Sahara Occidental",
        "fin": "Länsi-Sahara",
        "est": "Lääne-Sahara",
        "zho": "西撒哈拉",
        "pol": "Sahara Zachodnia",
        "urd": "مغربی صحارا",
        "kor": "서사하라"
      }
    },
    "YE": {
      "currency": [
        "YER"
      ],
      "callingCode": [
        "967"
      ],
      "region": "Asia",
      "subregion": "Western Asia",
      "flag": "flag-ye",
      "name": {
        "common": "Yemen",
        "ces": "Jemen",
        "deu": "Jemen",
        "fra": "Yémen",
        "hrv": "Jemen",
        "ita": "Yemen",
        "jpn": "イエメン",
        "nld": "Jemen",
        "por": "Iémen",
        "rus": "Йемен",
        "slk": "Jemen",
        "spa": "Yemen",
        "fin": "Jemen",
        "est": "Jeemen",
        "zho": "也门",
        "pol": "Jemen",
        "urd": "یمن",
        "kor": "예멘"
      }
    },
    "ZM": {
      "currency": [
        "ZMW"
      ],
      "callingCode": [
        "260"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-zm",
      "name": {
        "common": "Zambia",
        "ces": "Zambie",
        "deu": "Sambia",
        "fra": "Zambie",
        "hrv": "Zambija",
        "ita": "Zambia",
        "jpn": "ザンビア",
        "nld": "Zambia",
        "por": "Zâmbia",
        "rus": "Замбия",
        "slk": "Zambia",
        "spa": "Zambia",
        "fin": "Sambia",
        "est": "Sambia",
        "zho": "赞比亚",
        "pol": "Zambia",
        "urd": "زیمبیا",
        "kor": "잠비아"
      }
    },
    "ZW": {
      "currency": [
        "ZWL"
      ],
      "callingCode": [
        "263"
      ],
      "region": "Africa",
      "subregion": "Eastern Africa",
      "flag": "flag-zw",
      "name": {
        "common": "Zimbabwe",
        "ces": "Zimbabwe",
        "deu": "Simbabwe",
        "fra": "Zimbabwe",
        "hrv": "Zimbabve",
        "ita": "Zimbabwe",
        "jpn": "ジンバブエ",
        "nld": "Zimbabwe",
        "por": "Zimbabwe",
        "rus": "Зимбабве",
        "slk": "Zimbabwe",
        "spa": "Zimbabue",
        "fin": "Zimbabwe",
        "est": "Zimbabwe",
        "zho": "津巴布韦",
        "pol": "Zimbabwe",
        "urd": "زمبابوے",
        "kor": "짐바브웨"
      }
    },
    "AX": {
      "currency": [
        "EUR"
      ],
      "callingCode": [
        "358"
      ],
      "region": "Europe",
      "subregion": "Northern Europe",
      "flag": "flag-ax",
      "name": {
        "common": "Åland Islands",
        "ces": "Ålandy",
        "deu": "Åland",
        "fra": "Ahvenanmaa",
        "hrv": "Ålandski otoci",
        "ita": "Isole Aland",
        "jpn": "オーランド諸島",
        "nld": "Ålandeilanden",
        "por": "Alândia",
        "rus": "Аландские острова",
        "slk": "Alandy",
        "spa": "Alandia",
        "fin": "Ahvenanmaa",
        "est": "Ahvenamaa",
        "zho": "奥兰群岛",
        "pol": "Wyspy Alandzkie",
        "urd": "جزائر اولند",
        "kor": "올란드 제도"
      }
    }
  },
  countryCodeList: [
    'AF',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AQ',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BV',
    'BR',
    'IO',
    'VG',
    'BN',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'BQ',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CX',
    'CC',
    'CO',
    'KM',
    'CK',
    'CR',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'CD',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'SZ',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'GF',
    'PF',
    'TF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GP',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'GY',
    'HT',
    'HM',
    'HN',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'CI',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'XK',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'YT',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NC',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'KP',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'CG',
    'RO',
    'RU',
    'RW',
    'RE',
    'BL',
    'SH',
    'KN',
    'LC',
    'MF',
    'PM',
    'VC',
    'WS',
    'SM',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'GS',
    'KR',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SJ',
    'SE',
    'CH',
    'SY',
    'ST',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TL',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'US',
    'UM',
    'VI',
    'UY',
    'UZ',
    'VU',
    'VA',
    'VE',
    'VN',
    'WF',
    'EH',
    'YE',
    'ZM',
    'ZW',
    'KI',
    'HK',
    'AX',
  ]
}

export default data;