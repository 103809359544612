import { autorun, observable, toJS } from "mobx";
import locations from "../data/Locations.js";
import Store from "./Store.js";
import data from "../data/Events";
import moment from "moment";
import { rrulestr } from 'rrule';

class EventStore extends Store {
  // Event Calendar
  @observable pages = {
    events: {
      data: null,
      offset: 0,
      total: 0,
      count_featured: 0,
      filterValues: {},
      sortingValues: {
        sort: "date_start",
        order: "desc"
      },
    },
    pastEvents: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: null
    },
    allocatedProjects: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        search: ""
      }
    },
    reports: {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
      }
    }
  }
  @observable rsvpList = {
    all: {
      data: [],
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      }
    },
    attending: {
      data: [],
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      }
    },
    declined: {
      data: [],
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      }
    },
    canceled: {
      data: [],
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      }
    },
    no_response: {
      data: [],
      offset: 0,
      total: 0,
      count: 0,
      filterValues: {
        search: ""
      }
    }
  };
  @observable currentEvent = null;
  @observable currentRSVP = null;
  @observable insightsWithRSVP = {
    clicksTotal: null,
    clicksUnique: null
  };

  // Project Calendar
  @observable currentBooking = null;
  @observable calendarEvents = [];
  @observable bookingList = {
    data: null,
    offset: 0,
    total: 0
  }

  // Other
  @observable eventCalendarCounters = {};

  constructor() {
    super();
    this.types = data.categories;
    this.projectCalenarTypes = data.projectCalenarTypes;
    this.timeFilter = data.timeFilter;
    this.countries = locations;
    this.rsvpStatuses = data.rsvpStatuses;
    this.decisions = {
      all: "",
      attending: "yes",
      declined: "no",
      no_response: "maybe"
    };
    this.eventStatusColors = {
      work_order: "#aa62e3",
      event: "#67ccb5",
      asset: "#21aaea",
      contractor: "#e8374f",
      user: "#fd9a00",
      maintenance: "#d543a3",
      defect: "#7812DD",
      default: "#67ccb5"
    };
  }

  setData = (clientId, projectId, token) => {
    if (projectId) {
      this.setStoreData(token, `clients/${clientId}/projects/${projectId}/events`);
    } else {
      this.setStoreData(token, `global/events`);
    }
    
    this.calendarUrl = `${this.url}clients/${clientId}/projects/${projectId}/calendar`;
  }

  getUserUrl = (userId = 0) => {
    return this.apiUrl.replace("/events", `/users/${userId}/events`);
  }

  setPage = (page, params = {}, type = null) => {
    if (type && type == "categories") {
      this.categories.offset = page * this.itemsPerPage;
      return this.setCategories(params);
    } else if (type && type == "rsvp") {
      this.rsvpList[params.status].offset = page * this.itemsPerPage;
      return this.setRSVP({
        status: params.status,
        search: this.rsvpList[params.status].filterValues.search
      });
    } else if (type && type == "pastEvents") {
      this.pages.pastEvents.offset = page * this.itemsPerPage;
      return this.setEvents(params, "pastEvents");
    } else if (type && type == "reports") {
      this.pages.reports.offset = page * this.itemsPerPage;
      return this.setReports(params);
    } else {
      this.pages.events.offset = page * this.itemsPerPage;
      return this.setEvents(params);
    }
  }

  setFilters = (params) => {
    this.pages.events.filters = params;
  }

  setEvents = (params = {}, type = "events") => {
    params.offset = this.pages[type].offset;
    if (this.pages[type].filterValues) {
      params = Object.assign(this.pages[type].filterValues, params);
      params.limit = this.itemsPerPage;
    }

    if (this.pages[type].sortingValues) {
      params = Object.assign(params, this.pages[type].sortingValues);
    }

    return this.setList(null, params)
    .then(response => {
      this.pages[type].data = response.data.data;
      this.pages[type].total = response.data.count;
      this.pages[type].count_featured = response.data.count_featured;
      return response;
    });
  }

  setEventsByUser = (params = {}, userId) => {
    params.offset = this.rsvpList.all.offset;
    const url = this.getUserUrl(userId);

    return this.setList(url, params)
    .then(response => {
      this.rsvpList.all.data = response.data.data;
      this.rsvpList.all.total = response.data.count;
    });
  }

  setEvent = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentEvent = response.data;
      return response;
    });
  }

  setCurrentEvent = (event) => {
    this.currentEvent = event;
  }

  resetCurrentEvent = () => {
    this.currentEvent = null;
  }

  getCurrentEvent = () => {
    let data = {
      dateStart: moment(this.currentEvent.date_start).format("YYYY-MM-DD"),
      timeStart: moment(this.currentEvent.date_start).format("HH:mm"),
      dateEnd: moment(this.currentEvent.date_end).format("YYYY-MM-DD"),
      timeEnd: moment(this.currentEvent.date_end).format("HH:mm")
    };

    return Object.assign(toJS(this.currentEvent), data);
  }

  addEvent = (values) => {
    return this.addItem(values)
    .then(r => this.setEvents()); 
  }

  editEvent = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentEvent = response.data;
      this.setEvent(this.currentEvent.id);
      return response;
    });
  }

  deleteEvent = () => {
    return this.deleteItem(this.currentEvent.id);
  }

  importEvents = (clientId, projectId, ids) => {
    const url = `${this.url}clients/${clientId}/projects/${projectId}/attach/events`;
    return this.addItem({ ids: ids }, url);
  }

  setSortingValues = (params = {}) => {
    this.pages.events.sortingValues = params;
    return this.setEvents();
  }

  // RSVP List
  getRSVPUrl = () => {
    return `${this.apiUrl}/${this.currentEvent.id}/rsvp`;
  }

  setAllRSVP = () => {
    const statuses = [ "all", "attending", "declined", "canceled" ];        
    let promises = [];

    statuses.forEach(status => {
      promises.push(this.setRSVP({ status: status }));
    });

    return this.all(promises);
  }

  setRSVP = (params) => {
    params.offset = this.rsvpList[params.status].offset;
    params.decision = params.status != "all" ? params.status : "";
    return this.setList(this.getRSVPUrl(), params)
    .then(response => {
      this.rsvpList[params.status].data = response.data.data;
      this.rsvpList[params.status].total = response.data.count;
      this.rsvpList[params.status].count = response.data.count;
    });
  }

  setCurrentRSVP = (item) => {
    this.currentRSVP = item;
  }

  resetCurrentRSVP = () => {
    this.currentRSVP = null;
  }

  addRSVP = (values) => {
    return this.addItem(values, this.getRSVPUrl())
    .then(r => this.setAllRSVP());
  }

  editRSVP = (values) => {
    return this.editItem(values, this.getRSVPUrl())
    .then(response => {
      this.currentRSVP = response.data;
      this.setAllRSVP();
    });
  }

  exportRSVP = () => {
    return this.setList(`${this.apiUrl}/${this.currentEvent.id}/export/rsvp`)
    .then(response => response.data);
  }

  // Project Calendar
  setBookings = (sortObj) => {
    return this.setList(this.calendarUrl, sortObj)
    .then(response => {
      this.bookingList.data = response.data.data;
      return response;
    });
  }

  setBookingById = (id) => {
    return this.setItem(id, this.calendarUrl)
    .then(response => {
      this.currentBooking = response.data;
    });
  }

  setCurrentBooking = (booking) => {
    this.currentBooking = booking;
  }

  resetCurrentBooking = () => {
    this.currentBooking = null;
  }

  addBooking = (values) => {
    return this.addItem(values, this.calendarUrl);
  }

  editBooking = (values) => {
    return this.editItem(values, this.calendarUrl);
  }

  deleteBooking = (id) => {
    return this.deleteItem(id || this.currentBooking.id, this.calendarUrl);
  }

  deleteBookingsByType = (response, field) => {
    let params = {};
    params[field] = response.data.id;

    return this.deleteItems(params, this.calendarUrl.replace('calendar', 'berserk/calendar'))
    .then(r => response);
  }

  addCalendarEvents = (events) => {
    console.log(this.calendarEvents);
    this.calendarEvents = this.calendarEvents.concat(events);
    console.log(this.calendarEvents);
  }

  prepareEvents = (data, calendarDates = null) => {
    let events = [];

    data.forEach(item => {
      let event = item;
      let start = moment(item.date_start).format('HH:mm');
      event.status = item.status;
      event.start = item.date_start;
      event.end = item.date_end;
      event.color = item.event_type ? this.eventStatusColors[item.event_type] : this.eventStatusColors.default;
      event.title = `${start} - ${item.title}`;
      event.html = item.html;

      if (item.rrule_string && item.rrule_string.indexOf("undefined") != -1) {
        return false;
      }

      if (item.is_recurring == 1 && item.rrule_string && item.rrule_string.indexOf("DTSTART") != -1 && calendarDates) {
        let rrule_string = item.rrule_string;

        if (rrule_string.indexOf(";UNTIL=100000") != -1) {
          rrule_string = rrule_string.slice(0, rrule_string.indexOf(";UNTIL=100000"));
        }

        const rule = rrulestr(rrule_string);
        const dates = rule.between(moment(calendarDates.date_start).add(-1, "days").toDate(), moment(calendarDates.date_end).add(1, "days").toDate());
        const timeDuration = moment.duration(moment(item.date_end).diff(moment(item.date_start)));
        const hourDuration = timeDuration.hours();

        dates.forEach((date, index) => {
          const eventCopy = JSON.parse(JSON.stringify(event));
          const end_time = moment(date).add(hourDuration, 'hours');

          eventCopy.start = `${moment(date).format("MM/DD/YYYY")} ${start}`;
          eventCopy.end = `${end_time.format("MM/DD/YYYY")} ${end_time.format("HH:mm")}`;
          events.push(eventCopy);
        });
      } else {
        events.push(event);
      }
    });

    this.calendarEvents = events;
  }

  // Allocated projects
  getProjectUrl = () => {
    return `${this.url}global/events/${this.currentEvent.id}/allocated`;
  }

  setAllocatedProjects = (params = {}) => {
    if (this.pages.allocatedProjects.filters) {
      params = Object.assign(params, this.pages.allocatedProjects.filters);
    }
    params.offset = this.pages.allocatedProjects.offset;
    
    return this.setList(this.getProjectUrl(), params)
    .then(response => {
      this.pages.allocatedProjects.data = response.data.data;
      this.pages.allocatedProjects.total = response.data.count;
    });
  }

  // Reporting
  setReportsPage = (page, params = {}) => {
    const offset = page * (params.limit ? params.limit : this.itemsPerPage);
    this.pages.reports.offset = offset;
    params = {...this.pages.reports.filterValues};

    return this.setReports(params);
  }

  setReports = (params = {}) => {
    params = Object.assign(this.pages.reports.filterValues, params);
    params.offset = this.pages.reports.offset;

    return this.setList(null, params)
    .then(response => {
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count;       
    });
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filterValues: {
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    if (values.limit) {
      delete values.limit;
    }
    
    this.pages.reports.filterValues = values;
  }

  // Other
  setEventCalendarCounters = (clientId = null, projectId = null) => {
    const clientUrl = clientId ? `clients/${clientId}` : "";
    const projectUrl = projectId ? `/projects/${projectId}` : "";

    return this.setList(`${this.url}${clientUrl}${projectUrl}/counters/events`)
    .then(response => {
      this.eventCalendarCounters = response.data;
    })
    .catch(e => console.error(e));
  }

  setInsightsWithRSVP = (type, response) => {
    this.insightsWithRSVP[type] = response.data.data;
  }

  exportEventsPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/events", "/export/pdf/events")
    )
    .finally(this.hideLoader);
  }
}

const store = window.EventStore = new EventStore;
export default store;
autorun(() => {
    // console.log(store);
});