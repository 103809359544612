import { autorun, observable, toJS } from "mobx";
import { NotificationManager } from 'react-notifications';
import data from "../data/Stages";
import Helper from "../helpers/Helper";
import Store from "./Store";

class StageStore extends Store {
  @observable pages = {
    stages: {
      data: null,
      offset: 0,
      total: 0
    },
    addresses: {
      data: null,
      offset: 0,
      total: 0
    },
    notes: {
      data: null,
      offset: 0,
      total: 0
    },
    documents: {
      data: null,
      offset: 0,
      total: 0
    },
    ownerHistory: {
      data: null,
      offset: 0,
      total: 0
    }
  };
  @observable currentStage = null;
  @observable currentAddress = null;
  @observable currentOwner = null;
  @observable currentDocument = null;
  @observable categoryCount = 1;
  @observable chozenCategoryesIds = [];
  @observable categoriesValues = [];

  constructor() {
    super();
    this.stageTypes = data.stageTypes;
    this.t = Helper.translate;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/stages`);
  }

  setPage = (page, type = "stages", settings = {}) => {
    this.pages[type].offset = page * this.itemsPerPage;

    if (type == "stages") {
      return this.setStages();
    } else if (type == "addresses") {
      return this.setAddresses(settings);
    } else if (type == "ownerHistory") {
      return this.setOwners();
    }
  }

  setStages = (params = {}) => {
    let data = {
      offset: this.pages.stages.offset
    };

    return this.setList(null, params ? Object.assign(data, params) : data)
    .then(response => {
      this.pages.stages.data = response.data.data;
      this.pages.stages.total = response.data.count;
    });
  }

  setStage = (id) => {
    return this.setItem(id)
    .then(response => {
      this.currentStage = response.data;
      return response;
    });
  }

  setCurrentStage = (stage) => {
    this.currentStage = stage;
  }

  getCurrentStage = () => {
    return toJS(this.currentStage);
  }

  resetCurrentStage = () => {
    this.currentStage = null;
  }

  addStage = (values) => {
    return this.addItem(values)
    .then(r => this.setStages());
  }

  editStage = (values) => {
    return this.editItem(values)
    .then(response => {
      this.currentStage = response.data;
      return this.setStage(this.currentStage.id);
    });
  }

  deleteStage = () => {
    return this.deleteItem(this.currentStage.id)
    .then(r => {
      this.currentStage = null;
      return this.setStages();
    });
  }
  
  searchStages = (value) => {
    return this.setStages({ 
      search: value,
      limit: 100
    });
  }

  // Addresses
  getAddressUrl = () => {
    return `${this.apiUrl.replace("/stages", "")}/addresses`;
  }

  setAddresses = (params = {}) => {
    params.offset = this.pages.addresses.offset;
    // params.entity_id = this.currentStage ? this.currentStage.id : null;
    
    return this.setList(this.getAddressUrl(), params)
    .then(response => {
      this.pages.addresses.data = response.data.data;
      this.pages.addresses.total = response.data.count;
      return response;
    });
  }

  setAddressById = (id) => {
    return this.setItem(id, this.getAddressUrl())
    .then(response => {
      this.currentAddress = response.data;      
      return response;
    });
  }

  getAddresses = () => {
    return toJS(this.pages.addresses.data);
  }

  getCurrentAddress = () => {
    return toJS(this.currentAddress);
  }

  setCurrentAddress = (department) => {
    this.currentAddress = department;
  }

  resetCurrentAddress = () => {
    this.currentAddress = null;
  }

  addAddress = (values) => {
    return this.addItem( values, this.getAddressUrl())
    .then(r => this.setAddresses());
  }

  editAddress = (values) => {
    return this.editItem(values, this.getAddressUrl())
    .then(response => {
      this.currentAddress = response.data;
      return this.setAddresses();
    });
  }

  deleteAddress = () => {
    return this.deleteItem(this.currentAddress.id, this.getAddressUrl())
    .then(r => {
      this.currentAddress = null;
    });
  }

  searchAddresses = (value, entityId = null) => {
    return this.setList(this.getAddressUrl(), { 
      search: value,
      entity_id: entityId,
      limit: 100
    })
    .then(response => {
      this.pages.addresses.data = response.data.data;
      return response;
    });
  }

  // Department categories
  setCategoryData = (count, ids, values) => {
    this.categoryCount = count;
    this.chozenCategoryesIds = ids;
    this.categoriesValues = values;
  }

  setCategoryValue = (value, index) => {
    if (this.categoriesValues.indexOf(value) == -1) {
      this.categoriesValues[index] = value;
    } else {
      NotificationManager.error(this.t("This category was choosen before."));
    }
  }

  addCategory = () => {
    let count = this.categoryCount;
    if (this.categoriesValues[count - 1]) {
      this.categoryCount = ++count;
    } else {
      NotificationManager.error(this.t("Please select category."));
    }
  }

  assignCategories = (values) => {
    return this.addItem(values, `${this.apiUrl.replace("/stages", "")}/bulk-categories-sub-entities`);
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl.replace("/stages", "")}/documents/address/${this.currentAddress.id}`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.currentDocument = document;
  }

  resetCurrentDocument = () => {
    this.currentDocument = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.currentDocument.id, this.getDocumentsUrl())
    .then(r => {
      this.currentDocument = null;
      return this.setDocuments();
    });
  }

  // Owner History
  getOwnerUrl = () => {
    return `${this.apiUrl.replace("/stages", "")}/addresses/${this.currentAddress.id}/owners`;
  }

  setOwners = (params = {}) => {
    params.offset = this.pages.ownerHistory.offset;

    return this.setList(this.getOwnerUrl(), params)
    .then(response => {                    
      this.pages.ownerHistory.data = response.data.data;
      this.pages.ownerHistory.total = response.data.count; 
    });
  }

  setCurrentOwner = (note) => {
    this.currentOwner = note;
  }

  resetCurrentOwner = () => {
    this.currentOwner = null;
  }

  addOwner = (values) => {
    return this.addItem(values, this.getOwnerUrl())
    .then(r => this.setOwners());
  }

  editOwner = (values) => {
    return this.editItem(values, this.getOwnerUrl())
    .then(r => this.setOwners());
  }

  deleteOwner = () => {
    return this.deleteItem(this.currentOwner.id, this.getOwnerUrl())
    .then(r => this.setOwners());
  }
}

const store = window.StageStore = new StageStore;

export default store;

autorun(() => {
    // console.log(store);
});