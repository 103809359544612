import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import posthog from "posthog-js";
import App from 'components/App';
import stores from './stores/index';
import settings from "./data/Settings.json";

import createBrowserHistory from 'history/createBrowserHistory';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';

const rootEl = document.getElementById('root');
const renderComponent = Component => {
  const browserHistory = createBrowserHistory(),
        routingStore = new RouterStore(),
        history = syncHistoryWithStore(browserHistory, routingStore);
  
  window.appHistory = syncHistoryWithStore(browserHistory, routingStore);

  if (location.host != "localhost:8080") {
    posthog.init(settings.POSTHOG_KEY, { 
      api_host: settings.POSTHOG_HOST,
      loaded: (posthog) => {
        if (env === 'dev') posthog.debug();
      }
    });
  }

  ReactDOM.render(
    <AppContainer>
      <Router history={history}> 
        <Provider {...stores}>          
          <Component />        
        </Provider>
      </Router>
    </AppContainer>,
    rootEl,
  );
};

renderComponent(App);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./components/App', () => {
    renderComponent(App);
  });
}
