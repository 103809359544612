import { RouterStore } from 'mobx-react-router';
import AccessStore from './AccessStore';
import AuthStore from './AuthStore';
import BookingStore from './BookingStore';
import NavigationStore from './NavigationStore';
import KeysFobsStore from './KeysFobsStore';
import HelpDeskStore from './HelpDeskStore';
import DeliveryStore from './DeliveryStore';
import NewsStore from './NewsStore';
import DocumentStore from './DocumentStore';
import CaseStore from './CaseStore';
import StaffStore from './StaffStore';
import ClientStore from './ClientStore';
import ProjectStore from './ProjectStore';
import AlertStore from './AlertStore';
import ContractorStore from './ContractorStore';
import AssetsStore from './AssetsStore';
import PreloaderStore from './PreloaderStore';
import UserStore from './UserStore';
import BuildingStore from './BuildingStore';
import FacilityStore from './FacilityStore';
import LocalizationStore from './LocalizationStore';
import FaqStore from './FaqStore';
import LocalAreaStore from './LocalAreaStore';
import BusinessStore from './BusinessStore';
import EmailStore from './EmailStore';
import OwnersCorpStore from './OwnersCorpStore';
import EventStore from './EventStore';
import CompanyStore from './CompanyStore';
import ContactStore from './ContactStore';
import DirectoryStore from './DirectoryStore';
import ServiceStore from './ServiceStore';
import SurveysPollsStore from './SurveysPollsStore';
import OnlineFormsStore from './OnlineFormsStore';
import CategoryStore from './CategoryStore';
import IncidentStore from './IncidentStore';
import StageStore from './StageStore';
import MaintenanceStore from './MaintenanceStore';
import MemberKeysStore from './MemberKeysStore';
import ReportsStore from './ReportsStore';
import ShiftLogsStore from './ShiftLogsStore';
import OffersStore from './OffersStore';
import KeyOrdersStore from './KeyOrdersStore';
import HelpCentreStore from './HelpCentreStore';
import SafeTrustStore from './SafeTrustStore';
import LockersStore from './LockersStore';
import NotificationStore from './NotificationStore';
import NoticeboardStore from './NoticeboardStore';
import SocialGroupsStore from './SocialGroupsStore';
import ActivityLogsStore from './ActivityLogsStore';
import DefectsStore from './DefectsStore';

const RoutingStore = new RouterStore();

const stores = {
  RoutingStore,
  AccessStore,
  AuthStore,
  BookingStore,
  CategoryStore,
  NavigationStore,
  KeysFobsStore,
  KeyOrdersStore,
  HelpDeskStore,
  DeliveryStore,
  NewsStore,
  DocumentStore,
  CaseStore,
  StaffStore,
  ClientStore,
  ProjectStore,
  AlertStore,
  ContractorStore,
  AssetsStore,
  PreloaderStore,
  UserStore,
  BuildingStore,
  FacilityStore,
  LocalizationStore,
  FaqStore,
  LocalAreaStore,
  BusinessStore,
  EmailStore,
  OwnersCorpStore,
  EventStore,
  CompanyStore,
  ContactStore,
  DirectoryStore,
  ServiceStore,
  SurveysPollsStore,
  OnlineFormsStore,
  IncidentStore,
  StageStore,
  MaintenanceStore,
  MemberKeysStore,
  ReportsStore,
  ShiftLogsStore,
  OffersStore,
  HelpCentreStore,
  SafeTrustStore,
  LockersStore,
  NotificationStore,
  NoticeboardStore,
  SocialGroupsStore,
  ActivityLogsStore,
  DefectsStore
};

export default stores;