import { translate } from "../helpers/Helper";

const t = translate;
const data = {
  types: {
    survey: t("All surveys"),
    poll: t("All polls")
  },
  categories: {
		"amenities": t("Amenities"),
    "common_area": t("Common Area"),
    "facilities": t("Facilities"),
    "indemnity_forms": t("Indemnity Forms"),
    "inductions": t("Inductions"),    
    "meetings": t("Meetings"),
    "order_forms": t("Order Forms"),
    "private_property": t("Private Property"),
    "request_forms": t("Request Forms"),
    "services": t("Services"),
		"other": t("Other")
  }
};
export default data;