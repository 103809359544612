import { autorun, observable } from "mobx";
import Store from "./Store";
import axios from "axios";

class SocialGroupsStore extends Store {
  @observable pages = {
    groups: {
      current: null,

      all: {
        data: [],
        offset: 0,
        total: 0,
        filterValues: {
          search: ""
        }
      },
      public: {
        data: [],
        offset: 0,
        total: 0,
        filterValues: {
          search: ""
        }
      },
      private: {
        data: [],
        offset: 0,
        total: 0,
        filterValues: {
          search: ""
        }
      },
    },
    members: {
      current: null,
      data: [],
      offset: 0,
      total: 0,
      filterValues: {
        search: ""
      }
    },
    posts: {
      current: null,
      data: [],
      offset: 0,
      total: 0,
      filterValues: {
        search: ""
      }
    },
    comments: {
      current: null,
      data: [],
      offset: 0,
      total: 0
    },
    documents: {
      current: null,
      data: null,
      offset: 0,
      total: 0
    },
  };

  constructor(props) {
    super(props);
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/clubs`);
  }

  setPage = (page, type = "groups", subtype = "all", callback = "setGroups", params = {}) => {
    let data = !subtype ? this.pages[type] : this.pages[type][subtype];
    const offset = page * this.itemsPerPage
    
    data.offset = offset;
    return this[callback](params || { type: subtype, offset: offset });   
  }

  setGroupsPage = (page, type = "all") => {
    return this.setGroups({
      type: type
    });
  }

  setGroups = (params = {}) => {
    const type = params.type || "all";
    params.offset = this.pages.groups[type].offset;

    if (type != "all") {
      params.is_private = type == "private" ? "1" : "0";
    }

    return this.setList(this.apiUrl, params)
    .then(response => {
      this.pages.groups[type].data = response.data.data;
      this.pages.groups[type].total = response.data.count;
    });
  }

  setGroup = (id) => {
    return this.setItem(id)
    .then(response => {
      this.pages.groups.current = response.data;
      return response;
    })
  }

  setCurrentGroup = (group) => {
    this.pages.groups.current = group;
  }

  resetCurrentGroup = () => {
    this.pages.groups.current = null;
  }

  addGroup = (values) => {
    return this.addItem(values)
    .then(r => this.setGroups());
  }

  editGroup = (values) => {
    return this.editItem(values)
    .then(response => {
      this.setCurrentGroup(response.data);
      return this.setGroups();
    });
  }

  deleteGroup = () => {
    return this.deleteItem(this.pages.groups.current.id)
    .then(r => {
      this.resetCurrentGroup();
      return this.setGroups();
    })
  }

  // Members
  getMembersUrl = () => {
    const group = this.pages.groups.current;
    return `${this.apiUrl}/${group ? group.id : 0}/members`;
  }

  setMembers = (params = {}) => {
    return this.setList(this.getMembersUrl(), params)
    .then(response => {
      this.pages.members.data = response.data.data;
      this.pages.members.total = response.data.count;
    });
  }

  setMember = (id) => {
    return this.setItem(id, this.getMembersUrl())
    .then(response => {
      this.pages.members.current = response.data;
      return response;
    })
  }

  setCurrentMember = (member) => {
    this.pages.members.current = member;
  }

  resetCurrentMember = () => {
    this.pages.members.current = null;
  }

  addMember = (values) => {
    return this.addItem(values, this.getMembersUrl())
    .then(r => this.setMembers());
  }

  editMember = (values) => {
    return this.editItem(values, this.getMembersUrl())
    .then(response => {
      this.setCurrentMember(response.data);
      return this.setMembers();
    });
  }

  deleteMember = () => {
    return this.deleteItem(this.pages.members.current.id, this.getMembersUrl())
    .then(r => {
      this.resetCurrentMember();
      return this.setMembers();
    })
  }

  // Posts
  getPostsUrl = () => {
    const group = this.pages.groups.current;
    return `${this.apiUrl}/${group ? group.id : 0}/entries`;
  }

  setPosts = (params = {}) => {
    params.offset = this.pages.posts.offset;

    return this.setList(this.getPostsUrl(), params)
    .then(response => {
      this.pages.posts.data = response.data.data;
      this.pages.posts.total = response.data.count;
    });
  }

  setPost = (id) => {
    return this.setItem(id, this.getPostsUrl())
    .then(response => {
      this.pages.posts.current = response.data;
      return response;
    });
  }

  setCurrentPost = (post) => {
    this.pages.posts.current = post;
  }

  resetCurrentPost = () => {
    this.pages.posts.current = null;
  }

  addPost = (values) => {
    return this.addItem(values, this.getPostsUrl())
    .then(r => this.setPosts());
  }

  editPost = (values) => {
    return this.editItem(values, this.getPostsUrl())
    .then(response => {
      this.setCurrentPost(response.data);
      return this.setPosts();
    });
  }

  deletePost = () => {
    return this.deleteItem(this.pages.posts.current.id, this.getPostsUrl())
    .then(r => {
      this.resetCurrentPost();
      return this.setPosts();
    })
  }

  likePost = (id) => {
    return this.addItem({ id: id }, `${this.getPostsUrl()}/${id}/like`)
    .then(response => {
      this.setCurrentPost(response.data);
      return response;
    });
  }

  dislikePost = (id) => {
    this.showLoader();
    return axios.delete(`${this.getPostsUrl()}/${id}/like`, {
      headers: { 'Authorization': this.token }
    })
    .then(response => {
      this.setCurrentPost(response.data);
      return response;
    })
    .finally(this.hideLoader);
  }

  // Comments
  setComments = (params = {}) => {
    params.offset = this.pages.comments.offset;

    return this.setList(this.getPostsUrl(), params)
    .then(response => {
      this.pages.comments.data = response.data.data;
      this.pages.comments.total = response.data.count;
    });
  }

  setCurrentComment = (comment) => {
    this.pages.comments.current = comment;
  }

  resetCurrentComment = () => {
    this.pages.comments.current = null;
  }

  deleteComment = () => {
    return this.deleteItem(this.pages.comments.current.id, this.getPostsUrl())
    .then(r => this.resetCurrentComment());
  }

  // Documents
  getDocumentsUrl = () => {
    return `${this.apiUrl.replace("/clubs", "")}/documents/clubs/${this.pages.groups.current.id}`;
  }

  setDocumentsPage = (page) => {
    this.pages.documents.offset = page * this.itemsPerPage;
    this.setDocuments();
  }

  setDocuments = (params = {}) => {
    params.offset = this.pages.documents.offset;

    return this.setList(this.getDocumentsUrl(), params)
    .then(response => {
      this.pages.documents.data = response.data.data;
      this.pages.documents.total = response.data.count;
    });
  }

  setCurrentDocument = (document) => {
    this.pages.documents.current = document;
  }

  resetCurrentDocument = () => {
    this.pages.documents.current = null;
  }

  addDocuments = (values) => {
    return this.addItem(values, this.getDocumentsUrl()).then(r => this.setDocuments());
  }

  deleteDocument = () => {
    return this.deleteItem(this.pages.documents.current.id, this.getDocumentsUrl())
    .then(r => {
      this.pages.documents.current = null;
      return this.setDocuments();
    });
  }
}

const store = window.SocialGroupsStore = new SocialGroupsStore;

export default store;

autorun(() => {
  // console.log(store);
});