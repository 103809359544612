import { NotificationManager } from 'react-notifications';
import { autorun, observable, toJS } from "mobx";
import Helper from "../helpers/Helper";
import data from "../data/Keys.js";
import Store from "./Store.js";

class MemberKeysStore extends Store {
  @observable pages = {
    memberKeys: {
      all: {
        data: null,
        offset: 0,
        total: 0,
        filters: {
          building: "",
          search: ""
        }
      },
      held: {
        data: null,
        offset: 0,
        total: 0,
        filters: {
          building: "",
          search: ""
        }
      },
      collected: {
        data: null,
        offset: 0,
        total: 0,
        filters: {
          building: "",
          search: ""
        }
      }
    },

    reports: {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        entity_id: "",
        status: "",
        date_start: null,
        date_end: null
      }
    },

    types: {
      data: null,
      offset: 0,
      total: 0
    }
  };

  @observable currentKey = null;
  @observable currentKeyType = null;
  @observable keyTypes = {
    count: 0,
    values: []
  };

  constructor() {
    super();
    this.t = Helper.translate;
    this.statuses = data.memberKeyStatuses;
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/members-keys`);
  }

  setPage = (page, type = "keys", subtype = "all") => {
    if (type == "keys") {
      this.pages.memberKeys[subtype].offset = page * this.itemsPerPage;
      this.setMemberKeys({ type: subtype });
    } else if (type == "types") {
      this.pages.types.offset = page * this.itemsPerPage;
      this.setKeyTypes();
    } else if (type == "reports") {
      this.pages.reports.offset = page * this.itemsPerPage;
      this.setReports();
    }
  }

  getMemberKeysUrl = () => {
    return `${this.apiUrl}/keys`;
  }

  setAllMemberKeys = () => {
    let promises = [];
    const requests = [ 'all', 'held', 'collected' ];

    requests.forEach(type => {
      promises.push(this.setMemberKeys({ 
        type: type
      }));
    });

    return this.all(promises);
  }

  setMemberKeys = (params = {}) => {
    const pageType = params.type;
    params.offset = this.pages.memberKeys[pageType].offset;
    params.status = pageType != "all" ? pageType : null;
    delete params.type;

    return this.setList(this.getMemberKeysUrl(), params)
    .then(response => {                    
      this.pages.memberKeys[pageType].data = response.data.data;
      this.pages.memberKeys[pageType].total = response.data.count;
    });
  }

  setMemberKey = (id) => {
    return this.setItem(id, this.getMemberKeysUrl())
    .then(response => {                    
      this.currentKey = response.data;
    });
  }

  addKey = (values) => {
    return this.addItem(values, this.getMemberKeysUrl())
    .then(r => this.setAllMemberKeys());
  }

  editKey = (values) => {
    return this.editItem(values, this.getMemberKeysUrl())
    .then(response => {
      this.currentKey = response.data;
      this.setAllMemberKeys();
    });
  }

  setCurrentKey = (key) => {
    this.currentKey = key;
  }

  getCurrentKey = () => {
    return toJS(this.currentKey);
  }

  resetCurrentKey = () => {
    this.currentKey = null;
  }

  deleteKey = (id) => {
    return this.deleteItem(id, this.getMemberKeysUrl())
    .then(r => this.setAllMemberKeys());
  }

  // Key Types
  getKeyTypeUrl = () => {
    return `${this.apiUrl}/types`;
  }

  setKeyTypes = (params = {}) => {
    params.offset = this.offsetKeyTypes;

    return this.setList(this.getKeyTypeUrl(), params)
    .then(response => {          
      this.pages.types.data = response.data.data;
      this.totalKeyTypes = response.data.count;
    });
  }

  getKeyTypes = () => {
    return this.pages.types.data;
  }

  getCurrentKeyType = () => {
    return toJS(this.currentKeyType);
  }

  setCurrentKeyType = (keyType) => {
    this.currentKeyType = keyType;
  }

  resetCurrentKeyType = () => {
    this.currentKeyType = null;
  }

  addKeyType = (values) => {
    return this.addItem(values, this.getKeyTypeUrl())
    .then(r => this.setKeyTypes());
  }

  editKeyType = (values) => {
    return this.editItem(values, this.getKeyTypeUrl())
    .then(r => this.setKeyTypes());
  }

  deleteKeyType = () => {
    return this.deleteItem(this.currentKeyType.id, this.getKeyTypeUrl())
    .then(r => {
      this.currentKeyType = null;
      return this.setKeyTypes();
    });
  }

  getKeyTypeById = (id) => {
    return this.keyTypes.find((type) => {
      return type.id == id;
    });
  }

  // Reports
  setReports = (params = {}) => {
    params.offset = this.pages.reports.offset;
    params.limit = this.itemsPerPage;

    return this.setList(`${this.apiUrl}/keys`, Object.assign(this.pages.reports.filters, params))
    .then(response => {                    
      this.pages.reports.data = response.data.data;
      this.pages.reports.total = response.data.count; 
    });
  }

  resetReports = () => {
    this.pages.reports = {
      data: null,
      offset: 0,
      total: 0,
      filters: {
        role: "",
        status: "",
        date_start: null,
        date_end: null
      }
    };
  }

  setFilters = (values) => {
    this.pages.reports.filters = values;
  }

  // Other
  setRepeatedData = (name, count, values) => {
    this[name].count = count;
    this[name].values = values;
  }

  setRepeatedValue = (name, value, index) => {
    if (this[name].values.indexOf(value) == -1) {
      this[name].values[index] = value;
    } else {
      NotificationManager.error(this.t("Please item was choosen before."));
    }
  }

  addRepeatedItem = (name) => {
    let count = this[name].count;
    if (this[name].values[count]) {
      this[name].count = ++count;
    } else {
      NotificationManager.error(this.t("Please select item."));
    }
  }

  deleteRepeatedItem = (name, index) => {
    this[name].values.splice(index, 1);
    this.setRepeatedData(name, --this[name].count, this[name].values);	
  }

  exportMemberKeysPDF = (params = {}) => {
    this.showLoader();

    return this.addItem(
      params,
      this.apiUrl.replace("/members-keys", "/export/pdf/keys/members-keys")
    )
    .finally(this.hideLoader);
  }
}

const store = window.MemberKeysStore = new MemberKeysStore;

export default store;

autorun(() => {
    // console.log(store);
});