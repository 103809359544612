import { autorun, observable, toJS } from "mobx";
import Store from "./Store";

class ContactStore extends Store {
  @observable pages = {
    contacts: {
      data: null,
      offset: 0,
      total: 0
    }
  }
  @observable currentContact = null;

  constructor() {
    super();
  }

  setData = (clientId, projectId, token) => {
    this.setStoreData(token, `clients/${clientId}/projects/${projectId}/contacts`);
  }

  setPage = (page) => {
    this.pages.contacts.offset = page * this.itemsPerPage;
    return this.setContacts();
  }

  setContacts = (sortObj = null) => {
    let params = {
      offset: this.pages.contacts.offset
    };

    return this.setList(null, sortObj ? Object.assign(params, sortObj) : params)
    .then(response => {
      this.pages.contacts.data = response.data.data;
      this.pages.contacts.total = response.data.count;
    });
  }

  setContact = (id) => {
    this.setItem(id)
    .then(response => {
      this.currentContact = response.data;
      return response;
    });
  }

  setCurrentContact = (contact) => {
    this.currentContact = contact;
  }

  resetCurrentContact = () => {
    this.currentContact = null;
  }

  addContact = (values) => {
    return this.addItem(values)
    .then(r => this.setContacts());
  }

  editContact = (values) => {
    return this.editItem(values)
    .then(response => {
      this.setCurrentContact(response.data);
      return this.setContacts();
    });
  }

  deleteContact = () => {
    return this.deleteItem(this.currentContact.id)
    .then(r => {
      this.currentContact = null;
      return this.setContacts();
    })
  }
}

const store = window.ContactStore = new ContactStore;

export default store;

autorun(() => {
    // console.log(store);
});